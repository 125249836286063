import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
// @material-ui/icons
// import  from "@material-ui/icons/";
// core components
import GridContainer from "../MaterialKitComponents/GridContainer.js";
import GridItem from "../MaterialKitComponents/GridItem.js";
import Card from "../MaterialKitComponents/Card.js";
import CardHeader from "../MaterialKitComponents/CardHeader.js";
import CardBody from "../MaterialKitComponents/CardBody.js";
import CardFooter from "../MaterialKitComponents/CardFooter.js";
import Muted from '../MaterialKitComponents/Muted.js'
import Button from "../MaterialKitComponents/Button.js";

import hover from '../../Assets/images/futuresoldier.jpg'


import teamsStyle from "../../Assets/teamsStyle.js";
import teamStyle from "../../Assets/teamStyle.js";

const style = {
  ...teamsStyle,
  ...teamStyle,
  justifyContentCenter: {
    justifyContent: "center"
  }
};

const useStyles = makeStyles(style);

export default function SectionLeft() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <Grid container direction='row-reverse' justify='center' alignItems='center' spacing={5}>
        <Grid item sm>
          <h2 className={classes.title}>Drive Development</h2>
          <h4 className={classes.description}>
            Our Community of Users accelerate the development of best-in-class technologies 
            by providing expert, actionable feedback. They anticipate unstated design requirements, 
            propose solutions to latent problems and provide feedback or guidance on existing projects
          </h4>
        </Grid>
        <Grid item>
          <Paper elevation={24} style={{borderRadius: '5%'}}>
            <img style={{borderRadius: '5%', maxWidth: '90vw'}} src={hover} width='500' />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
