import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import FlaggedComment from './FlaggedComment'

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
}))

function FlaggedComments({ flags }) {
    const classes = useStyles()
    const root = {
        textAlign: 'center',
        marginTop: 150,
    }
        const loader = {
        display: 'inlineBlock'
    }

    if (!flags) return <div style={root}><CircularProgress style={loader} /> </div>

    return (
        <div>
            {flags.map(flag => (
                <ExpansionPanel key={flag.id}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="flag-content"
                        id={flag.id}
                    >
                        <h5>{flag.type}</h5>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <FlaggedComment 
                            forum_doc_id={flag.forum_doc_id}
                            genre_doc_id={flag.genre_doc_id}
                            topic_doc_id={flag.topic_doc_id}
                            comment_doc_id={flag.comment_doc_id}
                            flagId={flag.id}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            ))}
        </div>
    )
}

export default compose(
    firestoreConnect((props) => [
      {
        collection: 'flag',
      },
    ]),
    connect((state, props) => ({
      flags: state.firestore.ordered.flag
    }))
)(FlaggedComments)