import React, { useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { resetPW } from '../../Redux/Actions/authActions'
import { connect } from 'react-redux'
import grey from '@material-ui/core/colors/grey'
import green from '@material-ui/core/colors/green'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
    paper: {
        height: 300,
        width: 500,
        padding: 60,
        color: grey[900],
        textAlign: 'center',
    },
    form: {
        width: 300
    },
    icon: {
        padding: theme.spacing(1),
        paddingLeft: 0,
        color: green[700],
    },
    successText: {
        padding: theme.spacing(1)
    }
}));

function LostPassword({ resetPW, resetEmailError, resetEmailSuccess }) {
    const classes = useStyles();
    const [email, setEmail] = useState('')

    const resetPassword = (e) => {
        e.preventDefault()
        if (email !== '') {
            resetPW(email)
        }
    }

    const handleChange = (event) => {
        setEmail(event.target.value)
    }

    return (
        <div style={{paddingTop: 215}}>
            <Container maxWidth='md'>
                <Grid container direction='column' justify='center' alignItems='center'>
                    <Paper className={classes.paper} elevation={2}>
                        <Grid container direction='column' justify='center' alignItems='center' >  
                            {resetEmailSuccess ? 
                                <Fragment>
                                    <Grid container direction='row' justify='center' alignItems='center' >
                                        <div className={classes.icon}>
                                            <CheckCircleIcon />
                                        </div>
                                        <div className={classes.successText}>
                                            <h4><b>Success!</b></h4>
                                        </div>
                                    </Grid>
                                    <p>A password reset email has been sent to <b>{email}</b></p>
                                </Fragment>
                            :
                                <Fragment>
                                    <h5>Enter your email</h5>
                                    <form noValidate  className={classes.form}>
                                        <TextField 
                                            error={resetEmailError ? true : false}
                                            id="email" 
                                            label="email" 
                                            variant="outlined"
                                            helperText={resetEmailError ? resetEmailError : null} 
                                            fullWidth='true'
                                            value={email}
                                            onChange={handleChange}
                                        />
                                    </form>
                                    <Button 
                                        color='primary' 
                                        variant='contained'
                                        onClick={resetPassword}
                                    >
                                        Submit
                                    </Button>
                                </Fragment>
                            }
                        </Grid>
                    </Paper>
                </Grid>
            </Container>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
      resetEmailSuccess: state.auth.resetEmailSuccess,
      resetEmailError: state.auth.resetEmailError,
    }
  }

const mapDispatchToProps = (dispatch) => {
    return {
      resetPW: (email) => {dispatch(resetPW(email))},
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LostPassword)