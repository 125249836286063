import React, { Fragment, useState } from 'react'
// react router
import { useParams, useHistory } from 'react-router-dom'
// redux
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector, useDispatch } from 'react-redux'
import { activateConversation } from '../../Redux/Actions/chatActions'
import { grantContract } from '../../Redux/Actions/jobActions'
// material ui
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Chip from '@material-ui/core/Chip'
import CheckIcon from '@material-ui/icons/Check'
// local components
import Author from '../Components/ForumComponents/Author'

function DisplayProposals(props) {

    // anchor for 'more' menu
    const [anchorEl, setAnchorEl] = useState(null)
    const [focusedProposal, setFocusedProposal] = useState(null)
    const [openHire, setOpenHire] = useState(false)
    const [loading, setLoading] = useState(false)
    // pull url params
    const { job_id } = useParams()

    // for routing
    const history = useHistory()

    // dispatch redux actions
    const dispatch = useDispatch()

    // fetch proposals for this job
    useFirestoreConnect([
        {
            collection: 'proposals',
            where: [
                ['job_id', '==', job_id]
            ],
            storeAs: `${job_id}-proposals`
        }
    ])
    const proposals = useSelector(state => state.firestore.ordered[`${job_id}-proposals`])

    if (!proposals) return (
        <div style={{paddingTop: 100, textAlign: 'center'}}>
            <CircularProgress />
        </div>
    )
    
    return (
        <div style={{paddingTop: 130, paddingBottom: 100, textAlign: 'center'}}>
            <Container maxWidth='md'>
                <Paper variant='outlined' square style={{backgroundColor: 'whitesmoke'}}>
                    <h3 style={{textAlign: 'left', paddingLeft: 10}}>Proposals</h3>
                </Paper>
                {proposals.map((p, index) => (
                    <Paper key={index} variant='outlined' square style={{padding: 10}}>
                        <h4 style={{textAlign: 'left'}}><b>
                            <Author authorId={p.owner} link/>
                            {p.hired && p.hired == true ?
                                <Chip
                                    style={{marginLeft: 10}}
                                    icon={<CheckIcon style={{color: 'green'}} />}
                                    label={`Hired`}
                                    variant='outlined'               
                                />
                                :
                                null
                            }
                        </b></h4> 
                        <h5 style={{textAlign: 'left'}}><b>{`Cover`}</b></h5>
                        <p style={{textAlign: 'left'}}>{p.proposal.cover}</p>
                        
                        <h5 style={{textAlign: 'left'}}><b>{`Questions`}</b></h5>
                        {Object.keys(p.proposal.clientQuestions).map(key => {
                            return (
                                <Fragment key={key}>
                                    <p style={{textAlign: 'left'}}><b>{p.proposal.clientQuestions[key].question}</b></p>
                                    <p style={{textAlign: 'left'}}>{p.proposal.clientQuestions[key].answer}</p>
                                </Fragment>
                            )
                        })}
                        
                        <h5 style={{textAlign: 'left'}}><b>{`Terms`}</b></h5>
                        <Grid container direction='row' justify='space-between'>
                            <Grid item>
                                <p style={{textAlign: 'left'}}>{`$${p.proposal.hourly}/hour`}</p>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant='outlined'
                                    style={{marginRight: 10}}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        const user = {
                                            id: p.owner
                                        }
                                        dispatch(activateConversation(user))
                                    }}
                                >
                                    Send a Message
                                </Button>
                                <IconButton
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setAnchorEl(e.currentTarget)
                                        setFocusedProposal(p)
                                    }}
                                >
                                    <MoreHorizIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Paper>
                ))}
            </Container>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => {
                    setAnchorEl(null)
                    setFocusedProposal(null)
                }}
            >
                {focusedProposal && (!focusedProposal.hired || (focusedProposal.hire && focusedProposal.hired == false)) ? 
                <MenuItem onClick={(e) => {
                    e.preventDefault()
                    setAnchorEl(null)
                    setOpenHire(true)
                }}>Hire</MenuItem>
                : null }
                <MenuItem onClick={(e) => {
                    e.preventDefault()
                    setAnchorEl(null)
                    history.push(`/profile/${focusedProposal.owner}`)
                }}>
                    View Profile
                </MenuItem>
            </Menu>
            <Dialog
                open={openHire}
                onClose={() => {
                    setOpenHire(false)
                }}
            >
                {focusedProposal ? 
                    <div style={{float: 'left', paddingLeft: 20, paddingRight: 20}}>
                        <h3 style={{float: 'left'}}>Hire </h3>
                        <h3 style={{float: 'left', paddingLeft: 3}}><Author authorId={focusedProposal.owner} /></h3>
                    </div>
                    :
                    null
                }
                <DialogContent>
                
                </DialogContent>
                <DialogActions>
                <Button 
                    onClick={() => {
                        setOpenHire(false)
                    }} 
                    color="secondary"
                >
                    Cancel
                </Button>
                <Button 
                    style={{width: 100, height: 35}}
                    onClick={() => {
                        setOpenHire(false)
                        setLoading(true)
                        dispatch(grantContract(focusedProposal))
                            .then(() => {
                                setLoading(false)
                            })
                            .catch((e) => {
                                setLoading(false)
                                console.error(e)
                            })
                    }} 
                    color="primary" 
                    variant='outlined'
                    autoFocus
                >
                    {loading ?
                        <CircularProgress size='20px' />
                        :
                        `Confirm`
                    }
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DisplayProposals