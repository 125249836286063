import React, { useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import RightIcon from '@material-ui/icons/KeyboardArrowRight'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import Author from '../Components/ForumComponents/Author'
// react router
import { useParams } from 'react-router-dom'
// moment
import moment from 'moment'
// redux
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: '#f5f5f5',
    },
    body: {
        paddingLeft: 20,
        paddingTop: 20,
    },
    header: {
        paddingLeft: 20,
    },
    smallForm: {
        width: 150,
    },
    form: {
       width: 500,
    },
}))

const styles = (theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
})

function ViewTime(props) {
    // styles
    const classes = useStyles()

    // URL params
    const { user_id, job_id } = useParams()

    useFirestoreConnect([
        {
            collection: 'users',
            doc: user_id,
            subcollections: [
                {
                    collection: 'active_contracts',
                    doc: job_id,
                }
            ],
            storeAs: `${job_id}-contract`
        }
    ])
    const contractList = useSelector(state => state.firestore.ordered[`${job_id}-contract`])

    // get the times logged for this job
    useFirestoreConnect([
        {
            collection: 'contract_time',
            doc: job_id,
            subcollections: [
                {
                    collection: 'workers',
                    doc: user_id,
                    subcollections: [
                        {
                            collection: 'timecard'
                        }
                    ]
                }
            ],
            storeAs: `${job_id}-time`
        }
    ])
    const timecard = useSelector(state => state.firestore.ordered[`${job_id}-time`])

    // log time state
    const [dateSelected, setDateSelected] = useState(moment())
    const [startTime, setStartTime] = useState(null)
    const [endTime, setEndTime] = useState(null)
    const [workDescription, setWorkDescription] = useState('')
    const [totalTime, setTotalTime] = useState(0)

    // handlers
    const increaseDate = (e) => {
        e.preventDefault()
        if (!isCurrentDay()) {
            let dayAfter = moment(dateSelected).add(1, 'days')
            setDateSelected(dayAfter)
        }
    }

    const decreaseDate = (e) => {
        e.preventDefault()
        let dayBefore = moment(dateSelected).subtract(1, 'days')
        setDateSelected(dayBefore)
    }

    const isCurrentDay = () => {
        return dateSelected.isSame(moment(), 'day')
    }

    // function for filtering time entries by date selected
    const byDate = (entry) => {
        let startDate = entry.startTime.toDate()
        let endDate = entry.endTime.toDate()
        if (startDate.getMonth() == dateSelected.toDate().getMonth() && startDate.getDate() == dateSelected.toDate().getDate() && startDate.getFullYear() == dateSelected.toDate().getFullYear()) {
            return true
        }
        else {
            return false
        }
    }
    
    const getTimeForDate = (date) => {
        let minutes = 0
        if (timecard && timecard[0]) {
            timecard.map(entry => {
                let startDate = entry.startTime.toDate()
                let endDate = entry.endTime.toDate()
                if (startDate.getMonth() == date.getMonth() && startDate.getDate() == date.getDate() && startDate.getFullYear() == date.getFullYear()) {
                    let start = startDate.getTime()
                    let end = endDate.getTime()
                    minutes += (end - start) / (1000 * 60)
                }
            })
        }
        return minutes
    }

    useEffect(() => {
        if (timecard && timecard[0]) {
            let minutes = 0
            timecard.map(entry => {
                let start = entry.startTime.toDate().getTime()
                let end = entry.endTime.toDate().getTime()
                minutes += (end - start) / (1000 * 60)
            })
            setTotalTime(minutes)
        }

    }, [timecard])
    

    if (!contractList) return <CircularProgress />
    if (!contractList[0]) return <CircularProgress />
    const contract = contractList[0]

    return (
        <div style={{paddingTop: 100, paddingBottom: 50}}>
            <Container maxWidth='md'>
                <Paper>
                    <AppBar color='default' position='static'>
                        <h4 className={classes.header}><b>Log Time</b></h4>
                    </AppBar>
                    <Grid container direction='column' spacing={2} className={classes.body}>
                        <Grid item>
                            <h5><b>{contract.jobData.title}</b></h5>
                        </Grid>
                        <Grid item>
                            <p>{<Author authorId={contract.proposal.owner} />}</p>
                        </Grid>
                    </Grid>
                    <h3 style={{textAlign: 'center'}}>{`Total time for this job: ${moment.duration(totalTime, 'minutes').asHours().toFixed(2)} hrs`}</h3>
                    <hr />
                    <Grid container direction='column' justify='center' alignItems='center' spacing={2} >
                        <Grid item>
                            <Grid container direction='row' alignItems='center' justify='center' spacing={2}>
                                <Grid item>
                                    <IconButton onClick={decreaseDate}>
                                        <LeftIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <h4><b>{dateSelected.format('dddd, MMMM D, YYYY')}</b></h4>
                                </Grid>
                                <Grid item>
                                    <IconButton 
                                        onClick={increaseDate}
                                        disabled={isCurrentDay()}
                                    >
                                        <RightIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Display the times logged for the date selected */}
                    <div style={{padding: '0px 25px'}}>
                        {timecard && timecard.filter(byDate).map((entry, i) => (
                            <Paper 
                                key={i} 
                                square 
                                variant='outlined' 
                                style={{padding: 5, margin: 5, backgroundColor: 'whitesmoke'}}
                            >
                                <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                    <Grid item>
                                        <p>{`${moment(entry.startTime.toDate()).format('h:mm a')} - ${moment(entry.endTime.toDate()).format('h:mm a')}`}</p>
                                    </Grid>
                                    <Grid item xs>
                                        <ul>
                                            <li><p>{entry.workDescription}</p></li>
                                        </ul>
                                    </Grid>
                                </Grid>
                            </Paper>
                        ))}
                    </div>

                    <hr />
                    <Grid container direction='row' alignItems='center' spacing={2} className={classes.body}>
                        <Grid item>
                            <h3>{`Total time for ${dateSelected.format('ddd')}: ${moment.duration(getTimeForDate(dateSelected.toDate()), 'minutes').asHours().toFixed(2)} hrs`}</h3>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </div>
    )
}

export default ViewTime