export const addNewJob = (jobData) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firestore = getFirestore()
      const authId = getState().firebase.auth.uid
      return new Promise((resolve, reject) => {
        firestore.collection('active_jobs').doc()
            .set({
                jobData,
                timestamp: firestore.FieldValue.serverTimestamp(),
                owner: authId
            })
            .then(() => {
                resolve()
            })
            .catch(e => {
                reject(e)
            })  
      })
    }
  }

  export const updateJob = (jobData, docId) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firestore = getFirestore()
      const authId = getState().firebase.auth.uid
      return new Promise((resolve, reject) => {
        firestore.collection('active_jobs').doc(docId)
            .update({
                jobData,
            })
            .then(() => {
                resolve()
            })
            .catch(e => {
                reject(e)
            })  
      })
    }
  }

  export const deleteJob = (docId) => {
      return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()
        return new Promise((resolve, reject) => {
            firestore.collection('inactive_jobs').doc(docId)
                .delete()
                .then(() => {
                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
      }
  }

  export const archiveJob = (jobData, docId, timestamp) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firestore = getFirestore()
      const authId = getState().firebase.auth.uid
      const batch = firestore.batch()

      return new Promise((resolve, reject) => {
        firestore.collection('proposals').where('job_id', '==', docId).get()
          .then(snap => {
            let owners = []
            snap.forEach(doc => {
              owners.push([doc.data().owner, doc.data().id])
            })       
            
            // send a notification to each user who submitted a proposal
            while (owners.length > 0) {
              let propData = owners.shift()
              let userRef =  firestore.collection('users').doc(propData[0]).collection('notification').doc()
              let proposalRef = firestore.collection('proposals').doc(propData[1]) // points to that proposal doc
              batch.set(userRef, {
                createdAt: firestore.FieldValue.serverTimestamp(),
                type: 'job_removed',
                unread: true,
                jobData: jobData,
              })
              // update the proposal document to be marked as archived
              batch.update(proposalRef, {
                'archived': true
              })
            }
              
            // archive the job
            const jobRef = firestore.collection('active_jobs').doc(docId)
            batch.delete(jobRef)
            const newJobRef = firestore.collection('inactive_jobs').doc()
              batch.set(newJobRef, {
                  jobData,
                  owner: authId,
                  timestamp,
              })
  
              batch.commit()
                .then(() => {resolve()})
                .catch(e => {reject(e)})

          })
        
        
      })
    }
}

export const activateJob = (jobData, docId, timestamp) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firestore = getFirestore()
      const authId = getState().firebase.auth.uid
      const batch = firestore.batch()

      return new Promise((resolve, reject) => {
        const jobRef = firestore.collection('inactive_jobs').doc(docId)
            batch.delete(jobRef)
        const newJobRef = firestore.collection('active_jobs').doc()
            batch.set(newJobRef, {
                jobData,
                owner: authId,
                timestamp,
            })

            batch.commit()
              .then(() => {resolve()})
              .catch(e => {reject(e)})
      })
    }
}

export const setProposalData = (proposal) => {
  return { type: 'SET_PROPOSAL', proposal };
};

export const submitProposal = (proposal, jobId, job) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid
    const batch = firestore.batch()

    return new Promise((resolve, reject) => {
      const propRef = firestore.collection('proposals').doc()
      batch.set(propRef, {
          proposal,
          job_id: jobId,
          owner: authId,
          client: job.owner
      })

      const notifRef = firestore.collection('users').doc(job.owner).collection('notification').doc()
      batch.set(notifRef, {
        createdAt: firestore.FieldValue.serverTimestamp(),
        type: 'proposal_submitted',
        unread: true,
        job_id: jobId,
      })
      batch.commit()
      .then(() => {resolve()})
      .catch(e => {reject(e)})
    })
  }
}

export const grantContract = (proposal) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid

    // create refs
    let proposalRef = firestore.collection('proposals').doc(proposal.id)
    let activeJobRef = firestore.collection('active_jobs').doc(proposal.job_id)
    let userContractRef = firestore.collection('users').doc(proposal.owner).collection('active_contracts').doc()
    let clientContractRef = firestore.collection('users').doc(authId).collection('active_contracts').doc()
    let notifRef = firestore.collection('users').doc(proposal.owner).collection('notification').doc()


    return new Promise((resolve, reject) => {
      firestore.collection('proposals').where('job_id', '==', proposal.job_id).get()
      .then(snap => {
        var owners = []
        snap.forEach(doc => {
          owners.push([doc.data().owner, doc.data().id])
        })   

        firestore.runTransaction(transaction => {
          return transaction.get(activeJobRef)
            .then(activeJobDoc => {
              if (!activeJobDoc.exists) {
                  throw "Document does not exist!";
              }
              // send notification to all users who submitted proposal to this job
              while (owners.length > 0) {
                let propData = owners.shift()
                let userRef =  firestore.collection('users').doc(propData[0]).collection('notification').doc() // points to that users notification collection (new doc)
                let proposalRef = firestore.collection('proposals').doc(propData[1]) // points to that proposal doc
                if (owners[0] != proposal.owner) {
                  // send notification to user that the job is gone
                  transaction.set(userRef, {
                    createdAt: firestore.FieldValue.serverTimestamp(),
                    type: 'job_removed',
                    unread: true
                  })
                  // update the proposal document to be marked as archived
                  transaction.update(proposalRef, {
                    'archived': true
                  })
                }
                else {
                  owners.shift() // remove the first owner id in array
                }
              }

              // copy from active jobs to inactive
              var jobDocData = activeJobDoc.data()
              // put a hired flag on the proposal
              transaction.update(proposalRef, {
                'hired': true
              })
              // give contract to user
              transaction.set(userContractRef, {
                jobData: jobDocData.jobData,
                owner: authId,
                timestamp: firestore.FieldValue.serverTimestamp(),
                proposal: proposal
              })
              // activate contract for client
              transaction.set(clientContractRef, {
                jobData: jobDocData.jobData,
                jobId: activeJobRef.id,
                user: proposal.owner,
                timestamp: firestore.FieldValue.serverTimestamp(),
                proposal: proposal
              })
              // delete the active job
              transaction.delete(activeJobRef)
              // send notification to user that they have been hired
              transaction.set(notifRef, {
                createdAt: firestore.FieldValue.serverTimestamp(),
                type: 'contract_granted',
                unread: true,
                owner: authId,
              })
            })
        })
        .then(() => resolve())
        .catch((e) => reject(e))
      })
      .catch(e => {reject(e)})
    })
  }
}

export const deleteTimeEntry = (docId, jobId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid

    return new Promise((resolve, reject) => {
      firestore.collection('contract_time').doc(jobId).collection('workers').doc(authId).collection('timecard').doc(docId)
          .delete()
          .then(() => {resolve()})
          .catch(e => {reject(e)})
    })
  }
}

// the end user (SME) should call this when closing a contract with the client (business) who hired them
export const closeContractUserSide = (contract_id, job_id, client_id) => { 
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid
    const batch = firestore.batch()

    // Delete this contract
    const myContractRef = firestore.collection('users').doc(authId).collection('active_contracts').doc(contract_id)
    // new refs for closed contracts
    const myClosedContractRef = firestore.collection('users').doc(authId).collection('closed_contracts').doc()
    // notification ref
    let notifRef = firestore.collection('users').doc(client_id).collection('notification').doc()

    return new Promise((resolve, reject) => {
      firestore.collection('users').doc(client_id).collection('active_contracts').where('jobId', '==', job_id).get()
        .then(snap => {
          var client_contract = null
          var client_contract_id = null
          snap.forEach(doc => {
            var data = doc.data()
            client_contract_id = doc.id
            if (data.user === authId) {
              client_contract = data
            }
          })   

          firestore.runTransaction(transaction => {
            return transaction.get(myContractRef)
              .then(myContractDoc => {
                if (!myContractDoc.exists) {
                    throw "Document does not exist!";
                }
                const my_contract_data = myContractDoc.data()
                const my_contract_id = myContractDoc.id
                // copy data to closed_contract collection for each
                const clientClosedContractRef = firestore.collection('users').doc(client_id).collection('closed_contracts').doc()
                // copy client active contract to closed
                transaction.set(clientClosedContractRef, {
                  active_contract: client_contract,
                  active_contract_id: client_contract_id,
                  timestamp: firestore.FieldValue.serverTimestamp(),
                })
                // copy user active contract to closed
                transaction.set(myClosedContractRef, {
                  active_contract: my_contract_data,
                  active_contract_id: my_contract_id,
                  timestamp: firestore.FieldValue.serverTimestamp(),
                })
                // delete client active contract
                const clientActiveContractRef = firestore.collection('users').doc(client_id).collection('active_contracts').doc(client_contract_id)
                transaction.delete(clientActiveContractRef)
                // delete user active contract
                transaction.delete(myContractRef)
                // send notification to client that the contract has been closed
                transaction.set(notifRef, {
                  createdAt: firestore.FieldValue.serverTimestamp(),
                  type: 'contract_closed_by_client',
                  unread: true,
                  owner: authId,
                })
              })
          })
          .then(() => resolve())
          .catch((e) => {
            console.log(e)
            reject(e)
          })
        })
        .catch(e => {
          console.log(e)
          reject(e)
        })
    })
  }
}

// the client (business) should call this when closing contract with end user (SME) whom they hired
export const closeContractClientSide = (contract_id, job_id, user_id) => { 
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid
    console.log(contract_id)
    console.log(job_id)
    console.log(user_id)

    // Delete this contract
    const myContractRef = firestore.collection('users').doc(authId).collection('active_contracts').doc(contract_id)
    // new refs for closed contracts
    const myClosedContractRef = firestore.collection('users').doc(authId).collection('closed_contracts').doc()
    // notification ref (goes to end user (SME))
    let notifRef = firestore.collection('users').doc(user_id).collection('notification').doc()

    return new Promise((resolve, reject) => {
      firestore.collection('users').doc(user_id).collection('active_contracts').where('proposal.job_id', '==', job_id).get()
        .then(snap => {
          var user_contract = null
          var user_contract_id = null
          console.log('docs', snap.docs)
          snap.forEach(doc => {
            var data = doc.data()
            user_contract_id = doc.id
            if (data.owner === authId) {
              user_contract = data
            }
          })   

          firestore.runTransaction(transaction => {
            return transaction.get(myContractRef)
              .then(myContractDoc => {
                if (!myContractDoc.exists) {
                    throw "Document does not exist!";
                }
                const my_contract_data = myContractDoc.data()
                const my_contract_id = myContractDoc.id
                // copy data to closed_contract collection for each
                const userClosedContractRef = firestore.collection('users').doc(user_id).collection('closed_contracts').doc()
                // copy user active contract to closed
                transaction.set(userClosedContractRef, {
                  active_contract: user_contract,
                  active_contract_id: user_contract_id,
                  timestamp: firestore.FieldValue.serverTimestamp(),
                })
                // copy user active contract to closed
                transaction.set(myClosedContractRef, {
                  active_contract: my_contract_data,
                  active_contract_id: my_contract_id,
                  timestamp: firestore.FieldValue.serverTimestamp(),
                })
                // delete client active contract
                console.log('user_contract_id', user_contract_id)
                const userActiveContractRef = firestore.collection('users').doc(user_id).collection('active_contracts').doc(user_contract_id)
                transaction.delete(userActiveContractRef)
                // delete user active contract
                transaction.delete(myContractRef)
                // send notification to client that the contract has been closed
                transaction.set(notifRef, {
                  createdAt: firestore.FieldValue.serverTimestamp(),
                  type: 'contract_closed_by_business',
                  unread: true,
                  owner: authId,
                })
              })
          })
          .then(() => resolve())
          .catch((e) => {
            console.log(e)
            reject(e)
          })
        })
        .catch(e => {
          console.log(e)
          reject(e)
        })
    })
  }
}