import React, { Fragment, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import GoogleButton from '../Components/NavigationComponents/GoogleButton'
import UserAvatar from '../Components/UserComponents/UserAvatar'
import Author from '../Components/ForumComponents/Author'
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip'
import { calendarConfig } from '../../Config/CalendarConfig'
import { DateTime } from "luxon"


const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    title: {
        textAlign: 'center',
    },
    subtitle: {
        fontSize: 18,
    },
    paragraph: {
        fontSize: 18,
        paddingLeft: 20,
    },
    tech: {
        fontSize: 18,
        textAlign: 'center'
    },
    user: {
        paddingLeft: 20,
    },
    divider: {
        padding: 10,
    },
  }));


function Meeting(props) {
    const classes = useStyles()
    const meetingId = props.match.params.meeting_id
    const userId = props.match.params.user_id
    const gapi = window.gapi

    const handleAddEvent = (e) => {
        e.preventDefault()

        // sort times
        var times = meeting.times.map(time => {
            const date = new Date(time)
            return date.getTime()
        })
        times.sort((a, b) => (a > b) ? 1 : -1)
        const date1 = new Date(times[0])
        const beginTime = DateTime.fromJSDate(date1)
        var date2 = new Date(times[times.length-1])
        const thirtyMin = 1800000
        date2.setTime(date2.getTime() + thirtyMin)
        const endTime = DateTime.fromJSDate(date2)
        const timezone = moment.tz.guess()

        gapi.load('client:auth2', () => {
            
            gapi.client.init({
              apiKey: calendarConfig.API_KEY,
              clientId: calendarConfig.CLIENT_ID,
              discoveryDocs: calendarConfig.DISCOVERY_DOCS,
              scope: calendarConfig.SCOPES,
            })
      
            gapi.client.load('calendar', 'v3', () => {})
      
            gapi.auth2.getAuthInstance().signIn()
            .then(() => {
              var event = {
                'summary': 'Meeting with ' + (meeting.consultantName ? meeting.consultantName : 'Consultant'),
                'description': 'Agenda: ' + meeting.agenda,
                'start': {
                  'dateTime': beginTime,
                  'timeZone': timezone
                },
                'end': {
                  'dateTime': endTime,
                  'timeZone': timezone
                },
                'reminders': {
                  'useDefault': false,
                  'overrides': [
                    {'method': 'email', 'minutes': 24 * 60},
                    {'method': 'popup', 'minutes': 10}
                  ]
                }
              }
              
              var request = gapi.client.calendar.events.insert({
                'calendarId': 'primary',
                'resource': event
              })
              
              request.execute(function(event) {
                window.open(event.htmlLink, '_blank')
              })
            }).catch(e => {
              console.log('error', e)
            })
          })
    
    }

    const displayTimeInfo = () => {

        // sort times
        var times = meeting.times.map(time => {
            const date = new Date(time)
            return date.getTime()
        })
        times.sort((a, b) => (a > b) ? 1 : -1)
        const startTime = new Date(times[0])
        var endTime = new Date(times[times.length-1])
        const thirtyMin = 1800000
        endTime.setTime(endTime.getTime() + thirtyMin)
        const startTimeString = moment(startTime).format('LT')
        const endTimeString = moment(endTime).format('LT')
        const dayString = moment(startTime).format('MMMM Do YYYY')
        const dateArray = [startTime.getFullYear(), startTime.getMonth(), startTime.getDate()]
        const timezone = moment.tz.guess()
        const timezoneString = moment.tz(dateArray, timezone).format('z')
        
        return `${dayString} from ${startTimeString} - ${endTimeString} (${timezoneString ? timezoneString : ''})`
    }

    const displayOtherTimezoneInfo = () => {

        // sort times
        var times = meeting.times.map(time => {
            const date = new Date(time)
            return date.getTime()
        })
        times.sort((a, b) => (a > b) ? 1 : -1)

        const timezone = meeting.userTimezone
        const startTime = new Date(times[0])
        var endTime = new Date(times[times.length-1])
        const thirtyMin = 1800000
        endTime.setTime(endTime.getTime() + thirtyMin)
        const startTimeString = moment(startTime).tz(timezone).format('LT')
        const endTimeString = moment(endTime).tz(timezone).format('LT')
        const dayString = moment(startTime).tz(timezone).format('MMMM Do YYYY')
        const dateArray = [startTime.getFullYear(), startTime.getMonth(), startTime.getDate()]
        const timezoneString = moment.tz(dateArray, timezone).format('z')

        return `${dayString} from ${startTimeString} - ${endTimeString} (${timezoneString ? timezoneString : ''})`
    }

    useFirestoreConnect([
        { collection: 'users', 
          doc: userId,
          subcollections: [{ collection: 'notification', 
                             doc: meetingId,
                          }],
            storeAs: `${userId}-${meetingId}-meeting`
        }
    ])
    const meetingObj = useSelector(state => state.firestore.ordered[`${userId}-${meetingId}-meeting`])

    if (!meetingObj ) return <div />
    const meeting = meetingObj[0]

    return (
        <div style={{paddingTop: 115}}>
            <Container maxWidth='sm'>
                <Paper className={classes.paper}>
                    <h3 className={classes.title}><b>Meeting Details</b></h3>
                    <p className={classes.subtitle}><b>Time:</b></p>
                    <p style={{textAlign: 'center'}} className={classes.paragraph}>
                        {displayTimeInfo()}
                    </p>
                    {moment.tz.guess() !== meeting.userTimezone ?
                        <Fragment>
                            <p style={{textAlign: 'center'}} className={classes.paragraph}>and</p>
                            <Tooltip title={`Consultants Time Zone`}>
                                <p style={{textAlign: 'center'}} className={classes.paragraph}>
                                    {displayOtherTimezoneInfo()}
                                </p>
                            </Tooltip>
                        </Fragment>
                    :
                        null
                    }
                    <div className={classes.divider}/>
                    <p className={classes.subtitle}><b>With:</b></p>
                    <Grid container direction='row' justify='center' alignItems='center'>
                        <Grid item>
                            <UserAvatar userId={meeting.userId} />
                        </Grid>
                        <Grid item style={{paddingTop: 10}}>
                            <Author authorId={meeting.userId} link textStyles={classes.paragraph} />
                        </Grid>
                    </Grid>
                    <div className={classes.divider}/>
                    <p className={classes.subtitle}><b>Meeting Type:</b></p>
                    <p className={classes.tech}>{meeting.tech === 'Zoom Meeting' ? 'Video Meeting' : meeting.tech}</p>
                    <div className={classes.divider}/>
                    <p className={classes.subtitle}><b>Agenda:</b></p>
                    <p className={classes.paragraph}>{meeting.agenda}</p>
                    <Grid container direction='row' justify='flex-end' >
                        <GoogleButton clickHandler={handleAddEvent} />
                    </Grid>
                </Paper>
            </Container>
        </div>
    )
}

export default Meeting