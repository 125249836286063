import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'
import OutstandingPayments from '../Components/UserComponents/OutstandingPayments'
import PaidPayments from '../Components/UserComponents/PaidPayments'
import PaymentDetails from '../Components/UserComponents/PaymentDetails'
import FetchPaymentDetails from '../Components/UserComponents/FetchPaymentDetails'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(theme => ({
    background: {
        backgroundColor: grey[50],
        minHeight: '90vh',
    },
    requests: {
        padding: theme.spacing(2),
        maxHeight: '50vh',
    },
    details: {
        width: '50vw',
        minHeight: '50vh',
        padding: theme.spacing(4),
    }
}))

function PaymentRequest(props) {
    const classes = useStyles()
    // Collect the userId and optional docid from the url
    const userId = props.match.params.user_id
    const docId = props.match.params.doc_id
    // if there is no docId then load a dashboard for that users payment requests
    // If there is a docId then load that payment request as the central request, giving them options to accept and pay
    // Protect this route from anyone other than the owner of the account
    const { auth } = props

    const [selectedPayment, setSelectedPayment] = useState(null)

    const handleSelectedPayment = (payment) => {
        setSelectedPayment(payment)
    }

    if (!auth) return <div />

    if (!auth.uid) return <div />

    if (userId !== auth.uid) return <Redirect to='/'/>

    return (
        <div style={{paddingTop: 125}}>
            <Container maxWidth='lg' className={classes.background}>
                <Grid container direction='row' justify='flex-start' alignItems='flex-start' spacing={5} >
                    <Grid item>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <Paper className={classes.requests} >
                                    <h3 style={{textAlign: 'center'}}>Payment Requests</h3>
                                    <OutstandingPayments userId={userId} sendPaymentInfo={handleSelectedPayment}/>
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Paper className={classes.requests} >
                                    <h3 style={{textAlign: 'center'}}>Paid</h3>
                                    <PaidPayments userId={userId} sendPaymentInfo={handleSelectedPayment}/>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Paper className={classes.details}>
                            {selectedPayment ? 
                                <PaymentDetails payment={selectedPayment} />
                            :
                                (docId ? 
                                    <FetchPaymentDetails userId={userId} docId={docId} />
                                :
                                    <h3>Select a payment request from the list</h3>
                                )
                            }
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    }
}

export default connect(mapStateToProps)(PaymentRequest)