import React, { useState, Fragment } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Button from '../../MaterialKitComponents/Button'
import MUIButton from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash'
import { makeStyles } from '@material-ui/core/styles'
import red from '@material-ui/core/colors/red'
import Tooltip from '@material-ui/core/Tooltip'
import { restoreApp, deleteApp } from '../../../Redux/Actions/userActions'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import MuiAlert from '@material-ui/lab/Alert'
import TextField from '@material-ui/core/TextField'
import Author from '../ForumComponents/Author'
import GetEmail from '../UserComponents/GetEmail'
import GetResume from '../AdminComponents/GetResume'
import GetDD214 from '../AdminComponents/GetDD214'
//firebase
import firebase from 'firebase/app'


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1),
    },
    redPanel: {
        background: red[100]
    },
    main: {
        margin: "8px",
        padding: "24px",
    },
    text: {
        maxWidth: '600px',      
    },
}));

function DeniedApps({ deniedApps, restoreApp, deleteApp }) {
    const classes = useStyles()
    const preventDefault = event => event.preventDefault()
    const [openRestoreDialog, setOpenRestoreDialog] = useState(false)
    const [userToRestore, setUserToRestore] = useState(null)
    const [userToDelete, setUserToDelete] = useState(null)
    const [openRestoreSB, setOpenRestoreSB] = useState(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [textField, setTextField] = useState('')

    const handleChange = (event) => {
        setTextField(event.target.value)
    }

    const deleteAction = () => {

        // Individually delete each file in the users application folder
        firebase.storage().ref().child(`applications/${userToDelete}/`).listAll().then(result => {
            result.items.forEach(itemRef => {
                // All the items under listRef.
                const deleteRef = firebase.storage().ref().child(`applications/${userToDelete}/${itemRef.name}`)
                deleteRef.delete().then(() => {
                // File deleted successfully
                console.log('deleted successfully')
                }).catch(error => {
                console.log('failed to delete', error)
                })
            })
        }).catch(error => {
            console.log('error', error)
        })

        deleteApp(userToDelete)
        setOpenDeleteDialog(false)
        setUserToDelete(null)
        setTextField('')
    }

    const cancelDelete = () => {
        setOpenDeleteDialog(false)
        setUserToDelete(null)
        setTextField('')
    }

    const handleDelete = (event, userId) => {
        event.preventDefault()
        setUserToDelete(userId)
        setOpenDeleteDialog(true)
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenRestoreSB(false)
    }

    const restoreHandler = (event, userId) => {
        event.preventDefault()
        setUserToRestore(userId)
        setOpenRestoreDialog(true)
    }

    const cancelRestore = () => {
        setOpenRestoreDialog(false)
        setUserToRestore(null)
    }

    const restore = () => {
        restoreApp(userToRestore)
        setOpenRestoreDialog(false)
        setUserToRestore(null)
        setOpenRestoreSB(true)
    }

    const root = {
        textAlign: 'center',
        marginTop: 150,
      }
      const loader = {
        display: 'inlineBlock'
      }
    if (!deniedApps) {
        return <div style={root}><CircularProgress style={loader} /> </div>
    }

    return (
        <div style={{marginTop: 50}}>
            <div style={{textAlign: 'center'}}>
                <h3>Denied Applications</h3>
            </div>
            {deniedApps.map((app, index) => (
                <div key={index}>
                    <ExpansionPanel >
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className={classes.redPanel}
                        >
                            <Grid 
                                container
                                direction='row'
                                justify='space-between'
                                alignItems='center'
                            >
                                <Grid item>
                                    <p>{<Author authorId={app.id} textOnly />}</p>
                                </Grid>
                                <Grid item>
                                    <p>Applied {moment(app.appCompleteDate.toDate().toString()).calendar()} </p>
                                </Grid>
                            </Grid>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid 
                                container
                                direction='row'
                                justify='space-around'
                                alignItems='center'
                            >
                                <Grid item>
                                    <Grid 
                                        container
                                        direction='column'
                                        justify='flex-start'
                                        alignItems='flex-start'
                                    >
                                        <Grid item>
                                            <p>Branch: <b>{app.branchOfService}</b></p>
                                        </Grid>
                                        {app.dateOfSeparation ? 
                                            <Grid item>
                                                <p>Sep Date: <b>{moment(app.dateOfSeparation.toDate().toString()).format("MMM Do YYYY")}</b></p>
                                            </Grid>
                                        :
                                            null
                                        }
                                        {app.activeDuty === true ? 
                                            <Grid item>
                                                <p>Active Duty: <b>yes</b></p>
                                            </Grid>
                                        :
                                            null
                                        }
                                        <Grid item>
                                            <p>Job: <b>{app.job}</b></p>
                                        </Grid>
                                        {app.secondaryJob ? 
                                            <p>Secondary Job: <b>{app.secondaryJob}</b></p>
                                        :   
                                            null
                                        }
                                        <Grid item>
                                            <p>Rank: <b>{app.rank}</b></p>
                                        </Grid>
                                        <Grid item>
                                            <p>Skills: <b>{app.skills.join(', ')}</b></p>
                                        </Grid>
                                        <Grid item>
                                            <p>Email: <b>{<GetEmail userId={app.id} />}</b></p>
                                        </Grid>
                                        <Grid item>
                                            <GetDD214 userId={app.id} />
                                        </Grid>
                                        <Grid item>
                                            <GetResume userId={app.id} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        direction='column'
                                        justify='flex-start'
                                        alignItems='flex-start'
                                    >
                                        <Grid item>
                                            <Tooltip title='Restore to active applications' >
                                                <Button
                                                    simple
                                                    color='primary'
                                                    variant='contained'
                                                    className={classes.button}
                                                    startIcon={<RestoreFromTrashIcon />}
                                                    onClick={event => restoreHandler(event, app.id)}
                                                >
                                                    Restore
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item>
                                            <Tooltip title='Delete all data about this applicant'>
                                                <Button
                                                    simple
                                                    color='danger'
                                                    variant='contained'
                                                    className={classes.button}
                                                    startIcon={<DeleteIcon />}
                                                    onClick={event => handleDelete(event, app.id)}
                                                >
                                                    Delete
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
            ))}
            <Dialog
                open={openRestoreDialog}
                onClose={() => setOpenRestoreDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Restore to active application status?"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    This application will be restored to active status where it can then be approved. 
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <MUIButton onClick={cancelRestore} color="primary">
                    Cancel
                </MUIButton>
                <MUIButton onClick={restore} color="primary" autoFocus>
                    Restore
                </MUIButton>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                open={openRestoreSB}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
                    <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
                }
            >
                <Alert onClose={handleCloseSnackbar} severity="success">
                    Application Restored
                </Alert>
            </Snackbar>
            <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Delete this Application?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This action cannot be undone. Type in <b>delete</b> to delete.  
                    </DialogContentText>
                    <form className={classes.main} noValidate autoComplete="off">
                    <TextField 
                        id="title" 
                        label="delete" 
                        variant="outlined" 
                        fullWidth
                        className={classes.text}
                        value={textField}
                        onChange={handleChange}
                    />
                    </form>
                </DialogContent>
                <DialogActions>
                    <MUIButton 
                        id='cancel'
                        onClick={cancelDelete} 
                        color="primary"
                    >
                        Cancel
                    </MUIButton>
                    <MUIButton 
                        id='submit'
                        onClick={deleteAction} 
                        color="secondary"
                        disabled={'delete' !== textField}  
                    >
                        Delete
                    </MUIButton>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
      restoreApp: (userId) => {dispatch(restoreApp(userId))},
      deleteApp: (userId) => {dispatch(deleteApp(userId))},
    }
}

export default compose(
    firestoreConnect((props) => [
      {
        collection: 'application',
        where: [
            ['applicationComplete', '==', true],
            ['denied', '==', true],
          ],
          orderBy: [
            ['appCompleteDate', 'asc']
          ],
          storeAs: 'deniedApps'
      },
    ]),
    connect((state, props) => ({
      deniedApps: state.firestore.ordered.deniedApps
    }), mapDispatchToProps)
)(DeniedApps)