import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Badge from '@material-ui/core/Badge';
import Author from '../ForumComponents/Author'
import Container from '@material-ui/core/Container';
import moment from 'moment'
import Typography from '@material-ui/core/Typography';
import Redirect from 'react-router-dom'
import { Link, withRouter } from 'react-router-dom'
import blue from '@material-ui/core/colors/blue';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import Notification from './Notification'

const useStyles = makeStyles(theme => ({
  titleButton: {
    textDecoration: 'none',
    color: 'black',
    "&:hover": {
      color: grey[900],
      textDecoration: 'none'
    },
    "&:visited": {
      color: grey[900]
    },
    "&:active": {
      color: grey[900]
    },
    "&:link": {
      color: grey[900]
    },
    "&:link:active": {
      color: grey[900]
    },
    "&:visited:active": {
      color: grey[900]
    },
  },
  unreadStyle: {
    backgroundColor: blue[100],
  },
  adminUnreadStyle: {
    backgroundColor: green[200],
  }
}));


function AdminNotificationIcon({notifications, adminNotifications, markRead, markAppNotificationRead, authId}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.target);
  };

  const handleClickAway = (event) => {
    if (event.target !== anchorEl)
      setAnchorEl(null)
  }

  var open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined; 

  if (!notifications || !adminNotifications) return <IconButton aria-describedby={id} type="button"><NotificationsIcon  /></IconButton>

  // combine the notifications arrays
  const notificationsList = notifications.concat(adminNotifications)
  // sort in descending order
  notificationsList.sort((a, b) => (a.createdAt ? a.createdAt : a.completeDate < b.createdAt ? b.createdAt : b.completeDate) ? 1 : -1)

  /*
  * Count the number of new notifications that the user has
  */
  var newNotificationsCount = 0
  for (var i = 0; i < notificationsList.length; i++) {
    if (notificationsList[i].unread) {
      newNotificationsCount++
    }
  }
  const newNotifications = newNotificationsCount

  return (
    <div>
      <IconButton 
        aria-describedby={id} 
        type="button" 
        color='inherit'
        onClick={handleClick}
      >
        <Badge badgeContent={newNotifications} color="error">
          <NotificationsIcon  />
        </Badge>
      </IconButton>
        <Popper id={id} open={open} anchorEl={anchorEl} disablePortal placement='bottom'>
          <ClickAwayListener onClickAway={handleClickAway} >
            <Paper style={{marginTop: 15, maxHeight: 400, width: 600, maxWidth: '95vw', maxHeight: '90vh', overflow: 'auto'}}>
              <List disablePadding>
                {notificationsList.map(notification => (
                  <Fragment key={notification.id}>
                    <Notification notification={notification} authId={authId}/>
                  <Divider />
                  </Fragment>
                ))}
              </List>
            </Paper>
          </ClickAwayListener>
        </Popper>
    </div>
  );
}


export default compose(
  firestoreConnect((props) => [
    { collection: 'users', 
      doc: props.authId, 
      subcollections: [{collection: 'notification', orderBy: ['createdAt', 'desc'], limit: 30}],
      storeAs: `${props.userId}-notifications`
    },
    { collection: 'admin_notification', 
      orderBy: [
        ['completeDate', 'desc']
      ],
      limit: 30, // temporary until a better solution for not loading every notification that was ever made
      storeAs: 'adminNotifications'
    },
  ]),
  connect((state, props) => ({
    notifications: state.firestore.ordered[`${props.userId}-notifications`],
    adminNotifications: state.firestore.ordered.adminNotifications
  }))
  )(AdminNotificationIcon)
