import React, { useState } from 'react'
import ConsultantsPage from './ConsultantsPage'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
    root: {
      textAlign: 'center',
      marginTop: 150,
    },
    loader: {
      display: 'inlineBlock'
    },
  }))

function ConsultantsPageMiddleware(props) {
    const classes = useStyles()

    const [filters, setFilters] = useState({
        branch: ['Army', 'Navy', 'Marines', 'Air Force'],
        active: [true, false]
    })

    const handleFilters = (filters) => {
        setFilters(filters)
    }

    useFirestoreConnect([
        {
            collection: 'application',
            where: [
                ['approved', '==', true],
                ['branchOfService', 'in', filters.branch],
              ],
            storeAs: 'consultants'
        }
    ])
    const consultants = useSelector(state => state.firestore.data.consultants)

    if (!consultants) return <div className={classes.root}><CircularProgress className={classes.loader} /></div>

    const consultantsList =[]
    for (var c in consultants) {
      consultantsList.push({consultant: consultants[c], consultantId: c})
    }

    var filtered = null
    if (filters.active.length !== 2) {
        if (filters.active[0] === true) {
            filtered = consultantsList.slice().filter(c => {
                return c.consultant.activeDuty == true
            })
        }
        else if (filters.active[0] === false) {
            filtered = consultantsList.slice().filter(c => {
                return c.consultant.activeDuty == false
            })
        }
    } 

    return (
        <ConsultantsPage consultantsList={filtered ? filtered : consultantsList} sendFilters={handleFilters}/>
    )
}

export default ConsultantsPageMiddleware