import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import EmailIcon from '@material-ui/icons/EmailOutlined'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 100,
        backgroundColor: '#FFF',
        minHeight: '100vh',
        textAlign: 'center',
    },
    icon: {
        fontSize: 140,
    },
}))

function VerifyEmailReminder(props) {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Grid container direction='column' alignItems='center' justify='center' >
                <EmailIcon className={classes.icon}/>
                <h2>Please verify your email</h2>
                <h5>A verification link has been sent to your email on file</h5>
                <h5>Check your email then head back over here!</h5>
            </Grid>
        </div>
    )
}

export default VerifyEmailReminder