import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import purple from '@material-ui/core/colors/purple'
import grey from '@material-ui/core/colors/grey'
import Tooltip from '@material-ui/core/Tooltip'
import Author from '../ForumComponents/Author'
import { displayTimeInfo } from './Time'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 14,
        color: grey[100],
        paddingLeft: 5,
        paddingTop: 5,
        paddingBottom: 0,
        marginBottom: 0,
    },
    time: {
        fontSize: 12,
        color: grey[100],
        paddingLeft: 5,
        paddingTop: 0,
        marginTop: 0,
    },
}));

function Appointment({size, details, type, dayString, time, handleSlotClick}) {
    const classes = useStyles()
    const oneSlot = 3.35714285
    const total = oneSlot * size
    const percentString = `${total}%` 
    var color = null

    switch (type) {
        case 'scheduled':
            color = grey[800]
            break
        case 'requested':
            color = purple[300]
            break
        default:
            break;
    }

    const style = {
        height: percentString,
        width: '80%',
        backgroundColor: color,
    }

    return (
        <Tooltip title='Click to view details' placement="top">
            <Paper 
                style={style}
                elevation={5}
                onClick={(e) => handleSlotClick(e, dayString, time, true)}
            >
            {type === 'scheduled' ?
                <p className={classes.title}>{`Meeting with `} <Author authorId={details.clientId} textOnly /></p>
            :
                <p className={classes.title}><Author authorId={details.requestorId} textOnly /> {`has requested a meeting`}</p>
            }
            <p className={classes.time}>{displayTimeInfo(details.times)}</p>
            </Paper>
        </Tooltip>
    )
}

export default Appointment