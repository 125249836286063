import React, { Component, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { firestoreConnect } from 'react-redux-firebase'
import CircularProgress from '@material-ui/core/CircularProgress'
import ConsultantsPageMiddleware from '../Components/ConsultantsPageComponents/ConsultantsPageMiddleware'
import firebase from 'firebase'

function Consultants({ auth, token }) {

  const root = {
    textAlign: 'center',
    marginTop: 150,
  }
  const loader = {
    display: 'inlineBlock'
  }

  if (!auth.isLoaded) return <div style={root}><CircularProgress style={loader} /> </div>

  const loggedIn = auth.uid
  if (!loggedIn) return <Redirect to='/basic-access' />

  if (!token) return <div style={root}><CircularProgress style={loader} /> </div>
  const premiumUser = token.claims.premium
  const admin = token.claims.admin
  const SME = token.claims.SME

  if (!premiumUser && !admin && !SME) return <Redirect to='/premium-access' />

  return (
    <div style={{paddingTop: 60}} >
      <ConsultantsPageMiddleware />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    token: state.firebase.profile.token
  }
}

export default connect(mapStateToProps)(Consultants)