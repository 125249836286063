import React, { useState, Fragment, useEffect } from 'react'
// redux
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector, useDispatch } from 'react-redux'
import { setProposalData, submitProposal } from '../../Redux/Actions/jobActions'
// material
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
// icons
import CheckIcon from '@material-ui/icons/CheckCircle'
// react router
import { useParams } from 'react-router-dom'
// styles
import '../css/jobs.css'

const useStyles = makeStyles(theme => ({
    jobCard: {
        padding: theme.spacing(2),
    },
    skillTag: {
        backgroundColor: 'lightgrey',
        borderRadius: 10,
        padding: 5,
        color: 'black',
    },
    hourlyInput: {
        width: 150,
    }
}))

function Proposal({ userId }) {
    // local styles
    const classes = useStyles()

    const { job_id } = useParams()

    // for calling redux actions
    const dispatch = useDispatch()

    // redux state
    const proposal = useSelector(state => state.user.proposal_data)

    // local state
    const [formComplete, setFormComplete] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleChangeHourly = (e) => {
        const re = /^[0-9.\b]+$/
        if (e.target.value === '' || re.test(e.target.value)) {
            dispatch(setProposalData({...proposal, hourly: e.target.value}))
        }
    }

    const handleSubmitProposal = (e) => {
        e.preventDefault()
        setLoading(true)
        dispatch(submitProposal(proposal, job_id, job))
            .then(() => {
                setLoading(false)
            })
            .catch((e) => {
                console.error(e)
                setLoading(false)
            })
    }

    const afterFeePrice = () => {
        if (proposal.hourly !== '') {
            return (parseFloat(proposal.hourly) * 0.9).toFixed(2)
        }
        else{
            return ''
        }
    }

    const Fee = () => {
        if (proposal.hourly !== '') {
            return (-parseFloat(proposal.hourly) * 0.1).toFixed(2)
        }
        else{
            return ''
        }
    }

    // Get the job data
    useFirestoreConnect([
        {
            collection: 'active_jobs',
            doc: job_id,
        }
    ])
    const job = useSelector(({ firestore: { data } }) => data.active_jobs && data.active_jobs[job_id])

    // Check if a proposal has already been submitted
    useFirestoreConnect([
        {
            collection: 'proposals',
            where: [
                ['owner', '==', userId],
                ['job_id', '==', job_id]
            ],
            storeAs: `${job_id}-${userId}-proposal`
        }
    ])
    const proposalForThisJob = useSelector(state => state.firestore.ordered[`${job_id}-${userId}-proposal`])
    
    useEffect(() => {
        if (proposal.id === '') {
            dispatch(setProposalData({...proposal, id: job_id}))
        }
        else if (proposal.id !== job_id) {
            dispatch(setProposalData({hourly: '', cover: '', clientQuestions: {}, id: job_id}))
        }
    }, [])

    useEffect(() => {
        if (proposal.cover !== '' && proposal.hourly !== '') {
            let complete = true
            job && job.jobData && job.jobData.questions.map((question, index) => {
                if (!proposal.clientQuestions[index] || proposal.clientQuestions[index].answer === '') {
                    complete = false
                }
            })
            setFormComplete(complete)
        }
        else {
            setFormComplete(false)
        }
    }, [proposal])
    
    if (!job || !proposalForThisJob) return (
        <div style={{paddingTop: 150, textAlign: 'center'}}>
            <CircularProgress />
        </div>
    )

    if (proposalForThisJob.length !== 0) return (
        <div style={{paddingTop: 100, paddingBottom: 100}}>
            <Paper style={{width: 600, padding: 20, margin: 'auto'}}>
                <Grid container direction='row' justify='center' alignItems='center' spacing={2}>
                    <Grid item>
                        <CheckIcon style={{color: 'green', fontSize: 80}}/>
                    </Grid>
                    <Grid item>
                        <h2>Proposal Complete</h2>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )

    return (
        <div style={{paddingTop: 100, paddingBottom: 100}}>
            <Container maxWidth='md'>
                <Grid container direction='column' spacing={2}>
                    <Grid item>
                        <Paper style={{backgroundColor: 'whitesmoke'}} variant='outlined' square className='job-details'>
                            <h3>Job Details</h3>
                        </Paper>
                        <Paper variant='outlined' square className={classes.jobCard}>
                            <h5><b>{job.jobData.title}</b></h5>
                            <h5 style={{whiteSpace: 'pre-wrap'}}>
                                {job.jobData.description}
                            </h5>
                            <h5><b>{`${job.jobData.hourlyMin} - ${job.jobData.hourlyMax}`}</b>{` per hour - ${job.jobData.hoursPerWeek} hours per week - ${job.jobData.jobLength}`}</h5>
                            <Grid container direction='row' spacing={1}>
                                {job.jobData.skillsNeeded.map((skill, index) => (
                                    <Grid item key={index}>
                                        <p className={classes.skillTag}>{skill}</p>
                                    </Grid>
                                ))}
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item>
                        <Paper style={{backgroundColor: 'whitesmoke'}} variant='outlined' square className='job-details'>
                            <h3>Proposal</h3>
                        </Paper>
                        <Paper variant='outlined' square className={classes.jobCard}>
                            <Grid container direction='row' justify='space-between' alignItems='center'>
                                <Grid item>
                                    <h4><b>Hourly rate</b></h4>
                                </Grid>
                                <Grid item>
                                    <Input
                                        className={classes.hourlyInput}
                                        value={proposal.hourly  || ''}
                                        onChange={handleChangeHourly}
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        endAdornment={<InputAdornment position="start">/ hr</InputAdornment>}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction='row' justify='space-between' alignItems='center'>
                                <Grid item>
                                    <h4><b>10% service fee</b></h4>
                                </Grid>
                                <Grid item>
                                    <Input
                                        className={classes.hourlyInput}
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        endAdornment={<InputAdornment position="start">/ hr</InputAdornment>}
                                        disabled
                                        value={Fee() || ''}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction='row' justify='space-between' alignItems='center'>
                                <Grid item>
                                    <h4><b>You will receive</b></h4>
                                </Grid>
                                <Grid item>
                                    <Input
                                        className={classes.hourlyInput}
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        endAdornment={<InputAdornment position="start">/ hr</InputAdornment>}
                                        disabled
                                        value={afterFeePrice() || ''}
                                    />
                                </Grid>
                            </Grid>
                            <div style={{padding: 15}} />
                            <h5><b>Cover</b></h5>
                            <TextField
                                multiline
                                rows={8}
                                fullWidth
                                variant='outlined'
                                value={proposal.cover}
                                onChange={(e) => {
                                    dispatch(setProposalData({...proposal, cover: e.target.value}))
                                }}
                            />

                            {job.jobData.questions.map((question, index) => (
                                <Fragment key={index}>
                                    <h5><b>{question}</b></h5>
                                    <TextField
                                        multiline
                                        rows={4}
                                        fullWidth
                                        variant='outlined'
                                        value={proposal.clientQuestions[index] && proposal.clientQuestions[index].answer}
                                        onChange = {(e) => {
                                            let copy = proposal.clientQuestions
                                            copy[index] = {question: question, answer: e.target.value}
                                            dispatch(setProposalData({...proposal, clientQuestions: copy}))
                                        }}
                                    />
                                </Fragment>
                            ))}
                            <Button 
                                style={{marginTop: 10, width: 200}}
                                variant='contained'
                                color='primary'
                                onClick={handleSubmitProposal}
                                disabled={!formComplete || loading}
                            >
                                {loading ? 
                                    <CircularProgress color='secondary' size='20px' />
                                    :
                                    `Submit Proposal`
                                }
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Proposal