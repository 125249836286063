import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import DndForumList from './DndForumList'
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'

function DndForumListMiddleWare({ genres, forum_doc_id, sectionName }) {
    if (!genres) {
        return <div />
    }

    return (
        <Fragment>
          <DndForumList 
              genres={genres}
              forum_doc_id={forum_doc_id}
              sectionName={sectionName}
          />
        </Fragment>
    );
}

export default compose(
    firestoreConnect((props) => [
      { collection: 'forum', 
        doc: props.forum_doc_id, 
        subcollections: [{
          collection: 'genre',
          orderBy: 'index'
        }],
        storeAs: `${props.forum_doc_id}-genre`
      }
    ]),
    connect((state, props) => ({
      genres: state.firestore.ordered[`${props.forum_doc_id}-genre`]
    }))
)(DndForumListMiddleWare)