import React, { useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import firebase from 'firebase'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import UserAvatar from './UserAvatar'
import Author from '../ForumComponents/Author'
import { displayTimeInfo, displayDateFromUtcString } from './Time'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    root: {

    },
  }))

function MeetingHistory({ userId, sendMeetingToParent }) {
    const classes = useStyles()

    const todaysDate = new Date()
    const [pastMeetings, setPastMeetings] = useState(null)

    const handleClickMeeting = (meetingData) => {
        // pass this meeting data over to the time logging section
        sendMeetingToParent(meetingData)
    }

    useEffect(() => {
        const db = firebase.firestore()
        db.collection('users').doc(userId).collection('scheduled').where('date', '<=', todaysDate).limit(15)
        .get()
        .then(snap => {
            var temp = []
            snap.forEach(doc => {
                temp.push(doc.data())
            })
            setPastMeetings(temp)
        })
        .catch(err => {
            console.log('error', err)
        })
    }, [userId])

    if (!pastMeetings) return (
        <Grid container direction='row' alignItems='center' justify='center'>
            <CircularProgress />
        </Grid>
    )

    return (
        <div>
            <h3>Past Meetings:</h3>
            <List>
                {pastMeetings.map(meeting => (
                    <ListItem
                        key={meeting.date} 
                        button
                        onClick={() => handleClickMeeting(meeting)}
                    >
                        <ListItemIcon>
                            <UserAvatar userId={meeting.clientId} />
                        </ListItemIcon>
                        <ListItemText 
                            primary={<Author authorId={meeting.clientId} textOnly/>} 
                            secondary={`${displayDateFromUtcString(meeting.times[0])}, ${displayTimeInfo(meeting.times)}`}
                        />
                    </ListItem>
                ))}
            </List>
        </div>
    )
}

export default MeetingHistory