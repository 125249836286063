import React, { useState } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Button from '../../MaterialKitComponents/Button'
import MUIButton from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Tooltip from '@material-ui/core/Tooltip';
import { suspendApp } from '../../../Redux/Actions/userActions'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import Author from '../ForumComponents/Author'
import GetEmail from '../UserComponents/GetEmail'
import GetResume from '../AdminComponents/GetResume'
import GetDD214 from '../AdminComponents/GetDD214'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1),
    },
    greenPanel: {
        background: green[100]
    }
  }));

function ApprovedApps({ approvedApps, suspendApp }) {
    const classes = useStyles()
    const preventDefault = event => event.preventDefault()
    const [openDenyDialog, setOpenDenyDialog] = useState(false)
    const [userToDeny, setUserToDeny] = useState(null)
    const [openSuspendSB, setOpenSuspendSB] = useState(false);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSuspendSB(false)
    }

    const denyHandler = (event, userId) => {
        event.preventDefault()
        setUserToDeny(userId)
        setOpenDenyDialog(true)
    }

    const cancelDeny = () => {
        setOpenDenyDialog(false)
        setUserToDeny(null)
    }

    const deny = () => {
        suspendApp(userToDeny)
        setOpenDenyDialog(false)
        setUserToDeny(null)
        setOpenSuspendSB(true)
    }
    

    const root = {
        textAlign: 'center',
        marginTop: 150,
      }
      const loader = {
        display: 'inlineBlock'
      }
    if (!approvedApps) {
        return <div style={root}><CircularProgress style={loader} /> </div>
    }

    return (
        <div style={{marginTop: 50}}>
            <div style={{textAlign: 'center'}}>
                <h3>Approved Applications</h3>
            </div>
            {approvedApps.map(app => (
                <ExpansionPanel key={app.id}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={classes.greenPanel}
                    >
                        <Grid 
                            container
                            direction='row'
                            justify='space-between'
                            alignItems='center'
                        >
                            <Grid item>
                                <p>{<Author authorId={app.id} textOnly />}</p>
                            </Grid>
                            <Grid item>
                                <p>Applied {moment(app.appCompleteDate.toDate().toString()).calendar()} </p>
                            </Grid>
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid 
                            container
                            direction='row'
                            justify='space-around'
                            alignItems='center'
                        >
                            <Grid item>
                                <Grid 
                                    container
                                    direction='column'
                                    justify='flex-start'
                                    alignItems='flex-start'
                                >
                                    <Grid item>
                                        <p>Branch: <b>{app.branchOfService}</b></p>
                                    </Grid>
                                    {app.dateOfSeparation ? 
                                        <Grid item>
                                            <p>Sep Date: <b>{moment(app.dateOfSeparation.toDate().toString()).format("MMM Do YYYY")}</b></p>
                                        </Grid>
                                    :
                                        null
                                    }
                                    {app.activeDuty === true ? 
                                        <Grid item>
                                            <p>Active Duty: <b>yes</b></p>
                                        </Grid>
                                    :
                                        null
                                    }
                                    <Grid item>
                                        <p>Job: <b>{app.job}</b></p>
                                    </Grid>
                                    {app.secondaryJob ? 
                                        <p>Secondary Job: <b>{app.secondaryJob}</b></p>
                                    :   
                                        null
                                    }
                                    <Grid item>
                                        <p>Rank: <b>{app.rank}</b></p>
                                    </Grid>
                                    <Grid item>
                                        <p>Skills: <b>{app.skills.join(', ')}</b></p>
                                    </Grid>
                                    <Grid item>
                                        <p>Email: <b>{<GetEmail userId={app.id} />}</b></p>
                                    </Grid>
                                    <Grid item>
                                        <GetDD214 userId={app.id} />
                                    </Grid>
                                    <Grid item>
                                        <GetResume userId={app.id} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    direction='column'
                                    justify='flex-start'
                                    alignItems='flex-start'
                                >
                                    <Grid item>
                                        <Tooltip title='Place file in denied applications and remove consultant priveleges'>
                                            <Button
                                                simple
                                                color='danger'
                                                variant='outlined'
                                                className={classes.button}
                                                startIcon={<RemoveCircleIcon />}
                                                onClick={event => denyHandler(event, app.id)}
                                            >
                                                Suspend
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            ))}
            <Dialog
                open={openDenyDialog}
                onClose={() => setOpenDenyDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Suspend this user?"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    This user will be removed as a consultant and their file will be placed in the denied applications section.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <MUIButton onClick={cancelDeny} color="primary">
                    Cancel
                </MUIButton>
                <MUIButton onClick={deny} color="primary" autoFocus>
                    Suspend
                </MUIButton>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                open={openSuspendSB}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
                    <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
                }
            >
                <Alert onClose={handleCloseSnackbar} severity="error">
                    Application Suspended
                </Alert>
            </Snackbar>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
      suspendApp: (userId) => {dispatch(suspendApp(userId))}
    }
}

export default compose(
    firestoreConnect((props) => [
      {
        collection: 'application',
        where: [
            ['applicationComplete', '==', true],
            ['approved', '==', true],
          ],
          orderBy: [
            ['appCompleteDate', 'asc']
          ],
          storeAs: 'approvedApps'
      },
    ]),
    connect((state, props) => ({
      approvedApps: state.firestore.ordered.approvedApps
    }), mapDispatchToProps)
)(ApprovedApps)