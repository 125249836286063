import React, { Fragment, useState, useEffect } from 'react'
// material core
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Fab from '@material-ui/core/Fab'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import Skeleton from '@material-ui/lab/Skeleton'
// icons
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/EditOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined'
// axios
import axios from 'axios'
// redux
import { useDispatch, useSelector } from 'react-redux'
import { addEmployment, deleteEmployment, editEmployment } from '../../../Redux/Actions/userActions'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Icon } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    paper: {
        //marginTop: theme.spacing(1),
    },
    grid: {
        paddingTop: theme.spacing(2),
        paddingBotton: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    fab: {
        color: '#FFF'
    },
    icon: {
        color: '#000'
    },
    form: {
        width: 600,
    },
    smallForm: {
        width: 250,
    },
    select: {
        width: 250,
        height: 40,
    },
    empHeader: {
        backgroundColor: '#f5f5f5',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        height: 65,
    },
    empContainer: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }
}))

const styles = (theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
})

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <h4><b>{children}</b></h4>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function EmploymentHistory({ userId, isUsersProfile, employmentData }) {

    // styles
    const classes = useStyles()

    // redux dispatch
    const dispatch = useDispatch()

    // state
    const [openForm, setOpenForm] = useState(false)
    const [formComplete, setFormComplete] = useState(false)
    const [countries, setCountries] = useState([])
    const [years, setYears] = useState([])
    const [openConfirm, setOpenConfirm] = useState(false)
    const [toDelete, setToDelete] = useState(null)
    const [editData, setEditData] = useState(null)

    // Form state
    const [company, setCompany] = useState('')
    const [city, setCity] = useState('')
    const [country, setCountry] = useState('United States')
    const [title, setTitle] = useState('')
    const [fromMonth, setFromMonth] = useState('')
    const [fromYear, setFromYear] = useState('')
    const [toMonth, setToMonth] = useState('')
    const [toYear, setToYear] = useState('')
    const [workHere, setWorkHere] = useState(false)
    const [description, setDescription] = useState('')
    const [loading, setLoading] = useState(false)

    // functions
    const handleCloseForm = () => {
        setOpenForm(false)
    }

    const handleCheckBox = () => {
        setWorkHere(!workHere)
        validate()
    }

    const handleAddEmployment = (e) => {
        e.preventDefault()
        setOpenForm(true)
    }

    const handleCompanyChange = (e) => {
        setCompany(e.target.value)
        validate()
    }

    const handleCityChange = (e) => {
        setCity(e.target.value)
        validate()
    }

    const handleCountryChange = (e) => {
        setCountry(e.target.value)
        validate()
    }

    const handleTitleChange = (e) => {
        setTitle(e.target.value)
        validate()
    }

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value)
        validate()
    }

    const handleFromMonthChange = (e) => {
        setFromMonth(e.target.value)
        validate()
    }

    const handleToMonthChange = (e) => {
        setToMonth(e.target.value)
        validate()
    }

    const handleFromYearChange = (e) => {
        setFromYear(e.target.value)
        validate()
    }

    const handleToYearChange = (e) => {
        setToYear(e.target.value)
        validate()
    }

    const handleInitDelete = (e, id) => {
        e.preventDefault()
        setToDelete(id)
        setOpenConfirm(true)
    }

    const handleDelete = (e) => {
        e.preventDefault()
        dispatch(deleteEmployment(toDelete))
        setOpenConfirm(false)
    }

    const handleCloseConfirm = (e) => {
        e.preventDefault()
        setOpenConfirm(false)
        setToDelete(null)
    }

    const handleEdit = (e, data) => {
        e.preventDefault()
        setEditData(data.id)
        setCompany(data.data.company)
        setCity(data.data.city)
        setCountry(data.data.country)
        setTitle(data.data.title)
        setFromMonth(data.data.fromMonth)
        setFromYear(data.data.fromYear)
        setToMonth(data.data.toMonth)
        setToYear(data.data.toYear)
        setWorkHere(data.data.workHere)
        setDescription(data.data.description)
        setOpenForm(true)
    }

    const validate = () => {
        if (company !== '' && city !== '' && title !== '' && fromMonth !== '' && fromYear !== '' && (workHere === true || (toMonth !== '' && toYear !== ''))) {
            setFormComplete(true)
        }
    }

    // submit
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const employment = {
            company,
            city,
            country,
            title,
            fromMonth,
            fromYear,
            toMonth,
            toYear,
            workHere,
            description
        }
        if (editData) {
            dispatch(editEmployment(employment, editData))
                .then(response => {
                    setLoading(false)
                    setCompany('')
                    setCity('')
                    setTitle('')
                    setFromMonth('')
                    setFromYear('')
                    setToMonth('')
                    setToYear('')
                    setWorkHere(false)
                    setDescription('')
                    setOpenForm(false)
                    setEditData(null)
                })
                .catch(e => {
                    console.error(e)
                    setLoading(false)
                    setEditData(null)
                })
        }
        else {      
            dispatch(addEmployment(employment))
            .then(response => {
                setLoading(false)
                setCompany('')
                setCity('')
                setTitle('')
                setFromMonth('')
                setFromYear('')
                setToMonth('')
                setToYear('')
                setWorkHere(false)
                setDescription('')
                setOpenForm(false)
                setEditData(null)
            })
            .catch(e => {
                console.error(e)
                setLoading(false)
                setEditData(null)
            }) 
        }
    }

    // data
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ]

    useEffect(() => {
        if (company !== '' && city !== '' && title !== '' && fromMonth !== '' && fromYear !== '' && (workHere === true || (toMonth !== '' && toYear !== ''))) {
            setFormComplete(true)
        } else {
            setFormComplete(false)
        }
    }, [company, city, title, fromMonth, fromYear, toMonth, toYear, workHere])

    useEffect(() => {

        axios({
                "method":"GET",
                "url":"https://restcountries-v1.p.rapidapi.com/all",
                "headers":{
                "content-type":"application/octet-stream",
                "x-rapidapi-host":"restcountries-v1.p.rapidapi.com",
                "x-rapidapi-key":"f1ecb0550cmsh05f42b0b9311760p10200ejsncbb35561daa6",
                "useQueryString":true
            }
            })
            .then((response)=>{
              setCountries(response.data)
            })
            .catch((error)=>{
              console.log(error)
            })

            const years = function(startYear) {
                var currentYear = new Date().getFullYear(), years = [];
                startYear = startYear || 1980;  
                while ( startYear <= currentYear ) {
                    years.push(startYear++);
                }   
                return years;
            }

            setYears(years())

    }, [])

 
    return (
        <Fragment>
            <Grid
                container
                direction="column"
                className={classes.grid}
            >
                {!employmentData ? 
                    <Fragment>
                        <Skeleton animation='wave' height={100}/>  
                        <Skeleton animation='wave' height={50} />
                        <Skeleton animation='wave' height={50} />
                    </Fragment>  
               :
                <Paper className={classes.paper} elevation={2}>
                    <Grid container direction='row' justify='space-between' alignItems='center' className={classes.empHeader}>
                        <Grid item>
                            <h4><b>Employment History</b></h4>
                        </Grid>
                        {isUsersProfile ?
                            <Grid item>
                                <Fab className={classes.fab} onClick={handleAddEmployment} size='small' >
                                    <AddIcon className={classes.icon} />
                                </Fab>
                            </Grid>
                        : null}
                    </Grid> 
                    <div className={classes.empContainer}>
                        {
                            employmentData && employmentData.sort((a,b) => {return parseInt(b.data.fromYear) - parseInt(a.data.fromYear)}).map((emp, index) => (
                                <Fragment key={index}>
                                    <Grid container direction='row' justify='space-between' alignItems='center'>
                                        <Grid item>
                                            <h5><b>{`${emp.data.title} | ${emp.data.company}`}</b></h5>
                                        </Grid>
                                        {isUsersProfile ?
                                            <Grid>
                                                <IconButton onClick={(e) => handleEdit(e, emp)}><EditIcon /></IconButton>
                                                <IconButton onClick={(e) => handleInitDelete(e, emp.id)}><DeleteIcon /></IconButton>
                                            </Grid>
                                        : null}
                                    </Grid>
                                    <p>{`${emp.data.fromMonth} ${emp.data.fromYear} - ${emp.data.workHere ? 'present' : `${emp.data.toMonth} ${emp.data.toYear}`} `}</p>
                                    <p>{`${emp.data.description && emp.data.description}`}</p>
                                    <hr />
                                </Fragment>
                            ))
                        }
                    </div>
                </Paper>
                }
            </Grid>
            <Dialog maxWidth='xl' onClose={handleCloseForm} open={openForm}>
                <DialogTitle onClose={handleCloseForm}>
                    Add Employment
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container direction='column' spacing={2}>
                        <Grid item>
                            <p><b>Company</b></p>
                            <TextField 
                                variant='outlined'
                                className={classes.form}
                                size='small'
                                value={company}
                                onChange={handleCompanyChange}
                            />
                        </Grid>
                        <Grid item>
                            <Grid container direction='row' justify='space-between' alignItems='flex-end'>
                                <Grid item>
                                    <p><b>Location</b></p>
                                    <TextField 
                                        variant='outlined'
                                        size='small'
                                        className={classes.smallForm}
                                        label='City'
                                        value={city}
                                        onChange={handleCityChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <InputLabel>Country</InputLabel>
                                        <Select 
                                            className={classes.smallForm}
                                            value={country}
                                            onChange={handleCountryChange}
                                        >
                                            {
                                                countries.map((country, index) => (
                                                    <MenuItem key={index} value={country.name}>{country.name}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <p><b>Title</b></p>
                            <TextField 
                                variant='outlined'
                                size='small'
                                className={classes.form}
                                value={title}
                                onChange={handleTitleChange}
                            />
                        </Grid>
                        <Grid item>
                            <Grid container direction='row' justify='space-between' alignItems='flex-end'>
                                <Grid item>
                                    <p><b>From</b></p>
                                        <FormControl required>
                                            <InputLabel>Month</InputLabel>
                                            <Select 
                                                className={classes.smallForm}
                                                value={fromMonth}
                                                onChange={handleFromMonthChange}
                                            >
                                                {
                                                    months.map((month, index) => (
                                                        <MenuItem key={index} value={month}>{month}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl required>
                                        <InputLabel>Year</InputLabel>
                                        <Select 
                                            className={classes.smallForm}
                                            value={fromYear}
                                            onChange={handleFromYearChange}
                                        >
                                            {
                                                years.map(year => (
                                                    <MenuItem key={year} value={year}>{year}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction='row' justify='space-between' alignItems='flex-end'>
                                <Grid item>
                                    <p><b>To</b></p>
                                        <FormControl>
                                            <InputLabel>Month</InputLabel>
                                            <Select 
                                                className={classes.smallForm}
                                                value={toMonth}
                                                onChange={handleToMonthChange}
                                            >
                                                <MenuItem key={-1} value={''}></MenuItem>
                                                {
                                                    months.map((month, index) => (
                                                        <MenuItem key={index} value={month}>{month}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <InputLabel>Year</InputLabel>
                                        <Select 
                                            className={classes.smallForm}
                                            value={toYear}
                                            onChange={handleToYearChange}
                                        >
                                            <MenuItem key={-1} value={''}></MenuItem>
                                            {
                                                years.map(year => (
                                                    <MenuItem key={year} value={year}>{year}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={<Checkbox checked={workHere} onChange={handleCheckBox} color='primary' />}
                                label="I currently work here"
                            />
                        </Grid>
                        <Grid item>
                            <p><b>Description (optional)</b></p>
                            <TextField 
                                variant='outlined'
                                size='small'
                                className={classes.form}
                                rows={5}
                                multiline
                                value={description}
                                onChange={handleDescriptionChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseForm} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={!formComplete} onClick={handleSubmit} variant='contained' color="primary">
                        { loading ?
                            <CircularProgress color='secondary' size='20px' />
                            :
                            'Save'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
            >
                <DialogTitle >{"Delete this Employment?"}</DialogTitle>
                <DialogActions>
                <Button onClick={handleCloseConfirm} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleDelete} color="secondary" >
                    Delete
                </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default EmploymentHistory