import React, { useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { Link } from 'react-router-dom'
import grey from '@material-ui/core/colors/grey'
import Button from '@material-ui/core/Button'
import Helo from '../../Assets/images/jet.jpg'


const useStyles = makeStyles(theme => ({
  background: {
    paddingTop: 200,
    backgroundColor: '#FFF',
    minHeight: '100vh',
    backgroundImage: `url(${Helo})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: "top center"
  },
  container: {
    height: '100vh',
    overflow: 'auto',
  },
  card: {
    width: 350,
    height: 400,
    paddingTop: 10,
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: 'rgba(255,255,255,0.9)'
  },
  cardSolid: {
    width: 350,
    height: 400,
    paddingTop: 10,
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: 'rgba(255,255,255,1)'
  },
  title: {
    textAlign: 'center',
    fontWeight: 400,
  },
  item: {
    textAlign: 'center',
    fontSize: 16,
    color: grey[700],
  },
}))

function ChooseRegistrationType(props) {
  const classes = useStyles()

  const [hoverLeft, setHoverleft] = useState(false)
  const [hoverRight, setHoverRight] = useState(false)

  return (
    <div className={classes.background}>
      <Grid container direction='row' justify='center' alignItems='center' spacing={10}>
        <Grid item>
          <Link to='/pricing'>
            <Paper 
              className={hoverLeft ? classes.cardSolid : classes.card} 
              onMouseLeave={() => setHoverleft(false)} 
              onMouseOver={() => setHoverleft(true)} 
              elevation={hoverLeft ? 20 : 2}
            >
              <h2 className={classes.title}>Collaborator</h2>
              <p className={classes.item}>{`For businesses looking to develop better products`}</p>
              <p className={classes.item}>{`For veterans, students, and enthusiasts wanting to collaborate with the defense community`}</p>
            </Paper>
          </Link>
        </Grid>
        <Grid item>
          <Link to='/choose-registration/Consultant'>
            <Paper 
              className={hoverRight ? classes.cardSolid : classes.card}
              onMouseLeave={() => setHoverRight(false)} 
              onMouseOver={() => setHoverRight(true)} 
              elevation={hoverRight ? 20 : 2}
            >
              <h2 className={classes.title}>User</h2>
              <p className={classes.item}>{`For those seeking to help businesses develop better products for defense and get paid for their time`}</p>
              <p className={classes.item}>{`*Military experience required`}</p>
            </Paper>
          </Link>
        </Grid>
      </Grid>
    </div>
  )
}

export default ChooseRegistrationType