import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Badge from '@material-ui/core/Badge';
import Author from '../ForumComponents/Author'
import Container from '@material-ui/core/Container';
import moment from 'moment'
import Typography from '@material-ui/core/Typography';
import Redirect from 'react-router-dom'
import { Link, withRouter } from 'react-router-dom'
import { markRead } from '../../../Redux/Actions/commentActions'
import blue from '@material-ui/core/colors/blue';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import grey from '@material-ui/core/colors/grey';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import Notification from './Notification'
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';

const unreadColor = blue[100];

const useStyles = makeStyles(theme => ({
  titleButton: {
    textDecoration: 'none',
    color: 'black',
    "&:hover": {
      color: grey[900],
      textDecoration: 'none'
    },
    "&:visited": {
      color: grey[900]
    },
    "&:active": {
      color: grey[900]
    },
    "&:link": {
      color: grey[900]
    },
    "&:link:active": {
      color: grey[900]
    },
    "&:visited:active": {
      color: grey[900]
    },
  },
  unreadStyle: {
    backgroundColor: unreadColor,
  }
}));


function NotificationIcon({notifications, adminNotifications, markRead, authId}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.target);
  }

  const handleClickAway = (event) => {
    if (event.target !== anchorEl)
      setAnchorEl(null)
  }

  var open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined; 

  if (!notifications) return <IconButton aria-describedby={id} type="button"><NotificationsIcon  /></IconButton>

  const noNotifications = notifications.length === 0;

  var newNotifications = 0
  
  for (var i = 0; i < notifications.length; i++) {
    if (notifications[i].unread) {
      newNotifications++
    }
  }
  const unreadNotifications = newNotifications

  return (
    <div>
      <IconButton 
        aria-describedby={id} 
        type="button" 
        color='inherit'
        onClick={handleClick}
      >
        <Badge badgeContent={unreadNotifications} color="error">
          <NotificationsIcon  />
        </Badge>
      </IconButton>
        <Popper id={id} open={open} anchorEl={anchorEl} disablePortal placement='bottom'>
          <ClickAwayListener onClickAway={handleClickAway} >
            <Paper style={{marginTop: 15, maxHeight: 400, width: 600, maxWidth: '90vw', maxHeight: '90vh', overflow: 'auto'}}>
              <List disablePadding>
                {noNotifications ? 
                  <Link to='/forum' className={classes.titleButton}>
                    <ListItem
                        key={0}
                        button
                    >
                        <ListItemIcon>
                            <NotificationsOffIcon color='primary'/>
                        </ListItemIcon> 
                        <ListItemText 
                            primary={`You have no notifications at this time`}
                            secondary={`Head over to the forum and share your thoughts!`}
                        />
                    </ListItem>
                  </Link>
                :
                  (notifications.map(notification => (
                    <Fragment key={notification.id}>
                      <Notification notification={notification} authId={authId}/>
                    <Divider />
                    </Fragment>
                  )))
                }
              </List>
            </Paper>
          </ClickAwayListener>
        </Popper>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    markRead: (notificationId) => {dispatch(markRead(notificationId))}
  }
}

export default compose(
  firestoreConnect((props) => [
    { collection: 'users', 
      doc: props.authId, 
      subcollections: [{collection: 'notification', orderBy: ['createdAt', 'desc'], limit: 30}],
      storeAs: `${props.userId}-notifications`
    },
  ]),
  connect((state, props) => ({
    notifications: state.firestore.ordered[`${props.userId}-notifications`],
  }), mapDispatchToProps)
  )(NotificationIcon)

