import React from 'react'
import { useSelector } from 'react-redux'
import Proposal from './Proposal'


function ProposalMW(props) {

    const auth = useSelector(state => state.firebase.auth)

    if (!auth || !auth.uid) return <div />
    return (
        <Proposal userId={auth.uid} />
    )
}

export default ProposalMW