import React, { Component } from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import SignUpSMEPage from '../MaterialKitComponents/RegistrationComponents/SignUpSMEPage'

class SignupAsSME extends Component {

  render() {
    const { auth, profile } = this.props
    const loggedIn = auth.uid
    const emailVerified = auth.emailVerified

    if (loggedIn && profile.virgilComplete && emailVerified) {
      return <Redirect to='/' />
    }
    else if (loggedIn && !profile.virgilComplete && emailVerified) {
      return <Redirect to='/messenger-setup' />
    }
  
    if (!auth.isLoaded) return <div/> 

    return (
      <SignUpSMEPage />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

export default withRouter(connect(mapStateToProps)(SignupAsSME))