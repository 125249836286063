const initState = {
  authEmailError: null,
  authPasswordError: null,
  resetEmailSuccess: false,
  resetEmailError: null,
  signupEmailError: null,
  signupPasswordError: null,
  addAdminError: null,
  addAdminSuccess: null,
  admins: null,
  addSMEError: null,
  addSMESuccess: null,
  eThree: null,
}

const authReducer = (state = initState, action) => {
  switch(action.type) {
    case 'LOGIN_ERROR':
      //console.log('login failed', action.error)
      return {
        ...state,
        authEmailError: action.error.code === 'auth/invalid-email' ? action.error.message : action.error.code === 'auth/user-not-found' ? "No user associated with this email" : null,
        authPasswordError: action.error.code === 'auth/wrong-password' ? "Incorrect password" : action.error.code === 'auth/too-many-requests' ? action.error.message : null,
      }
    case 'LOGIN_SUCCESS':
      //console.log('login success')
      return {
        ...state,
        authEmailError: null,
        authPasswordError: null,
      }
    case 'SIGNOUT_SUCCESS':
      //console.log('signout success')
      return state
    case 'SIGNUP_SUCCESS':
      //console.log('signup success')
      return {
        ...state,
        signupEmailError: null,
        signupPasswordError: null,
      }
    case 'SIGNUP_ERROR':
      //console.log('signup error', action.error)
      return {
        ...state,
        signupEmailError: action.error.code === 'auth/invalid-email' ? action.error.message : action.error.code === 'auth/email-already-in-use' ? action.error.message : null,
        signupPasswordError: action.error.code === 'auth/weak-password' ? action.error.message : null,
      }
    case 'SIGNUP_SME_SUCCESS':
      //console.log('signup SME success')
      return {
        ...state,
        signupEmailError: null,
        signupPasswordError: null,
      }
    case 'SIGNUP_SME_ERROR':
      //console.log('signup SME error', action.error)
      return {
        ...state,
        signupEmailError: action.error.code === 'auth/invalid-email' ? action.error.message : action.error.code === 'auth/email-already-in-use' ? action.error.message : null,
        signupPasswordError: action.error.code === 'auth/weak-password' ? action.error.message : null,
      }
    case 'ADD_ADMIN':
      //console.log('Add admin', action.result)
      return {
        ...state,
        addAdminError: action.result.data.errorInfo && action.result.data.errorInfo.message ? action.result.data.errorInfo.message : null,
        addAdminSuccess: action.result.data && action.result.data.message ? action.result.data.message : null,
      }
    case 'ADD_ADMIN_ERROR':
      //console.log('Add admin error', action)
      return state
    case 'ADD_SME':
      console.log('Add sme', action.userId)
      return state
    case 'ADD_SME_ERROR':
      //console.log('Add sme error', action)
      return state
    case 'ADD_PREMIUM':
      //console.log('Add premium user', action.result)
      return {
        ...state,
        addPremiumError: action.result.data.errorInfo && action.result.data.errorInfo.message ? action.result.data.errorInfo.message : null,
        addPremiumSuccess: action.result.data && action.result.data.message ? action.result.data.message : null,
      }
    case 'ADD_PREMIUM_ERROR':
      //console.log('Add premium error', action)
      return state
    case 'REMOVE_SME':
      //console.log('Remove sme', action.result)
      return state
    case 'REMOVE_SME_ERROR':
      //console.log('Remove sme error', action)
      return state
      case 'RESET_VIRGIL':
        return state
      case 'RESET_VIRGIL_ERROR':
        return state
    case 'GET_ADMINS':
      //console.log('get admins', action.result.data)
      return {
        ...state,
        admins: action.result.data
      }
    case 'GET_ADMINS_ERROR':
      //console.log('get admins error', action.error)
      return state
    case 'REMOVE_ADMIN':
      //console.log('remove admin', action.resultObj)
      // if there is no errors
      if(!action.resultObj.result.data.errorInfo) {
        var newAdmins = []
        state.admins.map(admin => {
          if (admin.userId !== action.resultObj.userId) {
            newAdmins.push(admin)
          } 
        })
        return {
          ...state,
          admins: newAdmins
        }
      }
      else {
        return state
      }
    case 'REMOVE_ADMIN_ERROR':
      //console.log('remove admin error', action.error)
      return state
    case 'RESET_PW_EMAIL':
      //console.log('Reset pw email', action.email)
      return {
        ...state,
        resetEmailSuccess: true,
        resetEmailError: null
      }
    case 'RESET_PW_EMAIL_ERROR':
      //console.log('Reset pw email error', action.error)
      return {
        ...state,
        resetEmailSuccess: false,
        resetEmailError: action.error.code === 'auth/invalid-email' ? action.error.message : action.error.code === 'auth/user-not-found' ? 'There is no account associated with this email' : null,
      }
      case 'SAVE_ETHREE':
       //console.log('save eThree', action.eThree)
        return {
          ...state,
          eThree: action.eThree,
        }
    default:
      return state
  }
}
export default authReducer