import React, { Profiler } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import GetEmail from '../UserComponents/GetEmail'
import { grantPremium, denyPremium, removePremium } from '../../../Redux/Actions/userActions'

const useStyles = makeStyles(theme => ({
    title: {
        textAlign: 'center'
    },
    card: {
        padding: theme.spacing(1)
    },
}))

function LicensesAdmin({ grantPremium, denyPremium, removePremium }) {
    const classes = useStyles()

    useFirestoreConnect([
        {
            collection: 'users',
            where: ['profile', '==', 'business'],
            storeAs: 'businesses'
        }
    ])
    const businesses = useSelector(state => state.firestore.ordered['businesses'])

    if (!businesses) return <div />

    return (
        <div>
            <h3 className={classes.title}>License Requests</h3>
            <Grid container direction='column' spacing={1}>
                {businesses.slice().filter(b => b.status === 'requested').map(business => (
                    <Grid item key={business.id}>
                        <Paper className={classes.card}>
                            <Grid container direction='row' justify='space-between' alignItems='center'>
                                <Grid item>
                                    <h4>{business.username}</h4>
                                    <h6>{`Company: ${business.companyName}`}</h6>
                                    <p>Email: <span>{<GetEmail userId={business.id}/>}</span></p>
                                    <p>{`Phone: ${business.phone}`}</p>
                                    <p>{`Website: ${business.url}`}</p>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' color='primary' onClick={() => grantPremium(business.id)}>Grant license</Button>
                                    <Button variant='text' color='secondary' onClick={() => denyPremium(business.id)}>Deny</Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
            <Grid container direction='row' spacing={1}>
                <Grid item sm>
                    <h3 className={classes.title}>Active Licenses</h3>
                    <Grid container direction='column' spacing={1}>
                        {businesses.slice().filter(b => b.status === 'granted').map(business => (
                            <Grid item key={business.id}>
                                <Paper className={classes.card}>
                                    <Grid container direction='row' justify='space-between' alignItems='center'>
                                        <Grid item>
                                            <h4>{business.username}</h4>
                                            <h6>{`Company: ${business.companyName}`}</h6>
                                            <p>Email: <span>{<GetEmail userId={business.id}/>}</span></p>
                                            <p>{`Phone: ${business.phone}`}</p>
                                        </Grid>
                                        <Grid item>
                                            <Button variant='text' color='secondary' onClick={() => removePremium(business.id)}>Remove License</Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid item sm>
                    <h3 className={classes.title}>Rejected Licenses</h3>
                    <Grid container direction='column' spacing={1}>
                        {businesses.slice().filter(b => b.status === 'denied').map(business => (
                            <Grid item key={business.id}>
                                <Paper className={classes.card}>
                                    <Grid container direction='row' justify='space-between' alignItems='center'>
                                        <Grid item>
                                            <h4>{business.username}</h4>
                                            <h6>{`Company: ${business.companyName}`}</h6>
                                            <p>Email: <span>{<GetEmail userId={business.id}/>}</span></p>
                                            <p>{`Phone: ${business.phone}`}</p>
                                        </Grid>
                                        <Grid item>
                                            <Button variant='text' color='primary' onClick={() => grantPremium(business.id)}>Grant license</Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        grantPremium: (userId) => dispatch(grantPremium(userId)),
        denyPremium: (userId) => dispatch(denyPremium(userId)),
        removePremium: (userId) => dispatch(removePremium(userId)),
    }
}

export default connect(null, mapDispatchToProps)(LicensesAdmin)