import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'
import { updateSecurityQuestions, virgilComplete } from '../../Redux/Actions/userActions'
import { saveEThree } from '../../Redux/Actions/authActions'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import Alert from '@material-ui/lab/Alert'
// for Virgil security
import { EThree } from '@virgilsecurity/e3kit-browser'
import firebase from 'firebase'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const questions = [
    `What was your childhood nickname?`,
    `In what city did you meet your spouse/significant other?`,
    `What is the name of your favorite childhood friend?`,
    `What street did you live on in third grade?`,
    `What is your oldest sibling’s birthday month and year? (e.g., January 1900)`,
    `What is the middle name of your youngest child?`,
    `What is your oldest sibling's middle name?`,
    `What school did you attend for sixth grade?`,
    `What was your childhood phone number including area code? (e.g., 000-000-0000)`,
    `What is your oldest cousin's first and last name?`,
    `What was the name of your first stuffed animal?`,
    `In what city or town did your mother and father meet?`,
    `What was the last name of your third grade teacher?`,
    `In what city does your nearest sibling live?`,
    `What is your youngest brother’s birthday month and year? (e.g., January 1900)`,
    `What is your maternal grandmother's maiden name?`,
    `In what city or town was your first job?`,
    `What is the name of the place your wedding reception was held?`,
    `What is the name of a college you applied to but didn't attend?`,
    `Where were you when you first heard about 9/11?`
]

const useStyles = makeStyles(theme => ({
    background: {
        paddingTop: 100,
        minHeight: '100vh',
        backgroundColor: '#FFF',
        padding: 50,
    },
    select: {
        width: 400,
    },
    selectSmall: {
        width: '80vw',
        whiteSpace: 'normal',
    },
    spacer: {
        padding: theme.spacing(1),
    },
    title: {
        textAlign: 'center',
        maxWidth: 600,
    },
    error: {
        color: red[500],
    },
    success: {
        color: green[500],
    },
}))

function VirgilSetup({ updateSecurityQuestions, virgilComplete, saveEThree, profile, auth }) {
    const classes = useStyles()

    //  Callback to fetch virgil security token
    const getToken = firebase.functions().httpsCallable('getVirgilJwt');
    const initializeFunction = () => getToken().then(result => result.data.token);

    const smallScreen = useMediaQuery('(max-width:700px)')

    // state
    const [question1, setQuestion1] = useState('')
    const [question2, setQuestion2] = useState('')
    const [question3, setQuestion3] = useState('')
    const [answer1, setAnswer1] = useState('')
    const [answer2, setAnswer2] = useState('')
    const [answer3, setAnswer3] = useState('')
    const [q1Error, setQ1Error] = useState(null)
    const [q2Error, setQ2Error] = useState(null)
    const [q3Error, setQ3Error] = useState(null)
    const [a1Error, setA1Error] = useState(null)
    const [a2Error, setA2Error] = useState(null)
    const [a3Error, setA3Error] = useState(null)
    const [status, setStatus] = useState(null)
    const [loading, setLoading] = useState(false)

    // functions
    const handleQ1 = (event) => {
        setQuestion1(event.target.value)
        setQ1Error(null)
        setQ2Error(null)
        setQ3Error(null)
    }

    const handleQ2 = (event) => {
        setQuestion2(event.target.value)
        setQ1Error(null)
        setQ2Error(null)
        setQ3Error(null)
    }

    const handleQ3 = (event) => {
        setQuestion3(event.target.value)
        setQ3Error(null)
        setQ2Error(null)
        setQ1Error(null)
    }

    const handleA1 = (event) => {
        setAnswer1(event.target.value)
        setA1Error(null)
    }

    const handleA2 = (event) => {
        setAnswer2(event.target.value)
        setA2Error(null)
    }

    const handleA3 = (event) => {
        setAnswer3(event.target.value)
        setA3Error(null)
    }

    const handleConfirm = (event) => {
        event.preventDefault()
        setStatus(null)
        var error = false
        if (question1 === '') {
            setQ1Error('Select a question')
            error = true
        }
        if (question2 === '') {
            setQ2Error('Select a question')
            error = true
        }
        if (question3 === '') {
            setQ3Error('Select a question')
            error = true
        }
        if (question1 === question2 && question1 !== '') {
            setQ1Error('Questions match')
            setQ2Error('Questions match')
            error = true
        }
        if (question2 === question3 && question2 !== '') {
            setQ2Error('Questions match')
            setQ3Error('Questions match')
            error = true
        }
        if (question1 === question3 && question1 !== '') {
            setQ1Error('Questions match')
            setQ3Error('Questions match')
            error = true
        }

        if (!error) {
            // Questions are good, check answers
            if (!answer1.replace(/\s/g, '').length) {
                setA1Error('Answer cannot be blank')
                error = true
            }
            if (!answer2.replace(/\s/g, '').length) {
                setA2Error('Answer cannot be blank')
                error = true
            }
            if (!answer3.replace(/\s/g, '').length) {
                setA3Error('Answer cannot be blank')
                error = true
            }
        }

        if (!error) {
            // Questions and answers are good, submit
            setLoading(true)
            const securityQuestions = {
                question1,
                question2,
                question3,
                answer1: answer1.trim().toLowerCase(),
                answer2: answer2.trim().toLowerCase(),
                answer3: answer3.trim().toLowerCase()
            }
            updateSecurityQuestions(securityQuestions)
                .then(() => {
                    initAndbackupPK()
                })
                .catch(e => {
                    //console.error('Question update error: ', e)
                    setStatus('Failed, please try again')
                    setLoading(false)
                })
        }
        
    }

    const initAndbackupPK = () => {
        //console.log('backupPK')
        const pwString = `${answer1.trim().toLowerCase()}:${answer2.trim().toLowerCase()}:${answer3.trim().toLowerCase()}`
        //console.log(pwString)

        // Initialize Virgil Security E3Kit
        EThree.initialize(initializeFunction)
            .then(eThree => {
                //Init success, register user
                eThree.register()
                    .then(() => {
                        // registration success, backup PK
                        //console.log('registered with virgil')
                        eThree.backupPrivateKey(pwString)
                            .then(() => {
                                // PK backup success
                                setLoading(false)
                                //console.log('PK backup success')
                                virgilComplete()
                                saveEThree()
                                setStatus('Success')
                            })
                            .catch(e => {
                                //console.error('backupPK error: ', e)
                                setLoading(false)
                                setStatus('Failed, please try again')
                            })
                    })
                    .catch(e => {
                        //console.error('Registration error: ', e)
                        // backup PK since the user is already registered
                        eThree.backupPrivateKey(pwString)
                        .then(() => {
                            // PK backup success
                            setLoading(false)
                            //console.log('PK backup success')
                            virgilComplete()
                            saveEThree()
                            setStatus('Success')
                        })
                        .catch(e => {
                            //console.error('backupPK error: ', e)
                            setLoading(false)
                            setStatus('Failed, please try again')
                        })
                    })
            })
            .catch(e => {
                //console.error('Init error: ', e)
                setLoading(false)
                setStatus('Failed, please try again')
            })
    }

    if (!auth.uid) return <Redirect to='/' />

    if (profile && profile.profile === 'SME-applicant' && profile.virgilComplete) {
        return <Redirect to={`/consultant-application/${auth.uid}`} />
    }
    else if (profile && profile.profile !== 'SME-applicant' && profile.virgilComplete) {
        return <Redirect to={`/profile/${auth.uid}`} />
    }

    return (
        <div className={classes.background}>
            <Grid container direction='column' justify='flex-start' alignItems='center' spacing={2}>
                <h2 className={classes.title}>Security Questions</h2>
                <h3 className={classes.title}>These questions are used for multi-device support & security. You will be asked these questions if you log on with a different device or delete browser cookies.</h3>
                <Container maxWidth='sm'>
                    <Alert severity="warning" variant='standard' style={{textAlign: 'center'}}>If you forget the answers to these questions you will not be able to access past message data</Alert>
                </Container>
                <Grid item>
                    <FormControl>
                        <InputLabel>Security Question 1</InputLabel>
                        <Select className={smallScreen ? classes.selectSmall : classes.select} value={question1} onChange={handleQ1}>
                            {questions.map(q => (
                                <MenuItem value={q} key={q}>{q}</MenuItem>
                            ))}
                        </Select>
                        <p className={classes.error}>{q1Error}</p>
                        <div className={classes.spacer} />
                        <TextField 
                            placeholder='Answer'
                            required
                            value={answer1}
                            onChange={handleA1}
                            error={a1Error ? true : false}
                            helperText={a1Error ? a1Error : null}
                        />
                    </FormControl>
                    <div className={classes.spacer} />
                    <div className={classes.spacer} />
                    <FormControl>
                        <InputLabel>Security Question 2</InputLabel>
                        <Select className={smallScreen ? classes.selectSmall : classes.select} value={question2} onChange={handleQ2}>
                            {questions.map(q => (
                                <MenuItem value={q} key={q}>{q}</MenuItem>
                            ))}
                        </Select>
                        <p className={classes.error}>{q2Error}</p>
                        <div className={classes.spacer} />
                        <TextField 
                            placeholder='Answer'
                            required
                            value={answer2}
                            onChange={handleA2}
                            error={a2Error ? true : false}
                            helperText={a2Error ? a2Error : null}
                        />
                    </FormControl>
                    <div className={classes.spacer} />
                    <div className={classes.spacer} />
                    <FormControl>
                        <InputLabel>Security Question 3</InputLabel>
                        <Select className={smallScreen ? classes.selectSmall : classes.select} value={question3} onChange={handleQ3}>
                            {questions.map(q => (
                                <MenuItem value={q} key={q}>{q}</MenuItem>
                            ))}
                        </Select>
                        <p className={classes.error}>{q3Error}</p>
                        <div className={classes.spacer} />
                        <TextField 
                            placeholder='Answer'
                            required
                            value={answer3}
                            onChange={handleA3}
                            error={a3Error ? true : false}
                            helperText={a3Error ? a3Error : null}
                        />
                    </FormControl>
                </Grid>
                <Grid item>
                    {loading ? 
                        <CircularProgress />
                    :
                        <Button color='primary' variant='outlined' onClick={handleConfirm}>Confirm</Button>
                    }
                </Grid>
                <Grid item>
                    <div>{status}</div>
                </Grid>
            </Grid>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateSecurityQuestions: (questions) => dispatch(updateSecurityQuestions(questions)),
        virgilComplete: () => dispatch(virgilComplete()),
        saveEThree: (eThree) => {dispatch(saveEThree(eThree))},
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VirgilSetup)