import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Announcement from '@material-ui/icons/AccountBoxRounded'
import grey from '@material-ui/core/colors/grey'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    title: {
        textAlign: 'center',
    },
    subtitle: {
        textAlign: 'center',
    },
    background: {
        paddingTop: 100,
        backgroundColor: '#FFFFFF',
        minHeight: '100vh',
        paddingBottom: 100,
    },
    announcement: {
        fontSize: 160,
        color: grey[800],
    },
}))

function SmeAccess(props) {
    const classes = useStyles()


    return (
        <div className={classes.background}>
            <Grid container direction='column' justify='center' alignItems='center'>
                <h2 className={classes.title}>Access to this page is for our military end-users</h2>
                <Announcement className={classes.announcement}/>
                <h4 className={classes.subtitle}>If you are a new User and are seeing this page then please sign out and sign back in again</h4>
                <p className={classes.subtitle}>If you have recently applied to become a User then your application is being reviewed and you will receive a result shortly</p>
            </Grid>
        </div>
    )
}

export default SmeAccess