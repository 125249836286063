import React, { useState, Fragment } from 'react'
// Material Core
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Author from '../Components/ForumComponents/Author'
import Company from '../Components/UserComponents/Company'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
// Redux
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector, useDispatch } from 'react-redux'
import { activateConversation } from '../../Redux/Actions/chatActions'
import { closeContractUserSide } from '../../Redux/Actions/jobActions'
// react router
import { useParams, useHistory } from 'react-router-dom'
// icons
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
// moment
import moment from 'moment'
import { CircularProgress, DialogContentText } from '@material-ui/core'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: '#f5f5f5',
    },
    body: {
        paddingLeft: 20,
    },
}))

function Contracts(props) {
    const classes = useStyles()

    const { user_id, tab='active' } = useParams()

    const history = useHistory()

    // dispatch redux actions
    const dispatch = useDispatch()

    const [anchorEl, setAnchorEl] = useState(null)
    const [focusedContract, setFocusedContract] = useState(null)
    const [closeContract, setCloseContract] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    const handleTabChange = (e, newValue) => {
        history.push(`/contracts/${user_id}/${newValue === 0 ? 'active' : 'closed'}`) // switch the tab in the url
    }

    const handleOpenMenu = (event, contract) => {
        setAnchorEl(event.currentTarget);
        setFocusedContract(contract)
    }

    const handleCloseMenu = () => {
        setAnchorEl(null)
        setFocusedContract(null)
    }

    const handleLogTime = (job_id) => {
        history.push(`/log-time/${user_id}/${focusedContract.id}`)
    }

    useFirestoreConnect([
        {
            collection: 'users',
            doc: user_id,
            subcollections: [
                {
                    collection: 'active_contracts',
                    orderBy: ['timestamp', 'desc'],
                }
            ],
            storeAs: `${user_id}-active_contracts`
        }
    ])
    const contracts = useSelector(state => state.firestore.ordered[`${user_id}-active_contracts`])

    useFirestoreConnect([
        {
            collection: 'users',
            doc: user_id,
            subcollections: [
                {
                    collection: 'closed_contracts',
                    orderBy: ['timestamp', 'desc'],
                }
            ],
            storeAs: `${user_id}-closed_contracts`
        }
    ])
    const closedContracts = useSelector(state => state.firestore.ordered[`${user_id}-closed_contracts`])

    if (!contracts || !closedContracts) return <div />
    console.log(closedContracts)

    return (
        <div style={{paddingTop: 100}}>
            <Container maxWidth='md'>
                <Paper>
                    <AppBar color='default' position='static'>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={2}>
                            <Grid item >
                                <h4 className={classes.body}><b>Contracts</b></h4>
                            </Grid>
                            <Grid item>
                                <Tabs
                                    value={tab === 'active' ? 0 : 1}
                                    textColor='secondary'
                                    indicatorColor='primary'
                                    onChange={handleTabChange}
                                >
                                    <Tab label='Active' />
                                    <Tab label='Closed' />
                                </Tabs>
                            </Grid>
                        </Grid>
                    </AppBar>
                        <TabPanel value={tab} index={'active'}>
                            {contracts.length === 0 ? 
                                <Grid item className={classes.body}>
                                    <h4>No Contracts</h4>
                                    <p>This will contain a list of contracts</p>
                                </Grid>
                                :
                                <Fragment>
                                    {contracts.map((c, i) => (
                                        <Fragment key={i}>
                                            <Grid item className={classes.body}>
                                                <p><b>{c.jobData.title}</b></p>
                                                <Grid container direction='row' justify='space-between' >
                                                    <Grid item>
                                                        <Grid container direction='row' spacing={5}>
                                                            <Grid item>
                                                                <p><b>{<Company userId={c.owner} />}</b></p>
                                                            </Grid>
                                                            <Grid item>
                                                                <p>{<Author authorId={c.owner} />}</p>
                                                            </Grid>
                                                            <Grid item>
                                                                <p><b>{`$${c.proposal.proposal.hourly}`}</b>{`/hr`}</p>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Fab 
                                                            size='small'
                                                            onClick={(e) => {handleOpenMenu(e,c)}}
                                                        >
                                                            <MoreHorizIcon />
                                                        </Fab>
                                                    </Grid>
                                                </Grid>
                                                <p>{`${moment(c.timestamp.toDate()).format('MMM Do YYYY')} - Present`}</p>
                                            </Grid>
                                        <hr />
                                        </Fragment>
                                    ))}
                                </Fragment>
                            }
                        </TabPanel>
                        <TabPanel value={tab} index={'closed'}>
                            {closedContracts.length === 0 ? 
                                <Grid item className={classes.body}>
                                    <h4>You have no closed contracts</h4>
                                </Grid>
                                :
                                <Fragment>
                                    {closedContracts.map((c, i) => (
                                        <Fragment key={i}>
                                            <Grid item className={classes.body}>
                                                <p><b>{c.active_contract.jobData.title}</b></p>
                                                <Grid container direction='row' justify='space-between' >
                                                    <Grid item>
                                                        <Grid container direction='row' spacing={5}>
                                                            <Grid item>
                                                                <p><b>{<Company userId={c.active_contract.owner} />}</b></p>
                                                            </Grid>
                                                            <Grid item>
                                                                <p>{<Author authorId={c.active_contract.owner} />}</p>
                                                            </Grid>
                                                            <Grid item>
                                                                <p><b>{`$${c.active_contract.proposal.proposal.hourly}`}</b>{`/hr`}</p>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <p>{`${moment(c.active_contract.timestamp.toDate()).format('MMM Do YYYY')} - ${moment(c.timestamp.toDate()).format('MMM Do YYYY')}`}</p>
                                            </Grid>
                                        <hr />
                                        </Fragment>
                                    ))}
                                </Fragment>
                            }
                        </TabPanel>
                </Paper>
            </Container>
            {/* Menu for more floating action button */}
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                <MenuItem onClick={handleLogTime}>Log Time</MenuItem>
                <MenuItem 
                    onClick={(e) => {
                        e.preventDefault()
                        setCloseContract(true)
                    }}
                >
                    Close Contract
                </MenuItem>
                <MenuItem 
                    onClick={(e) => {
                        e.preventDefault()
                        const user = {
                            id: focusedContract.owner
                        }
                        dispatch(activateConversation(user))
                        handleCloseMenu()
                    }}
                >
                    Message Client
                </MenuItem>
            </Menu>
            <Dialog
                open={closeContract}
                onClose={() => {
                    setCloseContract(false)
                }}
            >
                <DialogTitle>
                    {focusedContract && <h3>Close Contract with <span><Company userId={focusedContract.owner}/></span>?</h3>}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <h5>Make sure that you have spoken with the client about closing the contract. It is best to proceed only when you and the client have agreed to end the contract.</h5>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color='secondary'
                        onClick={(e) => {
                            e.preventDefault()
                            setCloseContract(false)
                            handleCloseMenu()
                            setErrorMessage(null)
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        color='primary'
                        disabled={loading}
                        onClick= {e => {
                            e.preventDefault()
                            setLoading(true)
                            dispatch(closeContractUserSide(focusedContract.id, focusedContract.proposal.job_id, focusedContract.owner))
                            .then(() => {
                                setCloseContract(false)
                                handleCloseMenu()
                                setErrorMessage(null)
                            })
                            .catch(e => {
                                setErrorMessage('There was an error closing the contract')
                            })
                            .finally(() => {
                                setLoading(false)
                            })
                        }}
                    >
                        {loading ? 
                            <CircularProgress size='20px' />
                        :
                            `Confirm`
                        }
                    </Button>
                </DialogActions>
                <p style={{color: 'red', textAlign: 'right', paddingRight: 10}}>{errorMessage && errorMessage}</p>
            </Dialog>
        </div>
    )
}

export default Contracts