import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import CircularProgress from '@material-ui/core/CircularProgress'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import JobName from './JobName'
import grey from '@material-ui/core/colors/grey'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    waiting: {
        padding: theme.spacing(3),
    },
    card: {
        padding: theme.spacing(2),
        width: 250,
        height: 350,
    },
    largeAvatar: {
        width: theme.spacing(12),
        height: theme.spacing(12),
    },
    job: {
        textAlign: 'center',
    },
    grid: {
        width: '100%',
        height: '100%',
    },
    top: {
        width: '100%',
        backgroundColor: grey[400],
    },
}))

function NewProfileCard({ appProfile, consultantId }) {
    const classes = useStyles()

    useFirestoreConnect([
        {
            collection: 'users',
            doc: consultantId,
            storeAs: `${consultantId}-profile`
        }
    ])
    const profileArray = useSelector(state => state.firestore.ordered[`${consultantId}-profile`])

    if (!profileArray) return <Paper className={classes.waiting}><CircularProgress /></Paper>
    if (!profileArray[0]) return <Paper className={classes.waiting}><CircularProgress /></Paper>
    const profile = profileArray[0]

    return (
        <Paper className={classes.card}>
            <Grid container direction='column' justify='space-between' alignItems='center' className={classes.grid}>
                <Grid item>
                    <Grid container direction='column' justify='center' alignItems='center'>
                        <Grid item>
                            <Avatar 
                                src={profile.profileImageUrl}
                                className={classes.largeAvatar}
                            />
                        </Grid>
                        <Grid item>
                            <h4><b>{`${profile.firstName} ${profile.lastName}`}</b></h4>
                        </Grid>
                        <Grid item>
                            <JobName job={appProfile.job} hasSecondary={appProfile.secondaryJob}/>
                            {appProfile.secondaryJob ? 
                                <JobName job={appProfile.secondaryJob} hasSecondary/>
                            :
                                null
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <h6 className={classes.job}>{`U.S. ${appProfile.branchOfService}`}</h6>
                    <Link to={`/profile/${consultantId}`}>
                        <Button
                            color='primary'
                            variant='outlined'
                        >
                            Profile
                        </Button>
                    </Link>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default NewProfileCard