import React, { Fragment } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import blue from '@material-ui/core/colors/blue'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
  blue: {
    color: theme.palette.getContrastText(blue[800]),
    backgroundColor: blue[800],
  },
  blueLarge: {
    color: theme.palette.getContrastText(blue[800]),
    backgroundColor: blue[800],
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));


function UserAvatar({ userId, large, responsive }) {
  const classes = useStyles()

  const smallScreen = useMediaQuery('(max-width:700px)')

  useFirestoreConnect([
      { collection: 'users', 
        doc: userId,
        storeAs: `${userId}-profile`
      }
  ])
  const userProfile = useSelector(state => state.firestore.ordered[`${userId}-profile`])
  
  if (!userProfile || !userProfile[0]) return <div/>

  return (
      <Fragment>
        {userProfile[0].profileImageUrl ? 
          <Avatar 
            src={userProfile[0].profileImageUrl} 
            className={
              large ? classes.large : 
              null
            }
          />
        :
          <Avatar 
            alt={userProfile[0].username} 
            src="/broken-path" 
            className={
              large ? classes.blueLarge : 
              classes.blue
            }
          />
        }
      </Fragment>
  )
}
export default UserAvatar