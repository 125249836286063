import React, { useState, useCallback } from 'react'
import ForumCard from './ForumCard'
import update from 'immutability-helper'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { updateForumIndex } from '../../../Redux/Actions/forumActions'
import { connect } from 'react-redux'

const style = {
  width: 300,
  marginTop: '10px',
}
const Container = ({ genres, forum_doc_id, sectionName, updateForumIndex }) => {
  {

    var stateArray = genres && genres.map(genre => ({
      doc_id: genre.id,
      title: genre.title,
      index: genre.index,
    }))

    const [cards, setCards] = useState(stateArray)

    const handleSave = (e) => {
      e.preventDefault()
      cards.map((card, index) => {
        const genre = {
          forum_doc_id,
          genre_doc_id: card.doc_id,
          index
        }
        updateForumIndex(genre)
      })
    } 

    const moveCard = useCallback(
      (dragIndex, hoverIndex) => {
        const dragCard = cards[dragIndex]
        setCards(
          update(cards, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          }),
        )
      },
      [cards],
    )
    const renderCard = (card, index) => {
      return (
        <ForumCard
          key={card.doc_id}
          index={index}
          forum_id={forum_doc_id}
          genre_id={card.doc_id}
          text={card.title}
          moveCard={moveCard}
        />
      )
    }
    if (!cards) {
      return <div/>
    }
    return (
      <>
        <Grid container direction='row' justify='space-evenly' alignItems='center'>
          <h5><b>{sectionName}</b></h5>
          <Button 
            color='primary' 
            variant='outlined'
            onClick={handleSave}
          >
            Save Changes
          </Button>
        </Grid>
        <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateForumIndex: (genre) => {dispatch(updateForumIndex(genre))}
  }
}

export default connect(null, mapDispatchToProps)(Container)
