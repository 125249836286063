import React, { Fragment, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import UserAvatar from '../UserComponents/UserAvatar'
import Author from '../ForumComponents/Author'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import QuotedComment from '../ForumComponents/QuotedComment'
import DeleteComment from '../ForumComponents/DeleteComment'
import Toolbar from '@material-ui/core/Toolbar'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { removeFlag } from '../../../Redux/Actions/commentActions'
import ForumIcon from '@material-ui/icons/Forum';
import { Link } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
// This component is used to fetch the content of the flagged comment and give the admin the 
// ability to view it in context (link it to the forum where it appeared) and to delete the comment
// or remove the flagged status. 

const useStyles = makeStyles({
    user: {
      minWidth: 250,
    },
    toolbar: {
        marginLeft: 200,
    },  
    deleted: {
        marginLeft: 200,
    },
});

function FlaggedComment({ flagId, comment, forum_doc_id, genre_doc_id, topic_doc_id, removeFlag }) {
    const classes = useStyles()
    const [openRemoveDialog, setOpenRemoveDialog] = useState(false)

    const remove = () => {
        // deny user action here
        const commentProp = {
            flagId
        }
        removeFlag(commentProp)
        setOpenRemoveDialog(false)
    }

    const cancelRemove = () => {
        setOpenRemoveDialog(false)
    }

    const handleRemove = (e) => {
        e.preventDefault()
        setOpenRemoveDialog(true)
    }

    const root = {
        textAlign: 'center',
        marginTop: 150,
    }
        const loader = {
        display: 'inlineBlock'
    }

    if (!comment) return <div style={root}><CircularProgress style={loader} /> </div>
    
    return (
        <div>
            {comment && comment.map(com => (
                <Grid container direction='row' justify='flex-start' alignItems='center' wrap='nowrap' spacing={1} key={com.id}>
                    <div className={classes.user}>
                        <Grid container direction='column' justify='center' alignItems='center' spacing={1}>
                            <Grid item>
                                <UserAvatar userId={com.authorId} />
                            </Grid>
                            <Grid item>
                                <Author authorId={com.authorId} bold={true} link={true}/>
                            </Grid>
                            <Grid item>
                            {comment.createdAt ?
                                <p>{moment(com.createdAt.toDate().toString()).calendar()}</p>
                            :
                                null
                            }
                            </Grid>
                        </Grid>
                    </div>
                    <Grid item>
                        <Grid container direction='column' justify='flex-start' alignItems='center'>
                        <Grid item>
                            {com.repliedToId ? <p>in reply to <Author authorId={com.repliedToAuthorId} bold={false} /></p> : null}
                            {com.isQuoted ? 
                                <Fragment>
                                    <QuotedComment 
                                    topic_doc_id={topic_doc_id}
                                    commentId={com.repliedToId}
                                    authorId={com.repliedToAuthorId}
                                    />
                                </Fragment>
                            : 
                                null
                            }
                            <Typography variant="body1" style={{whiteSpace: 'pre-line', paddingTop: '15px', paddingBottom: '20px'}}>
                            <Fragment>{com.comment}</Fragment>
                            </Typography>
                        </Grid>
                    </Grid>
                    </Grid>
                </Grid>
            ))}
            {comment && comment.length === 0 ? 
                <div className={classes.deleted}>
                    <p>This comment has been deleted.</p>
                </div>
            :
                null
            }
            <Toolbar className={classes.toolbar}>
                {comment && comment.map(com => (
                    <Fragment key={com.id}>
                        <Tooltip title='View in forum'>
                            <Link to={`/forum/${forum_doc_id}/${genre_doc_id}/${topic_doc_id}`} >
                                <IconButton>
                                    <ForumIcon />
                                </IconButton>
                            </Link>
                        </Tooltip>
                        <DeleteComment 
                            forum_doc_id={forum_doc_id}
                            genre_doc_id={genre_doc_id}
                            topic_doc_id={topic_doc_id}
                            comment_doc_id={com.id}
                            flagId={flagId}
                            flag
                            large
                            tooltip='Delete comment from forum'
                        />
                    </Fragment>
                ))}
                <Tooltip title='Remove flag'>
                    <IconButton onClick={handleRemove}>
                        <RemoveCircleIcon />
                    </IconButton>
                </Tooltip>
            </Toolbar>
            <Dialog
                open={openRemoveDialog}
                onClose={() => setOpenRemoveDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Remove this flag?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This flag has been reviewed and no issues have been found. 
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={cancelRemove} color="primary">
                    Cancel
                </Button>
                <Button onClick={remove} color="primary" autoFocus>
                    Remove
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        removeFlag: (comment) => {dispatch(removeFlag(comment))}
    }
}
  
export default compose(
firestoreConnect((props) => [
    { collection: 'forum', 
    doc: props.forum_doc_id, 
    subcollections: [{collection: 'genre',
                        doc: props.genre_doc_id,
                        subcollections: [{collection: 'topic',
                                        doc: props.topic_doc_id,
                                        subcollections: [{collection: 'comment',
                                                          doc: props.comment_doc_id,
                                                        }]
                                        }]
                    }],
    storeAs: `${props.comment_doc_id}-flagged-comment`
    }
]),
connect((state, props) => ({
    comment: state.firestore.ordered[`${props.comment_doc_id}-flagged-comment`],
}), mapDispatchToProps))(FlaggedComment)