import React from "react";
import { Link } from 'react-router-dom'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/grid";
import Container from "@material-ui/core/container";
import Paper from '@material-ui/core/Paper'

//images
import rangers from '../../Assets/images/RGR_Silhouette.jpeg' 

// core components
import CustomInput from "../MaterialKitComponents/CustomInput.js";
import Button from '@material-ui/core/Button'

import workStyle from "../../Assets/workStyle.js";

const useStyles = makeStyles(workStyle);

export default function SectionWork() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <Container maxWidth='md'>
        <Grid container direction='row' justify='center' alignItems='center' spacing={5}>
          <Grid item>
            <Paper style={{borderRadius: '5%'}} elevation={24}>
              <img style={{borderRadius: '5%', maxWidth: '90vw'}} src={rangers} width='440' />
            </Paper>
          </Grid>
          <Grid item md>
            <Grid container direction='column' justify="center" alignItems='center' >
              <Grid item >
                <h2 className={classes.title}>Join the Ecosystem of Users</h2>
              </Grid>
              <Grid item>
                <h4 className={classes.description}>
                  Users understand the challenges facing our military. From form, fit and function to 
                  technology friction points, our industry partners are seeking your inputs to develop 
                  “best of breed” solutions. The User Community is a platform that connects the defense 
                  innovation ecosystem. Provide expertise gained from years of service to shape cutting 
                  edge solutions.
                </h4>
              </Grid>
              <Grid item>
                <Link to='/choose-registration/Consultant'>
                  <Button color='primary' variant='contained' size='large'>Apply</Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
