import React, {useState, Fragment} from 'react' 
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import DndForumListMiddleWare from './DndForumListMiddleWare'

function ArrangeForums({ forums }) {

  const [docId, setDocId] = useState(null) 

  const paper = {
    padding: 24,
    marginTop: 10,
  }

  const root = {
    flexGrow: 1,
  }

  const handleListItemClick = (event, id, index) => {
    // set the selected forum section
    setDocId(id)
  }

  if (!forums) {
    return <div/>
  }

  // Make sure every forum has an id before loading 
  // (There is an occasional glitch after arranging sections when one will not have an id and causes this page to crash)
  // The following code prevents that crash
  var loaded = true
  forums.map(section => {
    if (!section.id) {
      loaded = false
    }
  })

  if (!loaded) return <div />

  return (
    <div style={{paddingTop: 100}}>
      <Paper elevation={3} style={paper}>
        <Grid container direction='column' justify='center' alignItems='center'>
          <h5>Drag and drop to arrange forums</h5>
          <Divider />
            <Grid container direction='row' justify='space-evenly' alignItems='flex-start'>
              {forums.map(section => (
                <Grid item key={section.id}>
                  <div style={{textAlign: 'center'}}>
                    <DndForumListMiddleWare forum_doc_id={section.id} key={docId} sectionName={section.title} />
                  </div>
                </Grid>
              ))} 
            </Grid>
        </Grid>
      </Paper>
    </div>
  )
} 
export default compose(
  firestoreConnect((props) => [
    {
      collection: 'forum',
      orderBy: ['index'],
    },
  ]),
  connect((state, props) => ({
    forums: state.firestore.ordered.forum
  }))
)(ArrangeForums)