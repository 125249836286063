import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import PermMediaOutlinedIcon from '@material-ui/icons/PermMediaOutlined';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import { Link, withRouter } from 'react-router-dom'
import {signOut} from '../../../Redux/Actions/authActions'
import {connect} from 'react-redux'
import firebase from 'firebase/app'
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import NotificationIcon from './NotifcationIcon'
import AdminNotificationIcon from './AdminNotificationIcon'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  color: {
    backgroundColor: 'rgb(25,34,60)',
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 200,
  },
  link: {
    textDecoration: 'none',
    color: 'black',
  },
  loginLink: {
    textDecoration: 'none',
    color: 'white',
  }
}));

const HeaderSmall = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    drawer: false,
  });
  const [admin, setAdmin] = React.useState(false)

  firebase.auth().onAuthStateChanged(user => {
    if (user) {
      user.getIdTokenResult().then(idTokenResult => {
        setAdmin(idTokenResult.claims.admin)
      }).catch(error => {
      })
    }
    else {
      setAdmin(false)
    }
  })

  const sevenDaysAgo = new Date()

  const {signOut, auth, profile} = props
  const signedIn = auth.uid

  const toggleDrawer = (open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, drawer: open });
  };

  const goToLogin = () => {
    props.history.push('/login')
  }

  function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });
  
    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }

  return (
    <div className={classes.root}>
      <ElevationScroll {...props}>
        <AppBar className={classes.color}>
          <Toolbar>
            <IconButton onClick={toggleDrawer(true)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
            </Typography>
            {auth.isLoaded ? (signedIn ? 
              <Fragment>
                {admin ?
                  <AdminNotificationIcon 
                    authId={auth.uid}
                    timeFrame={sevenDaysAgo}
                  />
                :
                  <NotificationIcon 
                    authId={auth.uid}
                    timeFrame={sevenDaysAgo}
                  />
                }
                <Button color="inherit" onClick={signOut}>Logout</Button> 
              </Fragment>
              : 
              <Button color="inherit" onClick={goToLogin}>Login</Button>
            ) : null
            }
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Drawer anchor="left" open={state.drawer} onClose={toggleDrawer(false)}>
        <div
        className={classes.list}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
        >
          <List>
            <Link to='/' className={classes.link}>
              <ListItem button key='Home'>
                <ListItemIcon><HomeOutlinedIcon /></ListItemIcon>
                <ListItemText primary='Home' />
              </ListItem>
            </Link>
            <Link to='/forum' className={classes.link}>
              <ListItem button key='Forum'>
                <ListItemIcon><ForumOutlinedIcon /></ListItemIcon>
                <ListItemText primary='Forum' />
              </ListItem>
            </Link>
            <Link to='/experts' className={classes.link}>
              <ListItem button key='Users'>
                <ListItemIcon><AccountBoxOutlinedIcon /></ListItemIcon>
                <ListItemText primary='Users' />
              </ListItem>
            </Link>
            {profile && profile.token && profile.token.claims.SME ?
              <Link to='/jobs' className={classes.link}>
                <ListItem button key='Jobs'>
                  <ListItemIcon><AccountBoxOutlinedIcon /></ListItemIcon>
                  <ListItemText primary='Jobs' />
                </ListItem>
              </Link>
              :
              null
            }
            {profile && profile.token && profile.token.claims.premium ?
              <Link to={`/job-listings/${auth.uid}`} className={classes.link}>
                <ListItem button key='Jobs'>
                  <ListItemIcon><AccountBoxOutlinedIcon /></ListItemIcon>
                  <ListItemText primary='Jobs' />
                </ListItem>
              </Link>
              :
              null
            }
            {signedIn ?
              <Link to={`/profile/${auth.uid}`} className={classes.link}>
                <ListItem button key='Profile'>
                  <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                  <ListItemText primary='Profile' />
                </ListItem>
              </Link>
            :
              null
            }
            {admin ? 
              <Link to='/admin' className={classes.link}>
                <ListItem button key='Admin'>
                  <ListItemIcon><VerifiedUserIcon /></ListItemIcon>
                  <ListItemText primary='Admin' />
                </ListItem>
              </Link>
            :
              null
            }
          </List>
        </div>
      </Drawer>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => {dispatch(signOut())}
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderSmall))