import React, { Fragment } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

const JobTooltip = withStyles(theme => ({
    tooltip: {
      fontSize: 14,
      textAlign: 'center'
    },
}))(Tooltip);

const useStyles = makeStyles(theme => ({
    job: {
        textAlign: 'center',
    },
}))

function JobName({ job, hasSecondary }) {
    const classes = useStyles()

    if (hasSecondary) {
        return (
            <Fragment>
                {job.length > 28 ? 
                    <JobTooltip title={job} placement="top-start" arrow>
                        <h5 className={classes.job}>{job.substr(0, job.indexOf(' '))}</h5>
                    </JobTooltip>
                :
                    <h5 className={classes.job}>{job}</h5>
                }
            </Fragment>
        )
    }
    else {
        return (
            <Fragment>
                {job.length > 50 ? 
                    <JobTooltip title={job} placement="top-start" arrow>
                        <h5 className={classes.job}>{job.substr(0, job.indexOf(' '))}</h5>
                    </JobTooltip>
                :
                    <h5 className={classes.job}>{job}</h5>
                }
            </Fragment>
        )
    }
}

export default JobName