/*eslint-disable*/
import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
// @material-ui/icons
import Group from "@material-ui/icons/Group";
import Face from '@material-ui/icons/AccountCircle'
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";
import LockIcon from '@material-ui/icons/Lock';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import red from '@material-ui/core/colors/red'
// core components
import GridContainer from "../GridContainer.js"
import GridItem from "../GridItem.js"
import Button from "../Button.js"
import Card from "../Card.js"
import CardBody from "../CardBody.js"
import InfoArea from "../InfoArea.js"
// for signing up
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { signUpSME, signOut, saveEThree } from '../../../Redux/Actions/authActions'
import {getFirestore} from 'redux-firestore'
import { Link } from 'react-router-dom'

import signupPageStyle from "../../../Assets/signupPageStyle.js"
import backgroundPic from '../../../Assets/images/honey.jpg'

const useStyles = makeStyles(signupPageStyle);

const useStyles2 = makeStyles(theme => ({
  root: {
    backgroundColor: '#FFF',
    minHeight: '100vh',
    backgroundImage: `url(${backgroundPic})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  title: {
    textAlign: 'center',
    fontWeight: 400,
  },
  errorMsg: {
    color: red[500],
  },
}))

function SignUpSMEPage(props) {
  const {saveEThree, signUpSME, signOut, signupEmailError, signupPasswordError, ...rest } = props
  const classes2 = useStyles2()
  // state for getting signup info
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(null)
  const [username, setUsername] = useState('')
  const [usernameError, setUsernameError] = useState(null)
  const [firstNameError, setFirstNameError] = useState(null)
  const [lastNameError, setLastNameError] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const [uncheckedError, setUncheckedError] = useState(null)
  const [checkedTerms, setCheckedTerms] = useState(false);

  const [checked, setChecked] = useState([1])

  const handleChange = (event) => {
    if (event.target.id === 'email') {
      setEmail(event.target.value)
    } else if (event.target.id === 'password') {
      setPassword(event.target.value)
      const potentialPW = event.target.value
      if (potentialPW.replace(/\s/g, '').length >= 10 && /[A-Z]/.test(potentialPW) && /[a-z]/.test(potentialPW) && /\d/.test(potentialPW) && /[^\w\s]/.test(potentialPW)) {
        setPasswordError(null)
      }
    } else if (event.target.id === 'firstName') {
      setFirstName(event.target.value)
      setFirstNameError(null)
    } else if (event.target.id === 'lastName') {
      setLastName(event.target.value)
      setLastNameError(null)
    } else if (event.target.id === 'username') {
      setUsername(event.target.value)
      setUsernameError(null)
    }
  }

  const submit = (event) => { 
    event.preventDefault()

    if (!username.replace(/\s/g, '').length) {
      setUsernameError('Please create a username')
    }
    else if (!checkedTerms) {
      setUncheckedError('Please agree to the terms and conditions')
    }
    else if (!firstName.replace(/\s/g, '').length) {
      setFirstNameError('Enter first name')
    }
    else if (!lastName.replace(/\s/g, '').length) {
      setLastNameError('Enter last name')
    }
    else if (password.replace(/\s/g, '').length < 10) {
      setPasswordError('Password must be at least 10 characters, contain at least one uppercase and one lowercase letter, one number, and one special character')
    }
    else {
      setUsernameError(null)
      const hasUpperCase = /[A-Z]/.test(password)
      const hasLowerCase = /[a-z]/.test(password)
      const hasNumber = /\d/.test(password)
      const hasSpecialChar =/[^\w\s]/.test(password)

      if (hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar) {
        const newUser = {
          email,
          password,
          firstName,
          lastName,
          username,
        }
        signUpSME(newUser)
        .then(response => {
          response.user.sendEmailVerification()
            .then(() => {
              signOut().then(() => {
                props.history.push('/verify-email')
              })
            })
        })
      }
      else {
        setPasswordError('Password must be at least 10 characters, contain at least one uppercase and one lowercase letter, one number, and one special character')
      }
    }
  }

  const handleTermsCheck = event => {
    setCheckedTerms(event.target.checked)
    setUncheckedError(null)
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  })
  const classes = useStyles();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword )
  }

  const handleMouseDownPassword = event => {
    event.preventDefault();
  }

  return (
    <div className={classes2.root}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={10} md={10}>
            <Card className={classes.cardSignup}>
              <h2 className={classes2.title}>Apply</h2>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={5} md={5}>
                    <InfoArea
                      className={classes.infoArea}
                      title="Earn Income"
                      description="Get paid for the knowledge and experience you currently have. Companies developing products for the military need to speak with an end user. That's you!"
                      icon={AttachMoneyIcon}
                      iconColor="success"
                    />
                    <InfoArea
                      className={classes.infoArea}
                      title="Contribute"
                      description="Have great ideas? Get paid by contributing useful content."
                      icon={EmojiObjectsIcon}
                      iconColor="info"
                    />
                    <InfoArea
                      className={classes.infoArea}
                      title="Be in a Community"
                      description="Meet other veterans and be involved in a community seeking the good of those who fight for ours."
                      icon={Group}
                      iconColor="primary"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={5} md={5}>
                    <form className={classes.form}>
                      <Grid container spacing={2}>
                      <Grid item xs>
                      <div style={{paddingTop: 20, paddingBottom: 20}}>
                        <TextField
                          error={firstNameError ? true : false}
                          helperText={firstNameError ? firstNameError : null}
                          id='firstName'
                          onChange={handleChange}
                          value={firstName}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Face className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            placeholder: "First Name..."
                          }}
                        />
                      </div>
                      </Grid>
                      <Grid item xs>
                      <div style={{paddingTop: 20, paddingBottom: 20}}>
                        <TextField
                          error={lastNameError ? true : false}
                          helperText={lastNameError ? lastNameError : null}
                          id='lastName'
                          onChange={handleChange}
                          value={lastName}
                          fullWidth
                          inputProps={{
                            placeholder: "Last Name..."
                          }}
                        />  
                      </div>                
                      </Grid>                
                      </Grid>
                      <div style={{paddingTop: 20, paddingBottom: 20}}>
                        <TextField
                          error={usernameError ? true : false}
                          helperText={usernameError ? usernameError : null}
                          id='username'
                          onChange={handleChange}
                          value={username}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Face className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            placeholder: "Username...",
                            autoComplete: "off"
                          }}
                        />
                      </div>
                      <div style={{paddingTop: 20, paddingBottom: 20}}>
                        <TextField
                          error={signupEmailError ? true : false}
                          helperText={signupEmailError ? signupEmailError : null}
                          id='email'
                          onChange={handleChange}
                          value={email}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Email className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            placeholder: "Email..."
                          }}
                        />
                      </div>
                      <div style={{paddingTop: 20, paddingBottom: 20}}>
                        <TextField
                          error={signupPasswordError ? true : passwordError ? true : false}
                          helperText={signupPasswordError ? signupPasswordError : passwordError ? passwordError : null}
                          id='password'
                          onChange={handleChange}
                          value={password}
                          type={showPassword ? 'text' : 'password'}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <LockIcon className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                              ),
                            placeholder: "Password...",
                            autoComplete: "off"
                          }}
                        />
                      </div>
                      <FormControlLabel
                        classes={{
                          label: classes.label
                        }}
                        control={
                          <Checkbox
                            onClick={handleTermsCheck}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                            checked={checkedTerms}
                          />
                        }
                        label={
                          <span>
                            I agree to the{" "}
                            <Link to='/terms' target='_blank'>terms and conditions</Link>
                            {" "}and <Link to='/privacy' target='_blank'>privacy policy</Link>.
                          </span>
                        }
                      />
                      <div className={classes.textCenter} style={{color: red[500]}}>
                        {uncheckedError ? uncheckedError : null}
                      </div>
                      <div className={classes.textCenter}>
                        <Button 
                          round 
                          color="pinterest"
                          onClick={submit}
                        >
                          Get started
                        </Button>
                      </div>
                    </form>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    signupEmailError: state.auth.signupEmailError,
    signupPasswordError: state.auth.signupPasswordError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signUpSME: (newUser) => {return dispatch(signUpSME(newUser))},
    signOut: () => {return dispatch(signOut())},
    saveEThree: (eThree) => {dispatch(saveEThree(eThree))},
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUpSMEPage))
