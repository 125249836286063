import React, { Fragment, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import SortIcon from '@material-ui/icons/Sort';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import AddIcon from '@material-ui/icons/Add';
import ArrangeForums from '../Components/ForumComponents/ArrangeForums'
import ArrangeSections from '../Components/ForumComponents/ArrangeSections'
import NewSection from '../Components/ForumComponents/NewSection'
import NewForum from '../Components/ForumComponents/NewForum'
import ApplicationsAdmin from '../Components/AdminComponents/ApplicationsAdmin'
import LicensesAdmin from '../Components/AdminComponents/LicensesAdmin'
import ManageAdmins from '../Components/AdminComponents/ManageAdmins'
import AddAdmin from '../Components/AdminComponents/AddAdmin'
import FlaggedComments from '../Components/AdminComponents/FlaggedComments'
import PrivateTopics from '../Components/AdminComponents/PrivateTopics'
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import { getAdmins } from '../../Redux/Actions/authActions'
import {connect} from 'react-redux'
import { Redirect } from 'react-router-dom'
import firebase from 'firebase'
import BusinessIcon from '@material-ui/icons/Business'
import { withRouter } from 'react-router-dom'


const drawerWidth = 240

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      position: 'relative',
      zIndex: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
    nested: {
        paddingLeft: theme.spacing(6),
    },
    content: {
        flexGrow: 1,
    },
  }))

function AdminNavigation(props) {
    const classes = useStyles()
    const { getAdmins, auth, path } = props

    const [openForumTool, setOpenForumTool] = useState(false)
    const [openAdminTool, setOpenAdminTool] = useState(false)
    const [view, setView] = useState('Dashboard')

    const [admin, setAdmin] = useState(false)

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user.getIdTokenResult().then(idTokenResult => {
          setAdmin(idTokenResult.claims.admin)
        }).catch(error => {
          console.log(error)
        })
      }
      else {
        setAdmin(false)
      }
    })

    const handleForumClick = () => {
        setOpenForumTool(!openForumTool)
    }

    const handleAdminClick = () => {
        setOpenAdminTool(!openAdminTool)
    }

    const fetchAdmins = (e) => {
        e.preventDefault()
        getAdmins()
        props.history.push(`/admin/manage-admins`)
    } 

    const handleRouteChange = (route) => {
        props.history.push(`/admin/${route}`)
    }
 
    const viewSwitch = () => {
        switch (path) {
            case 'dashboard':
                return <h5>Dashboard</h5>
                break;
            case 'arrange-forums':
                return <ArrangeForums />
                break;
            case 'arrange-sections':
                return <ArrangeSections />
                break;
            case 'add-section':
                return <NewSection />
                break;
            case 'add-forum':
                return <NewForum />
                break;
            case 'flagged-comments':
                return <FlaggedComments />
                break;
            case 'private-topics':
                return <PrivateTopics />
                break;
            case 'applications':
                return <ApplicationsAdmin />
                break;
            case 'licenses':
                return <LicensesAdmin />
                break;
            case 'add-admins':
                return <AddAdmin />
                break;
            case 'manage-admins':
                return <ManageAdmins />
                break;
            default:
                break;
        }
    }

    if (!admin) return <div/>

    return (
        <div className={classes.root}>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                paper: classes.drawerPaper,
                }}
                anchor="left"
            >
                <div className={classes.toolbar}/>
                <div className={classes.toolbar}>
                    <Grid container direction='column' justify='center' alignItems='center'>
                        <h4 style={{paddingTop: 10}}><b>Admin</b></h4>
                    </Grid>
                </div>
                <Divider />
                <List>
                    <ListItem button onClick={() => handleRouteChange('dashboard')} key={0}>
                        <ListItemIcon><DashboardOutlinedIcon /></ListItemIcon>
                        <ListItemText primary='Dashboard' />
                    </ListItem>
                    <ListItem button onClick={handleForumClick} key={1}>
                        <ListItemIcon><ForumOutlinedIcon /></ListItemIcon>
                        <ListItemText primary='Forum' />
                        {openForumTool ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openForumTool} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                        <ListItem button onClick={() => handleRouteChange('arrange-sections')} className={classes.nested}>
                            <ListItemText primary="Arrange Sections" />
                        </ListItem>
                        <ListItem button onClick={() => handleRouteChange('arrange-forums')} className={classes.nested}>
                            <ListItemText primary="Arrange Forums" />
                        </ListItem>
                        <ListItem button onClick={() => handleRouteChange('add-section')} className={classes.nested}>
                            <ListItemText primary="Add Section" />
                        </ListItem>
                        <ListItem button onClick={() => handleRouteChange('add-forum')} className={classes.nested}>
                            <ListItemText primary="Add Forum" />
                        </ListItem>
                        <ListItem button onClick={() => handleRouteChange('flagged-comments')} className={classes.nested}>
                            <ListItemText primary="Flagged Comments" />
                        </ListItem>
                        <ListItem button onClick={() => handleRouteChange('private-topics')} className={classes.nested}>
                            <ListItemText primary="Private Topics" />
                        </ListItem>
                        </List>
                    </Collapse>
                    <ListItem button onClick={() => handleRouteChange('applications')} key={2}>
                        <ListItemIcon><AssignmentOutlinedIcon /></ListItemIcon>
                        <ListItemText primary='Applications' />
                    </ListItem>
                    <ListItem button onClick={() => handleRouteChange('licenses')} key={2.1}>
                        <ListItemIcon><BusinessIcon /></ListItemIcon>
                        <ListItemText primary='Licenses' />
                    </ListItem>
                    <ListItem button onClick={handleAdminClick} key={3}>
                        <ListItemIcon><VerifiedUserOutlinedIcon /></ListItemIcon>
                        <ListItemText primary='Administrators' />
                        {openAdminTool ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openAdminTool} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                        <ListItem button onClick={() => handleRouteChange('add-admins')} className={classes.nested}>
                            <ListItemText primary="Add Admins" />
                        </ListItem>
                        <ListItem button onClick={fetchAdmins} className={classes.nested}>
                            <ListItemText primary="Manage Admins" />
                        </ListItem>
                        </List>
                    </Collapse>
                </List>
            </Drawer>
            <main className={classes.content}>
                <div style={{paddingTop: 115}}>
                    <Container maxWidth='lg'>
                        <DndProvider backend={Backend}>
                            {viewSwitch()}
                        </DndProvider>
                    </Container>
                </div>
            </main>
        </div>
    );
}

const mapStateToProps = (state, props) => {
    return {
        auth: state.firebase.auth,
        path: props.match.params.location
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAdmins: () => {dispatch(getAdmins())}
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminNavigation))