import React, { Component } from 'react'
import Container from '@material-ui/core/Container';


class Media extends Component {

  render() {
    return (
      <div style={{paddingTop: 60}}>
        <Container maxWidth='lg'>
          <h1>Media Page</h1>
        </Container>
      </div>
    )
  }
}
export default Media