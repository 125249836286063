import React, { Fragment, useState } from 'react'
import AdminSheet from '../Components/ForumComponents/AdminSheet'
import {withRouter} from 'react-router-dom'
import firebase from 'firebase/app'
import Container from '@material-ui/core/Container';


function ForumAdmin() {
  const [admin, setAdmin] = useState(false)
  
  firebase.auth().onAuthStateChanged(user => {
    if (user) {
      user.getIdTokenResult().then(idTokenResult => {
        setAdmin(idTokenResult.claims.admin)
      }).catch(error => {
        console.log(error)
      })
    }
    else {
      setAdmin(false)
    }
  })

  return (
    <div style={{paddingTop: 60}}>
      <Container maxWidth='lg'>
        <Fragment>
          {admin ?
            <AdminSheet />
          :
            null
          }
        </Fragment>
      </Container>
    </div>
  )
}
export default withRouter(ForumAdmin)