import React, {useState} from 'react';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import MKButton from '../../MaterialKitComponents/Button'
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AttachFile from "@material-ui/icons/AttachFile";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import { updateBOS, updateJobRank, updateSkills} from '../../../Redux/Actions/userActions'
import { Redirect } from 'react-router-dom'
import TagsInput from "react-tagsinput";
import DD214FileInput from '../../MaterialKitComponents/RegistrationComponents/DD214FileInput'
import ResumeFileInput from '../../MaterialKitComponents/RegistrationComponents/ResumeFileInput'
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {Link} from "react-router-dom";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Alert from '@material-ui/lab/Alert'
import useMediaQuery from '@material-ui/core/useMediaQuery'

//Style
import selectStyles from "../../../Assets/customSelectStyle.js";

// Material Kit
// core components
import GridContainer from '../../MaterialKitComponents/GridContainer'
import GridItem from '../../MaterialKitComponents/GridItem'
import { connect } from 'react-redux'
import CustomInput from '../../MaterialKitComponents/CustomInput';

// Data for forms
import * as OccupationData from './OccupationData'

const matKitStyles = makeStyles(selectStyles)

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: '50px'
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    minHeight: 450,
  },
  link: { // gws 2/12/20
    textDecoration: 'none',
    color: 'white'
  },
}));

function getSteps() {
  return ['Branch of Service', 'Expertise', 'Skills', 'DD214', 'Resume'];
}

function getStepContent(matKitClasses,
                        classes, 
                        stepIndex, 
                        branch, 
                        handleBranchChange, 
                        separationDate, 
                        handleSepDateChange, 
                        datePickerOpen,
                        setDatePickerOpen,
                        activeDuty,
                        handleActiveDuty,
                        job, 
                        handleJobChange,
                        jobList,
                        secondaryJob,
                        handleSecondaryJob,
                        rank,
                        handleRankChange,
                        skillTags,
                        handleSkillTags,
                        dd214,
                        setdd214,
                        resume,
                        setResume,
                        userId,
                        firstName,
                        lastName,
                        smallScreen
                        ) {

  const jobAcronymFactory = {
    'Navy': 'Rate',
    'Army': 'MOS',
    'Marines': 'MOS',
    'Air Force': 'AFSC',
  }

  const handleCheckActive = event => {
    handleActiveDuty(event.target.checked)
  }

  switch (stepIndex) {
    case 0:
      return (
        <Paper elevation={1} className={classes.paper}>
          <GridContainer alignItems='center' direction='column' spacing={5}>
            <GridItem xs={6} sm={6} md={5} lg={5} md={5} lg={5} style={{textAlign: 'center'}}>
              <h2>Branch, Rank, and Separation Date</h2>
            </GridItem>
            <GridItem xs={6} sm={6} md={5} lg={5} md={5} lg={5}>
              <FormControl fullWidth className={matKitClasses.selectFormControl}>
                <InputLabel
                  htmlFor="simple-select"
                >
                  Branch of Service
                </InputLabel>
                <Select
                  MenuProps={{
                    className: matKitClasses.selectMenu
                  }}
                  classes={{
                    select: matKitClasses.select
                  }}
                  value={branch}
                  onChange={handleBranchChange}
                  inputProps={{
                    name: "selectBranch",
                    id: "select-branch"
                  }}
                >
                <MenuItem 
                  value='Army'
                  classes={{
                    root: matKitClasses.selectMenuItem,
                    selected: matKitClasses.selectMenuItemSelected
                  }}
                >
                  Army
                </MenuItem>
                <MenuItem 
                  value='Navy'
                  classes={{
                    root: matKitClasses.selectMenuItem,
                    selected: matKitClasses.selectMenuItemSelected
                  }}
                >
                  Navy
                </MenuItem>
                <MenuItem 
                  value='Marines'
                  classes={{
                    root: matKitClasses.selectMenuItem,
                    selected: matKitClasses.selectMenuItemSelected
                  }}
                >
                  Marines
                </MenuItem>
                <MenuItem 
                  value='Air Force'
                  classes={{
                    root: matKitClasses.selectMenuItem,
                    selected: matKitClasses.selectMenuItemSelected
                  }}
                >
                  Air Force
                </MenuItem>
              </Select>
              </FormControl>
              </GridItem>
              <GridItem xs={6} sm={6} md={5} lg={5} md={5} lg={5}>
                <FormControl fullWidth className={matKitClasses.selectFormControl}>
                  <InputLabel
                    htmlFor="simple-select"
                  >
                    Rank
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: matKitClasses.selectMenu
                    }}
                    classes={{
                      select: matKitClasses.select
                    }}
                    value={rank}
                    onChange={handleRankChange}
                    inputProps={{
                      name: "selectRank",
                      id: "select-rank"
                    }}
                  >
                  {OccupationData.ranks.map(_rank => (
                    <MenuItem 
                      value={_rank}
                      key={_rank}
                      classes={{
                        root: matKitClasses.selectMenuItem,
                        selected: matKitClasses.selectMenuItemSelected
                      }}
                    >
                      {_rank}
                    </MenuItem>
                  ))}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={6} sm={6} md={5} lg={5} md={5} lg={5}>
              <Snackbar open={datePickerOpen} autoHideDuration={6000}>
                <MuiAlert elevation={6} variant="filled" severity='info' >
                  Select the YEAR at the VERY TOP of the date picker to change the year!
                </MuiAlert>
              </Snackbar>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Separation Date"
                    value={separationDate}
                    onOpen={() => setDatePickerOpen(true)}
                    onClose={() => setDatePickerOpen(false)}
                    onChange={handleSepDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <FormControlLabel
                    style={{paddingTop: 20, paddingLeft: 30}}
                    control={
                      <Checkbox 
                        checked={activeDuty}
                        onChange={handleCheckActive}
                        checkedIcon={<Check className={matKitClasses.checkedIcon} />}
                        icon={<Check className={matKitClasses.uncheckedIcon} />}
                        classes={{
                          checked: matKitClasses.checked,
                          root: matKitClasses.checkRoot
                        }}
                      />}
                    label="I'm active duty"
                  />
                </MuiPickersUtilsProvider>
              </GridItem>
          </GridContainer>
        </Paper>
      )
    case 1:
      return (
        <Paper elevation={1} className={classes.paper}>
        <GridContainer alignItems='center' direction='column' spacing={5} >
          <GridItem xs={6} sm={6} md={5} lg={5} md={5} lg={5} style={{textAlign: 'center'}}>
            <h2>{jobAcronymFactory[branch]}</h2>
          </GridItem>
          <GridItem xs={9} sm={9} md={8} lg={8} md={7} lg={7}>
            <FormControl fullWidth className={matKitClasses.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
              >
                {`Enter ${branch} ${jobAcronymFactory[branch]}`}
              </InputLabel>
              <Select
                MenuProps={{
                  className: matKitClasses.selectMenu
                }}
                classes={{
                  select: matKitClasses.select
                }}
                value={job}
                onChange={handleJobChange}
                inputProps={{
                  name: "selectJob",
                  id: "select-job"
                }}
              >
              {jobList.map(job => (
                <MenuItem 
                  value={job}
                  key={job}
                  classes={{
                    root: matKitClasses.selectMenuItem,
                    selected: matKitClasses.selectMenuItemSelected
                  }}
                >
                {job}
              </MenuItem>
              ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={9} sm={9} md={8} lg={8} md={7} lg={7}>
            <FormControl fullWidth className={matKitClasses.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
              >
                {`Enter secondary ${jobAcronymFactory[branch]} if applicable`}
              </InputLabel>
              <Select
                MenuProps={{
                  className: matKitClasses.selectMenu
                }}
                classes={{
                  select: matKitClasses.select
                }}
                value={secondaryJob ? secondaryJob : ''}
                onChange={handleSecondaryJob}
                inputProps={{
                  name: "selectSecondaryJob",
                  id: "select-secondary-job"
                }}
              >
              {jobList.map(secondary_job => (
                <MenuItem 
                  value={secondary_job}
                  key={secondary_job}
                  classes={{
                    root: matKitClasses.selectMenuItem,
                    selected: matKitClasses.selectMenuItemSelected
                  }}
                >
                {secondary_job}
              </MenuItem>
              ))}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
      </Paper>
      )
    case 2:
      return (
        <Paper elevation={1} className={classes.paper}>
          <GridContainer alignItems='center' direction='column' style={{textAlign: 'center'}} spacing={5} >
            <GridItem xs={6} sm={6} md={5} lg={5} md={5} lg={5}>
              <h2>Skills</h2>
              <h4>These skills will be used to match you up with businesses who need your expertise. Take some time on this step. We know you have skills!</h4>
            </GridItem>
            <GridItem xs={6} sm={6} md={5} lg={5} md={5} lg={5}>
              <TagsInput
                value={skillTags}
                onChange={handleSkillTags}
                tagProps={{ className: "react-tagsinput-tag primary" }}
                inputProps={{placeholder: 'Add a skill'}}
              />
            </GridItem>
          </GridContainer>
        </Paper>
      )
      case 3: 
        return (
          <Paper elevation={1} className={classes.paper}>
          <GridContainer alignItems='center' direction='column'  spacing={5} >
            <GridItem xs={6} sm={6} md={5} lg={5} md={5} lg={5} style={{textAlign: 'center'}}>
              <h2>Upload DD-214</h2>
              <Alert severity="warning">Please redact your social security number before uploading</Alert>
            </GridItem>
              <GridItem xs={6} sm={6} md={5} lg={5} md={5} lg={5}>
                <DD214FileInput
                  userId={userId}
                  dd214={dd214}
                  setdd214={setdd214}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    placeholder: "DD214..."
                  }}
                  endButton={{
                    buttonProps: {
                      round: true,
                      color: "primary",
                      justIcon: true,
                      fileButton: true
                    },
                    icon: <AttachFile />
                  }}
                />
              </GridItem>
            </GridContainer>
          </Paper>
        )
        case 4: 
        return (
          <Paper elevation={1} className={classes.paper}>
          <GridContainer alignItems='center' direction='column' spacing={5} >
            <GridItem xs={6} sm={6} md={5} lg={5} md={5} lg={5} style={{textAlign: 'center'}}>
              <h2>Upload Resume</h2>
            </GridItem>
              <GridItem xs={6} sm={6} md={5} lg={5} md={5} lg={5} >
                <ResumeFileInput
                  userId={userId}
                  name={`${firstName} ${lastName}`}
                  resume={resume}
                  setResume={setResume}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    placeholder: "Resume..."
                  }}
                  endButton={{
                    buttonProps: {
                      round: true,
                      color: "primary",
                      justIcon: true,
                      fileButton: true
                    },
                    icon: <AttachFile />
                  }}
                />
              </GridItem>
            </GridContainer>
          </Paper>
        )
    default:
      return 'Unknown stepIndex';
  }
}

function SMEApplication({applicationProps, smePrivateProps, updateBOS, updateJobRank, updateSkills, auth, profile}) {

  const smallScreen = useMediaQuery('(max-width:700px)')

  const classes = useStyles();
  const matKitClasses = matKitStyles()
  // This state determines what step in the 'stepper' we are on
  const [activeStep, setActiveStep] = useState( (applicationProps.branchOfService && applicationProps.rank && (applicationProps.dateOfSeparation || applicationProps.activeDuty)) ? ( (applicationProps.job && applicationProps.rank) ? (applicationProps.skills ? (smePrivateProps.DD214 ? (smePrivateProps.resume ? 4 : 4) : 3) : 2) : 1 ) : 0 );
  /*
    Here is the state for the Application entries
  */
  // Branch of service
  const [branch, setBranch] = useState(applicationProps.branchOfService ? applicationProps.branchOfService : '')
  // Separation Date
  const [separationDate, setSeparationDate] = useState(applicationProps.dateOfSeparation ? applicationProps.dateOfSeparation.toDate() : null);
  // active duty
  const [activeDuty, setActiveDuty] = useState(applicationProps.activeDuty ? applicationProps.activeDuty : false)
  // The MOS, Rate, or AFSC
  const [job, setJob] = useState(applicationProps.job ? applicationProps.job : '')
  // secondary MAS, rate, or AFSC if they had one
  const [secondaryJob, setSecondaryJob] = useState(applicationProps.secondaryJob ? applicationProps.secondaryJob : null)
  // The rank (E-1 to O-10)
  const [rank, setRank] = useState(applicationProps.rank ? applicationProps.rank : '')
  // The list of skills the applicant has listed
  const [skillTags, setSkillTags] = useState(applicationProps.skills ? applicationProps.skills : [])
  // the filename of DD214 
  const [dd214, setdd214] = useState(smePrivateProps.DD214 ? smePrivateProps.DD214 : null)
  // the filename of resume
  const [resume, setResume] = useState(smePrivateProps.resume ? smePrivateProps.resume : null)
  // in order to give alert when date picker is open
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  // Job list 
  const [jobList, setJobList] = useState(
    (applicationProps.branchOfService && applicationProps.rank) ? 
      (applicationProps.branchOfService === 'Army' ? 
        (applicationProps.rank.charAt(0) === 'E' ? 
          OccupationData.enlisted_army_codes
        :
          OccupationData.officer_army_codes
        )
      :
        (applicationProps.branchOfService === 'Navy' ? 
          (applicationProps.rank.charAt(0) === 'E' ? 
            OccupationData.enlisted_navy_rates
          :
            OccupationData.officer_navy_rates
          )
        :
          (applicationProps.branchOfService === 'Marines' ? 
            (applicationProps.rank.charAt(0) === 'E' ? 
              OccupationData.enlisted_marine_codes
            :
              OccupationData.officer_marine_codes
            )
          :
            (applicationProps.branchOfService === 'Air Force' ? 
              (applicationProps.rank.charAt(0) === 'E' ? 
                OccupationData.enlisted_AFSC_codes
              :
                OccupationData.officer_AFSC_codes
              )
            :
              []
            )
          )
        )
      )
    :
      []
  )
  
  /*
    Here are the handlers for the application input
    They are passed into the getStepContent function
  */
  const handleBranchChange = event => {
    setBranch(event.target.value);
    jobListFactory(event.target.value, rank)
  };

  const handleSepDateChange = date => {
    setSeparationDate(date);
  };

  const handleJobChange = event => {
    setJob(event.target.value);
  };

  const handleSecondaryJob = event => {
    if (event.target.value === '') {
      setSecondaryJob(null)
    } else {
      setSecondaryJob(event.target.value);
    }
  };

  const handleRankChange = event => {
    setRank(event.target.value);
    jobListFactory(branch, event.target.value)
  };

  const handleSkillTags = regularTags => {
    setSkillTags(regularTags);
  };

  const handleActiveDuty = isChecked => {
    setActiveDuty(isChecked)
  }

  const jobListFactory = (_branch, _rank) => {
    if (_branch !== '' && _rank !== '') {
      if (_branch === 'Army') {
        if (_rank.charAt(0) === 'E') {
          setJobList(OccupationData.enlisted_army_codes)
        } else {
          setJobList(OccupationData.officer_army_codes)
        }
      }
      else if (_branch === 'Navy') {
        if (_rank.charAt(0) === 'E') {
          setJobList(OccupationData.enlisted_navy_rates)
        } else {
          setJobList(OccupationData.officer_navy_rates)
        }
      }
      else if (_branch === 'Marines') {
        if (_rank.charAt(0) === 'E') {
          setJobList(OccupationData.enlisted_marine_codes)
        } else {
          setJobList(OccupationData.officer_marine_codes)
        }
      }
      else if (_branch === 'Air Force') {
        if (_rank.charAt(0) === 'E') {
          setJobList(OccupationData.enlisted_AFSC_codes)
        } else {
          setJobList(OccupationData.officer_AFSC_codes)
        }
      }
    }
    else {
      setJobList([])
    }
  }

  const steps = getSteps();

  const handleNext = () => {
    // If nothing was updated, then do not write the same thing to the database again 
    if (activeStep === 0) {
      if (activeDuty) {
        const user = {
          branch: branch,
          activeDuty: activeDuty,
          rank: rank,
          separationDate: null,
        }
        updateBOS(user)
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
      else {
        const user = {
          branch: branch,
          activeDuty: activeDuty,
          rank: rank,
          separationDate: separationDate
        }
        updateBOS(user)
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
    }
    else if (activeStep === 1) {
      const user = {
        job: job,
        secondaryJob: secondaryJob,
      }
      updateJobRank(user)
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
    else if (activeStep === 2) {
      const user = {
        skills: skillTags
      }
      updateSkills(user)
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
    else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  if (!profile.isLoaded || !auth.isLoaded) {
    return <div/> 
  } 
  else if (!auth.uid) {
    return <Redirect to='/' />
  } 

  return (
    <div style={{paddingTop: 115, paddingBottom: 115}}>
      <Container maxWidth='lg'>
        <div className={classes.root}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <Paper elevation={1} className={classes.paper}>
                <Container maxWidth='sm' style={{textAlign: 'center'}}>
                  <GridContainer alignItems='center' direction='column' spacing={5} >
                    <h2>You're done!</h2>
                    <h4>Thanks for taking the time to fill out this application. If you are accepted as a User you will be available for businesses to contact you. While you are waiting, head over to your profile, add a picture as well as other information that will help you stand out!</h4>
                    <Link to={`/profile/${auth.uid}`} className={classes.link}>
                      <MKButton 
                        round
                        color='primary'
                      >
                        <AccountCircleIcon />
                          Profile
                      </MKButton>
                    </Link>
                  </GridContainer>
                </Container>
              </Paper>
            ) : (
              <div>
                {getStepContent(matKitClasses, 
                                classes,
                                activeStep, 
                                branch, 
                                handleBranchChange, 
                                separationDate, 
                                handleSepDateChange,
                                datePickerOpen,
                                setDatePickerOpen, 
                                activeDuty,
                                setActiveDuty,
                                job, 
                                handleJobChange,
                                jobList,
                                secondaryJob,
                                handleSecondaryJob,
                                rank,
                                handleRankChange,
                                skillTags,
                                handleSkillTags,
                                dd214,
                                setdd214,
                                resume,
                                setResume,
                                auth.uid,
                                profile.firstName,
                                profile.lastName,
                                smallScreen
                                )}
                  <div style={{marginTop: '30px'}} >
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.backButton}
                    >
                      Back
                    </Button>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      onClick={handleNext}
                      disabled={ (activeStep === 0 && (branch === '' || rank === '' || (!separationDate && !activeDuty)) || (activeStep === 1 && (job === '' || rank === '')) || (activeStep === 2 && skillTags.length === 0) || (activeStep === 3 && !smePrivateProps.DD214) || (activeStep === 4 && !smePrivateProps.resume) )}
                    >
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                </div>
            </div>
          )}
        </div>
      </div>
      </Container>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateBOS: (user) => dispatch(updateBOS(user)),
    updateJobRank: (user) => dispatch(updateJobRank(user)),
    updateSkills: (user) => dispatch(updateSkills(user))
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SMEApplication)