import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, CircularProgress } from '@material-ui/core'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
    text: {
        paddingTop: 200,
    }
}))


function AboutUs(props) {
    const classes = useStyles()

    useFirestoreConnect([
        {
            collection: 'users',
            storeAs: `awesome-users`
        }
    ])
    const users = useSelector(state => state.firestore.ordered[`awesome-users`])
    
    return (
        <div style={{paddingTop:200}}>
            <Grid container direction='column' justify='center' alignItems='center'>
                <h1 className={classes.text}>{users[0].firstName}</h1>
            </Grid>
        </div>
    )
}

export default AboutUs