export const updateBOS = (user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid
    firestore.collection('application')
             .doc(authId)
             .set({
                'branchOfService': user.branch,
                'rank': user.rank,
                'activeDuty': user.activeDuty,
                'dateOfSeparation': user.separationDate,
                'approved': false,
                'denied': false,
                'applicationComplete': false,
    }, {merge: true}).then(() => {
      dispatch({type: 'UPDATE_BOS', user})
    }).catch((error) => {
      dispatch({type: 'UPDATE_BOS_ERROR', error})
    })
  }
}

export const updateUsername = (username) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid
    firestore.collection('users')
             .doc(authId)
             .update({
                'username': username
    }).then(() => {
      dispatch({type: 'UPDATE_USERNAME', username})
    }).catch((error) => {
      dispatch({type: 'UPDATE_USERNAME_ERROR', error})
    })
  }
}

export const updateJobRank = (user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid
    firestore.collection('application')
             .doc(authId)
             .update({
                'job': user.job,
                'secondaryJob': user.secondaryJob,
    }).then(() => {
      dispatch({type: 'UPDATE_JOB', user})
    }).catch((error) => {
      dispatch({type: 'UPDATE_JOB_ERROR', error})
    })
  }
}

export const updateSkills = (user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid
    firestore.collection('application')
             .doc(authId)
             .update({
                'skills': user.skills,
    }).then(() => {
      dispatch({type: 'UPDATE_SKILLS', user})
    }).catch((error) => {
      dispatch({type: 'UPDATE_SKILLS_ERROR', error})
    })
  }
}

export const updateDD214 = (dd214) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid
    firestore.collection('sme_private')
             .doc(authId)
             .set({
                'DD214': dd214,
    }, {merge: true}).then(() => {
      dispatch({type: 'UPDATE_DD214', dd214})
    }).catch((error) => {
      dispatch({type: 'UPDATE_DD214_ERROR', error})
    })
  }
}

export const updateResume = (resume) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const batch = firestore.batch()
    const authId = getState().firebase.auth.uid
    // add resume
    const resRef = firestore.collection('sme_private').doc(authId)
    batch.update(resRef, {
      'resume': resume.url,
    })
    const dataRef = firestore.collection('application').doc(authId)
    batch.update(dataRef, {
      'applicationComplete': true,
      'appCompleteDate': firestore.FieldValue.serverTimestamp(),
    })
    // send notification to admin
    const notifRef = firestore.collection('admin_notification').doc(authId)
    batch.set(notifRef, {
      'type': 'appComplete',
      'user': resume.name,
      'completeDate': firestore.FieldValue.serverTimestamp(),
      'unread': true,
    })
    // commit batch
    batch.commit()
    .then(() => {
      dispatch({type: 'UPDATE_RESUME', resume})
      dispatch({type: 'NOTIFY_ADMIN', resume})
    }).catch((error) => {
      dispatch({type: 'UPDATE_RESUME_ERROR', error})
      dispatch({type: 'NOTIFY_ADMIN_ERROR', resume})
    })
  }
}

export const updateProfileImage = (photoData) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid
    firestore.collection('users')
             .doc(authId)
             .update({
                'profileImageUrl': photoData.url,
                'imagePosition': {
                  'horizontal': photoData.horizontal,
                  'vertical': photoData.vertical
                }
    }).then(() => {
      dispatch({type: 'UPDATE_PROFILE_IMAGE', photoData})
    }).catch((error) => {
      dispatch({type: 'UPDATE_PROFILE_IMAGE_ERROR', error})
    })
  }
}

export const updateProfileImagePosition = (position) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid
    firestore.collection('users')
             .doc(authId)
             .update({
                'imagePosition': {
                  'horizontal': position.horizontal,
                  'vertical': position.vertical
                }
    }).then(() => {
      dispatch({type: 'UPDATE_PROFILE_IMAGE_POSITION', position})
    }).catch((error) => {
      dispatch({type: 'UPDATE_PROFILE_IMAGE_POSITION_ERROR', error})
    })
  }
}

export const updateBio = (bio) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid
    firestore.collection('users')
             .doc(authId)
             .update({
                'bio': bio
    }).then(() => {
      dispatch({type: 'UPDATE_BIO', bio})
    }).catch((error) => {
      dispatch({type: 'UPDATE_BIO_ERROR', error})
    })
  }
}

export const addEmployment = (data) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid
    return new Promise((resolve, reject) => {
      firestore.collection('application')
                .doc(authId)
                .collection('employment')
                .doc()
                .set({data})
      .then(() => {
        resolve(data)
      })
      .catch(e => {
        reject(e)
      })  
    })
  }
}

export const editEmployment = (data, id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid
    return new Promise((resolve, reject) => {
      firestore.collection('application')
                .doc(authId)
                .collection('employment')
                .doc(id)
                .set({data})
      .then(() => {
        resolve(data)
      })
      .catch(e => {
        reject(e)
      })  
    })
  }
}

export const deleteEmployment = (id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid
    firestore.collection('application')
              .doc(authId)
              .collection('employment')
              .doc(id)
              .delete()
    .then(() => {
    })
    .catch(e => {
    })  
  }
}

export const addSchool = (data) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid
    return new Promise((resolve, reject) => {
      firestore.collection('application')
                .doc(authId)
                .collection('education')
                .doc()
                .set({data})
      .then(() => {
        resolve(data)
      })
      .catch(e => {
        reject(e)
      })  
    })
  }
}

export const editSchool = (data, id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid
    return new Promise((resolve, reject) => {
      firestore.collection('application')
                .doc(authId)
                .collection('education')
                .doc(id)
                .set({data})
      .then(() => {
        resolve(data)
      })
      .catch(e => {
        reject(e)
      })  
    })
  }
}

export const deleteSchool = (id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid
    firestore.collection('application')
              .doc(authId)
              .collection('education')
              .doc(id)
              .delete()
    .then(() => {
    })
    .catch(e => {
    })  
  }
}

export const approveApp = (userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const batch = firestore.batch()

    // Write premium license to database which will trigger cloud function to grant SME license
    const subRef = firestore.collection('subscription').doc(userId)
    batch.set(subRef, {
      'type': 'SME'
    })
    // Send a notification to the user letting them know that they have been granted a license
    const notifRef = firestore.collection('users').doc(userId).collection('notification').doc()
    batch.set(notifRef, {
      'createdAt': firestore.FieldValue.serverTimestamp(),
      'type': 'SMEGranted',
      'unread': true,
    })
    // change the users profile status to 'granted'
    const grantedRef = firestore.collection('application').doc(userId)
    batch.update(grantedRef, {
      'license': 'SME',
      'approved': true,
      'denied': false,
      'profile': 'SME',
    })
    // change the users profile license to 'basic'
    const userRef = firestore.collection('users').doc(userId)
    batch.update(userRef, {
      'license': 'SME',
    })
    // Commit the batch write
    batch.commit()
    .then(() => {
      dispatch({type: 'ADD_SME', userId})
    }).catch((error) => {
      dispatch({type: 'ADD_SME_ERROR', error})
    })
  }
}

export const denyApp = (userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const batch = firestore.batch()

    // Write premium license to database which will trigger cloud function to grant SME license
    const subRef = firestore.collection('subscription').doc(userId)
    batch.set(subRef, {
      'type': 'basic'
    })
    // change the users profile status to 'granted'
    const appRef = firestore.collection('application').doc(userId)
    batch.update(appRef, {
      'denied': true,
      'approved': false,
      'profile': 'SME-applicant',
    })
    // change the users profile license to 'basic'
    const userRef = firestore.collection('users').doc(userId)
    batch.update(userRef, {
      'license': 'basic',
    })
    // Commit the batch write
    batch.commit()
    .then(() => {
      dispatch({type: 'DENY_APP', userId})
    }).catch((error) => {
      dispatch({type: 'DENY_APP_ERROR', error})
    })
  }
}

export const suspendApp = (userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const batch = firestore.batch()

    // Write premium license to database which will trigger cloud function to grant SME license
    const subRef = firestore.collection('subscription').doc(userId)
    batch.set(subRef, {
      'type': 'basic'
    })
    // change the users app status to 'granted'
    const appRef = firestore.collection('application').doc(userId)
    batch.update(appRef, {
      'denied': true,
      'approved': false,
      'profile': 'SME-applicant',
      'license': 'basic',
    })
    // change the users profile license to 'basic'
    const userRef = firestore.collection('users').doc(userId)
    batch.update(userRef, {
      'license': 'basic',
    })
    // Commit the batch write
    batch.commit()
    .then(() => {
      dispatch({type: 'DENY_APP', userId})
    }).catch((error) => {
      dispatch({type: 'DENY_APP_ERROR', error})
    })
  }
}

export const restoreApp = (userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    firestore.collection('application')
             .doc(userId)
             .update({
                'denied': false,
                'approved': false,
                'profile': 'SME-applicant'
    }).then(() => {
      dispatch({type: 'RESTORE_APP', userId})
    }).catch((error) => {
      dispatch({type: 'RESTORE_APP_ERROR', error})
    })
  }
}

export const deleteApp = (userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const firebase = getFirebase()
    firestore.collection('application')
             .doc(userId)
             .delete().then(() => {
      dispatch({type: 'DELETE_APP', userId})
    }).catch((error) => {
      dispatch({type: 'DELETE_APP_ERROR', error})
    })
  }
}

export const saveSchedule = (schedule) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid

    firestore.collection('users')
              .doc(authId)
              .collection('schedule')
              .doc(schedule.week)
              .set({
                'times': schedule.times,
                'timezone': schedule.timezone,
                'exceptions': schedule.exceptions,
              })
    .then(() => {
      dispatch({type: 'SAVE_SCHEDULE', schedule})
    }).catch(error => {
      dispatch({type: 'SAVE_SCHEDULE_ERROR', error})
    })
  }
}

export const saveRepTimes = (repTimes) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid

    firestore.collection('users')
              .doc(authId)
              .collection('schedule')
              .doc('repeating')
              .set({
                'times': repTimes.times,
                'timezone': repTimes.timezone,
              })
    .then(() => {
      dispatch({type: 'SAVE_REPEATING_SCHED', repTimes})
    }).catch(error => {
      dispatch({type: 'SAVE_REPEATING_SCHED_ERROR', error})
    })
  }
}

// informs the database that the user has read the instructions for the scheduler
export const hasReadInstructions = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid

    firestore.collection('users')
              .doc(authId)
              .update({
                'readSchedHelp': true
              })
    .then(() => {
      dispatch({type: 'SCHEDULE_HELP'})
    }).catch(error => {
      dispatch({type: 'SCHEDULE_HELP_ERROR', error})
    })
  }
}


export const requestMeetingAction = (meeting) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const batch = firestore.batch()
    const authId = getState().firebase.auth.uid
    // request the meeting
    const reqMeetingRef = firestore.collection('users').doc(meeting.userId).collection('requested').doc()
    batch.set(reqMeetingRef, {
      'requestorId': authId,
      'times' : meeting.times,
      'timezone': meeting.timezone,
      'techPref': meeting.techPref,
      'agenda': meeting.agenda,
      'weekOf': meeting.weekOf,
    })
    // send notification that meeting is requested      
    const notificationRef = firestore.collection('users').doc(meeting.userId).collection('notification').doc()
    batch.set(notificationRef, {
      'type': 'meetingRequest',
      'userId': authId,
      'times': meeting.times,
      'createdAt': firestore.FieldValue.serverTimestamp(),
      'unread': true,
    })
    // Commit the batch
    batch.commit()
    .then(() => {
      dispatch({type: 'REQUEST_MEETING', meeting})
    }).catch(error => {
      dispatch({type: 'REQUEST_MEETING_ERROR', error})
    })
  }
}

export const rejectMeetingRequest = (meeting) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const batch = firestore.batch()
    const authId = getState().firebase.auth.uid
    // reject the meeting (delete)
    const deleteRef = firestore.collection('users').doc(authId).collection('requested').doc(meeting.docId)
    batch.delete(deleteRef)
    // send notification to user that meeting is rejected
    const notificationRef = firestore.collection('users').doc(meeting.clientId).collection('notification').doc()
    batch.set(notificationRef, {
      'type': 'meetingReject',
      'clientId': meeting.clientId,
      'times' : meeting.times,
      'weekOf': meeting.weekOf,
      'userId': authId,
      'createdAt': firestore.FieldValue.serverTimestamp(),
      'unread': true,
    })
    // commit the batch
    batch.commit()
    .then(() => {
      dispatch({type: 'REJECT_MEETING', meeting})
    }).catch(error => {
      dispatch({type: 'REJECT_MEETING_ERROR', error})
    })
  }
}

export const acceptMeetingRequest = (meeting) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const batch = firestore.batch()
    const authId = getState().firebase.auth.uid
    // accept the meeting
    const acceptRef = firestore.collection('users').doc(authId).collection('scheduled').doc()
    batch.set(acceptRef, {
      'clientId': meeting.clientId,
      'times' : meeting.times,
      'userTimezone': meeting.userTimezone,
      'clientTimezone': meeting.clientTimezone,
      'tech': meeting.tech,
      'agenda': meeting.agenda,
      'weekOf': meeting.weekOf,
    })
    // delete the doc from requested collection
    const deleteRef = firestore.collection('users').doc(authId).collection('requested').doc(meeting.requestDocId)
    batch.delete(deleteRef)
    // send the user a notification that meeting is confirmed
    const notificationRef = firestore.collection('users').doc(meeting.clientId).collection('notification').doc()
    batch.set(notificationRef, {
      'type': 'meetingConfirm',
      'userId': authId,
      'times': meeting.times,
      'createdAt': firestore.FieldValue.serverTimestamp(),
      'tech': meeting.tech,
      'userTimezone': meeting.userTimezone,
      'weekOf': meeting.weekOf,
      'agenda': meeting.agenda,
      'unread': true,
      'consultantName': meeting.consultantName,
    })
    // commit the batch
    batch.commit()
    .then(() => {
      dispatch({type: 'ACCEPT_MEETING', meeting})
    }).catch(error => {
      dispatch({type: 'ACCEPT_MEETING_ERROR', error})
    })
  }
}

export const clearRequestStatus = () => {
  return {type: 'CLEAR_REQUEST_STATUS'}
}

export const clearAcceptStatus = () => {
  return {type: 'CLEAR_ACCEPT_STATUS'}
}

export const billUser = (data) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const batch = firestore.batch()
    const authId = getState().firebase.auth.uid
    // Log the billable hours to the client
    const clientRef = firestore.collection('users').doc(data.clientId).collection('paymentRequest').doc()
    batch.set(clientRef, {
      'hours': data.hours,
      'beginDate': data.beginDate,
      'endDate': data.endDate,
      'description': data.description,
      'accepted': false,
      'paid': false,
      'requestorId': authId,
      'timestamp': firestore.FieldValue.serverTimestamp()
    })
    // Log the payment request to the owners collected
    const ownerRef = firestore.collection('users').doc(authId).collection('billed').doc()
    batch.set(ownerRef, {
      'hours': data.hours,
      'beginDate': data.beginDate,
      'endDate': data.endDate,
      'description': data.description,
      'accepted': false,
      'paid': false,
      'clientId': data.clientId,
      'timestamp': firestore.FieldValue.serverTimestamp()
    })
    // send the client a notification
    const notificationRef = firestore.collection('users').doc(data.clientId).collection('notification').doc()
    batch.set(notificationRef, {
      'type': 'paymentRequest',
      'requestorId': authId,
      'createdAt': firestore.FieldValue.serverTimestamp(),
      'unread': true,
      'docRef': clientRef.id
    })
    // commit the batch
    batch.commit()
    .then(() => {
      dispatch({type: 'REQUEST_PAYMENT', data})
    }).catch(error => {
      dispatch({type: 'REQUEST_PAYMENT_ERROR', error})
    })
  }
}

export const getPremium = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid
    firestore.collection('subscription')
             .doc(authId)
             .set({
                'type': 'premium'
    }).then(() => {
      dispatch({type: 'PREMIUM_SUBSCRIPTION'})
    }).catch((error) => {
      dispatch({type: 'PREMIUM_SUBSCRIPTION_ERROR', error})
    })
  }
}

export const grantPremium = (userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const batch = firestore.batch()

    // Write premium license to database which will trigger cloud function to grant premium license
    const subRef = firestore.collection('subscription').doc(userId)
    batch.set(subRef, {
      'type': 'premium'
    })
    // Send a notification to the user letting them know that they have been granted a license
    const notifRef = firestore.collection('users').doc(userId).collection('notification').doc()
    batch.set(notifRef, {
      'createdAt': firestore.FieldValue.serverTimestamp(),
      'type': 'premiumGranted',
      'unread': true
    })
    // change the users profile status to 'granted'
    const grantedRef = firestore.collection('users').doc(userId)
    batch.update(grantedRef, {
      'status': 'granted',
      'license': 'premium',
    })
    // Commit the batch write
    batch.commit()
    .then(() => {
      dispatch({type: 'PREMIUM_SUBSCRIPTION'})
    }).catch((error) => {
      dispatch({type: 'PREMIUM_SUBSCRIPTION_ERROR', error})
    })
  }
}

export const removePremium = (userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const batch = firestore.batch()

    // Write premium license to database which will trigger cloud function to grant premium license
    const subRef = firestore.collection('subscription').doc(userId)
    batch.set(subRef, {
      'type': 'basic'
    })
    // change the users profile status to 'denied'
    const grantedRef = firestore.collection('users').doc(userId)
    batch.update(grantedRef, {
      'status': 'denied',
      'license': 'basic',
    })
    // Commit the batch write
    batch.commit()
    .then(() => {
      dispatch({type: 'DENY_SUBSCRIPTION'})
    }).catch((error) => {
      dispatch({type: 'DENY_SUBSCRIPTION_ERROR', error})
    })
  }
}

export const denyPremium = (userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid
    firestore.collection('users')
             .doc(userId)
             .update({
                'status': 'denied',
                'license': 'basic',
    }).then(() => {
      dispatch({type: 'DENY_SUBSCRIPTION'})
    }).catch((error) => {
      dispatch({type: 'DENY_SUBSCRIPTION_ERROR', error})
    })
  }
}

export const test = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid
    firestore.collection('test')
             .add({
                'type': 'premium'
    }).then((docRef) => {
      firestore.collection('test').doc(docRef.id).get().then(snap => {
      })
    }).catch((error) => {
      //console.error(error)
    })
  }
}

export const updateSecurityQuestions = (questions) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid

    return new Promise(function(resolve, reject) {
      firestore.collection('security_questions').doc(authId)
      .set({
        'question1': questions.question1,
        'question2': questions.question2,
        'question3': questions.question3,
      })
      .then(() => {
        resolve()
        dispatch({type: 'UPDATE_QUESTIONS', questions})
      }).catch((error) => {
        reject(error)
        dispatch({type: 'UPDATE_QUESTIONS_ERROR', error})
      })
    })
  }
}

export const virgilComplete = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const authId = getState().firebase.auth.uid
    
    firestore.collection('users').doc(authId)
    .update({
      'virgilComplete': true
    })
    .then(() => {
      dispatch({type: 'VIRGIL_COMPLETE'})
    }).catch((error) => {
      dispatch({type: 'VIRGIL_COMPLETE_ERROR', error})
    })
  }
}