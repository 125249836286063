import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import {withRouter} from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import { Redirect } from 'react-router-dom'
import ForumTable from '../Components/ForumComponents/ForumTable'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

function Forum(props) {

  const { forum, auth } = props

  const root = {
    textAlign: 'center',
    marginTop: 150,
  }
  const loader = {
    display: 'inlineBlock'
  }

  if (!auth) return <div style={root}><CircularProgress style={loader} /> </div>

  if (!forum) {
    return <div style={root}><CircularProgress style={loader} /> </div>
  } 
  // Make sure every forum has an id before loading 
  // (There is an occasional glitch after arranging sections when one will not have an id and causes this page to crash)
  // The following code prevents that crash
  var loaded = true
  forum.map(section => {
    if (!section.id) {
      loaded = false
    }
  })

  if (!loaded) return <div style={root}><CircularProgress style={loader} /> </div>

  return (
    <div style={{paddingTop: 115, paddingBottom: 100 }}>
      <Container maxWidth='md'>
          <h3 style={{textAlign: 'center'}}>The First SOF Truth:</h3>
          <h3 style={{textAlign: 'center'}}><i>"Humans are more important than hardware."</i></h3>
            <Grid container direction='column' >
              <Fragment>
                {forum.map(forumSection => {
                  return (
                    <ForumTable 
                      title={forumSection.title} 
                      forum_doc_id={forumSection.id} 
                      key={forumSection.id}
                    />
                  )
                })}
              </Fragment> 
            </Grid>
      </Container>
    </div>
  ) 
}

export default withRouter(compose(
  firestoreConnect((props) => [
    {
      collection: 'forum',
      orderBy: [
        ['index', 'asc']
      ],
      storeAs: 'forumList'
    },
  ]),
  connect((state, props) => ({
    forum: state.firestore.ordered['forumList'],
    auth: state.firebase.auth,
  }))
)(Forum))
