const initState = {
  meetingRequestStatus: null,
  acceptMeetingStatus: null,
  messageStatus: null,
  paymentRequestStatus: null,
  proposal_data: JSON.parse(sessionStorage.getItem('proposal')) || {hourly: '', cover: '', clientQuestions: {}, id: ''},
}

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case 'UPDATE_BOS':
      //console.log('Update BOS', action.user)
      return state
    case 'UPDATE_BOS_ERROR':
      //console.log('Update BOS error', action.error)
      return state
    case 'UPDATE_USERNAME':
      //console.log('Update BOS', action.user)
      return state
    case 'UPDATE_USERNAME_ERROR':
      //console.log('Update BOS error', action.error)
      return state
    case 'UPDATE_JOB':
      //console.log('Update job', action.user)
      return state
    case 'UPDATE_JOB_ERROR':
      //console.log('Update job error', action.error)
      return state
    case 'UPDATE_SKILLS':
      //console.log('Update skills', action.user)
      return state
    case 'UPDATE_SKILLS_ERROR':
      //console.log('Update skills error', action.error)
      return state
    case 'UPDATE_DD214':
      //console.log('Update DD214', action.dd214)
      return state
    case 'UPDATE_DD214_ERROR':
      //console.log('Update DD214 error', action.error)
      return state
    case 'UPDATE_RESUME':
      //console.log('Update resume', action.resume)
      return state
    case 'UPDATE_RESUME_ERROR':
      //console.log('Update resume error', action.error)
      return state
    case 'UPDATE_QUESTIONS':
      //console.log('Update questions', action.questions)
      return state
    case 'UPDATE_QUESTIONS_ERROR':
      //console.log('Update questions error', action.error)
      return state
    case 'VIRGIL_COMPLETE':
      //console.log('Virgil Complete')
      return state
    case 'VIRGIL_COMPLETE_ERROR':
      //console.log('Virgil Complete error', action.error)
      return state
    case 'NOTIFY_ADMIN':
      //console.log('Notify admin', action.resume)
      return state
    case 'NOTIFY_ADMIN_ERROR':
      //console.log('Notify admin error', action.error)
      return state
    case 'UPDATE_PROFILE_IMAGE':
      //console.log('Update profile image', action.photoData)
      return state
    case 'UPDATE_PROFILE_IMAGE_ERROR':
      //console.log('Update profile image error', action.error)
      return state
    case 'UPDATE_PROFILE_IMAGE_POSITION':
      //console.log('Update profile image position', action.position)
      return state
    case 'UPDATE_PROFILE_IMAGE_POSITION_ERROR':
      //console.log('Update profile image position error', action.error)
      return state
    case 'UPDATE_BIO':
      //console.log('Update bio', action.bio)
      return state
    case 'UPDATE_BIO_ERROR':
      //console.log('Update bio error', action.error)
      return state
    case 'APPROVE_APP':
      //console.log('Approve app', action.userId)
      return state
    case 'APPROVE_APP_ERROR':
      //console.log('Approve app error', action.error)
      return state
    case 'DENY_APP':
      //console.log('Deny app', action.userId)
      return state
    case 'DENY_APP_ERROR':
      //console.log('Deny app error', action.error)
      return state
    case 'RESTORE_APP':
      //console.log('Restore app', action.userId)
      return state
    case 'RESTORE_APP_ERROR':
      //console.log('Restore app error', action.error)
      return state
    case 'DELETE_APP':
      //console.log('Delete app', action.userId)
      return state
    case 'DELETE_APP_ERROR':
      //console.log('Delete app error', action.error)
      return state
    case 'NOTIFY_ADMIN':
      //console.log('Notify admin', action.resume)
      return state
    case 'NOTIFY_ADMIN_ERROR':
      //console.log('Notify admin error', action.error)
      return state
    case 'ACTIVATE_CONVERSATION':
      //console.log('Activate conversation', action.user)
      return state
    case 'ACTIVATE_CONVERSATION_ERROR':
      //console.log('Activate conversation error', action.error)
      return state
    case 'DEACTIVATE_CONVERSATION':
      //console.log('Deactivate conversation', action.user)
      return state
    case 'MINIMIZE_CONVERSATION_ERROR':
      //console.log('Activate conversation error', action.error)
      return state
    case 'MINIMIZE_CONVERSATION':
      //console.log('Deactivate conversation', action.user)
      return state
    case 'DEACTIVATE_CONVERSATION_ERROR':
      //console.log('Deactivate conversation error', action.error)
      return state
    case 'SCHEDULE_HELP':
      return state
    case 'SCHEDULE_HELP_ERROR':
      return state
    case 'SEND_MESSAGE':
      //console.log('Send message other', action.message)
      return {
        ...state,
        messageStatus: 'success'
      }
    case 'SEND_MESSAGE_ERROR':
      //console.log('Send message other error', action.error)
      return {
        ...state,
        messageStatus: 'error'
      }
    case 'DELETE_MESSAGE':
      //console.log('Send message other', action.message)
      return state
    case 'DELETE_MESSAGE_ERROR':
      //console.log('Send message other error', action.error)
      return state
    case 'CLEAR_MESSAGE_STATUS':
      return {
        ...state,
        messageStatus: null
      }
    case 'MARK_MSG_READ':
      //console.log('Mark message read', action.message)
      return state
    case 'MARK_MSG_READ_ERROR':
      //console.log('Mark message read error', action.error)
      return state
      return state
    case 'SAVE_SCHEDULE':
      //console.log('Save schedule', action.schedule)
      return state
    case 'SAVE_SCHEDULE_ERROR':
      //console.log('Save schedule error', action.error)
      return state
    case 'SAVE_REPEATING_SCHED':
      //console.log('Save repeating schedule', action.repTimes)
      return state
    case 'SAVE_REPEATING_SCHED_ERROR':
      //console.log('Save repeating schedule error', action.error)
      return state
    case 'REQUEST_MEETING':
      //console.log('Request meeting', action.meeting)
      return {
        ...state,
        meetingRequestStatus: 'success'
      }
    case 'REQUEST_MEETING_ERROR':
      //console.log('Request meeting error', action.error)
      return {
        ...state,
        meetingRequestStatus: 'failed'
      }
    case 'REJECT_MEETING':
      //console.log('Reject meeting', action.meeting)
      return state
    case 'REJECT_MEETING_ERROR':
      //console.log('Reject meeting error', action.error)
      return state
    case 'ACCEPT_MEETING':
      //console.log('Accept meeting', action.meeting)
      return {
        ...state,
        acceptMeetingStatus: 'success'
      }
    case 'ACCEPT_MEETING_ERROR':
      //console.log('Accept meeting error', action.error)
      return {
        ...state,
        acceptMeetingStatus: 'failed'
      }
    case 'CLEAR_REQUEST_STATUS':
      //console.log('clear request status')
      return {
        ...state,
        meetingRequestStatus: null
      }
    case 'CLEAR_ACCEPT_STATUS':
      //console.log('clear accept status')
      return {
        ...state,
        acceptMeetingStatus: null
      }
    case 'REQUEST_PAYMENT':
      //console.log('request payment')
      return {
        ...state,
        paymentRequestStatus: 'success'
      }
    case 'REQUEST_PAYMENT_ERROR':
      //console.log('request payment error', action.error)
      return {
        ...state,
        paymentRequestStatus: 'error'
      }
    case 'PREMIUM_SUBSCRIPTION':
      //console.log('premium subscription')
      return state
    case 'PREMIUM_SUBSCRIPTION_ERROR':
      //console.log('premium subscription error', action.error)
      return state
    case 'DENY_SUBSCRIPTION':
      //console.log('deny subscription')
      return state
    case 'DENY_SUBSCRIPTION_ERROR':
      //console.log('deny subscription error', action.error)
      return state
    case 'SET_PROPOSAL':
      sessionStorage.setItem('proposal', JSON.stringify(action.proposal))
      return {
        ...state,
        proposal_data: action.proposal
      }
    default:
      return state
  }
}

export default userReducer