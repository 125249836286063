import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import CheckIcon from '@material-ui/icons/CheckCircle'
import green from '@material-ui/core/colors/green'
import Container from '@material-ui/core/Container'
import { useSelector } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    background: {
        paddingTop: 100,
        backgroundColor: '#FFFFFF',
        minHeight: '100vh',
        paddingBottom: 100,
    },
    check: {
        fontSize: 140,
        color: green[700],
    },
    title: {
        fontWeight: 500,
    },
    subtitle: {
        fontWeight: 400,
    },
}))

function UserAccessGranted(props) {
    const classes = useStyles()

    const profile = useSelector(state => state.firebase.profile)
    const auth = useSelector(state => state.firebase.auth)

    if (!profile || !auth) return <div />
    if (!profile.isLoaded || !auth.isLoaded) return <div />
    if (profile.license !== 'SME') return <Redirect to='/'/>
    
    return (
        <div className={classes.background}>
            <Container maxWidth='md'>
                <Grid container direction='column' justify='center' alignItems='center'>
                    <CheckIcon className={classes.check} />
                    <h3 className={classes.title}>User Access Granted</h3>
                    <h4 className={classes.subtitle}>Congratulations! You are now a "User" on The User Community platform with the following priveleges:</h4>
                    <Container maxWidth='sm'>
                        <ul>
                            <li><h4>Access to all forums (Basic, Premium, and User)</h4></li>
                            <li><h4>Your name will appear with a "User" badge on forum posts</h4></li>
                            <li><h4>Access to all user profiles</h4></li>
                            <li><h4>Your profile will appear on the "Users" page where Premium license holders can connect with you</h4></li>
                            <li><h4>The respect of collaborators on the platform. You will be respected as an expert in your field and collaborators will want feedback from you about their products and ideas</h4></li>
                        </ul>
                    </Container>
                    <h4 className={classes.subtitle}>Here are some tips on how to build your reputation and be successful on the platform:</h4>
                    <Container maxWidth='sm'>
                        <ul>
                            <li><h4>Respond to collaborators questions in the forums</h4></li>
                            <li><h4>Our Premium license holders are companies looking to hire, pay special attention to their posts in the forums</h4></li>
                            <li><h4>Companies may reach out to you by direct message to learn more about your skills, be kind and helpful but remember to manage this interaction wisely and bring the conversation into a consulting space where you can get paid for your time*</h4></li>
                            <li><h4>Use the in-app <Link to={`/schedule/${auth.uid}`}>scheduler</Link> to manage your availabilities so that Premium license holders can request meetings with you</h4></li>
                        </ul>
                        <p>*We are currently in Phase 1 of the platform launch which does not support payments. This feature will be here soon! Use this time to familiarize yourself with the platform and give feedback in the feedback forum</p>
                    </Container>
                </Grid>
            </Container>
        </div>
    )
}

export default UserAccessGranted