import React, { useState, useEffect } from 'react'
import Fab from '@material-ui/core/Fab'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import RightIcon from '@material-ui/icons/KeyboardArrowRight'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MUISelect from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Author from '../Components/ForumComponents/Author'
import Company from '../Components/UserComponents/Company'
// react router
import { useParams, useHistory } from 'react-router-dom'
// icons
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
// moment
import moment from 'moment'
import { MenuItem } from '@material-ui/core'
// redux
import { useDispatch, useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { deleteTimeEntry } from '../../Redux/Actions/jobActions'
// firebase
import firebase from 'firebase'

const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: '#f5f5f5',
    },
    body: {
        paddingLeft: 20,
        paddingTop: 20,
    },
    header: {
        paddingLeft: 20,
    },
    smallForm: {
        width: 150,
    },
    form: {
       width: 500,
    },
}))

const styles = (theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
})

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <h4><b>{children}</b></h4>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


function LogTime(props) {
    // styles
    const classes = useStyles()

    // URL params
    const { user_id, job_id } = useParams()

    // cloud function for logging time
    var logTimeCF = firebase.functions().httpsCallable('logTime')

    useFirestoreConnect([
        {
            collection: 'users',
            doc: user_id,
            subcollections: [
                {
                    collection: 'active_contracts',
                    doc: job_id,
                }
            ],
            storeAs: `${job_id}-contract`
        }
    ])
    const contractList = useSelector(state => state.firestore.ordered[`${job_id}-contract`])

    // get the times logged for this job
    useFirestoreConnect([
        {
            collection: 'contract_time',
            doc: job_id,
            subcollections: [
                {
                    collection: 'workers',
                    doc: user_id,
                    subcollections: [
                        {
                            collection: 'timecard'
                        }
                    ]
                }
            ],
            storeAs: `${job_id}-time`
        }
    ])
    const timecard = useSelector(state => state.firestore.ordered[`${job_id}-time`])
    // for navigation
    const history = useHistory()

    // for redux actions
    const dispatch = useDispatch()

    // log time state
    const [dateSelected, setDateSelected] = useState(moment())
    const [startTime, setStartTime] = useState(null)
    const [endTime, setEndTime] = useState(null)
    const [workDescription, setWorkDescription] = useState('')
    const [totalTime, setTotalTime] = useState(0)
    // form state
    const [openForm, setOpenForm] = useState(false)
    const [loading, setLoading] = useState(false)
    const [formComplete, setFormComplete] = useState(false)
    const [timeslist, setTimesList] = useState([])
    const [error, setError] = useState(null)
    const [entryDocId, setEntryDocId] = useState(null)

    const [openConfirmDelete, setOpenConfirmDelete] = useState(false)
    const [entryToDelete, setEntryToDelete] = useState(null)

    // handlers
    const increaseDate = (e) => {
        e.preventDefault()
        if (!isCurrentDay()) {
            let dayAfter = moment(dateSelected).add(1, 'days')
            setDateSelected(dayAfter)
        }
    }

    const decreaseDate = (e) => {
        e.preventDefault()
        let dayBefore = moment(dateSelected).subtract(1, 'days')
        setDateSelected(dayBefore)
    }

    const isCurrentDay = () => {
        return dateSelected.isSame(moment(), 'day')
    }

    const clearState = () => {
        setStartTime(null)
        setEndTime(null)
        setWorkDescription('')
        setEntryDocId(null)
    }

    const handleCloseForm = (e) => {
        e.preventDefault()
        setOpenForm(false)
        setError(null)
        clearState()
    }

    const handleStartTimeChange = (e) => {
        setStartTime(e.target.value)
    }

    const handleEndTimeChange = (e) => {
        setEndTime(e.target.value)
    }

    const handleDescriptionChange = (e) => {
        setWorkDescription(e.target.value)
    }

    const handleDeleteEntry = (e) => {
        e.preventDefault()
        setLoading(true)
        dispatch(deleteTimeEntry(entryToDelete, job_id))
        .then(() => {
            setEntryToDelete(null)
            setOpenConfirmDelete(false)
            setError(null)
        }) 
        .catch(e => {
            setError('Error: ' + e.message)
        }) 
        .finally(() => {
            setLoading(false)
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        let time = {
            startTime: startTime.toDate().toString(),
            endTime: endTime.toDate().toString(),
            workDescription,
            jobId: job_id,
            userId: user_id,
            docId: entryDocId,
            owner: contractList[0] && contractList[0].owner
        }
        // cloud function for securely logging time which will be billed to client
        logTimeCF({time: time})
            .then(r => {
                console.log(r)
                setLoading(false)
                setOpenForm(false)
                setError(null)
                clearState()
            })
            .catch(e => {
                console.error(e)
                setLoading(false)
                setError(e.message)
            })
    }

    const handleOpenForm = (e) => {
        e.preventDefault()
        setOpenForm(true)
    }

    // function for filtering time entries by date selected
    const byDate = (entry) => {
        let startDate = entry.startTime.toDate()
        let endDate = entry.endTime.toDate()
        if (startDate.getMonth() == dateSelected.toDate().getMonth() && startDate.getDate() == dateSelected.toDate().getDate() && startDate.getFullYear() == dateSelected.toDate().getFullYear()) {
            return true
        }
        else {
            return false
        }
    }
    
    const getTimeForDate = (date) => {
        let minutes = 0
        if (timecard && timecard[0]) {
            timecard.map(entry => {
                //console.log('entry', entry)
                let startDate = entry.startTime.toDate()
                let endDate = entry.endTime.toDate()
                if (startDate.getMonth() == date.getMonth() && startDate.getDate() == date.getDate() && startDate.getFullYear() == date.getFullYear()) {
                    let start = startDate.getTime()
                    //console.log('start', entry.startTime.toDate())
                    let end = endDate.getTime()
                    //console.log('end', entry.endTime.toDate())
                    minutes += (end - start) / (1000 * 60)
                }
            })
        }
        return minutes
    }


    // lifecycle to check if form is complete
    useEffect(() => {
        if (endTime !== null && startTime !== null && workDescription !== '') {
            setFormComplete(true)
        } 
        else {
            setFormComplete(false)
        }
    }, [workDescription, startTime, endTime])

    useEffect(() => {
        let midnight = moment(dateSelected || moment()).startOf('day')
        let timesList = [midnight]
        let clone = moment(midnight)
        for (let i = 0; i < 144; i++) {
            let newClone = moment(clone)
            timesList.push(newClone.add(10, 'minutes'))
            clone = moment(newClone)
        }
        setTimesList(timesList)
    }, [dateSelected])

    useEffect(() => {
        if (timecard && timecard[0]) {
            let minutes = 0
            timecard.map(entry => {
                //console.log('entry', entry)
                let start = entry.startTime.toDate().getTime()
                //console.log('start', entry.startTime.toDate())
                let end = entry.endTime.toDate().getTime()
                //console.log('end', entry.endTime.toDate())
                minutes += (end - start) / (1000 * 60)
            })
            setTotalTime(minutes)
        }

    }, [timecard])
    

    if (!contractList) return <CircularProgress />
    if (!contractList[0]) return <CircularProgress />
    const contract = contractList[0]

    return (
        <div style={{paddingTop: 100}}>
            <Container maxWidth='md'>
                <Paper>
                    <AppBar color='default' position='static'>
                        <h4 className={classes.header}><b>Log Time</b></h4>
                    </AppBar>
                    <Grid container direction='column' spacing={2} className={classes.body}>
                        <Grid item>
                            <h5><b>{<Company userId={contract.owner} />}</b></h5>
                        </Grid>
                        <Grid item>
                            <p>{<Author authorId={contract.owner} />}</p>
                        </Grid>
                    </Grid>
                    <h3 style={{textAlign: 'center'}}>{`Total time for this job: ${moment.duration(totalTime, 'minutes').asHours().toFixed(2)} hrs`}</h3>
                    <hr />
                    <Grid container direction='column' justify='center' alignItems='center' spacing={2} >
                        <Grid item>
                            <Grid container direction='row' alignItems='center' justify='center' spacing={2}>
                                <Grid item>
                                    <IconButton onClick={decreaseDate}>
                                        <LeftIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <h4><b>{dateSelected.format('dddd, MMMM D, YYYY')}</b></h4>
                                </Grid>
                                <Grid item>
                                    <IconButton 
                                        onClick={increaseDate}
                                        disabled={isCurrentDay()}
                                    >
                                        <RightIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Display the times logged for the date selected */}
                    <div style={{padding: '0px 25px'}}>
                        {timecard && timecard.filter(byDate).map((entry, i) => (
                            <Paper 
                                key={i} 
                                square 
                                variant='outlined' 
                                style={{padding: 5, margin: 5, backgroundColor: 'whitesmoke'}}
                            >
                                <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                    <Grid item>
                                        <p>{`${moment(entry.startTime.toDate()).format('h:mm a')} - ${moment(entry.endTime.toDate()).format('h:mm a')}`}</p>
                                    </Grid>
                                    <Grid item xs>
                                        <ul>
                                            <li><p>{entry.workDescription}</p></li>
                                        </ul>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction='row'>
                                            <Grid item>
                                                <IconButton 
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        for (var t of timeslist) {
                                                            if (t.isSame(moment(entry.startTime.toDate()), 'minute')) {
                                                                setStartTime(t)
                                                                break
                                                            }
                                                        }
                                                        for (var t of timeslist) {
                                                            if (t.isSame(moment(entry.endTime.toDate()), 'minute')) {
                                                                setEndTime(t)
                                                                break
                                                            }
                                                        }
                                                        setWorkDescription(entry.workDescription)
                                                        setEntryDocId(entry.id)
                                                        setOpenForm(true)
                                                    }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <IconButton 
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        setEntryToDelete(entry.id)
                                                        setOpenConfirmDelete(true)
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        ))}
                    </div>

                    <hr />
                    <Grid container direction='row' alignItems='center' spacing={2} className={classes.body}>
                        <Grid item>
                            <h3>{`Total time for ${dateSelected.format('ddd')}: ${moment.duration(getTimeForDate(dateSelected.toDate()), 'minutes').asHours().toFixed(2)} hrs`}</h3>
                        </Grid>
                        <Grid item>
                            <Fab 
                                size='small'
                                onClick={handleOpenForm}
                            >
                                <AddIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>



            {/* Popup for logging time */}

            <Dialog maxWidth='xl' onClose={handleCloseForm} open={openForm}>
                <DialogTitle>
                    {`Log Time for ${dateSelected.format('ddd, MMM D, YYYY')}`}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container direction='column' spacing={4}>
                        <Grid item>
                            <Grid container direction='row' justify='space-between' alignItems='flex-end'>
                                <Grid item>
                                    <FormControl>
                                        <InputLabel>From</InputLabel>
                                        <MUISelect 
                                            className={classes.smallForm}
                                            value={startTime}
                                            onChange={handleStartTimeChange}
                                        >
                                            {
                                                timeslist.map((time, index) => (
                                                    <MenuItem key={index} value={time}>{time.format('h:mm a')}</MenuItem>
                                                ))
                                            }
                                        </MUISelect>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <InputLabel>To</InputLabel>
                                        <MUISelect 
                                            className={classes.smallForm}
                                            value={endTime}
                                            onChange={handleEndTimeChange}
                                        >
                                            {
                                                timeslist.map((time, index) => (
                                                    <MenuItem key={index} value={time}>{time.format('h:mm a')}</MenuItem>
                                                ))
                                            }
                                        </MUISelect>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <TextField 
                                variant='outlined'
                                placeholder='Description'
                                size='small'
                                className={classes.form}
                                rows={5}
                                multiline
                                value={workDescription}
                                onChange={handleDescriptionChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseForm} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={!formComplete} onClick={handleSubmit} variant='contained' color="primary">
                        { loading ?
                            <CircularProgress color='secondary' size='20px' />
                            :
                            'Save'
                        }
                    </Button>
                </DialogActions>
                {error ? 
                    <p style={{textAlign: 'right', marginRight: 10, color: 'red'}}>{`Error: ${error}`}</p>
                    : null
                }
            </Dialog>

            {/* Dialog for delete confirmation */}
            <Dialog open={openConfirmDelete} onClose={() => {
                setOpenConfirmDelete(false)
                setEntryToDelete(null)
                setError(null)
            }}>
                <DialogTitle>
                    <h3>Delete this time entry?</h3>
                </DialogTitle>
                <DialogActions>
                <Button onClick={() => {
                    setOpenConfirmDelete(false)
                    setEntryToDelete(null)
                    setError(null)
                }} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteEntry} variant='outlined' color="secondary">
                        { loading ?
                            <CircularProgress color='secondary' size='20px' />
                            :
                            'Delete'
                        }
                    </Button>
                </DialogActions>
                {error ? 
                    <p style={{textAlign: 'right', marginRight: 10, color: 'red'}}>{`${error}`}</p>
                    : null
                }
            </Dialog>

        </div>
    )
}

export default LogTime