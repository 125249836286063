import React, {Component, Fragment} from 'react';
import { TopicsTable } from '../Components'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {Redirect} from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
 
function ForumTopics(props) {

  const forum_doc_id = props.match.params.forum_id
  const genre_doc_id = props.match.params.genre_id
  const { auth, token } = props

  useFirestoreConnect([
    {
      collection: 'forum',
      doc: forum_doc_id,
      subcollections: [{
        collection: 'genre',
        doc: genre_doc_id,
      }],
      storeAs: `${genre_doc_id}-genre-doc`
    }
  ])
  const genre = useSelector(state => state.firestore.ordered[`${genre_doc_id}-genre-doc`])

  const root = {
    textAlign: 'center',
    marginTop: 150,
  }
  const loader = {
    display: 'inlineBlock'
  }

  if (!auth.isLoaded) return <div style={root}><CircularProgress style={loader} /> </div>
  const loggedOut = !auth.uid

  if (loggedOut) return <Redirect to='/basic-access' />

  if (!token || !genre) return <div style={root}><CircularProgress style={loader} /> </div>
  
  if (!genre[0]) return <div style={root}><CircularProgress style={loader} /> </div>

  switch (genre[0].access) {
    case 'basic':
      // do nothing, this has been checked already
      break
    case 'sme':
      if (!token.claims.SME && !token.claims.admin) return <Redirect to='/sme-access' />
      break
    case 'premium':
      if (!token.claims.premium && !token.claims.SME && !token.claims.admin) return <Redirect to='/premium-access' />
      break
    default:
      break
  }

  return(
    <div style={{paddingTop: 115, paddingBottom: 100}}>
      <Container maxWidth='lg'>
        <Fragment>
          <TopicsTable 
            forum_doc_id={forum_doc_id}
            genre_doc_id={genre_doc_id}
          />
        </Fragment>
      </Container>
    </div>
  )
}

export default compose(
  connect((state, props) => ({
    auth: state.firebase.auth,
    token: state.firebase.profile.token,
  }))
 )(ForumTopics)