import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper';
import Author from './Author'
import { makeStyles } from '@material-ui/core/styles';
import QuotedCommentRecursive from './QuotedCommentRecursive';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';


const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1),
  },
  paper: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));


function QuotedComment({topic_doc_id, comments, commentId, authorId }) {

  const classes = useStyles();
  const [openQuotes, setOpenQuotes] = useState(false)

  const openHandler = (e) => {
    e.preventDefault()
    setOpenQuotes(true)
  } 

  const closeHandler = (e) => {
    e.preventDefault()
    setOpenQuotes(false)
  } 

  if (!comments) {
    return null
  }
  else {
    var quotedComment = {}
    for (var i = 0; i < comments.length; i++) {
      if (comments[i].id === commentId) {
        quotedComment = comments[i]
      }
    }

    return (
      <div className={classes.root} >
        <Paper elevation={1} className={classes.paper}>
          <Grid container direction='column' justify='flex-start' alignItems='flex-start'>
            {quotedComment.isQuoted ? 
              <Fragment>
                {openQuotes ? 
                  <Fragment>
                    <Button onClick={closeHandler}>
                      <ExpandMoreIcon />
                      hide
                    </Button>
                    <QuotedCommentRecursive
                      comments={comments}
                      commentId={quotedComment.repliedToId}
                      authorId={quotedComment.repliedToAuthorId}
                    />
                  </Fragment>
                :
                  <Tooltip title='See additional comments'>
                    <Button onClick={openHandler}>
                      <ExpandLessIcon />
                      open
                    </Button>
                  </Tooltip>
                }

              </Fragment>
            :
              null
            }
            <Fragment>
              <Author 
                authorId={authorId} 
                bold={false}
              /> wrote
            </Fragment>
            <p>{quotedComment.comment}</p>
          </Grid>
        </Paper>   
      </div>
    )
  }
}

export default connect((state, props) => ({
  comments: state.firestore.ordered[`${props.topic_doc_id}-comments`],
}))(QuotedComment)