import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Announcement from '@material-ui/icons/AccountBoxRounded'
import grey from '@material-ui/core/colors/grey'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    title: {
        textAlign: 'center',
    },
    subtitle: {
        textAlign: 'center',
    },
    background: {
        paddingTop: 100,
        backgroundColor: '#FFFFFF',
        minHeight: '100vh',
        paddingBottom: 100,
    },
    announcement: {
        fontSize: 160,
        color: grey[800],
    },
}))

function BasicAccess(props) {
    const classes = useStyles()

    return (
        <div className={classes.background}>
            <Grid container direction='column' justify='center' alignItems='center'>
                <h2 className={classes.title}>Access to this page is for registered users</h2>
                <Announcement className={classes.announcement}/>
                <h3 className={classes.subtitle}>Please sign up or sign in to access this content</h3>
                <Grid container direction='row' justify='center'>
                <Grid item>
                    <Link to='/choose-registration' >
                        <Button color='primary' variant='contained'>Sign UP</Button>
                    </Link>
                </Grid>
                <Grid item>
                    <Link to='/login'>
                        <Button color='primary' variant='text'>Sign In</Button>
                    </Link>
                </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default BasicAccess