import React, { Fragment } from 'react'
import Author from './Author'
import Badge from '@material-ui/core/Badge'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Star from '@material-ui/icons/StarBorder'
import amber from '@material-ui/core/colors/amber'
import green from '@material-ui/core/colors/green'
import blue from '@material-ui/core/colors/blue'


const UserBadge = withStyles((theme) => ({
    badge: {
      right: -20,
      top: 0,
      backgroundColor: blue[500],
    },
}))(Badge);

const PremiumBadge = withStyles((theme) => ({
    badge: {
      right: -30,
      top: 0,
      backgroundColor: amber[300],
    },
}))(Badge);

const StaffBadge = withStyles((theme) => ({
    badge: {
      right: -20,
      top: 0,
      backgroundColor: green[300],
    },
}))(Badge);

const useStyles = makeStyles(theme => ({
    black: {
        color: '#000',
    },
}))

function CommentAuthor({ userId }) {
    const classes = useStyles()

    useFirestoreConnect([
        {
            collection: 'users',
            doc: userId
        }
    ])
    const author = useSelector(({firestore: {data}}) => data.users && data.users[userId])

    if (!author) return <div/>
    let sme = false
    let staff = false
    let premium = false
    switch (author.license) {
        case 'SME':
            sme = true
            staff = false
            premium = false
            break
        case 'admin':
            staff = true
            sme = false
            premium = false
            break;
        case 'premium':
            premium = true
            staff = false
            sme = false
            break;
        case 'basic': 
            premium = false
            staff = false
            sme = false
            break
        default:
            break
    } 

    if (sme) {
        return (
            <Tooltip title={`Certified User`}>
                <UserBadge badgeContent={`User`} className={classes.black} >
                    <Author authorId={userId} bold={true} link={true}/>
                </UserBadge>
            </Tooltip>
        )
    }
    else if (staff) {
        return (
            <Tooltip title={`Administrator`}>
                <StaffBadge badgeContent={`Staff`} >
                    <Author authorId={userId} bold={true} link={true}/>
                </StaffBadge>
            </Tooltip>
        )
    }
    else if (premium) {
        return (
            <Tooltip title={`Premium collaborator`}>
                <PremiumBadge badgeContent={`Premium`} >
                    <Author authorId={userId} bold={true} link={true}/>
                </PremiumBadge>
            </Tooltip>
        )
    }
    else {
        return (
            <Author authorId={userId} bold={true} link={true}/>
        )
    }
}

export default CommentAuthor;