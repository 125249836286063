import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import LockIcon from '@material-ui/icons/Lock';
import red from '@material-ui/core/colors/red'

// core components
import GridContainer from "../MaterialKitComponents/GridContainer.js";
import GridItem from "../MaterialKitComponents/GridItem.js";
import InfoArea from "../MaterialKitComponents/InfoArea.js";

import productStyle from '../../Assets/productStyle.js'

const useStyles = makeStyles(productStyle);

export default function SectionProduct() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h2 className={classes.title}>Voice of the User</h2>
          <h5 className={classes.description}>
            Incorporating user feedback in the design and development 
            process is critical to success and widget adoption. Doing 
            this earlier will reduce cycle time and the iterative process 
            of "develop, field/test, feedback then spiral.” This time 
            savings reduces cost while increasing the likelihood of a 
            successful prototype.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="Verified Users"
              description="Our network of Users are experts in their field, and their experiences span the Department. Every User is verified so you can hire with confidence."
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="Collaboration"
              description="Start a thread in the forum to generate discussion with Community members about an idea or product. Reply to others, post questions, or just observe. Experience virtual collaboration anytime, anywhere."
              icon={ForumOutlinedIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="Security"
              description="We fully encrypt all chat messages and use private forums and database hardening to keep your information and ideas secure."
              icon={LockIcon}
              iconColor='gray'
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
