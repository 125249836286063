import React, { useState, Fragment } from 'react' 
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ImageUpload from '../../MaterialKitComponents/ImageUpload';
import Button from '../../MaterialKitComponents/Button'
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import TextField from '@material-ui/core/TextField';
import { updateBio, updateSkills } from '../../../Redux/Actions/userActions'
import { connect } from 'react-redux'
import MessageIcon from '@material-ui/icons/Message';
import { activateConversation } from '../../../Redux/Actions/chatActions'
import Tooltip from '@material-ui/core/Tooltip';
import grey from '@material-ui/core/colors/grey'
import Badge from '@material-ui/core/Badge'
import Chip from '@material-ui/core/Chip'
import green from '@material-ui/core/colors/green'
import amber from '@material-ui/core/colors/amber'



//from material kit pro react
import typographyStyle from "../../../Assets/typographyStyle";

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      marginTop: 30,
      paddingBottom: 100,
    },
    photo: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(4),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    title: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: grey[800],
      },
      bio: {
        paddingTop: theme.spacing(2),
        paddingBotton: theme.spacing(2),
        marginTop: theme.spacing(2),
      },
      bioPaper: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          paddingBottom: theme.spacing(2),
      },
      skills: {
        paddingTop: theme.spacing(2),
        paddingBotton: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(10),
      },
      bioEditButton: {
          marginTop: theme.spacing(1),
          marginLeft: theme.spacing(1)
      },
      editBioField: {
          marginBottom: theme.spacing(2),
          width: '100%'
      },
      green: {
        backgroundColor: green[700],
        color: '#FFF',
      },
      gold: {
        backgroundColor: amber[300],
        color: '#000',
      },
  }));


function UserProfilePage({authProps, profileProps, profileUserId, updateBio, activateConversation}) {
    const classes = useStyles();
    const [editBio, setEditBio] = useState(false)
    const [editedBioText, setEditedBioText] = useState('')
    const isUsersProfile = authProps.uid === profileUserId ? true : false 

    /*
    * Functions for editing bio
    */
    const handleBioEdit = () => {
        // bio edit handler
        setEditBio(true)
    }

    const handleBioEditCancel = () => {
        setEditBio(false)
    }

    const handleBioTextChange = (event) => {
        setEditedBioText(event.target.value)
    }

    const saveBioEdits = (event) => {
        event.preventDefault()
        updateBio(editedBioText)
        setEditBio(false)
    }

    const sendMessage = (event) => {
        event.preventDefault()
        // begin an active conversation in the database
        // If one exists, make it active.
        const user = {
            id: profileUserId
        }
        activateConversation(user)
   } 

   const staff = <Chip className={classes.green} label='Administrator' />
   const premium = <Chip className={classes.gold} label='Premium' />
    

    return (
        <div className={classes.root}>
            <Container maxWidth='md' spacing={2}>
                <Paper elevation={2}>
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                >
                <Grid item>
                    <Paper className={classes.photo} elevation={0}>
                        <ImageUpload 
                         avatar
                         userId={authProps.uid}
                         profile={profileProps}
                         isUsersProfile={isUsersProfile}
                        />
                    </Paper>
                </Grid>
                <Grid item>
                    <Paper className={classes.title} elevation={0}>
                        <h4><b>{profileProps.firstName && profileProps.lastName ? `${profileProps.firstName} ${profileProps.lastName}` : profileProps.username}</b> <span>{profileProps.license === 'admin' ? staff : profileProps.license === 'premium' ? premium : null}</span></h4>
                        {authProps.uid != profileUserId ? 
                            <Tooltip title='Send a message'>
                                <IconButton
                                    color='primary'
                                    onClick={sendMessage}
                                >
                                    <MessageIcon/>
                                </IconButton>
                            </Tooltip>
                        :
                            null
                        }
                    </Paper>
                </Grid>
                </Grid>
                </Paper>         
                <Grid
                    container
                    direction="column"
                    className={classes.bio}
                >
                    <Paper elevation={2} >
                        <div className={classes.bioPaper}> 
                            <Grid container direction='row'>
                                <Grid item >
                                    <h4><b>Bio</b></h4>
                                </Grid>
                                {isUsersProfile ? 
                                    <Grid item>
                                        <IconButton size='small' onClick={handleBioEdit} className={classes.bioEditButton}>
                                            <EditIcon fontSize='small' color='disabled'/>
                                        </IconButton>
                                    </Grid>
                                :
                                    null
                                }
                            </Grid>
                            <Grid item>
                                    {editBio ? 
                                        <Fragment>
                                            <TextField
                                                id="outlined-multiline-static"
                                                label="Edit bio"
                                                multiline
                                                rowsMax="20"
                                                defaultValue={profileProps.bio}
                                                variant="outlined"
                                                className={classes.editBioField}
                                                onChange={handleBioTextChange}
                                            />
                                            <Button
                                                color='success'
                                                onClick={saveBioEdits}
                                            >
                                                Save Changes
                                            </Button>
                                            <Button
                                                color='default'
                                                onClick={handleBioEditCancel}
                                            >
                                                Cancel
                                            </Button>
                                        </Fragment>
                                    :
                                        <h5>
                                            {profileProps.bio}
                                        </h5>
                                    }
                                </Grid>
                        </div>
                    </Paper>
                </Grid>           
            </Container>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateBio: (bio) => dispatch(updateBio(bio)),
        activateConversation: (user) => dispatch(activateConversation(user))
    }
}

export default connect(null, mapDispatchToProps)(UserProfilePage)