  import firebase from 'firebase/app'
  import 'firebase/firestore'
  import 'firebase/auth'
  import 'firebase/storage'
  import 'firebase/analytics'
  import 'firebase/functions'
  
  // Your web app's Firebase configuration
  var firebaseConfig = {
    apiKey: "AIzaSyBwhJJhNBoaj8B0R46uCD_IlpPKNcwA4O0",
    authDomain: "user-community.firebaseapp.com",
    databaseURL: "https://user-community.firebaseio.com",
    projectId: "user-community",
    storageBucket: "user-community.appspot.com",
    messagingSenderId: "1073203520674",
    appId: "1:1073203520674:web:77535cd84ce58504f2e3fb",
    measurementId: "G-7TBY64HX5J"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics()

  export default firebase