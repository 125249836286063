import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { CircularProgress } from '@material-ui/core'
import Author from '../ForumComponents/Author'
import UserAvatar from '../UserComponents/UserAvatar'
import Grid from '@material-ui/core/Grid'
import Check from '@material-ui/icons/CheckCircle'

function ShowUser({ userId, changeLoading }) {

    useFirestoreConnect([
        {
            collection: 'users',
            doc: userId,
            storeAs: `${userId}-potential-admin`
        }
    ])
    const user = useSelector(state => state.firestore.ordered[`${userId}-potential-admin`])

    if (!user) return <CircularProgress />
    if (!user[0]) return <CircularProgress />
 
    const profile = user[0]
    if (profile.license === 'admin') {
        changeLoading(false)
    }
    
    return (
        <div>
            <Grid container direction='row' justify='center' alignItems='center' spacing={1}>
                <Grid item>
                    <UserAvatar userId={userId} />
                </Grid>
                <Grid item>
                    <Author authorId={userId} />
                </Grid>
                {profile.license === 'admin' ?
                    <Fragment>
                        <Grid item>
                            <Check style={{color: '#4caf50'}} />
                        </Grid>
                        <Grid item> 
                            <p>Admin</p>
                        </Grid>
                    </Fragment> 
                :
                    null
                }
            </Grid> 
            <div style={{padding: 5}} />
            <Grid container direction='column' justify='flex-start' alignItems='center' >
                <Grid item>
                    <p>{`Email(s): ${profile.emails ? profile.emails[0] : null}`}</p>
                    {profile.emails ? 
                        (profile.emails.length > 1 ? 
                            profile.emails.map(e => (
                                <p>{e}</p>
                            ))
                            : null
                        )
                        : null
                    }
                </Grid>
            </Grid>
        </div>
    )
}

export default ShowUser