import React, { useState } from 'react'
// redux
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
// material
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import AppBar from '@material-ui/core/AppBar'
import Skeleton from '@material-ui/lab/Skeleton'
// icons
import CheckIcon from '@material-ui/icons/Check'
// react router
import { useHistory, Link } from 'react-router-dom'
// styles
import '../css/jobs.css'
// firebase 
import firebase from 'firebase'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    jobCard: {
        padding: theme.spacing(2)
    },
    skillTag: {
        backgroundColor: 'lightgrey',
        borderRadius: 10,
        padding: 5,
        color: 'black',
    },
}))


function Job({ job, userId }) {
    // local styles
    const classes = useStyles()

    // local state
    const [showMore, setShowMore] = useState(false)

    // for navigation
    const history = useHistory()

    let auth = useSelector(state => state.firebase.auth)
    if (auth.isLoaded) {
        if (!auth.uid) {
            history.push('/login')
        }
    }

    // fetch proposal for this job (if there is one)
    useFirestoreConnect([
        {
            collection: 'proposals',
            where: [
                ['owner', '==', userId],
                ['job_id', '==', job.id]
            ],
            storeAs: `${job.id}-${userId}-proposal`
        }
    ])
    const proposal = useSelector(state => state.firestore.ordered[`${job.id}-${userId}-proposal`])

    // handlers
    const handleShowMore = (e) => {
        e.preventDefault()
        setShowMore(true)
    }

    const handleShowLess = (e) => {
        e.preventDefault()
        setShowMore(false)
    }

    if (!proposal) return null
    const alreadyApplied = proposal.length > 0
 
    return (
        <Paper variant='outlined' square className={classes.jobCard}>
            <Grid container direction='row' justify='space-between'>
                <Grid item>
                    <Grid container direction='row' alignItems='center' spacing={1}>
                        <Grid item>
                            <h5 style={{flexGrow: 1}}><b>{job.jobData.title}</b></h5>
                        </Grid>
                        <Grid item>
                            {alreadyApplied ?
                                <Chip
                                    icon={<CheckIcon style={{color: 'green'}}/>}
                                    label="Applied"
                                    variant='outlined'
                                />
                                : null 
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    {alreadyApplied ?
                        <Button 
                            color='primary'
                            variant='contained'
                            disabled
                        >
                            Submit Proposal
                        </Button>
                        :
                        <Link to={`/proposal/${job.id}`} target='_blank' >
                            <Button 
                                color='primary'
                                variant='contained'
                            >
                                Submit Proposal
                            </Button>
                        </Link>
                    }
                </Grid>
            </Grid>
            <h5 style={{whiteSpace: 'pre-wrap'}}>
                {
                    job.jobData.description.length > 500 && !showMore ?
                    `${job.jobData.description.substring(0, 500)}...`
                    :
                    job.jobData.description
                }
                {job.jobData.description.length > 500 && !showMore ?
                    <span className='more' onClick={handleShowMore}>{` more`}</span>
                    :
                    job.jobData.description.length > 500 && showMore ?
                    <span className='more' onClick={handleShowLess}>{` less`}</span>
                    : null
                }
            </h5>
            <h5><b>{`$${job.jobData.hourlyMin} - $${job.jobData.hourlyMax}`}</b>{` per hour - ${job.jobData.hoursPerWeek} hours per week - ${job.jobData.jobLength} ${job.jobData.jobLengthName}`}</h5>
            <Grid container direction='row' spacing={1}>
                {job.jobData.skillsNeeded.map((skill, index) => (
                    <Grid item key={index}>
                        <p className={classes.skillTag}>{skill}</p>
                    </Grid>
                ))}
            </Grid>
        </Paper>
    )
}

function JobsPage({ userId }) {
    const classes = useStyles()

    useFirestoreConnect([
        {
            collection: 'active_jobs',
            orderBy: [
                ['timestamp', 'desc']
            ],
            storeAs: 'active_jobs'
        }
    ])
    const jobs = useSelector(state => state.firestore.ordered['active_jobs'])

    return (
        <div style={{paddingTop: 100}}>
            <Container maxWidth='md'>
                <AppBar color='default' position='static'>
                    <h3 style={{marginLeft: 15}}>Jobs</h3>
                </AppBar>

                    {jobs && jobs.length > 0 ? jobs.map(job => (
                        <Job job={job} userId={userId} />
                    )) : 
                        (jobs ?  
                            <Paper variant='outlined' square style={{padding: 10, textAlign: 'center'}}>
                                <h4>There are currently no active job listings. Please check back soon!</h4>
                            </Paper>
                            : 
                            <div>
                                <Skeleton type='rect' height={300}/>
                                <Skeleton type='rect' height={300}/>
                                <Skeleton type='rect' height={300}/>
                            </div>
                        )
                    }
            </Container>
        </div>
    )
}

function Jobs(props) {
    // get user
    const user = firebase.auth().currentUser

    // for navigation
    const history = useHistory()

    const auth = useSelector(state => state.firebase.auth)

    if (!auth.isLoaded) return null
    // route guarding, navigate to login if user is not signed in
    if (!auth.uid) {
        history.push('/login')
    }
    else {
        user.getIdTokenResult()
            .then(r => {
                // only SME's and admins can view jobs posted by companies
                // This is just client route guarding. The data is secured by 
                // security rules and cannot be read by anyone but SME's and admins
                if (!r.claims.SME && !r.claims.admin) {
                    history.push('/forum')
                }
            })
            .catch(e => {
                history.push('/forum')
            }) 
    }

    return (
        <JobsPage userId={auth.uid} />
    )
}

export default Jobs