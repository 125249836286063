import React from 'react'
// Redux
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux'

function Company({userId}) {

    useFirestoreConnect([
        {
            collection: 'users',
            doc: userId,
            storeAs: `${userId}-profile`
        }
    ])
    const userList = useSelector(state => state.firestore.ordered[`${userId}-profile`])
    if (!userList) return null
    if (!userList[0]) return null
    const user = userList[0]
    
    return `${user.companyName}`
}

export default Company;