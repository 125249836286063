import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import TimeLog from './TimeLog'

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  }));


function DisplayBillUser({ userId }) {
    const classes = useStyles()

    useFirestoreConnect([
        {   collection: 'users', 
            doc: userId,
            storeAs: `${userId}-profile`
        }
    ])
    const userProfileArray = useSelector(state => state.firestore.ordered[`${userId}-profile`])

    if (!userProfileArray) return (
        <Grid container direction='row' alignItems='center' justify='center'>
            <CircularProgress />
        </Grid>
    )
    const userProfile = userProfileArray[0]
    if (!userProfile) return (
        <div>
            <h3>The information for this user cannot be found.</h3>
        </div>
    )

    return (
        <div>
            <Grid container direction='row' justify='flex-start' alignItems='center' spacing={3}>
                <Grid item>
                    <Avatar src={userProfile.profileImageUrl} className={classes.large} />
                </Grid>
                <Grid item>
                    <Grid container direction='column'>
                        <div>
                            <h3 style={{paddingBottom: 0, marginBottom: 0}}>{`${userProfile.firstName ? userProfile.firstName : userProfile.username} ${userProfile.lastName ? userProfile.lastName : null}`}</h3>
                            {userProfile.company ? 
                                <p style={{paddingTop: 0, marginTop: 0}}>{`Company: ${userProfile.company}`}</p>
                            :
                                null
                            }
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <TimeLog userProfile={userProfile} />
        </div>
    )
}

export default DisplayBillUser