import React from 'react'
import PaymentDetails from'./PaymentDetails'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import CircularProgress from '@material-ui/core/CircularProgress'

function FetchPaymentDetails({ docId, userId }) {

    useFirestoreConnect([
        {
            collection: 'users',
            doc: userId,
            subcollections: [{ collection: 'paymentRequest', 
                                doc: docId
                            }],
            storeAs: `${userId}-${docId}-payment-request`
        }
    ])
    const paymentArr = useSelector(state => state.firestore.ordered[`${userId}-${docId}-payment-request`])

    if (!paymentArr) return <CircularProgress />
    if (!paymentArr[0]) return (
        <h3>Information about this payment could not be found</h3>
    )
    const payment = paymentArr[0]

    return (
        <PaymentDetails payment={payment} />
    )
}

export default FetchPaymentDetails