import React, { Fragment, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { ThemeProvider, useTheme, createMuiTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { activateConversation } from '../../../Redux/Actions/chatActions'
import { saveEThree } from '../../../Redux/Actions/authActions'
import ChatBox from './ChatBox'
import { Container } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import grey from '@material-ui/core/colors/grey'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import MailIcon from '@material-ui/icons/Mail';
import { Link } from "react-router-dom";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Author from '../ForumComponents/Author'
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux'
import UserAvatar from '../UserComponents/UserAvatar'
import UserMessagesListItem from './UserMessagesListItem'
import Badge from '@material-ui/core/Badge'
import LockIcon from '@material-ui/icons/Lock'
import Tooltip from '@material-ui/core/Tooltip'
import { EThree } from '@virgilsecurity/e3kit-browser'
import firebase, { auth } from 'firebase'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import NewDeviceConfirmation from '../../Pages/NewDeviceConfirmation'
import ResetVirgil from './ResetVirgil'
import { useEffect } from 'react'

const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

const useStyles = makeStyles({
    messagesAnchor: {
        position: 'relative',
        height: 50,
        width: 250,
        backgroundColor: grey[800],
        zIndex: '1',
        paddingLeft: 10,
        paddingRight: 10,
    },
    chatboxPosition: {
        position: 'fixed', 
        bottom: 0, 
    },
    mobileChatboxPosition: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '9999',
        overflow: 'hidden',
    },
    messagesContainer: {
        maxHeight: '70vh',
        height: 500,
        width: 250,
        backgroundColor: grey[50],
        marginBottom: 10,
        zIndex: '1',
    },
    messageIcon: {
        color: grey[100],
    },
    lock: {
 
    },
    messagesTitle: {
        color: grey[100],
    },
    messagesContainerHeader: {
        height: 50,
        width: 250,
        backgroundColor: grey[800],
    },
    messagesListContainer: {
        height: 450,
        width: 250,
        backgroundColor: grey[50],
    },
    messagesList: {
        height: 450,
        width: 250,
        overflow: 'auto',
    },
    link: { // gws 2/12/20
        textDecoration: 'none',
        color: grey[800],
        "&:hover": {
          color: grey[800],
          textDecoration: 'none'
        },
        "&:visited": {
          color: grey[800]
        },
        "&:active": {
          color: grey[800]
        },
        "&:link": {
          color: grey[800]
        },
        "&:link:active": {
          color: grey[800]
        },
        "&:visited:active": {
          color: grey[800]
        },
    },
})


function useWidth() {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
      keys.reduce((output, key) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const matches = useMediaQuery(theme.breakpoints.up(key));
        return !output && matches ? key : output;
      }, null) || 'xs'
    );
  }

function ActiveChats({ authProps, profile, activateConversation, saveEThree }) {

    const history = useHistory()
    const location = useLocation()

    const smallScreen = useMediaQuery('(max-width:700px)')

    //  Callback to fetch virgil security token
    const getToken = firebase.functions().httpsCallable('getVirgilJwt');
    const initializeFunction = () => getToken().then(result => result.data.token)

    const classes = useStyles()
    const screenWidth = useWidth()

    const [openChats, setOpenChats] = useState(false)
    const [noPK, setNoPK] = useState(false)
    const [resetPK, setResetPK] = useState(false)

    const openChatsContainer = (event) => {
        event.preventDefault()
        setOpenChats(!openChats)
    }

    const openConversation = (event, chatId) => {
        event.preventDefault()
        const user = {
            id: chatId
        }
        activateConversation(user)
    }
    
    useFirestoreConnect([
        { collection: 'users', 
          doc: authProps.uid,
          subcollections: [{ collection: 'conversations',
                             orderBy: [
                                ['timeActive', 'desc']
                              ],
                          }],
          storeAs: 'conversations'
        }
      ])
    var conversations = useSelector(state => state.firestore.ordered.conversations)
    const eThree = useSelector(state => state.auth.eThree)

    /*

    useEffect(() => {
        if (!eThree) {
            EThree.initialize(initializeFunction).then(eThree => {
                eThree.hasLocalPrivateKey()
                    .then(hasPK => {
                        if(!hasPK) {
                            if (profile.virgilComplete === true) {
                                setNoPK(true)
                            }
                            else {
                                history.push('/messenger-setup')
                            }
                        }
                        saveEThree(eThree)
                    })
            })
        } 
        else {
            eThree.hasLocalPrivateKey()
                .then(hasPK => {
                    if(!hasPK) {
                        if (profile.virgilComplete === true) {
                            setNoPK(true)
                        }
                        else {
                            history.push('/messenger-setup')
                        }
                    }
                })
        }
    }, [location.pathname]) */

    if (!profile) return <div />
    if (!profile.isLoaded) return <div/>

    //if (profile && profile.virgilComplete === false) return <Redirect to='/messenger-setup' />

    if (!conversations) return <div/>

    // collect the active conversations and get a count of conversations with unread messages
    var activeConversations = []
    var unreadCount = 0
    conversations.map(conversation => {
        if (conversation.active) {
            activeConversations.push(conversation)
        }
        if (conversation.unread) {
            unreadCount++
        }
    })

    if (screenWidth === 'lg' && activeConversations.length > 3) {
        activeConversations = activeConversations.slice(0,3)
    } 
    else if (screenWidth === 'md' && activeConversations.length > 2) {
        activeConversations = activeConversations.slice(0,2)
    } 
    else if (screenWidth === 'sm' && activeConversations.length > 1) {
        activeConversations = activeConversations.slice(0,1)
    } 
    else if (screenWidth === 'xs' && activeConversations.length > 1) {
        activeConversations = activeConversations.slice(0,1)
    } 

    return (
        <Fragment>
            {openChats ? 
                <div style={{position: 'fixed', bottom: 0, right: 30}}>
                    <Paper elevation={3} className={classes.messagesContainer}>
                        <Link to='javascript:;' className={classes.link}>
                            <Paper elevation={3} className={classes.messagesAnchor} onClick={openChatsContainer}>
                                <Grid container direction='row' justify='space-between' alignItems='center' >
                                    <Grid item xs={10}>
                                        <Grid container direction='row' justify='flex-start' alignItems='center' spacing={1}>
                                            <Grid item>
                                                <Badge badgeContent={unreadCount} color='error'>
                                                    <MailIcon className={classes.messageIcon}/>
                                                </Badge>
                                            </Grid>
                                            <Grid item>
                                                <h5 className={classes.messagesTitle}>Messages</h5>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <LightTooltip title='Fully end-to-end encrypted (E2EE) chat messages. E2EE coming soon for photos, videos, and files'>
                                            <LockIcon className={classes.lock} />
                                        </LightTooltip>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Link>
                        <Paper className={classes.messagesListContainer}>
                            <List className={classes.messagesList} >
                                {conversations.map(conversation => (
                                    <Fragment key={conversation.id}>
                                        <Link to='javascript:;' className={classes.link}>
                                            <ListItem key={conversation.id} onClick={event => openConversation(event, conversation.id)}>
                                                <UserMessagesListItem userId={conversation.id} read={!conversation.unread} />
                                            </ListItem>
                                        </Link>
                                    </Fragment>
                                ))}
                            </List>
                        </Paper> 
                    </Paper>
                </div>
            :
                <div style={{position: 'fixed', bottom: 0, right: 30}}>
                    <Link to='javascript:;' className={classes.link}>
                        <Paper elevation={3} className={classes.messagesAnchor} onClick={openChatsContainer}>
                            <Grid container direction='row' justify='space-between' alignItems='center' >
                                <Grid item xs={10}>
                                    <Grid container direction='row' justify='flex-start' alignItems='center' spacing={1}>
                                        <Grid item >
                                            <Badge badgeContent={unreadCount} color='error'>
                                                <MailIcon className={classes.messageIcon}/>
                                            </Badge>
                                        </Grid>
                                        <Grid item>
                                            <h5 className={classes.messagesTitle}>Messages</h5>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1}>
                                    <LightTooltip title='Fully end-to-end encrypted (E2EE) chat messages. E2EE coming soon for photos, videos, and files'>
                                        <LockIcon className={classes.lock}/>
                                    </LightTooltip>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Link>
                </div>
            }
            {activeConversations.map((conversation, index) => (
                <div key={conversation.id} className={smallScreen ? classes.mobileChatboxPosition : classes.chatboxPosition} style={smallScreen ? null : {right: (index * 410) + 300}} >
                    <ChatBox 
                        key={conversation.id} 
                        chatId={conversation.id} 
                        userId={authProps.uid}
                        index={index}    
                    />
                </div>
            ))}
            <Dialog
                open={noPK}
                onClose={() => setNoPK(false)}
            >
                <div style={{paddingLeft: 20, paddingRight: 20}}>
                    {resetPK ? 
                        <ResetVirgil cancelCallback={() => setResetPK(false)} successCallback={() => setNoPK(false)}/>
                    :
                        <NewDeviceConfirmation userId={authProps.uid} successCallback={() => setNoPK(false)} resetCallback={() => setResetPK(true)}/>
                    }
                </div>
            </Dialog>
        </Fragment>
    )
}
const mapDispatchToProps = (dispatch) => {
    return {
      activateConversation: (user) => {dispatch(activateConversation(user))},
      saveEThree: (eThree) => {dispatch(saveEThree(eThree))},
    }
}
  
  export default connect(null, mapDispatchToProps)(ActiveChats)