import React from 'react'
import Author from '../ForumComponents/Author'
import UserAvatar from './UserAvatar'
import Grid from '@material-ui/core/Grid'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import CircularProgress from '@material-ui/core/CircularProgress'
import TimeLog from './TimeLog'
import Divider from '@material-ui/core/Divider'
import { displayTimeInfo, displayDateFromUtcString } from './Time'

function DisplayBillMeeting({ meeting }) {

    useFirestoreConnect([
        {   collection: 'users', 
            doc: meeting.clientId,
            storeAs: `${meeting.clientId}-profile`
        }
    ])
    const userProfileArray = useSelector(state => state.firestore.ordered[`${meeting.clientId}-profile`])

    if (!userProfileArray) return (
        <Grid container direction='row' alignItems='center' justify='center'>
            <CircularProgress />
        </Grid>
    )
    const userProfile = userProfileArray[0]
    if (!userProfile) return (
        <div>
            <h3>The information for this user cannot be found.</h3>
        </div>
    )
    console.log(meeting)

    return (
        <div>
            <Grid container direction='row' justify='flex-start' alignItems='center' spacing={2}>
                <Grid item>
                    <UserAvatar userId={meeting.clientId} large />
                </Grid>
                <Grid item>
                    <h3>{<Author authorId={meeting.clientId} textOnly/>}</h3>
                </Grid> 
            </Grid>
            <p>{`Meeting Details: ${displayDateFromUtcString(meeting.times[0])}, ${displayTimeInfo(meeting.times)}`}</p>
            <h3>{`Please confirm the date and time that this meeting took place:`}</h3>
            <TimeLog userProfile={userProfile} key={meeting.date}/>
        </div>
    )
}

export default DisplayBillMeeting