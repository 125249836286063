import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import Grid from '@material-ui/core/Grid'
import Circular from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { EThree } from '@virgilsecurity/e3kit-browser'
import firebase from 'firebase'
import { Redirect } from 'react-router-dom'
import red from '@material-ui/core/colors/red'
import CircularProgress from '@material-ui/core/CircularProgress'


const useStyles = makeStyles(theme => ({
    center: {
        textAlign: 'center',
        maxWidth: 700,
    },
    error: {
        color: red[500],
    },
}))

function NewDeviceConfirmation({ userId, successCallback, resetCallback }) {
    const classes = useStyles()

    //  Callback to fetch virgil security token
    const getToken = firebase.functions().httpsCallable('getVirgilJwt');
    const initializeFunction = () => getToken().then(result => result.data.token)

    // state
    const [answer1, setAnswer1] = useState('')
    const [answer2, setAnswer2] = useState('')
    const [answer3, setAnswer3] = useState('')
    const [success, setSuccess] = useState(false)
    const [status, setStatus] = useState(null)
    const [loading, setLoading] = useState(false)

    // functions
    const handleChange = (event) => {
        switch (event.target.id) {
            case 'a1':
                setAnswer1(event.target.value)
                break

            case 'a2':
                setAnswer2(event.target.value)
                break

            case 'a3':
                setAnswer3(event.target.value)
                break
    
            default:
                break
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setStatus(null)
        setLoading(true)
        // build password
        const keyPassword = `${answer1.trim().toLowerCase()}:${answer2.trim().toLowerCase()}:${answer3.trim().toLowerCase()}`
        // init e3
        EThree.initialize(initializeFunction).then(eThree => {
            eThree.hasLocalPrivateKey().then(hasLocalPrivateKey => {
                if (!hasLocalPrivateKey) {
                    eThree.restorePrivateKey(keyPassword)
                        .then(() => {
                            console.log('restore pk success')
                            setSuccess(true)
                            setLoading(false)
                            successCallback()
                        })
                        .catch(e => {
                            console.log('restore pk error: ', e)
                            setStatus('Failed Questions')
                            setLoading(false)
                        })
                }
                else {
                    setSuccess(true)
                    setLoading(false)
                }
            })
        })
        .catch(e => {
            console.error('init error: ', e)
            setLoading(false)
        })
    }

    // fetch data
    useFirestoreConnect([
        {
            collection: 'security_questions',
            doc: userId,
            storeAs: `${userId}-questions`
        }
    ])
    const questions = useSelector(state => state.firestore.ordered[`${userId}-questions`])
    const eThree = useSelector(state => state.auth.eThree)
    const profile = useSelector(state => state.firebase.profile)

    if (!questions || !questions[0]) {
        return (
            <Grid container direction='column' justify='center' alignItems='center'>
                <Circular />
            </Grid>
        )
    }

    return (
        <div style={{paddingTop: 100, backgroundColor: '#FFF', minHeight: '100vh'}}>
            <Grid container direction='column' justify='center' alignItems='center'>
                <h2 className={classes.center}>New Device</h2>
                <h3 className={classes.center}>Please answer your security questions below</h3>
                <h5 className={classes.center}>{`1. ${questions[0].question1}`}</h5>
                <TextField 
                    value={answer1}
                    placeholder='Answer 1'
                    variant='filled'
                    onChange={handleChange}
                    id='a1'
                    autoComplete='off'
                />
                <h5 className={classes.center}>{`2. ${questions[0].question2}`}</h5>
                <TextField 
                    value={answer2}
                    placeholder='Answer 2'
                    variant='filled'
                    onChange={handleChange}
                    id='a2'
                    autoComplete='off'
                />
                <h5 className={classes.center}>{`3. ${questions[0].question3}`}</h5>
                <TextField 
                    value={answer3}
                    placeholder='Answer 3'
                    variant='filled'
                    onChange={handleChange}
                    id='a3'
                    autoComplete='off'
                />
                <div style={{padding: 10}} />
                {loading ? 
                    <CircularProgress />
                :
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                }
                <div style={{padding: 10}} />
                <p className={classes.error}>{status}</p>
                <p>Forgot answers?</p>
                <Button onClick={resetCallback}>Reset</Button>
            </Grid>
        </div>
    )
}

export default NewDeviceConfirmation