import React, { useState } from 'react'
import algoliasearch from 'algoliasearch'
import { algoliaConfig } from '../../../Config/algoliaConfig'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import grey from '@material-ui/core/colors/grey'
import { Link } from "react-router-dom"
import UserAvatar from './UserAvatar'
import Author from '../ForumComponents/Author'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

const useStyles = makeStyles((theme) => ({
    text: {
        fontSize: 18,
    },
    link: {
        textDecoration: 'none',
        color: 'black',
        "&:hover": {
          color: grey[900],
          textDecoration: 'none'
        },
        "&:visited": {
          color: grey[900]
        },
        "&:active": {
          color: grey[900]
        },
        "&:link": {
          color: grey[900]
        },
        "&:link:active": {
          color: grey[900]
        },
        "&:visited:active": {
          color: grey[900]
        },
      },
    list: {
        maxHeight: '60vh',
        overflow: 'auto',
    },
}))
 
function ListOfPeople({ results, inputWidth, sendUserId, handleUserSelected, link }) {
    const classes = useStyles()

    const handleSelect = (objectID) => {
        sendUserId(objectID)
        handleUserSelected()
    }

    if (!results) return <div/>
    return (
        <div style={results.length > 0 ? {minWidth: inputWidth + 100, backgroundColor: grey[50]} : null} >
            <Paper elevation={2}>
                <List disablePadding className={classes.list}>
                    {results.map(result => (
                        (result.firstName && result.lastName ?
                            (link ? 
                                <Link to={`/profile/${result.objectID}`} className={classes.link} key={result.objectID}>
                                    <ListItem button key={result.objectID}>
                                        <ListItemIcon>
                                            <UserAvatar userId={result.objectID} />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={<Author authorId={result.objectID} textOnly/>} 
                                        />
                                    </ListItem>
                                </Link>
                            :
                                <ListItem button onClick={() => handleSelect(result.objectID)} key={result.objectID}>
                                    <ListItemIcon>
                                        <UserAvatar userId={result.objectID} />
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary={<Author authorId={result.objectID} textOnly/>} 
                                    />
                                </ListItem>
                            )
                        :
                            null
                        )
                    ))}
                </List>
            </Paper>
        </div>
    )
}

export default ListOfPeople