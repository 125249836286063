import React from 'react'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import Author from '../ForumComponents/Author'
import UserAvatar from './UserAvatar'

const useStyles = makeStyles((theme) => ({
    list: {
        height: 300,
        overflow: 'auto',
    },
}))

function OutstandingPayments({ userId, sendPaymentInfo }) {
    const classes = useStyles()
    
    const handleSelect = (request) => {
        sendPaymentInfo(request)
    }

    useFirestoreConnect([
        {
            collection: 'users',
            doc: userId,
            subcollections: [{ collection: 'paymentRequest', 
                                where: [
                                    ['paid', '==', true],
                                ]  
                        }],
            storeAs: `${userId}-payment-requests-paid`
        }
    ])
    const requests = useSelector(state => state.firestore.ordered[`${userId}-payment-requests-paid`])

    if (!requests) return (
        <Grid container direction='row' alignItems='center' justify='center'>
            <CircularProgress />
        </Grid>
    )

    return (
            <List className={classes.list}>
                {requests.map(request => (
                    <ListItem key={request.id} button onClick={() => handleSelect(request)} >
                        <ListItemIcon>
                            <UserAvatar userId={request.requestorId} />
                        </ListItemIcon> 
                        <ListItemText
                            primary={<Author authorId={request.requestorId}/>}
                            secondary={`${request.hours} ${request.hours === '1.00' ? 'hour' : 'hours'}`}
                        />
                    </ListItem>
                ))}
            </List>
    )
}

export default OutstandingPayments