import moment from 'moment'
export const displayTimeInfo = (_times) => {

    // sort times
    var times = _times.map(time => {
        const date = new Date(time)
        return date.getTime()
    })
    times.sort((a, b) => (a > b) ? 1 : -1)
    const startTime = new Date(times[0])
    var endTime = new Date(times[times.length-1])
    const thirtyMin = 1800000
    endTime.setTime(endTime.getTime() + thirtyMin)
    const startTimeString = moment(startTime).format('LT')
    const endTimeString = moment(endTime).format('LT')
    
    return `${startTimeString} - ${endTimeString}`
}

export const displayDateFromUtcString = (dateString) => {
    const date = new Date(dateString)
    const dateMoment = moment(date)
    return `${dateMoment.format('MMMM Do YYYY')}`
}