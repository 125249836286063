import React from 'react'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

function GetEmail({ userId }) {

    // get users emails from email collection
    const storeAs = `${userId}-emails`
    useFirestoreConnect([
        {
            collection: 'email',
            doc: userId,
            storeAs
        }
    ])
    const emails = useSelector(state => state.firestore.ordered[storeAs])

    if (!emails) return null
    if (!emails[0]) return null
    if (!emails[0].emails) return null

    const email = emails[0].emails[0]

    return email

}

export default GetEmail