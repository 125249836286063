import React, { useState, Fragment } from 'react' 
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ImageUpload from '../../MaterialKitComponents/ImageUpload';
import Button from '@material-ui/core/Button'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/EditOutlined';
import TextField from '@material-ui/core/TextField';
import { updateBio, updateSkills } from '../../../Redux/Actions/userActions'
import { activateConversation } from '../../../Redux/Actions/chatActions'
import { connect } from 'react-redux'
import TagsInput from "react-tagsinput";
import MessageIcon from '@material-ui/icons/Message';
import EventIcon from '@material-ui/icons/Event';
import Tooltip from '@material-ui/core/Tooltip';
import grey from '@material-ui/core/colors/grey'
import {Link} from "react-router-dom";
import Alert from '@material-ui/lab/Alert';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
// Icons
import SettingsIcon from '@material-ui/icons/Settings'
import Check from '@material-ui/icons/CheckCircle'
import Chip from '@material-ui/core/Chip'
// profile sections
import EmploymentHistory from './EmploymentHistory'
import Education from './Education'
//from material kit pro react
import { TableBody } from '@material-ui/core'
import ProfileSkeleton from './ProfileSkeleton';


const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      marginTop: 30,
      paddingTop: 100,
      paddingBottom: 100
    },
    photo: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(4),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    header: {
        backgroundColor: '#f5f5f5',
        height: 65,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: grey[800],
      },
      bio: {
        paddingTop: theme.spacing(2),
        //paddingBotton: theme.spacing(2),
        marginTop: theme.spacing(2),
      },
      bioPaper: {
          //paddingLeft: theme.spacing(2),
          //paddingRight: theme.spacing(2),
          //paddingBottom: theme.spacing(2),
      },
      edit: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
      skills: {
        paddingTop: theme.spacing(2),
        paddingBotton: theme.spacing(2),
        marginTop: theme.spacing(2),
      },
      bioEditButton: {
          marginTop: theme.spacing(1),
          marginLeft: theme.spacing(1)
      },
      editBioField: {
          marginBottom: theme.spacing(2),
          width: '100%'
      },
      aboutAlert: {
        marginBottom: theme.spacing(2),
      },
  }));


function ProfilePage({ authProps, profileProps, profileUserId, updateBio, updateSkills, activateConversation}) {
    const classes = useStyles();
    const [editBio, setEditBio] = useState(false)
    const [editedBioText, setEditedBioText] = useState('')
    const [editSkills, setEditSkills] = useState(false)
    const [editedSkills, setEditedSkills] = useState([])
    const isUsersProfile = authProps.uid === profileUserId ? true : false 
    var isUserApproved = false
    if (profileProps.license === 'SME') {
        isUserApproved = true
    }

    // guard this route from all but the owner, admins, and paid subscribers

    useFirestoreConnect([
        {   collection: 'application', 
            doc: profileUserId,
            storeAs: `${profileUserId}-appData`
        }
    ])
    const appData = useSelector(state => state.firestore.ordered[`${profileUserId}-appData`])

    // fetch employment data
    useFirestoreConnect([
        {
            collection: 'application',
            doc: profileUserId,
            subcollections: [
                {
                    collection: 'employment'
                }
            ],
            storeAs: `${profileUserId}-employment`
        }
    ])
    const employmentData = useSelector(state => state.firestore.ordered[`${profileUserId}-employment`])

    // fetch education data
    useFirestoreConnect([
        {
            collection: 'application',
            doc: profileUserId,
            subcollections: [
                {
                    collection: 'education'
                }
            ],
            storeAs: `${profileUserId}-education`
        }
    ])
    const educationData = useSelector(state => state.firestore.ordered[`${profileUserId}-education`])

    if (!appData) return <ProfileSkeleton />
    if (!appData[0]) return <ProfileSkeleton />

    const appProfile = appData[0]

    const skillRows = []

    if (appProfile.skills.length % 2 !== 0) {
        for (var i = 0; i < appProfile.skills.length - 1; i += 2) {
            skillRows.push(
                <TableRow key={appProfile.skills[i]}>
                    <TableCell component='th' scope='row'>{appProfile.skills[i]}</TableCell>
                    <TableCell align='left'>{appProfile.skills[i+1]}</TableCell>
                </TableRow>
            )
        }
        skillRows.push(
            <TableRow key={appProfile.skills[appProfile.skills.length-1]}>
                <TableCell component='th' scope='row'>{appProfile.skills[appProfile.skills.length-1]}</TableCell>
                <TableCell align='left'></TableCell>
            </TableRow>
        )
    }
    else {
        for (var i = 0; i < appProfile.skills.length; i += 2) {
            skillRows.push(
                <TableRow key={appProfile.skills[i]}>
                    <TableCell component='th' scope='row'>{appProfile.skills[i]}</TableCell>
                    <TableCell align='left'>{appProfile.skills[i+1]}</TableCell>
                </TableRow>
            )
        }
    }

    /*
    * Functions for editing bio
    */
    const handleBioEdit = () => {
        // bio edit handler
        setEditedBioText(profileProps.bio)
        setEditBio(true)
    }

    const handleBioEditCancel = () => {
        setEditBio(false)
    }

    const handleBioTextChange = (event) => {
        setEditedBioText(event.target.value)
    }

    const saveBioEdits = (event) => {
        event.preventDefault()
        updateBio(editedBioText)
        setEditBio(false)
    }
    

    /*
    * Functions for editing skills
    */

    const handleSkillsEdit = () => {
        setEditedSkills(appProfile.skills)
        setEditSkills(true)
    }

    const handleSkillsEditCancel = () => {
        setEditSkills(false)
    }

    const saveSkillsEdits = (event) => {
        event.preventDefault()
        const user ={
            skills: editedSkills
        }
        updateSkills(user)
        setEditSkills(false)
    }

    const handleSkillTags = regularTags => {
        setEditedSkills(regularTags);
    }

    /*
    * Functions for messaging
    */
   const sendMessage = (event) => {
        event.preventDefault()
        // begin an active conversation in the database
        // If one exists, make it active.
        const user = {
            id: profileUserId
        }
        activateConversation(user)
   } 

    return (
        <div className={classes.root}>
            <Container maxWidth='md' spacing={2}>
                <Paper elevation={2} >
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                >
                <Grid item>
                    <Paper className={classes.photo} elevation={0}>
                        <ImageUpload 
                         avatar
                         userId={authProps.uid}
                         profile={profileProps}
                         isUsersProfile={isUsersProfile}
                         profileUserId={profileUserId}
                        />
                    </Paper>
                </Grid>
                <Grid item>
                    <Paper className={classes.title} elevation={0}>
                        <h5><b>{profileProps.firstName}</b> <b>{profileProps.lastName}</b> <span><Chip color='primary' icon={<Check />} label={isUserApproved ? 'Certified User' : 'Pending certification'} /></span></h5>
                        <h5>{appProfile.activeDuty ? 'Active Duty' : 'Former'} {appProfile.job} in the U.S. {appProfile.branchOfService}</h5>
                        {authProps.uid != profileUserId ? 
                            <Fragment>
                                <Tooltip title='Send a message'>
                                    <IconButton
                                        color='primary'
                                        onClick={sendMessage}
                                    >
                                        <MessageIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Link to={`/schedule/${profileUserId}`}>
                                    <Tooltip title='Schedule a session'>
                                        <IconButton
                                            color='primary'
                                        >
                                            <EventIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Link>
                            </Fragment>
                        :
                            <Fragment>
                                <Link to={`/schedule/${profileUserId}`}>
                                    <Tooltip title='Manage availabilities'>
                                        <IconButton
                                            color='primary'
                                        >
                                            <EventIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Link>
                                {/* Add this when billing is live
                                <Link to={`/billing/${profileUserId}`}>
                                    <Tooltip title='Log billable hours'>
                                        <IconButton
                                            color='primary'
                                        >
                                            <AttachMoneyIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Link>
                                */}
                                <Link to={`/account/${profileUserId}`}>
                                    <Tooltip title='Update account settings'>
                                        <IconButton
                                            color='primary'
                                        >
                                            <SettingsIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Link>
                            </Fragment>
                        }
                    </Paper>
                </Grid>
                </Grid>
                </Paper>         
                <Grid
                    container
                    direction="column"
                    className={classes.bio}
                >
                    <Paper elevation={2} >
                        <div className={classes.bioPaper}> 
                            <Grid container direction='row' justify='space-between' alignItems='center' className={classes.header}>
                                <Grid item>
                                    <h4><b>About</b></h4>
                                </Grid>
                                {isUsersProfile ? 
                                <Grid item>
                                    <IconButton onClick={handleBioEdit} className={classes.bioEditButton}>
                                        <EditIcon />
                                    </IconButton>
                                </Grid>
                                    :
                                    null
                                }
                            </Grid> 
                            <Grid item>
                                    {editBio ? 
                                        <div className={classes.edit}>
                                            <Alert className={classes.aboutAlert} severity='info'>A sentence or two which will give someone more insight into your background and expertise</Alert>
                                            <TextField
                                                id="outlined-multiline-static"
                                                label="Edit bio"
                                                multiline
                                                rowsMax="20"
                                                defaultValue={editedBioText}
                                                variant="outlined"
                                                className={classes.editBioField}
                                                onChange={handleBioTextChange}
                                            />
                                            <Button
                                                color='primary'
                                                onClick={saveBioEdits}
                                            >
                                                Save Changes
                                            </Button>
                                            <Button
                                                color='secondary'
                                                onClick={handleBioEditCancel}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    :
                                        <div className={classes.edit}>
                                            <h5>
                                                {profileProps.bio}
                                            </h5>
                                        </div>
                                    }
                                </Grid>
                        </div>
                    </Paper>
                </Grid>           
                <Grid
                    container
                    direction="column"
                    className={classes.skills}
                >
                    <Paper elevation={2} >
                        <div className={classes.bioPaper}> 
                            <Grid container direction='row' justify='space-between' alignItems='center' className={classes.header}>
                                <Grid item>
                                    <h4><b>Skills</b></h4>
                                </Grid>
                                {isUsersProfile ? 
                                    <Grid item>
                                        <IconButton onClick={handleSkillsEdit} className={classes.bioEditButton}>
                                            <EditIcon />
                                        </IconButton>
                                    </Grid>
                                    :
                                    null
                                }
                            </Grid>
                            {editSkills ? 
                                <Grid container direction='column' className={classes.edit}>
                                <Alert className={classes.aboutAlert} severity='info'>These skills are used to match you up with businesses that may want to hire you. Choose skills that are short and descriptive.</Alert>
                                    <Grid item>
                                        <TagsInput
                                            value={editedSkills}
                                            onChange={handleSkillTags}
                                            tagProps={{ className: "react-tagsinput-tag default" }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            color='primary'
                                            onClick={saveSkillsEdits}
                                        >
                                            Save Changes
                                        </Button>
                                        <Button
                                            color='secondary'
                                            onClick={handleSkillsEditCancel}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            :
                                <Table>
                                    <TableBody>
                                        {skillRows}
                                    </TableBody>
                                </Table>
                            }
                        </div>
                    </Paper>
                </Grid>
                <EmploymentHistory isUsersProfile={isUsersProfile} employmentData={employmentData} userId={profileUserId}/>
                <Education isUsersProfile={isUsersProfile} educationData={educationData} userId={profileUserId} />
            </Container>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateBio: (bio) => dispatch(updateBio(bio)),
        updateSkills: (user) => dispatch(updateSkills(user)),
        activateConversation: (user) => dispatch(activateConversation(user))
    }
}

export default connect(null, mapDispatchToProps)(ProfilePage)