import React, { Component, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux'
import { createGenre } from '../../../Redux/Actions/forumActions'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel'
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  label: {
    fontSize: 20,
  },
}));


function NewForum({ forums, createGenre }) {
  const classes = useStyles()

  const [title, setTitle] = useState('')
  const [subtitle, setSubtitle] = useState('')
  const [forumId, setForumId] = useState(null)
  const [access, setAccess] = useState('basic')

  const handleChange = (event) => {
    if (event.target.id === 'title') {
      setTitle(event.target.value)
    } else if (event.target.id === 'subtitle') {
      setSubtitle(event.target.value)
    } 
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (forumId) {
      const genre = {
        title,
        subtitle,
        forumId,
        access,
      }
      createGenre(genre)
      
      setTitle('')
      setSubtitle('')
      setAccess('basic')
    } 
  }

  const handleListItemClick = (event, id) => {
    // set the selected forum section
    setForumId(id)
  }

  const handleChangeAccess = (event) => {
    setAccess(event.target.value)
  }

    const main = {
      padding: "24px",
      width: 600,
      textAlign: 'center',
    }
    const button = {
      padding: "8px",
    }
    const text = {
      maxWidth: '600px',
    }

    if (!forums) {
      return <div />
    }

    return (
      <div style={{paddingTop: 100}}>
        <Grid container direction='column' justify='center' alignItems='center'>
          <Paper style={main} elevation={3}>
          <h4>Select a section and create a forum</h4>
            <Grid container direction='row' justify='space-between' alignItems='flex-start'>
              <Grid item>
                <List component='nav'>
                  {forums.map(section => {
                    return (
                      <ListItem
                        key={section.id}
                        button
                        doc_id={section.id}
                        selected={forumId === section.id}
                        onClick={event => handleListItemClick(event, section.id)}
                      >
                        <ListItemText primary={section.title} />
                      </ListItem>
                    )
                  })}
                </List>
              </Grid>
              <Grid item>
                <div style={{width: 300}}>
                  <Grid container direction='column' justify='center' alignItems='center'>
                    <TextField 
                      id="title" 
                      label="Title" 
                      autoComplete='off'
                      fullWidth
                      style={text}
                      value={title}
                      onChange={handleChange}
                    />
                    <TextField
                      id="subtitle"
                      label="Subtitle"
                      placeholder=""
                      autoComplete='off'
                      fullWidth
                      style={text}
                      value={subtitle}
                      onChange={handleChange}
                    />
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel className={classes.label}>Access Level</InputLabel>
                      <Select
                        value={access}
                        onChange={handleChangeAccess}
                      >
                        <MenuItem value={'basic'}>Basic Subscribers</MenuItem>
                        <MenuItem value={'premium'}>Premium Subscribers</MenuItem>
                        <MenuItem value={'sme'}>SME Only</MenuItem>
                      </Select>
                    </FormControl>
                    <div style={button}>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      onClick={handleSubmit}
                      style={button}>
                      Submit
                    </Button>
                  </div>
                </Grid>
              </div>
            </Grid>
          </Grid>
          </Paper>
        </Grid>
      </div>
    )

}

const mapDispatchToProps = (dispatch) => {
  return {
    createGenre: (genre) => {dispatch(createGenre(genre))}
  }
}

export default compose(
  firestoreConnect((props) => [
    {
      collection: 'forum',
      orderBy: 'index'
    },
  ]),
  connect((state, props) => ({
    forums: state.firestore.ordered.forum
  }), mapDispatchToProps)
)(NewForum)
