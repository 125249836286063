import React from 'react'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import Link from '@material-ui/core/Link'

function GetResume({ userId }) {

    // get users emails from email collection
    const storeAs = `${userId}-sme_private`
    useFirestoreConnect([
        {
            collection: 'sme_private',
            doc: userId,
            storeAs
        }
    ])
    const smePrivate = useSelector(state => state.firestore.ordered[storeAs])

    if (!smePrivate) return null
    if (!smePrivate[0]) return null
    if (!smePrivate[0].resume) return null

    const resume = smePrivate[0].resume

    return <p><Link href={resume} target='_blank' >Resume</Link></p>

}

export default GetResume