import React, { Fragment, useState } from 'react'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined'
import { Link, withRouter } from 'react-router-dom'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import Redirect from 'react-router-dom'
import Author from '../ForumComponents/Author'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { markRead, markAdminNotificationRead } from '../../../Redux/Actions/commentActions'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'
import green from '@material-ui/core/colors/green'
import blue from '@material-ui/core/colors/blue'
import EventIcon from '@material-ui/icons/Event'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import BusinessIcon from '@material-ui/icons/Business'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Proposal from '@material-ui/icons/Description'
import Money from '@material-ui/icons/MonetizationOn'

const unreadColor = blue[100]

const useStyles = makeStyles(theme => ({
    titleButton: {
      textDecoration: 'none',
      color: 'black',
      "&:hover": {
        color: grey[900],
        textDecoration: 'none'
      },
      "&:visited": {
        color: grey[900]
      },
      "&:active": {
        color: grey[900]
      },
      "&:link": {
        color: grey[900]
      },
      "&:link:active": {
        color: grey[900]
      },
      "&:visited:active": {
        color: grey[900]
      },
    },
    unreadStyle: {
      backgroundColor: unreadColor,
    },
    adminUnreadStyle: {
        backgroundColor: green[200],
    },
  }))


function Notification({notification, authId, markRead, markAdminNotificationRead}) {
    const classes = useStyles();
    const handleListItemClick = (event, notification) => {
      // mark this notification as read
      if (notification.unread) {

        markRead(notification.id)
      } 
    }

    const handleAdminNotificationClick = (event, notification) => {
        // mark this notification as read
        if (notification.unread) {
            markAdminNotificationRead(notification.id)
        } 
    }


    switch (notification.type) {
        case 'reply':
            return (
                <Fragment>
                    <Link 
                        to={`/forum/${notification.forumDocId}/${notification.genreDocId}/${notification.topicDocId}`} 
                        className={classes.titleButton}
                    >
                        <ListItem
                            key={notification.id}
                            button
                            onClick={event => handleListItemClick(event, notification)}
                            className={notification.unread ? classes.unreadStyle : null}
                        >
                            <ListItemIcon>
                                <ForumOutlinedIcon color='primary'/>
                            </ListItemIcon>
                            <ListItemText 
                                primary={<Author authorId={notification.author} textOnly addText={`replied: ${notification.text.length < 100 ? notification.text : notification.text.substring(0,100)} ${notification.text.length > 100 ? '...' : ''}`} />} 
                                secondary={moment(notification.createdAt.toDate().toString()).calendar()}
                            />
                        </ListItem>
                    </Link>
                </Fragment>
            )
            break
        case 'meetingRequest':
            return (
                <Fragment>
                    <Link to={`/schedule/${authId}`} className={classes.titleButton}>
                        <ListItem
                            key={notification.id}
                            button
                            onClick={event => handleListItemClick(event, notification)}
                            className={notification.unread ? classes.unreadStyle : null}
                        >
                            <ListItemIcon>
                                <EventIcon color='primary'/>
                            </ListItemIcon> 
                            <ListItemText 
                                primary={<Author authorId={notification.userId} textOnly addText={`has requested an appointment`} />} 
                                secondary={moment(notification.createdAt.toDate().toString()).calendar()}
                            />
                        </ListItem>
                    </Link>
                </Fragment>
            )
            break
        case 'meetingConfirm':
            return (
                <Fragment>
                    <Link 
                        to={`/meeting/${notification.id}/${authId}`} 
                        className={classes.titleButton}
                    >
                        <ListItem
                            key={notification.id}
                            button
                            onClick={event => handleListItemClick(event, notification)}
                            className={notification.unread ? classes.unreadStyle : null}
                        >
                            <ListItemIcon>
                                <EventIcon color='primary'/>
                            </ListItemIcon>
                            <ListItemText 
                                primary={<Author authorId={notification.userId} textOnly addText={`has accepted your appointment`} />} 
                                secondary={moment(notification.createdAt.toDate().toString()).calendar()}
                            />
                        </ListItem>
                    </Link>
                </Fragment>
            )
            break
        case 'meetingReject':
            return (
                <Fragment>
                    <ListItem
                        key={notification.id}
                        button
                        onClick={event => handleListItemClick(event, notification)}
                        className={notification.unread ? classes.unreadStyle : null}
                    >
                        <ListItemIcon>
                            <EventIcon color='primary'/>
                        </ListItemIcon>
                        <ListItemText 
                            primary={<Author authorId={notification.userId} textOnly addText={`has not accepted your appointment`} />} 
                            secondary={moment(notification.createdAt.toDate().toString()).calendar()}
                        />
                    </ListItem>
                </Fragment>
            )
            break
        case 'paymentRequest':
            return (
                <Fragment>
                    <Link 
                        to={`/payment-request/${authId}/${notification.docRef}`} 
                        className={classes.titleButton}
                    >
                        <ListItem
                            key={notification.id}
                            button
                            onClick={event => handleListItemClick(event, notification)}
                            className={notification.unread ? classes.unreadStyle : null}
                        >
                            <ListItemIcon>
                                <AccountBalanceIcon color='primary'/>
                            </ListItemIcon>
                            <ListItemText 
                                primary={<Author authorId={notification.requestorId} textOnly addText={`has logged billable hours`} />} 
                                secondary={moment(notification.createdAt.toDate().toString()).calendar()}
                            />
                        </ListItem>
                    </Link>
                </Fragment>
            )
            break
        case 'premiumGranted':
            return (
                <Fragment>
                    <Link 
                        to={`/premium-granted`} 
                        className={classes.titleButton}
                    >
                        <ListItem
                            key={notification.id}
                            button
                            onClick={event => handleListItemClick(event, notification)}
                            className={notification.unread ? classes.unreadStyle : null}
                        >
                            <ListItemIcon>
                                <CheckCircleIcon color='primary'/>
                            </ListItemIcon>
                            <ListItemText 
                                primary={`Premium license granted`} 
                                secondary={moment(notification.createdAt.toDate().toString()).calendar()}
                            />
                        </ListItem>
                    </Link>
                </Fragment>
            )
            break
        case 'SMEGranted':
            return (
                <Fragment>
                    <Link 
                        to={`/user-access`} 
                        className={classes.titleButton}
                    >
                        <ListItem
                            key={notification.id}
                            button
                            onClick={event => handleListItemClick(event, notification)}
                            className={notification.unread ? classes.unreadStyle : null}
                        >
                            <ListItemIcon>
                                <CheckCircleIcon color='primary'/>
                            </ListItemIcon>
                            <ListItemText 
                                primary={`You have been accepted as a User and granted special access on the platform. Click this notification to learn more.`} 
                                secondary={moment(notification.createdAt.toDate().toString()).calendar()}
                            />
                        </ListItem>
                    </Link>
                </Fragment>
            )
            break
        case 'appComplete':
            return (
                <Fragment>
                    <Link 
                        to='/admin/applications' 
                        className={classes.titleButton}
                    >
                        <ListItem
                            key={notification.id}
                            button
                            onClick={event => handleAdminNotificationClick(event, notification)}
                            className={notification.unread ? classes.adminUnreadStyle : null}
                        >
                            <ListItemIcon>
                                <VerifiedUserIcon color='primary'/>
                            </ListItemIcon>
                            <ListItemText 
                                primary={`${notification.user} has completed the consultant application.`} 
                                secondary={moment(notification.completeDate.toDate().toString()).calendar()}
                            />
                        </ListItem>
                    </Link>
                </Fragment>
            )
            break
        case 'proposal_submitted':
            return (
                <Fragment>
                    <Link 
                        to={`/proposals/${notification.job_id}`} 
                        className={classes.titleButton}
                    >
                        <ListItem
                            key={notification.id}
                            button
                            onClick={event => handleListItemClick(event, notification)}
                            className={notification.unread ? classes.unreadStyle : null}
                        >
                            <ListItemIcon>
                                <Proposal color='primary'/>
                            </ListItemIcon>
                            <ListItemText 
                                primary={`You have a new proposal!`} 
                                secondary={moment(notification.createdAt.toDate().toString()).calendar()}
                            />
                        </ListItem>
                    </Link>
                </Fragment>
            )
        break
        case 'contract_granted':
            return (
                <Fragment>
                    <Link 
                        to={`/contracts/${authId}/`} 
                        className={classes.titleButton}
                    >
                        <ListItem
                            key={notification.id}
                            button
                            onClick={event => handleListItemClick(event, notification)}
                            className={notification.unread ? classes.unreadStyle : null}
                        >
                            <ListItemIcon>
                                <Money color='primary'/>
                            </ListItemIcon>
                            <ListItemText 
                                primary={<Author authorId={notification.owner} textOnly addTextBegin={`You have been hired by`} />} 
                                secondary={moment(notification.createdAt.toDate().toString()).calendar()}
                            />
                        </ListItem>
                    </Link>
                </Fragment>
            )
            break
        case 'job_removed':
            return (
                <Fragment>
                    <Link 
                        to={`/jobs`} 
                        className={classes.titleButton}
                    >
                        <ListItem
                            key={notification.id}
                            button
                            onClick={event => handleListItemClick(event, notification)}
                            className={notification.unread ? classes.unreadStyle : null}
                        >
                            <ListItemIcon>
                                <Money color='primary'/>
                            </ListItemIcon>
                            <ListItemText 
                                primary={`A job which you submitted a proposal to has been removed.`} 
                                secondary={moment(notification.createdAt.toDate().toString()).calendar()}
                            />
                        </ListItem>
                    </Link>
                </Fragment>
            )
            break
        case 'contract_closed_by_client':
            return (
                <Fragment>
                    <Link 
                        to={`/job-listings/${authId}/archived`} 
                        className={classes.titleButton}
                    >
                        <ListItem
                            key={notification.id}
                            button
                            onClick={event => handleListItemClick(event, notification)}
                            className={notification.unread ? classes.unreadStyle : null}
                        >
                            <ListItemIcon>
                                <Proposal color='primary'/>
                            </ListItemIcon>
                            <ListItemText 
                                primary={<Author authorId={notification.owner} textOnly addText={`has closed a contract.`} />} 
                                secondary={moment(notification.createdAt.toDate().toString()).calendar()}
                            />
                        </ListItem>
                    </Link>
                </Fragment>
            )
            break
        case 'contract_closed_by_business':
            return (
                <Fragment>
                    <Link 
                        to={`/contracts/${authId}`} // ***FIXME add 'closed' to nav to closed contracts
                        className={classes.titleButton}
                    >
                        <ListItem
                            key={notification.id}
                            button
                            onClick={event => handleListItemClick(event, notification)}
                            className={notification.unread ? classes.unreadStyle : null}
                        >
                            <ListItemIcon>
                                <Proposal color='primary'/>
                            </ListItemIcon>
                            <ListItemText 
                                primary={<Author authorId={notification.owner} textOnly addText={`has closed a contract.`} />} 
                                secondary={moment(notification.createdAt.toDate().toString()).calendar()}
                            />
                        </ListItem>
                    </Link>
                </Fragment>
            )
            break
        case 'newBusiness':
            return (
                <Fragment>
                    <Link 
                        to='/admin/licenses' 
                        className={classes.titleButton}
                    >
                        <ListItem
                            key={notification.id}
                            button
                            onClick={event => handleAdminNotificationClick(event, notification)}
                            className={notification.unread ? classes.adminUnreadStyle : null}
                        >
                            <ListItemIcon>
                                <BusinessIcon color='primary'/>
                            </ListItemIcon>
                            <ListItemText 
                                primary={`${notification.user} has requested a premium license`} 
                                secondary={moment(notification.completeDate.toDate().toString()).calendar()}
                            />
                        </ListItem>
                    </Link>
                </Fragment>
            )
            break
        default:
            return <div />
            break
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
      markRead: (notificationId) => dispatch(markRead(notificationId)),
      markAdminNotificationRead: (notificationId) => dispatch(markAdminNotificationRead(notificationId))
    }
}

export default withRouter(connect(null, mapDispatchToProps)(Notification))