import React, {Fragment, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'
import { Link, withRouter } from 'react-router-dom'
import {signOut} from '../../../Redux/Actions/authActions'
import {connect} from 'react-redux'
import NotificationIcon from './NotifcationIcon'
import AdminNotificationIcon from './AdminNotificationIcon'
import Container from '@material-ui/core/Container';
import firebase from 'firebase/app'
import grey from '@material-ui/core/colors/grey';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import AdminButton from './AdminButton'
import ProfileIcon from './ProfileIcon'
import PeopleSearch from '../UserComponents/PeopleSearch'
import Logo from '../../../Assets/images/logo.png'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  color: {
    backgroundColor: 'rgb(25,34,60)',
  },
  logo: {
    borderRadius: '10%',
    marginRight: 10,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  titleButton: {
    textDecoration: 'none',
    color: grey[200],
    "&:hover": {
      textDecoration: 'underline',
      color: grey[200]
    },
    "&:visited": {
      color: grey[200]
    },
    "&:active": {
      color: grey[200]
    },
    "&:link": {
      color: grey[200]
    },
  },
  button: {
    textDecoration: 'none',
    color: grey[200],
    "&:hover": {
      color: grey[200]
    },
    "&:visited": {
      color: grey[200]
    },
    "&:active": {
      color: grey[200]
    },
    "&:link": {
      color: grey[200]
    },
    "&:link:active": {
      color: grey[200]
    },
    "&:visited:active": {
      color: grey[200]
    },
  },
  login: {
    paddingRight: 10,
  },
  searchStyles: {
    backgroundColor: grey[100],
  },
}));


const Header = (props) => {
  const { signOut, auth, profile } = props
  const signedIn = auth.uid && auth.emailVerified

  const classes = useStyles();

  const [admin, setAdmin] = useState(false)

  firebase.auth().onAuthStateChanged(user => {
    if (user) {
      user.getIdTokenResult().then(idTokenResult => {
        setAdmin(idTokenResult.claims.admin)
      }).catch(error => {
        console.log(error)
      })
    }
    else {
      setAdmin(false)
    }
  })

  const goToLogin = () => {
    props.history.push('/login')
  }

  const goToSignup = () => {
    props.history.push('/choose-registration')
  }

  var d = new Date();
  d.setDate(d.getDate() - 2);

  const sevenDaysAgo = new Date()

  function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });
  
    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }


  return (
    <div className={classes.root}>
      <ElevationScroll {...props}>
        <AppBar className={classes.color}>
        <Container maxWidth='lg'>
          <Toolbar>
            <div className={classes.title}>
              <Link to='/' className={classes.titleButton}>
                <Grid container direction='row' alignItems='center'>
                  <img src={Logo} className={classes.logo} width='63'/>
                  <h4><b>User Community</b></h4>
                </Grid>
              </Link>
            </div>
            {signedIn ? 
              <PeopleSearch link styleClass={classes.searchStyles}/>
            :
              <Fragment>
                {/*
                <Link to={'/about'} className={classes.button}>
                  <Button 
                    color="inherit"
                    size='small'
                    style={{marginRight: 10}}
                  >
                    About Us
                  </Button>
                </Link>
                */}
                <Link to={'/pricing'} className={classes.button}>
                  <Button 
                    color="inherit"
                    size='small'
                    style={{marginRight: 7}}
                  >
                    Pricing
                  </Button>
                </Link>
              </Fragment>
            }
            <Link to={'/forum'} className={classes.button}>
              <Button 
                color="inherit"
                size='small'
              >
                Forum
              </Button>
            </Link>
            <Link to={'/experts'} className={classes.button}>
              <Button 
                color="inherit"
                size='small'
              >
                Users
              </Button>
            </Link>
            {profile.license && profile.token && profile.token.claims.SME ?
              <Link to={'/jobs'} className={classes.button}>
                <Button 
                  color="inherit"
                  size='small'
                >
                  Jobs
                </Button>
              </Link>
              :
              null
            }
            {profile.license && profile.token && profile.token.claims.premium ?
              <Link to={`/job-listings/${auth.uid}`} className={classes.button}>
                <Button 
                  color="inherit"
                  size='small'
                >
                  Jobs
                </Button>
              </Link>
              :
              null
            }
            {admin ?
              <Link to={'/admin/dashboard'} className={classes.button}>
                <Button 
                  color="inherit"
                  size='small'
                >
                  Admin
                </Button>
              </Link>
            :
              null
            }
            {auth.isLoaded ? (signedIn ? 
              <Fragment>
                <ProfileIcon userId={auth.uid} profile={profile}/>
                {admin ?
                  <AdminNotificationIcon 
                    authId={auth.uid}
                    timeFrame={sevenDaysAgo}
                  />
                :
                  <NotificationIcon 
                    authId={auth.uid}
                    timeFrame={sevenDaysAgo}
                  />
                }
                <Button 
                  color="inherit"
                  size='small'
                  onClick={signOut}
                >
                  Sign out
                </Button>
              </Fragment>
            :
              <Fragment>
                <Button 
                  color="inherit" 
                  onClick={goToLogin}
                  size='small'
                >
                  Sign in
                </Button>
                <Typography variant="caption" >
                  <Button 
                    color="inherit" 
                    onClick={goToSignup}
                    variant='outlined'
                    size='small'
                  >
                    Sign up
                  </Button>
                </Typography>
              </Fragment>
            ) : null
            }
          </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => {dispatch(signOut())}
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))