import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import {Link} from "react-router-dom";
import moment from 'moment'
import grey from '@material-ui/core/colors/grey'
import amber from '@material-ui/core/colors/amber'
import blue from '@material-ui/core/colors/blue'
import Chip from '@material-ui/core/Chip'
import Star from '@material-ui/icons/StarBorder'

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 16,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: grey[50],
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#FFF',
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  link: { // gws 2/12/20
    textDecoration: 'none',
    color: grey[900],
    "&:hover": {
      color: grey[900],
      textDecoration: 'underline'
    },
    "&:visited": {
      color: grey[900]
    },
    "&:active": {
      color: grey[900]
    },
    "&:link": {
      color: grey[900]
    },
    "&:link:active": {
      color: grey[900]
    },
    "&:visited:active": {
      color: grey[900]
    },
  },
  container: {
    marginBottom: 50,
  },
  userChip: {
    marginLeft: 5,
    backgroundColor: blue[500],
    color: '#000',
  },
  premiumChip: {
    marginLeft: 5,
    backgroundColor: amber[300],
  },
});


function ForumTable(props) {
  const classes = useStyles();
  const {orderedGenres} = props

  if (!orderedGenres) {
    return <div />
  }

  return (
      <TableContainer component={Paper} className={classes.container}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell >{props.title}</StyledTableCell>
              <StyledTableCell size='small' align="right">Last Post</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderedGenres.map(genre => (
              <StyledTableRow key={genre.id}>
                <StyledTableCell component="th" scope="row" key={genre.id}> 
                  <ul style={ {listStyleType: 'none', padding: '0px', margin: '0px'} } key={genre.id}>
                    <li style={{fontWeight: 'bold'}}>
                      <Link 
                        to={`/forum/${props.forum_doc_id}/${genre.id}`} 
                        className={classes.link} 
                      >
                        {genre.title}
                      </Link>
                      {genre.access && genre.access === 'sme' ?
                        <Chip className={classes.userChip} label='User' color='primary' size='small' disabled/>
                        : null
                      }
                      {genre.access && genre.access === 'premium' ?
                        <Chip className={classes.premiumChip} label='Premium' size='small' disabled/>
                        : null
                      }
                    </li>
                    <li>
                      <Typography variant="caption">
                        {genre.subtitle}
                      </Typography>
                    </li>
                  </ul>
                </StyledTableCell>
                <StyledTableCell size='small' align="right">
                  {genre.lastPost ? 
                    moment(genre.lastPost.toDate().toString()).fromNow()
                  :
                    null
                  }
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  );
}
export default compose(
  firestoreConnect((props) => [
    { collection: 'forum', 
      doc: props.forum_doc_id, 
      subcollections: [
        {
          collection: 'genre',
          orderBy: [
            ['index', 'asc']
          ],
        },
      ],
      storeAs: `${props.forum_doc_id}-genre`
    }
  ]),
  connect((state, props) => ({
    orderedGenres: state.firestore.ordered[`${props.forum_doc_id}-genre`]
  }))
)(ForumTable)