import React, { useState } from 'react'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'

const useStyles = makeStyles(theme => ({
    search: {
      width: '100%',
      marginLeft: theme.spacing(2),
      padding: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}))

function ConsultantsFilter({ sendUpFilters }) {
    const classes = useStyles()
    const [branch, setBranch] = useState('all')
    const [active, setActive] = useState('all')

    const handleBranchChange = (event) => {
        setBranch(event.target.value)
        const filters = {
            branch: event.target.value === 'all' ? ['Army', 'Navy', 'Marines', 'Air Force'] : [event.target.value],
            active: active === 'all' ? [false, true] : active === 'active' ? [true] : [false]
        }
        sendUpFilters(filters)
    }

    const handleActiveChange = (event) => {
        setActive(event.target.value)
        const filters = {
            branch: branch === 'all' ? ['Army', 'Navy', 'Marines', 'Air Force'] : [branch],
            active: event.target.value === 'all' ? [false, true] : event.target.value === 'active' ? [true] : [false]
        }
        sendUpFilters(filters)
    }

    return (
        <Paper className={classes.search}>
            <h4>Filter</h4>
            <FormControl fullWidth className={classes.formControl}>
                <InputLabel>Branch</InputLabel>
                <Select
                    value={branch}
                    onChange={handleBranchChange}
                >
                    <MenuItem value={'all'}>All</MenuItem>
                    <MenuItem value={'Army'}>Army</MenuItem>
                    <MenuItem value={'Navy'}>Navy</MenuItem>
                    <MenuItem value={'Marines'}>Marines</MenuItem>
                    <MenuItem value={'Air Force'}>Air Force</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
                <InputLabel>Active/Separated</InputLabel>
                <Select
                    value={active}
                    onChange={handleActiveChange}
                >
                    <MenuItem value={'all'}>All</MenuItem>
                    <MenuItem value={'active'}>Active</MenuItem>
                    <MenuItem value={'separated'}>Separated</MenuItem>
                </Select>
            </FormControl>
        </Paper>
    )
}

export default ConsultantsFilter