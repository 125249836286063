import React, { useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux'
import ActiveChats from './ActiveChats'


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function BottomAppBar({ auth, profile }) {
  const classes = useStyles();

  // wait to fetch auth
  if (!auth) return <div/>
  // We dont need the bottom app bar if the user is not logged in since it is for messages
  if (!auth.uid) return <div/>

  if (!auth.emailVerified) return <div />

  return (
      <div>
        <ActiveChats authProps={auth} profile={profile}/>
      </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

export default connect(mapStateToProps)(BottomAppBar)