import React, { useState, Fragment } from 'react'
// Material Core
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import CheckIcon from '@material-ui/icons/Check'
import Accordion from '@material-ui/core/ExpansionPanel'
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary'
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
// local components
import Company from '../Components/UserComponents/Company'
// react router
import { useParams, useHistory } from 'react-router-dom'
// Redux
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: '#f5f5f5',
    },
    body: {
        paddingLeft: 20,
    },
}))

function DisplayUserProposals(props) {

    const classes = useStyles()

    const { user_id, tab='active' } = useParams()

    const history = useHistory()
    
    const [tabValue, setTabValue] = useState(tab == 'active' ? 0 : 1)

    const handleTabChange = (e, newValue) => {
        setTabValue(newValue)
        if (newValue === 0 && tab !== 'active') {
            history.push(`/proposals/user/${user_id}/active`)
        }
        else if (newValue === 1 && tab !== 'archived') {
            history.push(`/proposals/user/${user_id}/archived`)
        }
    }

    useFirestoreConnect([
        {
            collection: 'proposals',
            where: ['owner', '==', user_id],
            storeAs: `${user_id}-proposals`
        }
    ])
    const proposals = useSelector(state => state.firestore.ordered[`${user_id}-proposals`])

    if (!proposals) return <div />
    console.log('proposals', proposals)

    return (
        <div style={{paddingTop: 100}}>
            <Container maxWidth='md'>
                <Paper>
                    <AppBar color='default' position='static'>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={2}>
                            <Grid item >
                                <h4 className={classes.body}><b>Proposals</b></h4>
                            </Grid>
                            <Grid item>
                                <Tabs
                                    value={tabValue}
                                    textColor='secondary'
                                    indicatorColor='primary'
                                    onChange={handleTabChange}
                                >
                                    <Tab label='Active' />
                                    <Tab label='Archived' />
                                </Tabs>
                            </Grid>
                        </Grid>
                    </AppBar>
                    <TabPanel value={tabValue} index={0}>
                        {proposals.filter(p => !p.archived).length > 0 ? 
                            (proposals.filter(p => !p.archived).map((p, i) => (
                                <Grid key={i} item className={classes.body}>
                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Grid container alignItems='center' direction='row' spacing={4}>
                                                <Grid item>
                                                    <Company userId={p.client} />
                                                </Grid>
                                                {p.hired ? 
                                                    <Chip
                                                        style={{marginLeft: 10}}
                                                        icon={<CheckIcon style={{color: 'green'}} />}
                                                        label={`Hired`}
                                                        variant='outlined'               
                                                    />
                                                :
                                                    null
                                                }
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container direction='column'>
                                                <Grid item>
                                                    <h5><b>Cover:</b></h5>
                                                </Grid>
                                                <Grid item>
                                                    <p>{p.proposal.cover}</p>
                                                </Grid>
                                                {Object.keys(p.proposal.clientQuestions).map(key => {
                                                    return (
                                                        <Fragment key={key}>
                                                            <p style={{textAlign: 'left'}}><b>{p.proposal.clientQuestions[key].question}</b></p>
                                                            <p style={{textAlign: 'left'}}>{p.proposal.clientQuestions[key].answer}</p>
                                                        </Fragment>
                                                    )
                                                })}
                                                <h5 style={{textAlign: 'left'}}><b>{`Terms`}</b></h5>
                                                <p style={{textAlign: 'left'}}>{`$${p.proposal.hourly}/hour`}</p>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    <hr />
                                </Grid>
                            )))
                        :
                            <Grid item className={classes.body}>
                                <h4>No Active Proposals</h4>
                            </Grid>
                        }      
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        {proposals.filter(p => p.archived).length > 0 ? 
                            (proposals.filter(p => p.archived).map((p, i) => (
                                <Grid key={i} item className={classes.body}>
                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Grid container alignItems='center' direction='row' spacing={4}>
                                                <Grid item>
                                                    <Company userId={p.client} />
                                                </Grid>
                                                {p.hired ? 
                                                    <Chip
                                                        style={{marginLeft: 10}}
                                                        icon={<CheckIcon style={{color: 'green'}} />}
                                                        label={`Hired`}
                                                        variant='outlined'               
                                                    />
                                                :
                                                    null
                                                }
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container direction='column'>
                                                <Grid item>
                                                    <h5><b>Cover:</b></h5>
                                                </Grid>
                                                <Grid item>
                                                    <p>{p.proposal.cover}</p>
                                                </Grid>
                                                {Object.keys(p.proposal.clientQuestions).map(key => {
                                                    return (
                                                        <Fragment key={key}>
                                                            <p style={{textAlign: 'left'}}><b>{p.proposal.clientQuestions[key].question}</b></p>
                                                            <p style={{textAlign: 'left'}}>{p.proposal.clientQuestions[key].answer}</p>
                                                        </Fragment>
                                                    )
                                                })}
                                                <h5 style={{textAlign: 'left'}}><b>{`Terms`}</b></h5>
                                                <p style={{textAlign: 'left'}}>{`$${p.proposal.hourly}/hour`}</p>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    <hr />
                                </Grid>
                            )))
                        :
                            <Grid item className={classes.body}>
                                <h4>No Archived Proposals</h4>
                            </Grid>
                        }      
                    </TabPanel>
                </Paper>
            </Container>
        </div>
    )
}

export default DisplayUserProposals