import React, { useState, Fragment } from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {connect} from 'react-redux'
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'
import blue from '@material-ui/core/colors/blue'
import purple from '@material-ui/core/colors/purple'
import grey from '@material-ui/core/colors/grey'
import { saveSchedule, saveRepTimes } from '../../../Redux/Actions/userActions'
import moment from 'moment-timezone'
import { useEffect } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MuiAlert from '@material-ui/lab/Alert'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle' 
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Author from '../ForumComponents/Author'
import UserAvatar from './UserAvatar'
import Tooltip from '@material-ui/core/Tooltip'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import { requestMeetingAction, rejectMeetingRequest, acceptMeetingRequest, clearRequestStatus, clearAcceptStatus } from '../../../Redux/Actions/userActions'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { Link, withRouter } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import EventIcon from '@material-ui/icons/Event'
import { calendarConfig } from '../../../Config/CalendarConfig'
import { DateTime } from "luxon"
import TimeSlot from './TimeSlot'
import Appointment from './Appointment'
import GoogleButton from '../NavigationComponents/GoogleButton'


function Alert(props) {
    return <MuiAlert elevation={6} {...props} />;
}

const ownerStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1),
    },
    root: {
        textAlign: 'center',
        marginTop: 150,
    },
    loader: {
        display: 'inlineBlock'
    },
    calendarContainer: {
        width: '100%',
        height: '150vh',
    }, 
    calendarParent: {
        height: '80vh',
        overflow: 'scroll',
    },
    confirm: {
        width: '100%',
        padding: theme.spacing(1),
    },
    weekDay: {
        width: '100%',
        height: '6%',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        color: grey[800],
        borderTop: 'hidden',
        borderLeft: 'hidden',
        borderRight: 'hidden',
    },
    unchecked: {
        height: '3.35714285%',
        width: '100%',
    },
    checked: {
        height: '3.35714285%',
        width: '100%',
        backgroundColor: green[200],
    },
    repeating: {
        height: '3.35714285%',
        width: '100%',
        backgroundColor: blue[200],
    },
    exception: {
        height: '3.35714285%',
        width: '100%',
        backgroundColor: grey[300],
    },
    meeting: {
        height: '3.35714285%',
        width: '100%',
        backgroundColor: blue[300],
    },
    requested: {
        height: '3.35714285%',
        width: '100%',
        backgroundColor: purple[300],
        borderTop: 'hidden',
        borderBottom: 'hidden',
    },
    scheduled: {
        height: '3.35714285%',
        width: '100%',
        backgroundColor: grey[800],
        borderTop: 'hidden',
        borderBottom: 'hidden',
    },
    timeMarker: {
        height: '3.35714285%',
        width: '100%',
        textAlign: 'right',
        paddingRight: 3,
    },
    timeMarkerFirst: {
        height: '2.75%',
        width: '100%',
        textAlign: 'right',
        paddingRight: 3,
    },
    container:{
        width: '100%',
        height: '100%',
    },
    column: {
        width:'13.3%',
        height: '100%',
    },
    timeColumn: {
        width:'5.8%',
        height: '100%',
    },
    timeHeader: {
        height: '100%',
    },
    dateHeader: {
        marginRight: 17,
    },
    columnInside: {
        height: '100%',
    },
    paragraph: {
        fontSize: 18,
    },
    paragraphDetails: {
        fontSize: 18,
        textAlign: 'center',
    },
    agenda: {
        fontSize: 18,
        whiteSpace: 'pre-line'
    },
    username: {
        fontSize: 18,
        marginTop: 15,
    },
    divider: {
        margin: theme.spacing(2),
    },
    buttonLink: {
        textDecoration: 'none',
        color: grey[200],
        "&:hover": {
          color: grey[200]
        },
        "&:visited": {
          color: grey[200]
        },
        "&:active": {
          color: grey[200]
        },
        "&:link": {
          color: grey[200]
        },
        "&:link:active": {
          color: grey[200]
        },
        "&:visited:active": {
          color: grey[200]
        },
    },
    successIcon: {
        color: green[400],
    },
    number: {
        fontSize: 20,
    },
}))

const viewerStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1),
    },
    root: {
        textAlign: 'center',
        marginTop: 150,
    },
    loader: {
        display: 'inlineBlock'
    },
    calendarContainer: {
        width: '100%',
        height: '150vh',
    }, 
    calendarParent: {
        height: '80vh',
        overflow: 'scroll',
    },
    confirm: {
        width: '100%',
        padding: theme.spacing(1),
    },
    weekDay: {
        width: '100%',
        height: '6%',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        color: grey[800],
        borderTop: 'hidden',
        borderLeft: 'hidden',
        borderRight: 'hidden',
    },
    unchecked: {
        height: '3.35714285%',
        width: '100%',
    },
    checked: {
        height: '3.35714285%',
        width: '100%',
        backgroundColor: green[200],
    },
    repeating: {
        height: '3.35714285%',
        width: '100%',
        backgroundColor: green[200],
    },
    exception: {
        height: '3.35714285%',
        width: '100%',
    },
    meeting: {
        height: '3.35714285%',
        width: '100%',
        backgroundColor: blue[300],
    },
    requested: {
        height: '3.35714285%',
        width: '100%',
    },
    scheduled: {
        height: '3.35714285%',
        width: '100%',
    },
    timeMarkerFirst: {
        height: '2.75%',
        width: '100%',
        textAlign: 'right',
        paddingRight: 3,
    },
    timeMarker: {
        height: '3.35714285%',
        width: '100%',
        textAlign: 'right',
        paddingRight: 3,
    },
    container:{
        width: '100%',
        height: '100%',
    },
    column: {
        width:'13.3%',
        height: '100%'
    },
    timeColumn: {
        width:'5.8%',
        height: '100%'
    },
    timeHeader: {
        width: '100%',
    },
    columnInside: {
        height: '100%'
    },
    paragraph: {
        fontSize: 18,
    },
    paragraphDetails: {
        fontSize: 18,
        textAlign: 'center',
    },
    agenda: {
        fontSize: 18,
        whiteSpace: 'pre-line'
    },
    username: {
        fontSize: 18,
        marginTop: 15,
    },
    divider: {
        margin: theme.spacing(2),
    },
    buttonLink: {
        textDecoration: 'none',
        color: grey[200],
        "&:hover": {
          color: grey[200]
        },
        "&:visited": {
          color: grey[200]
        },
        "&:active": {
          color: grey[200]
        },
        "&:link": {
          color: grey[200]
        },
        "&:link:active": {
          color: grey[200]
        },
        "&:visited:active": {
          color: grey[200]
        },
    },
    successIcon: {
        color: green[400],
    },
    number: {
        fontSize: 20,
    },
}))

const dialogStyles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
})

const DialogTitle = withStyles(dialogStyles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    )
})

const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent)
  
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
}))(MuiDialogActions)

function Calendar(props) {
    var classes = null
    const ownerClasses = ownerStyles()
    const viewerClasses = viewerStyles()
    const {
        ownerProfile,
        requestMeetingAction, 
        meetingRequestStatus, 
        acceptMeetingStatus, 
        clearAcceptStatus, 
        clearRequestStatus, 
        rejectMeetingRequest, 
        acceptMeetingRequest, 
        saveSchedule, 
        saveRepTimes, 
        UTCSchedule, 
        repeatingSchedule, 
        weekOf, 
        requested, 
        scheduled,
        docId, 
        repeating, 
        exception, 
        isOwner, 
        userId 
    } = props
    // Checkbox state for meeting confirmation dialog
    const [zoom, setZoom] = useState(false)
    const [phone, setPhone] = useState(false)
    const [messaging, setMessaging] = useState(false)
    const [noPref, setNoPref] = useState(false)
    const [techPref, setTechPref] = useState('')
    const [agenda, setAgenda] = useState('')
    const [noSelectionAlert, setNoSelectionAlert] = useState(false)
    const [noAgendaAlert, setNoAgendaAlert] = useState(false)

   
    const handleTextIn = (event) => {
        setAgenda(event.target.value)
    }

    const handleRequestMeeting = (e) => {
        e.preventDefault()
        clearRequestStatus()
        
        if (!zoom && !phone && !messaging && !noPref) {
            // send out an alert!!
            setNoSelectionAlert(true)
        }
        else if (agenda === '') {
            // send out an alert!!
            setNoAgendaAlert(true)
        }
        else {
            // send this meeting request to the consultant

            // loop through times and find all open times
            // Convert them to date format
            // Convert them to UTC date strings which will be stored in the database
            const requestList = []
            for (let[time, _day] of meetingRequest) {
                if (_day) {
                    // create a UTC date from this time
                    const dayDate = dateFactory(_day).toDate()
                    const date = new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate(), timesMap.get(time).hours, timesMap.get(time).minutes)
                    requestList.push(date.toUTCString())
                }
            }

            const meeting = {
                times: requestList,
                timezone: moment.tz.guess(),
                techPref,
                agenda,
                userId,
                weekOf: docId,
            }
            setRequestingMeeting(true)
            requestMeetingAction(meeting)
        }
    }

    const handleChecked = (event) => {
        switch (event.target.name) {
            case 'zoom':
                setTechPref('Zoom Meeting')
                setZoom(true)
                setPhone(false)
                setMessaging(false)
                setNoPref(false)
                break
            case 'phone':
                setTechPref('Phone Call')
                setZoom(false)
                setPhone(true)
                setMessaging(false)
                setNoPref(false)
                break
            case 'messaging':
                setTechPref('Messaging')
                setZoom(false)
                setPhone(false)
                setMessaging(true)
                setNoPref(false)
                break
            case 'noPref':
                setTechPref('No Preference')
                setZoom(false)
                setPhone(false)
                setMessaging(false)
                setNoPref(true)
                break
            default:
                break
        }
    }

    // Display different styles depending on whether the user is the owner of the calendar or someone scheduling an appointment
    if (isOwner) {
        classes = ownerClasses
    } else {
        classes = viewerClasses
    }

    // Have to set these individually so that they do not update eachother
    const mon = moment(weekOf)
    const tue = moment(weekOf)
    const wed = moment(weekOf)
    const thu = moment(weekOf)
    const fri = moment(weekOf)
    const sat = moment(weekOf)
    const sun = moment(weekOf)

    // Add the correct number of days to be the correct date
    tue.add(1, 'days')
    wed.add(2, 'days')
    thu.add(3, 'days')
    fri.add(4, 'days')
    sat.add(5, 'days')
    sun.add(6, 'days')


    const timesMap = new Map([
        ["6:30 AM", {hours: 6, minutes: 30}],
        ["7:00 AM", {hours: 7, minutes: 0}], 
        ["7:30 AM", {hours: 7, minutes: 30}], 
        ["8:00 AM", {hours: 8, minutes: 0}], 
        ["8:30 AM", {hours: 8, minutes: 30}], 
        ["9:00 AM", {hours: 9, minutes: 0}], 
        ["9:30 AM", {hours: 9, minutes: 30}], 
        ["10:00 AM", {hours: 10, minutes: 0}], 
        ["10:30 AM", {hours: 10, minutes: 30}], 
        ["11:00 AM", {hours: 11, minutes: 0}], 
        ["11:30 AM", {hours: 11, minutes: 30}], 
        ["12:00 PM", {hours: 12, minutes: 0}], 
        ["12:30 PM", {hours: 12, minutes: 30}], 
        ["1:00 PM", {hours: 13, minutes: 0}], 
        ["1:30 PM", {hours: 13, minutes: 30}], 
        ["2:00 PM", {hours: 14, minutes: 0}], 
        ["2:30 PM", {hours: 14, minutes: 30}], 
        ["3:00 PM", {hours: 15, minutes: 0}], 
        ["3:30 PM", {hours: 15, minutes: 30}], 
        ["4:00 PM", {hours: 16, minutes: 0}], 
        ["4:30 PM", {hours: 16, minutes: 30}], 
        ["5:00 PM", {hours: 17, minutes: 0}], 
        ["5:30 PM", {hours: 17, minutes: 30}], 
        ["6:00 PM", {hours: 18, minutes: 0}], 
        ["6:30 PM", {hours: 18, minutes: 30}], 
        ["7:00 PM", {hours: 19, minutes: 0}], 
        ["7:30 PM", {hours: 19, minutes: 30}], 
        ["8:00 PM", {hours: 20, minutes: 0}]
    ])


        /****************************************************************************
    *
    *                    BUILD THIS WEEKS AVAILABILITY DATA
    *
    * ************************************************************************** */

    // Convert UTCSchedule times from UTC to local times
    // Filter out any times that are not this week. 
    const monMap = new Map()
    const tueMap = new Map()
    const wedMap = new Map()
    const thuMap = new Map()
    const friMap = new Map()
    const satMap = new Map()
    const sunMap = new Map()

    const monReqMap = new Map()
    const tueReqMap = new Map()
    const wedReqMap = new Map()
    const thuReqMap = new Map()
    const friReqMap = new Map()
    const satReqMap = new Map()
    const sunReqMap = new Map()

    const monSchedMap = new Map()
    const tueSchedMap = new Map()
    const wedSchedMap = new Map()
    const thuSchedMap = new Map()
    const friSchedMap = new Map()
    const satSchedMap = new Map()
    const sunSchedMap = new Map()


    const timeOfDayFactory = (_time) => {
        var timeOfDay = _time.substring(0, _time.length - 2) + _time.substring(_time.length - 2, _time.length).toUpperCase()

        if (timeOfDay.charAt(0) === '0') {
            timeOfDay = timeOfDay.substring(1, timeOfDay.length)
        }
        
        return timeOfDay
    }

    scheduled && scheduled.map(appt => {
       
        appt.times && appt.times.map(time => {
            const date = new Date(time)
            const momDate = moment(date)
            const dayOfWeek = momDate.format('dddd')
            const timeOfDay = timeOfDayFactory(momDate.format('hh:mm a'))

            switch (dayOfWeek) {
                case "Monday":
                    // Associate the time of day for the SME with the time of day for the hiring agent. 
                    monSchedMap.set(timeOfDay, appt)
                    break
                case "Tuesday":
                    tueSchedMap.set(timeOfDay, appt)
                    break
                case "Wednesday":
                    wedSchedMap.set(timeOfDay, appt)
                    break
                case "Thursday":
                    thuSchedMap.set(timeOfDay, appt)
                    break
                case "Friday":
                    friSchedMap.set(timeOfDay, appt)
                    break;
                case "Saturday":
                    satSchedMap.set(timeOfDay, appt)
                    break
                case "Sunday":
                    sunSchedMap.set(timeOfDay, appt)
                    break
                default:
                    break
            }
        })
    }) 

    const [mondaySchedTimes, setMondaySchedTimes] = useState(null)
    const [tuesdaySchedTimes, setTuesdaySchedTimes] = useState(null)
    const [wednesdaySchedTimes, setWednesdaySchedTimes] = useState(null)
    const [thursdaySchedTimes, setThursdaySchedTimes] = useState(null)
    const [fridaySchedTimes, setFridaySchedTimes] = useState(null)
    const [saturdaySchedTimes, setSaturdaySchedTimes] = useState(null)
    const [sundaySchedTimes, setSundaySchedTimes] = useState(null)

    useEffect(() => {
        setMondaySchedTimes(new Map([
            ["6:30 AM", monSchedMap.get("6:30 AM")], 
            ["7:00 AM", monSchedMap.get("7:00 AM")], 
            ["7:30 AM", monSchedMap.get("7:30 AM")], 
            ["8:00 AM", monSchedMap.get("8:00 AM")], 
            ["8:30 AM", monSchedMap.get("8:30 AM")], 
            ["9:00 AM", monSchedMap.get("9:00 AM")], 
            ["9:30 AM", monSchedMap.get("9:30 AM")], 
            ["10:00 AM", monSchedMap.get("10:00 AM")], 
            ["10:30 AM", monSchedMap.get("10:30 AM")], 
            ["11:00 AM", monSchedMap.get("11:00 AM")], 
            ["11:30 AM", monSchedMap.get("11:30 AM")], 
            ["12:00 PM", monSchedMap.get("12:00 PM")], 
            ["12:30 PM", monSchedMap.get("12:30 PM")], 
            ["1:00 PM", monSchedMap.get("1:00 PM")], 
            ["1:30 PM", monSchedMap.get("1:30 PM")], 
            ["2:00 PM", monSchedMap.get("2:00 PM")], 
            ["2:30 PM", monSchedMap.get("2:30 PM")], 
            ["3:00 PM", monSchedMap.get("3:00 PM")], 
            ["3:30 PM", monSchedMap.get("3:30 PM")], 
            ["4:00 PM", monSchedMap.get("4:00 PM")], 
            ["4:30 PM", monSchedMap.get("4:30 PM")], 
            ["5:00 PM", monSchedMap.get("5:00 PM")], 
            ["5:30 PM", monSchedMap.get("5:30 PM")], 
            ["6:00 PM", monSchedMap.get("6:00 PM")], 
            ["6:30 PM", monSchedMap.get("6:30 PM")], 
            ["7:00 PM", monSchedMap.get("7:00 PM")], 
            ["7:30 PM", monSchedMap.get("7:30 PM")], 
            ["8:00 PM", monSchedMap.get("8:00 PM")],
        ]))

        setTuesdaySchedTimes(new Map([
            ["6:30 AM", tueSchedMap.get("6:30 AM")], 
            ["7:00 AM", tueSchedMap.get("7:00 AM")], 
            ["7:30 AM", tueSchedMap.get("7:30 AM")], 
            ["8:00 AM", tueSchedMap.get("8:00 AM")], 
            ["8:30 AM", tueSchedMap.get("8:30 AM")], 
            ["9:00 AM", tueSchedMap.get("9:00 AM")], 
            ["9:30 AM", tueSchedMap.get("9:30 AM")], 
            ["10:00 AM", tueSchedMap.get("10:00 AM")], 
            ["10:30 AM", tueSchedMap.get("10:30 AM")], 
            ["11:00 AM", tueSchedMap.get("11:00 AM")], 
            ["11:30 AM", tueSchedMap.get("11:30 AM")], 
            ["12:00 PM", tueSchedMap.get("12:00 PM")], 
            ["12:30 PM", tueSchedMap.get("12:30 PM")], 
            ["1:00 PM", tueSchedMap.get("1:00 PM")], 
            ["1:30 PM", tueSchedMap.get("1:30 PM")], 
            ["2:00 PM", tueSchedMap.get("2:00 PM")], 
            ["2:30 PM", tueSchedMap.get("2:30 PM")], 
            ["3:00 PM", tueSchedMap.get("3:00 PM")], 
            ["3:30 PM", tueSchedMap.get("3:30 PM")], 
            ["4:00 PM", tueSchedMap.get("4:00 PM")], 
            ["4:30 PM", tueSchedMap.get("4:30 PM")], 
            ["5:00 PM", tueSchedMap.get("5:00 PM")], 
            ["5:30 PM", tueSchedMap.get("5:30 PM")], 
            ["6:00 PM", tueSchedMap.get("6:00 PM")], 
            ["6:30 PM", tueSchedMap.get("6:30 PM")], 
            ["7:00 PM", tueSchedMap.get("7:00 PM")], 
            ["7:30 PM", tueSchedMap.get("7:30 PM")], 
            ["8:00 PM", tueSchedMap.get("8:00 PM")],
        ]))

        setWednesdaySchedTimes(new Map([
            ["6:30 AM", wedSchedMap.get("6:30 AM")], 
            ["7:00 AM", wedSchedMap.get("7:00 AM")], 
            ["7:30 AM", wedSchedMap.get("7:30 AM")], 
            ["8:00 AM", wedSchedMap.get("8:00 AM")], 
            ["8:30 AM", wedSchedMap.get("8:30 AM")], 
            ["9:00 AM", wedSchedMap.get("9:00 AM")], 
            ["9:30 AM", wedSchedMap.get("9:30 AM")], 
            ["10:00 AM", wedSchedMap.get("10:00 AM")], 
            ["10:30 AM", wedSchedMap.get("10:30 AM")], 
            ["11:00 AM", wedSchedMap.get("11:00 AM")], 
            ["11:30 AM", wedSchedMap.get("11:30 AM")], 
            ["12:00 PM", wedSchedMap.get("12:00 PM")], 
            ["12:30 PM", wedSchedMap.get("12:30 PM")], 
            ["1:00 PM", wedSchedMap.get("1:00 PM")], 
            ["1:30 PM", wedSchedMap.get("1:30 PM")], 
            ["2:00 PM", wedSchedMap.get("2:00 PM")], 
            ["2:30 PM", wedSchedMap.get("2:30 PM")], 
            ["3:00 PM", wedSchedMap.get("3:00 PM")], 
            ["3:30 PM", wedSchedMap.get("3:30 PM")], 
            ["4:00 PM", wedSchedMap.get("4:00 PM")], 
            ["4:30 PM", wedSchedMap.get("4:30 PM")], 
            ["5:00 PM", wedSchedMap.get("5:00 PM")], 
            ["5:30 PM", wedSchedMap.get("5:30 PM")], 
            ["6:00 PM", wedSchedMap.get("6:00 PM")], 
            ["6:30 PM", wedSchedMap.get("6:30 PM")], 
            ["7:00 PM", wedSchedMap.get("7:00 PM")], 
            ["7:30 PM", wedSchedMap.get("7:30 PM")], 
            ["8:00 PM", wedSchedMap.get("8:00 PM")],
        ]))

        setThursdaySchedTimes(new Map([
            ["6:30 AM", thuSchedMap.get("6:30 AM")], 
            ["7:00 AM", thuSchedMap.get("7:00 AM")], 
            ["7:30 AM", thuSchedMap.get("7:30 AM")], 
            ["8:00 AM", thuSchedMap.get("8:00 AM")], 
            ["8:30 AM", thuSchedMap.get("8:30 AM")], 
            ["9:00 AM", thuSchedMap.get("9:00 AM")], 
            ["9:30 AM", thuSchedMap.get("9:30 AM")], 
            ["10:00 AM", thuSchedMap.get("10:00 AM")], 
            ["10:30 AM", thuSchedMap.get("10:30 AM")], 
            ["11:00 AM", thuSchedMap.get("11:00 AM")], 
            ["11:30 AM", thuSchedMap.get("11:30 AM")], 
            ["12:00 PM", thuSchedMap.get("12:00 PM")], 
            ["12:30 PM", thuSchedMap.get("12:30 PM")], 
            ["1:00 PM", thuSchedMap.get("1:00 PM")], 
            ["1:30 PM", thuSchedMap.get("1:30 PM")], 
            ["2:00 PM", thuSchedMap.get("2:00 PM")], 
            ["2:30 PM", thuSchedMap.get("2:30 PM")], 
            ["3:00 PM", thuSchedMap.get("3:00 PM")], 
            ["3:30 PM", thuSchedMap.get("3:30 PM")], 
            ["4:00 PM", thuSchedMap.get("4:00 PM")], 
            ["4:30 PM", thuSchedMap.get("4:30 PM")], 
            ["5:00 PM", thuSchedMap.get("5:00 PM")], 
            ["5:30 PM", thuSchedMap.get("5:30 PM")], 
            ["6:00 PM", thuSchedMap.get("6:00 PM")], 
            ["6:30 PM", thuSchedMap.get("6:30 PM")], 
            ["7:00 PM", thuSchedMap.get("7:00 PM")], 
            ["7:30 PM", thuSchedMap.get("7:30 PM")], 
            ["8:00 PM", thuSchedMap.get("8:00 PM")],
        ]))

        setFridaySchedTimes(new Map([
            ["6:30 AM", friSchedMap.get("6:30 AM")], 
            ["7:00 AM", friSchedMap.get("7:00 AM")], 
            ["7:30 AM", friSchedMap.get("7:30 AM")], 
            ["8:00 AM", friSchedMap.get("8:00 AM")], 
            ["8:30 AM", friSchedMap.get("8:30 AM")], 
            ["9:00 AM", friSchedMap.get("9:00 AM")], 
            ["9:30 AM", friSchedMap.get("9:30 AM")], 
            ["10:00 AM", friSchedMap.get("10:00 AM")], 
            ["10:30 AM", friSchedMap.get("10:30 AM")], 
            ["11:00 AM", friSchedMap.get("11:00 AM")], 
            ["11:30 AM", friSchedMap.get("11:30 AM")], 
            ["12:00 PM", friSchedMap.get("12:00 PM")], 
            ["12:30 PM", friSchedMap.get("12:30 PM")], 
            ["1:00 PM", friSchedMap.get("1:00 PM")], 
            ["1:30 PM", friSchedMap.get("1:30 PM")], 
            ["2:00 PM", friSchedMap.get("2:00 PM")], 
            ["2:30 PM", friSchedMap.get("2:30 PM")], 
            ["3:00 PM", friSchedMap.get("3:00 PM")], 
            ["3:30 PM", friSchedMap.get("3:30 PM")], 
            ["4:00 PM", friSchedMap.get("4:00 PM")], 
            ["4:30 PM", friSchedMap.get("4:30 PM")], 
            ["5:00 PM", friSchedMap.get("5:00 PM")], 
            ["5:30 PM", friSchedMap.get("5:30 PM")], 
            ["6:00 PM", friSchedMap.get("6:00 PM")], 
            ["6:30 PM", friSchedMap.get("6:30 PM")], 
            ["7:00 PM", friSchedMap.get("7:00 PM")], 
            ["7:30 PM", friSchedMap.get("7:30 PM")], 
            ["8:00 PM", friSchedMap.get("8:00 PM")],
        ]))

        setSaturdaySchedTimes(new Map([
            ["6:30 AM", satSchedMap.get("6:30 AM")], 
            ["7:00 AM", satSchedMap.get("7:00 AM")], 
            ["7:30 AM", satSchedMap.get("7:30 AM")], 
            ["8:00 AM", satSchedMap.get("8:00 AM")], 
            ["8:30 AM", satSchedMap.get("8:30 AM")], 
            ["9:00 AM", satSchedMap.get("9:00 AM")], 
            ["9:30 AM", satSchedMap.get("9:30 AM")], 
            ["10:00 AM", satSchedMap.get("10:00 AM")], 
            ["10:30 AM", satSchedMap.get("10:30 AM")], 
            ["11:00 AM", satSchedMap.get("11:00 AM")], 
            ["11:30 AM", satSchedMap.get("11:30 AM")], 
            ["12:00 PM", satSchedMap.get("12:00 PM")], 
            ["12:30 PM", satSchedMap.get("12:30 PM")], 
            ["1:00 PM", satSchedMap.get("1:00 PM")], 
            ["1:30 PM", satSchedMap.get("1:30 PM")], 
            ["2:00 PM", satSchedMap.get("2:00 PM")], 
            ["2:30 PM", satSchedMap.get("2:30 PM")], 
            ["3:00 PM", satSchedMap.get("3:00 PM")], 
            ["3:30 PM", satSchedMap.get("3:30 PM")], 
            ["4:00 PM", satSchedMap.get("4:00 PM")], 
            ["4:30 PM", satSchedMap.get("4:30 PM")], 
            ["5:00 PM", satSchedMap.get("5:00 PM")], 
            ["5:30 PM", satSchedMap.get("5:30 PM")], 
            ["6:00 PM", satSchedMap.get("6:00 PM")], 
            ["6:30 PM", satSchedMap.get("6:30 PM")], 
            ["7:00 PM", satSchedMap.get("7:00 PM")], 
            ["7:30 PM", satSchedMap.get("7:30 PM")], 
            ["8:00 PM", satSchedMap.get("8:00 PM")],
        ]))

        setSundaySchedTimes(new Map([
            ["6:30 AM", sunSchedMap.get("6:30 AM")], 
            ["7:00 AM", sunSchedMap.get("7:00 AM")], 
            ["7:30 AM", sunSchedMap.get("7:30 AM")], 
            ["8:00 AM", sunSchedMap.get("8:00 AM")], 
            ["8:30 AM", sunSchedMap.get("8:30 AM")], 
            ["9:00 AM", sunSchedMap.get("9:00 AM")], 
            ["9:30 AM", sunSchedMap.get("9:30 AM")], 
            ["10:00 AM", sunSchedMap.get("10:00 AM")], 
            ["10:30 AM", sunSchedMap.get("10:30 AM")], 
            ["11:00 AM", sunSchedMap.get("11:00 AM")], 
            ["11:30 AM", sunSchedMap.get("11:30 AM")], 
            ["12:00 PM", sunSchedMap.get("12:00 PM")], 
            ["12:30 PM", sunSchedMap.get("12:30 PM")], 
            ["1:00 PM", sunSchedMap.get("1:00 PM")], 
            ["1:30 PM", sunSchedMap.get("1:30 PM")], 
            ["2:00 PM", sunSchedMap.get("2:00 PM")], 
            ["2:30 PM", sunSchedMap.get("2:30 PM")], 
            ["3:00 PM", sunSchedMap.get("3:00 PM")], 
            ["3:30 PM", sunSchedMap.get("3:30 PM")], 
            ["4:00 PM", sunSchedMap.get("4:00 PM")], 
            ["4:30 PM", sunSchedMap.get("4:30 PM")], 
            ["5:00 PM", sunSchedMap.get("5:00 PM")], 
            ["5:30 PM", sunSchedMap.get("5:30 PM")], 
            ["6:00 PM", sunSchedMap.get("6:00 PM")], 
            ["6:30 PM", sunSchedMap.get("6:30 PM")], 
            ["7:00 PM", sunSchedMap.get("7:00 PM")], 
            ["7:30 PM", sunSchedMap.get("7:30 PM")], 
            ["8:00 PM", sunSchedMap.get("8:00 PM")],
        ]))
    }, [scheduled])

    requested && requested.map(appt => {
       
        appt.times && appt.times.map(time => {
            const date = new Date(time)
            const momDate = moment(date)
            const dayOfWeek = momDate.format('dddd')
            const timeOfDay = timeOfDayFactory(momDate.format('hh:mm a'))

            switch (dayOfWeek) {
                case "Monday":
                    // Associate the time of day for the SME with the time of day for the hiring agent. 
                    monReqMap.set(timeOfDay, appt)
                    break
                case "Tuesday":
                    tueReqMap.set(timeOfDay, appt)
                    break
                case "Wednesday":
                    wedReqMap.set(timeOfDay, appt)
                    break
                case "Thursday":
                    thuReqMap.set(timeOfDay, appt)
                    break
                case "Friday":
                    friReqMap.set(timeOfDay, appt)
                    break;
                case "Saturday":
                    satReqMap.set(timeOfDay, appt)
                    break
                case "Sunday":
                    sunReqMap.set(timeOfDay, appt)
                    break
                default:
                    break
            }
        })
    }) 

    const [mondayReqTimes, setMondayReqTimes] = useState(null)
    const [tuesdayReqTimes, setTuesdayReqTimes] = useState(null)
    const [wednesdayReqTimes, setWednesdayReqTimes] = useState(null)
    const [thursdayReqTimes, setThursdayReqTimes] = useState(null)
    const [fridayReqTimes, setFridayReqTimes] = useState(null)
    const [saturdayReqTimes, setSaturdayReqTimes] = useState(null)
    const [sundayReqTimes, setSundayReqTimes] = useState(null)

    useEffect(() => {
        setMondayReqTimes(new Map([
            ["6:30 AM", monReqMap.get("6:30 AM")], 
            ["7:00 AM", monReqMap.get("7:00 AM")], 
            ["7:30 AM", monReqMap.get("7:30 AM")], 
            ["8:00 AM", monReqMap.get("8:00 AM")], 
            ["8:30 AM", monReqMap.get("8:30 AM")], 
            ["9:00 AM", monReqMap.get("9:00 AM")], 
            ["9:30 AM", monReqMap.get("9:30 AM")], 
            ["10:00 AM", monReqMap.get("10:00 AM")], 
            ["10:30 AM", monReqMap.get("10:30 AM")], 
            ["11:00 AM", monReqMap.get("11:00 AM")], 
            ["11:30 AM", monReqMap.get("11:30 AM")], 
            ["12:00 PM", monReqMap.get("12:00 PM")], 
            ["12:30 PM", monReqMap.get("12:30 PM")], 
            ["1:00 PM", monReqMap.get("1:00 PM")], 
            ["1:30 PM", monReqMap.get("1:30 PM")], 
            ["2:00 PM", monReqMap.get("2:00 PM")], 
            ["2:30 PM", monReqMap.get("2:30 PM")], 
            ["3:00 PM", monReqMap.get("3:00 PM")], 
            ["3:30 PM", monReqMap.get("3:30 PM")], 
            ["4:00 PM", monReqMap.get("4:00 PM")], 
            ["4:30 PM", monReqMap.get("4:30 PM")], 
            ["5:00 PM", monReqMap.get("5:00 PM")], 
            ["5:30 PM", monReqMap.get("5:30 PM")], 
            ["6:00 PM", monReqMap.get("6:00 PM")], 
            ["6:30 PM", monReqMap.get("6:30 PM")], 
            ["7:00 PM", monReqMap.get("7:00 PM")], 
            ["7:30 PM", monReqMap.get("7:30 PM")], 
            ["8:00 PM", monReqMap.get("8:00 PM")],
        ]))

        setTuesdayReqTimes(new Map([
            ["6:30 AM", tueReqMap.get("6:30 AM")], 
            ["7:00 AM", tueReqMap.get("7:00 AM")], 
            ["7:30 AM", tueReqMap.get("7:30 AM")], 
            ["8:00 AM", tueReqMap.get("8:00 AM")], 
            ["8:30 AM", tueReqMap.get("8:30 AM")], 
            ["9:00 AM", tueReqMap.get("9:00 AM")], 
            ["9:30 AM", tueReqMap.get("9:30 AM")], 
            ["10:00 AM", tueReqMap.get("10:00 AM")], 
            ["10:30 AM", tueReqMap.get("10:30 AM")], 
            ["11:00 AM", tueReqMap.get("11:00 AM")], 
            ["11:30 AM", tueReqMap.get("11:30 AM")], 
            ["12:00 PM", tueReqMap.get("12:00 PM")], 
            ["12:30 PM", tueReqMap.get("12:30 PM")], 
            ["1:00 PM", tueReqMap.get("1:00 PM")], 
            ["1:30 PM", tueReqMap.get("1:30 PM")], 
            ["2:00 PM", tueReqMap.get("2:00 PM")], 
            ["2:30 PM", tueReqMap.get("2:30 PM")], 
            ["3:00 PM", tueReqMap.get("3:00 PM")], 
            ["3:30 PM", tueReqMap.get("3:30 PM")], 
            ["4:00 PM", tueReqMap.get("4:00 PM")], 
            ["4:30 PM", tueReqMap.get("4:30 PM")], 
            ["5:00 PM", tueReqMap.get("5:00 PM")], 
            ["5:30 PM", tueReqMap.get("5:30 PM")], 
            ["6:00 PM", tueReqMap.get("6:00 PM")], 
            ["6:30 PM", tueReqMap.get("6:30 PM")], 
            ["7:00 PM", tueReqMap.get("7:00 PM")], 
            ["7:30 PM", tueReqMap.get("7:30 PM")], 
            ["8:00 PM", tueReqMap.get("8:00 PM")],
        ]))

        setWednesdayReqTimes(new Map([
            ["6:30 AM", wedReqMap.get("6:30 AM")], 
            ["7:00 AM", wedReqMap.get("7:00 AM")], 
            ["7:30 AM", wedReqMap.get("7:30 AM")], 
            ["8:00 AM", wedReqMap.get("8:00 AM")], 
            ["8:30 AM", wedReqMap.get("8:30 AM")], 
            ["9:00 AM", wedReqMap.get("9:00 AM")], 
            ["9:30 AM", wedReqMap.get("9:30 AM")], 
            ["10:00 AM", wedReqMap.get("10:00 AM")], 
            ["10:30 AM", wedReqMap.get("10:30 AM")], 
            ["11:00 AM", wedReqMap.get("11:00 AM")], 
            ["11:30 AM", wedReqMap.get("11:30 AM")], 
            ["12:00 PM", wedReqMap.get("12:00 PM")], 
            ["12:30 PM", wedReqMap.get("12:30 PM")], 
            ["1:00 PM", wedReqMap.get("1:00 PM")], 
            ["1:30 PM", wedReqMap.get("1:30 PM")], 
            ["2:00 PM", wedReqMap.get("2:00 PM")], 
            ["2:30 PM", wedReqMap.get("2:30 PM")], 
            ["3:00 PM", wedReqMap.get("3:00 PM")], 
            ["3:30 PM", wedReqMap.get("3:30 PM")], 
            ["4:00 PM", wedReqMap.get("4:00 PM")], 
            ["4:30 PM", wedReqMap.get("4:30 PM")], 
            ["5:00 PM", wedReqMap.get("5:00 PM")], 
            ["5:30 PM", wedReqMap.get("5:30 PM")], 
            ["6:00 PM", wedReqMap.get("6:00 PM")], 
            ["6:30 PM", wedReqMap.get("6:30 PM")], 
            ["7:00 PM", wedReqMap.get("7:00 PM")], 
            ["7:30 PM", wedReqMap.get("7:30 PM")], 
            ["8:00 PM", wedReqMap.get("8:00 PM")],
        ]))

        setThursdayReqTimes(new Map([
            ["6:30 AM", thuReqMap.get("6:30 AM")], 
            ["7:00 AM", thuReqMap.get("7:00 AM")], 
            ["7:30 AM", thuReqMap.get("7:30 AM")], 
            ["8:00 AM", thuReqMap.get("8:00 AM")], 
            ["8:30 AM", thuReqMap.get("8:30 AM")], 
            ["9:00 AM", thuReqMap.get("9:00 AM")], 
            ["9:30 AM", thuReqMap.get("9:30 AM")], 
            ["10:00 AM", thuReqMap.get("10:00 AM")], 
            ["10:30 AM", thuReqMap.get("10:30 AM")], 
            ["11:00 AM", thuReqMap.get("11:00 AM")], 
            ["11:30 AM", thuReqMap.get("11:30 AM")], 
            ["12:00 PM", thuReqMap.get("12:00 PM")], 
            ["12:30 PM", thuReqMap.get("12:30 PM")], 
            ["1:00 PM", thuReqMap.get("1:00 PM")], 
            ["1:30 PM", thuReqMap.get("1:30 PM")], 
            ["2:00 PM", thuReqMap.get("2:00 PM")], 
            ["2:30 PM", thuReqMap.get("2:30 PM")], 
            ["3:00 PM", thuReqMap.get("3:00 PM")], 
            ["3:30 PM", thuReqMap.get("3:30 PM")], 
            ["4:00 PM", thuReqMap.get("4:00 PM")], 
            ["4:30 PM", thuReqMap.get("4:30 PM")], 
            ["5:00 PM", thuReqMap.get("5:00 PM")], 
            ["5:30 PM", thuReqMap.get("5:30 PM")], 
            ["6:00 PM", thuReqMap.get("6:00 PM")], 
            ["6:30 PM", thuReqMap.get("6:30 PM")], 
            ["7:00 PM", thuReqMap.get("7:00 PM")], 
            ["7:30 PM", thuReqMap.get("7:30 PM")], 
            ["8:00 PM", thuReqMap.get("8:00 PM")],
        ]))
            
        setFridayReqTimes(new Map([
            ["6:30 AM", friReqMap.get("6:30 AM")], 
            ["7:00 AM", friReqMap.get("7:00 AM")], 
            ["7:30 AM", friReqMap.get("7:30 AM")], 
            ["8:00 AM", friReqMap.get("8:00 AM")], 
            ["8:30 AM", friReqMap.get("8:30 AM")], 
            ["9:00 AM", friReqMap.get("9:00 AM")], 
            ["9:30 AM", friReqMap.get("9:30 AM")], 
            ["10:00 AM", friReqMap.get("10:00 AM")], 
            ["10:30 AM", friReqMap.get("10:30 AM")], 
            ["11:00 AM", friReqMap.get("11:00 AM")], 
            ["11:30 AM", friReqMap.get("11:30 AM")], 
            ["12:00 PM", friReqMap.get("12:00 PM")], 
            ["12:30 PM", friReqMap.get("12:30 PM")], 
            ["1:00 PM", friReqMap.get("1:00 PM")], 
            ["1:30 PM", friReqMap.get("1:30 PM")], 
            ["2:00 PM", friReqMap.get("2:00 PM")], 
            ["2:30 PM", friReqMap.get("2:30 PM")], 
            ["3:00 PM", friReqMap.get("3:00 PM")], 
            ["3:30 PM", friReqMap.get("3:30 PM")], 
            ["4:00 PM", friReqMap.get("4:00 PM")], 
            ["4:30 PM", friReqMap.get("4:30 PM")], 
            ["5:00 PM", friReqMap.get("5:00 PM")], 
            ["5:30 PM", friReqMap.get("5:30 PM")], 
            ["6:00 PM", friReqMap.get("6:00 PM")], 
            ["6:30 PM", friReqMap.get("6:30 PM")], 
            ["7:00 PM", friReqMap.get("7:00 PM")], 
            ["7:30 PM", friReqMap.get("7:30 PM")], 
            ["8:00 PM", friReqMap.get("8:00 PM")],
        ]))

        setSaturdayReqTimes(new Map([
            ["6:30 AM", satReqMap.get("6:30 AM")], 
            ["7:00 AM", satReqMap.get("7:00 AM")], 
            ["7:30 AM", satReqMap.get("7:30 AM")], 
            ["8:00 AM", satReqMap.get("8:00 AM")], 
            ["8:30 AM", satReqMap.get("8:30 AM")], 
            ["9:00 AM", satReqMap.get("9:00 AM")], 
            ["9:30 AM", satReqMap.get("9:30 AM")], 
            ["10:00 AM", satReqMap.get("10:00 AM")], 
            ["10:30 AM", satReqMap.get("10:30 AM")], 
            ["11:00 AM", satReqMap.get("11:00 AM")], 
            ["11:30 AM", satReqMap.get("11:30 AM")], 
            ["12:00 PM", satReqMap.get("12:00 PM")], 
            ["12:30 PM", satReqMap.get("12:30 PM")], 
            ["1:00 PM", satReqMap.get("1:00 PM")], 
            ["1:30 PM", satReqMap.get("1:30 PM")], 
            ["2:00 PM", satReqMap.get("2:00 PM")], 
            ["2:30 PM", satReqMap.get("2:30 PM")], 
            ["3:00 PM", satReqMap.get("3:00 PM")], 
            ["3:30 PM", satReqMap.get("3:30 PM")], 
            ["4:00 PM", satReqMap.get("4:00 PM")], 
            ["4:30 PM", satReqMap.get("4:30 PM")], 
            ["5:00 PM", satReqMap.get("5:00 PM")], 
            ["5:30 PM", satReqMap.get("5:30 PM")], 
            ["6:00 PM", satReqMap.get("6:00 PM")], 
            ["6:30 PM", satReqMap.get("6:30 PM")], 
            ["7:00 PM", satReqMap.get("7:00 PM")], 
            ["7:30 PM", satReqMap.get("7:30 PM")], 
            ["8:00 PM", satReqMap.get("8:00 PM")],
        ]))

        setSundayReqTimes(new Map([
            ["6:30 AM", sunReqMap.get("6:30 AM")], 
            ["7:00 AM", sunReqMap.get("7:00 AM")], 
            ["7:30 AM", sunReqMap.get("7:30 AM")], 
            ["8:00 AM", sunReqMap.get("8:00 AM")], 
            ["8:30 AM", sunReqMap.get("8:30 AM")], 
            ["9:00 AM", sunReqMap.get("9:00 AM")], 
            ["9:30 AM", sunReqMap.get("9:30 AM")], 
            ["10:00 AM", sunReqMap.get("10:00 AM")], 
            ["10:30 AM", sunReqMap.get("10:30 AM")], 
            ["11:00 AM", sunReqMap.get("11:00 AM")], 
            ["11:30 AM", sunReqMap.get("11:30 AM")], 
            ["12:00 PM", sunReqMap.get("12:00 PM")], 
            ["12:30 PM", sunReqMap.get("12:30 PM")], 
            ["1:00 PM", sunReqMap.get("1:00 PM")], 
            ["1:30 PM", sunReqMap.get("1:30 PM")], 
            ["2:00 PM", sunReqMap.get("2:00 PM")], 
            ["2:30 PM", sunReqMap.get("2:30 PM")], 
            ["3:00 PM", sunReqMap.get("3:00 PM")], 
            ["3:30 PM", sunReqMap.get("3:30 PM")], 
            ["4:00 PM", sunReqMap.get("4:00 PM")], 
            ["4:30 PM", sunReqMap.get("4:30 PM")], 
            ["5:00 PM", sunReqMap.get("5:00 PM")], 
            ["5:30 PM", sunReqMap.get("5:30 PM")], 
            ["6:00 PM", sunReqMap.get("6:00 PM")], 
            ["6:30 PM", sunReqMap.get("6:30 PM")], 
            ["7:00 PM", sunReqMap.get("7:00 PM")], 
            ["7:30 PM", sunReqMap.get("7:30 PM")], 
            ["8:00 PM", sunReqMap.get("8:00 PM")],
        ]))
        
    }, [requested.join(',')])


    // Convert UTC schedule times to local and populate map
    UTCSchedule[0] && UTCSchedule[0].times && UTCSchedule[0].times.map(time => {
        const date = new Date(time)
        const momDate = moment(date)
        const dayOfWeek = momDate.format('dddd')
        const timeOfDay = timeOfDayFactory(momDate.format('hh:mm a'))

        switch (dayOfWeek) {
            case "Monday":
                // Associate the time of day for the SME with the time of day for the hiring agent. 
                monMap.set(timeOfDay, moment(date).tz(UTCSchedule[0].timezone).format('MMMM Do YYYY, h:mm:ss a'))
                break
            case "Tuesday":
                tueMap.set(timeOfDay, moment(date).tz(UTCSchedule[0].timezone).format('MMMM Do YYYY, h:mm:ss a'))
                break
            case "Wednesday":
                wedMap.set(timeOfDay, moment(date).tz(UTCSchedule[0].timezone).format('MMMM Do YYYY, h:mm:ss a'))
                break
            case "Thursday":
                thuMap.set(timeOfDay, moment(date).tz(UTCSchedule[0].timezone).format('MMMM Do YYYY, h:mm:ss a'))
                break
            case "Friday":
                friMap.set(timeOfDay, moment(date).tz(UTCSchedule[0].timezone).format('MMMM Do YYYY, h:mm:ss a'))
                break;
            case "Saturday":
                satMap.set(timeOfDay, moment(date).tz(UTCSchedule[0].timezone).format('MMMM Do YYYY, h:mm:ss a'))
                break
            case "Sunday":
                sunMap.set(timeOfDay, moment(date).tz(UTCSchedule[0].timezone).format('MMMM Do YYYY, h:mm:ss a'))
                break
            default:
                break
        }
    })


    const [mondayTimes, setMondayTimes] = useState(null)
    const [tuesdayTimes, setTuesdayTimes] = useState(null)
    const [wednesdayTimes, setWednesdayTimes] = useState(null)
    const [thursdayTimes, setThursdayTimes] = useState(null)
    const [fridayTimes, setFridayTimes] = useState(null)
    const [saturdayTimes, setSaturdayTimes] = useState(null)
    const [sundayTimes, setSundayTimes] = useState(null)

    useEffect(() => {
        setMondayTimes(new Map([
            ["6:30 AM", monMap.has("6:30 AM")], 
            ["7:00 AM", monMap.has("7:00 AM")], 
            ["7:30 AM", monMap.has("7:30 AM")], 
            ["8:00 AM", monMap.has("8:00 AM")], 
            ["8:30 AM", monMap.has("8:30 AM")], 
            ["9:00 AM", monMap.has("9:00 AM")], 
            ["9:30 AM", monMap.has("9:30 AM")], 
            ["10:00 AM", monMap.has("10:00 AM")], 
            ["10:30 AM", monMap.has("10:30 AM")], 
            ["11:00 AM", monMap.has("11:00 AM")], 
            ["11:30 AM", monMap.has("11:30 AM")], 
            ["12:00 PM", monMap.has("12:00 PM")], 
            ["12:30 PM", monMap.has("12:30 PM")], 
            ["1:00 PM", monMap.has("1:00 PM")], 
            ["1:30 PM", monMap.has("1:30 PM")], 
            ["2:00 PM", monMap.has("2:00 PM")], 
            ["2:30 PM", monMap.has("2:30 PM")], 
            ["3:00 PM", monMap.has("3:00 PM")], 
            ["3:30 PM", monMap.has("3:30 PM")], 
            ["4:00 PM", monMap.has("4:00 PM")], 
            ["4:30 PM", monMap.has("4:30 PM")], 
            ["5:00 PM", monMap.has("5:00 PM")], 
            ["5:30 PM", monMap.has("5:30 PM")], 
            ["6:00 PM", monMap.has("6:00 PM")], 
            ["6:30 PM", monMap.has("6:30 PM")], 
            ["7:00 PM", monMap.has("7:00 PM")], 
            ["7:30 PM", monMap.has("7:30 PM")], 
            ["8:00 PM", monMap.has("8:00 PM")],
        ]))

        setTuesdayTimes(new Map([
            ["6:30 AM", tueMap.has("6:30 AM")], 
            ["7:00 AM", tueMap.has("7:00 AM")], 
            ["7:30 AM", tueMap.has("7:30 AM")], 
            ["8:00 AM", tueMap.has("8:00 AM")], 
            ["8:30 AM", tueMap.has("8:30 AM")], 
            ["9:00 AM", tueMap.has("9:00 AM")], 
            ["9:30 AM", tueMap.has("9:30 AM")], 
            ["10:00 AM", tueMap.has("10:00 AM")], 
            ["10:30 AM", tueMap.has("10:30 AM")], 
            ["11:00 AM", tueMap.has("11:00 AM")], 
            ["11:30 AM", tueMap.has("11:30 AM")], 
            ["12:00 PM", tueMap.has("12:00 PM")], 
            ["12:30 PM", tueMap.has("12:30 PM")], 
            ["1:00 PM", tueMap.has("1:00 PM")], 
            ["1:30 PM", tueMap.has("1:30 PM")], 
            ["2:00 PM", tueMap.has("2:00 PM")], 
            ["2:30 PM", tueMap.has("2:30 PM")], 
            ["3:00 PM", tueMap.has("3:00 PM")], 
            ["3:30 PM", tueMap.has("3:30 PM")], 
            ["4:00 PM", tueMap.has("4:00 PM")], 
            ["4:30 PM", tueMap.has("4:30 PM")], 
            ["5:00 PM", tueMap.has("5:00 PM")], 
            ["5:30 PM", tueMap.has("5:30 PM")], 
            ["6:00 PM", tueMap.has("6:00 PM")], 
            ["6:30 PM", tueMap.has("6:30 PM")], 
            ["7:00 PM", tueMap.has("7:00 PM")], 
            ["7:30 PM", tueMap.has("7:30 PM")], 
            ["8:00 PM", tueMap.has("8:00 PM")],
        ]))

        setWednesdayTimes(new Map([
            ["6:30 AM", wedMap.has("6:30 AM")], 
            ["7:00 AM", wedMap.has("7:00 AM")], 
            ["7:30 AM", wedMap.has("7:30 AM")], 
            ["8:00 AM", wedMap.has("8:00 AM")], 
            ["8:30 AM", wedMap.has("8:30 AM")], 
            ["9:00 AM", wedMap.has("9:00 AM")], 
            ["9:30 AM", wedMap.has("9:30 AM")], 
            ["10:00 AM", wedMap.has("10:00 AM")], 
            ["10:30 AM", wedMap.has("10:30 AM")], 
            ["11:00 AM", wedMap.has("11:00 AM")], 
            ["11:30 AM", wedMap.has("11:30 AM")], 
            ["12:00 PM", wedMap.has("12:00 PM")], 
            ["12:30 PM", wedMap.has("12:30 PM")], 
            ["1:00 PM", wedMap.has("1:00 PM")], 
            ["1:30 PM", wedMap.has("1:30 PM")], 
            ["2:00 PM", wedMap.has("2:00 PM")], 
            ["2:30 PM", wedMap.has("2:30 PM")], 
            ["3:00 PM", wedMap.has("3:00 PM")], 
            ["3:30 PM", wedMap.has("3:30 PM")], 
            ["4:00 PM", wedMap.has("4:00 PM")], 
            ["4:30 PM", wedMap.has("4:30 PM")], 
            ["5:00 PM", wedMap.has("5:00 PM")], 
            ["5:30 PM", wedMap.has("5:30 PM")], 
            ["6:00 PM", wedMap.has("6:00 PM")], 
            ["6:30 PM", wedMap.has("6:30 PM")], 
            ["7:00 PM", wedMap.has("7:00 PM")], 
            ["7:30 PM", wedMap.has("7:30 PM")], 
            ["8:00 PM", wedMap.has("8:00 PM")],
        ]))

        setThursdayTimes(new Map([
            ["6:30 AM", thuMap.has("6:30 AM")], 
            ["7:00 AM", thuMap.has("7:00 AM")], 
            ["7:30 AM", thuMap.has("7:30 AM")], 
            ["8:00 AM", thuMap.has("8:00 AM")], 
            ["8:30 AM", thuMap.has("8:30 AM")], 
            ["9:00 AM", thuMap.has("9:00 AM")], 
            ["9:30 AM", thuMap.has("9:30 AM")], 
            ["10:00 AM", thuMap.has("10:00 AM")], 
            ["10:30 AM", thuMap.has("10:30 AM")], 
            ["11:00 AM", thuMap.has("11:00 AM")], 
            ["11:30 AM", thuMap.has("11:30 AM")], 
            ["12:00 PM", thuMap.has("12:00 PM")], 
            ["12:30 PM", thuMap.has("12:30 PM")], 
            ["1:00 PM", thuMap.has("1:00 PM")], 
            ["1:30 PM", thuMap.has("1:30 PM")], 
            ["2:00 PM", thuMap.has("2:00 PM")], 
            ["2:30 PM", thuMap.has("2:30 PM")], 
            ["3:00 PM", thuMap.has("3:00 PM")], 
            ["3:30 PM", thuMap.has("3:30 PM")], 
            ["4:00 PM", thuMap.has("4:00 PM")], 
            ["4:30 PM", thuMap.has("4:30 PM")], 
            ["5:00 PM", thuMap.has("5:00 PM")], 
            ["5:30 PM", thuMap.has("5:30 PM")], 
            ["6:00 PM", thuMap.has("6:00 PM")], 
            ["6:30 PM", thuMap.has("6:30 PM")], 
            ["7:00 PM", thuMap.has("7:00 PM")], 
            ["7:30 PM", thuMap.has("7:30 PM")], 
            ["8:00 PM", thuMap.has("8:00 PM")],
        ]))
            
        setFridayTimes(new Map([
            ["6:30 AM", friMap.has("6:30 AM")], 
            ["7:00 AM", friMap.has("7:00 AM")], 
            ["7:30 AM", friMap.has("7:30 AM")], 
            ["8:00 AM", friMap.has("8:00 AM")], 
            ["8:30 AM", friMap.has("8:30 AM")], 
            ["9:00 AM", friMap.has("9:00 AM")], 
            ["9:30 AM", friMap.has("9:30 AM")], 
            ["10:00 AM", friMap.has("10:00 AM")], 
            ["10:30 AM", friMap.has("10:30 AM")], 
            ["11:00 AM", friMap.has("11:00 AM")], 
            ["11:30 AM", friMap.has("11:30 AM")], 
            ["12:00 PM", friMap.has("12:00 PM")], 
            ["12:30 PM", friMap.has("12:30 PM")], 
            ["1:00 PM", friMap.has("1:00 PM")], 
            ["1:30 PM", friMap.has("1:30 PM")], 
            ["2:00 PM", friMap.has("2:00 PM")], 
            ["2:30 PM", friMap.has("2:30 PM")], 
            ["3:00 PM", friMap.has("3:00 PM")], 
            ["3:30 PM", friMap.has("3:30 PM")], 
            ["4:00 PM", friMap.has("4:00 PM")], 
            ["4:30 PM", friMap.has("4:30 PM")], 
            ["5:00 PM", friMap.has("5:00 PM")], 
            ["5:30 PM", friMap.has("5:30 PM")], 
            ["6:00 PM", friMap.has("6:00 PM")], 
            ["6:30 PM", friMap.has("6:30 PM")], 
            ["7:00 PM", friMap.has("7:00 PM")], 
            ["7:30 PM", friMap.has("7:30 PM")], 
            ["8:00 PM", friMap.has("8:00 PM")],
        ]))

        setSaturdayTimes(new Map([
            ["6:30 AM", satMap.has("6:30 AM")], 
            ["7:00 AM", satMap.has("7:00 AM")], 
            ["7:30 AM", satMap.has("7:30 AM")], 
            ["8:00 AM", satMap.has("8:00 AM")], 
            ["8:30 AM", satMap.has("8:30 AM")], 
            ["9:00 AM", satMap.has("9:00 AM")], 
            ["9:30 AM", satMap.has("9:30 AM")], 
            ["10:00 AM", satMap.has("10:00 AM")], 
            ["10:30 AM", satMap.has("10:30 AM")], 
            ["11:00 AM", satMap.has("11:00 AM")], 
            ["11:30 AM", satMap.has("11:30 AM")], 
            ["12:00 PM", satMap.has("12:00 PM")], 
            ["12:30 PM", satMap.has("12:30 PM")], 
            ["1:00 PM", satMap.has("1:00 PM")], 
            ["1:30 PM", satMap.has("1:30 PM")], 
            ["2:00 PM", satMap.has("2:00 PM")], 
            ["2:30 PM", satMap.has("2:30 PM")], 
            ["3:00 PM", satMap.has("3:00 PM")], 
            ["3:30 PM", satMap.has("3:30 PM")], 
            ["4:00 PM", satMap.has("4:00 PM")], 
            ["4:30 PM", satMap.has("4:30 PM")], 
            ["5:00 PM", satMap.has("5:00 PM")], 
            ["5:30 PM", satMap.has("5:30 PM")], 
            ["6:00 PM", satMap.has("6:00 PM")], 
            ["6:30 PM", satMap.has("6:30 PM")], 
            ["7:00 PM", satMap.has("7:00 PM")], 
            ["7:30 PM", satMap.has("7:30 PM")], 
            ["8:00 PM", satMap.has("8:00 PM")],
        ]))

        setSundayTimes(new Map([
            ["6:30 AM", sunMap.has("6:30 AM")], 
            ["7:00 AM", sunMap.has("7:00 AM")], 
            ["7:30 AM", sunMap.has("7:30 AM")], 
            ["8:00 AM", sunMap.has("8:00 AM")], 
            ["8:30 AM", sunMap.has("8:30 AM")], 
            ["9:00 AM", sunMap.has("9:00 AM")], 
            ["9:30 AM", sunMap.has("9:30 AM")], 
            ["10:00 AM", sunMap.has("10:00 AM")], 
            ["10:30 AM", sunMap.has("10:30 AM")], 
            ["11:00 AM", sunMap.has("11:00 AM")], 
            ["11:30 AM", sunMap.has("11:30 AM")], 
            ["12:00 PM", sunMap.has("12:00 PM")], 
            ["12:30 PM", sunMap.has("12:30 PM")], 
            ["1:00 PM", sunMap.has("1:00 PM")], 
            ["1:30 PM", sunMap.has("1:30 PM")], 
            ["2:00 PM", sunMap.has("2:00 PM")], 
            ["2:30 PM", sunMap.has("2:30 PM")], 
            ["3:00 PM", sunMap.has("3:00 PM")], 
            ["3:30 PM", sunMap.has("3:30 PM")], 
            ["4:00 PM", sunMap.has("4:00 PM")], 
            ["4:30 PM", sunMap.has("4:30 PM")], 
            ["5:00 PM", sunMap.has("5:00 PM")], 
            ["5:30 PM", sunMap.has("5:30 PM")], 
            ["6:00 PM", sunMap.has("6:00 PM")], 
            ["6:30 PM", sunMap.has("6:30 PM")], 
            ["7:00 PM", sunMap.has("7:00 PM")], 
            ["7:30 PM", sunMap.has("7:30 PM")], 
            ["8:00 PM", sunMap.has("8:00 PM")],
        ]))
        
    }, [UTCSchedule])

        /****************************************************************************
    *
    *                     BUILD REPEATING AVAILABILITIES DATA
    *
    * ************************************************************************** */

    /* The following code is for loading the users repeating availabilities */
    // initialize the map for repeating times
    const monRepMap = new Map()
    const tueRepMap = new Map()
    const wedRepMap = new Map()
    const thuRepMap = new Map()
    const friRepMap = new Map()
    const satRepMap = new Map()
    const sunRepMap = new Map()


    // Loop through repeating schedule and convert UTC day and time to users timezone day and time. 
    // get users UTC offset
    const offset = new Date().getTimezoneOffset() / 60
    repeatingSchedule[0] && repeatingSchedule[0].times.map(time => {

        var localHours = time.UTCHours - offset
        var day = time.UTCDay
        const minutes = time.UTCMinutes

        // Check if we switched to a new day after converting to local time
        if (localHours > 23 || localHours < 0) {
            if (localHours > 23) {
                if (day === 6) {
                    day = 0
                } else {
                    day = day + 1
                }
                // get the new hours for the next day
                localHours = localHours - 24
            }
            else if (localHours < 0) {
                if (day === 0) {
                    day = 6
                } else {
                    day = day - 1
                }
                // get the new hours for the previous day
                localHours = 24 + localHours
            }
        }

        // calculate the time of day in the maps world (e.g. 14 hours, 30 minutes -> 2:00 PM)
        var timeOfDay = ''
        // convert to 1:00 PM, 2:00 PM etc. 
        if (localHours > 12) {
            const convertedTime = localHours - 12
            if (minutes === 30) {
                timeOfDay = convertedTime.toString() + ':30 PM'
            }
            else {
                timeOfDay = convertedTime.toString() + ':00 PM'
            }
        }
        else if (localHours === 12) {
            if (minutes === 30) {
                timeOfDay = '12:30 PM'
            }
            else {
                timeOfDay = '12:00 PM'
            }
        }
        // convert to midnight 
        else if (localHours === 0) {
            if (minutes === 30) {
                timeOfDay = '12:30 AM'
            }
            else {
                timeOfDay = '12:00 AM'
            }
        } 
        // mapping is trivial
        else {
            if (minutes === 30) {
                timeOfDay = localHours.toString() + ':30 AM'
            }
            else {
                timeOfDay = localHours.toString() + ':00 AM'
            }
        }

        const dayOfWeek = day
        

        // place this day/time combo into the map
        switch (dayOfWeek) {
            case 1:
                monRepMap.set(timeOfDay, true)
                break
            case 2:
                tueRepMap.set(timeOfDay, true)
                break
            case 3:
                wedRepMap.set(timeOfDay, true)
                break
            case 4:
                thuRepMap.set(timeOfDay, true)
                break
            case 5:
                friRepMap.set(timeOfDay, true)
                break
            case 6:
                satRepMap.set(timeOfDay, true)
                break
            case 0:
                sunRepMap.set(timeOfDay, true)
                break
        
            default:
                break
        }

    })


    const [mondayRepTimes, setMondayRepTimes] = useState(null)
    const [tuesdayRepTimes, setTuesdayRepTimes] = useState(null)
    const [wednesdayRepTimes, setWednesdayRepTimes] = useState(null)
    const [thursdayRepTimes, setThursdayRepTimes] = useState(null)
    const [fridayRepTimes, setFridayRepTimes] = useState(null)
    const [saturdayRepTimes, setSaturdayRepTimes] = useState(null)
    const [sundayRepTimes, setSundayRepTimes] = useState(null)

    useEffect(() => {
        setMondayRepTimes(new Map([
            ["6:30 AM", monRepMap.has("6:30 AM")], 
            ["7:00 AM", monRepMap.has("7:00 AM")], 
            ["7:30 AM", monRepMap.has("7:30 AM")], 
            ["8:00 AM", monRepMap.has("8:00 AM")], 
            ["8:30 AM", monRepMap.has("8:30 AM")], 
            ["9:00 AM", monRepMap.has("9:00 AM")], 
            ["9:30 AM", monRepMap.has("9:30 AM")], 
            ["10:00 AM", monRepMap.has("10:00 AM")], 
            ["10:30 AM", monRepMap.has("10:30 AM")], 
            ["11:00 AM", monRepMap.has("11:00 AM")], 
            ["11:30 AM", monRepMap.has("11:30 AM")], 
            ["12:00 PM", monRepMap.has("12:00 PM")], 
            ["12:30 PM", monRepMap.has("12:30 PM")], 
            ["1:00 PM", monRepMap.has("1:00 PM")], 
            ["1:30 PM", monRepMap.has("1:30 PM")], 
            ["2:00 PM", monRepMap.has("2:00 PM")], 
            ["2:30 PM", monRepMap.has("2:30 PM")], 
            ["3:00 PM", monRepMap.has("3:00 PM")], 
            ["3:30 PM", monRepMap.has("3:30 PM")], 
            ["4:00 PM", monRepMap.has("4:00 PM")], 
            ["4:30 PM", monRepMap.has("4:30 PM")], 
            ["5:00 PM", monRepMap.has("5:00 PM")], 
            ["5:30 PM", monRepMap.has("5:30 PM")], 
            ["6:00 PM", monRepMap.has("6:00 PM")], 
            ["6:30 PM", monRepMap.has("6:30 PM")], 
            ["7:00 PM", monRepMap.has("7:00 PM")], 
            ["7:30 PM", monRepMap.has("7:30 PM")], 
            ["8:00 PM", monRepMap.has("8:00 PM")],
        ]))

        setTuesdayRepTimes(new Map([
            ["6:30 AM", tueRepMap.has("6:30 AM")], 
            ["7:00 AM", tueRepMap.has("7:00 AM")], 
            ["7:30 AM", tueRepMap.has("7:30 AM")], 
            ["8:00 AM", tueRepMap.has("8:00 AM")], 
            ["8:30 AM", tueRepMap.has("8:30 AM")], 
            ["9:00 AM", tueRepMap.has("9:00 AM")], 
            ["9:30 AM", tueRepMap.has("9:30 AM")], 
            ["10:00 AM", tueRepMap.has("10:00 AM")], 
            ["10:30 AM", tueRepMap.has("10:30 AM")], 
            ["11:00 AM", tueRepMap.has("11:00 AM")], 
            ["11:30 AM", tueRepMap.has("11:30 AM")], 
            ["12:00 PM", tueRepMap.has("12:00 PM")], 
            ["12:30 PM", tueRepMap.has("12:30 PM")], 
            ["1:00 PM", tueRepMap.has("1:00 PM")], 
            ["1:30 PM", tueRepMap.has("1:30 PM")], 
            ["2:00 PM", tueRepMap.has("2:00 PM")], 
            ["2:30 PM", tueRepMap.has("2:30 PM")], 
            ["3:00 PM", tueRepMap.has("3:00 PM")], 
            ["3:30 PM", tueRepMap.has("3:30 PM")], 
            ["4:00 PM", tueRepMap.has("4:00 PM")], 
            ["4:30 PM", tueRepMap.has("4:30 PM")], 
            ["5:00 PM", tueRepMap.has("5:00 PM")], 
            ["5:30 PM", tueRepMap.has("5:30 PM")], 
            ["6:00 PM", tueRepMap.has("6:00 PM")], 
            ["6:30 PM", tueRepMap.has("6:30 PM")], 
            ["7:00 PM", tueRepMap.has("7:00 PM")], 
            ["7:30 PM", tueRepMap.has("7:30 PM")], 
            ["8:00 PM", tueRepMap.has("8:00 PM")],
        ]))

        setWednesdayRepTimes(new Map([
            ["6:30 AM", wedRepMap.has("6:30 AM")], 
            ["7:00 AM", wedRepMap.has("7:00 AM")], 
            ["7:30 AM", wedRepMap.has("7:30 AM")], 
            ["8:00 AM", wedRepMap.has("8:00 AM")], 
            ["8:30 AM", wedRepMap.has("8:30 AM")], 
            ["9:00 AM", wedRepMap.has("9:00 AM")], 
            ["9:30 AM", wedRepMap.has("9:30 AM")], 
            ["10:00 AM", wedRepMap.has("10:00 AM")], 
            ["10:30 AM", wedRepMap.has("10:30 AM")], 
            ["11:00 AM", wedRepMap.has("11:00 AM")], 
            ["11:30 AM", wedRepMap.has("11:30 AM")], 
            ["12:00 PM", wedRepMap.has("12:00 PM")], 
            ["12:30 PM", wedRepMap.has("12:30 PM")], 
            ["1:00 PM", wedRepMap.has("1:00 PM")], 
            ["1:30 PM", wedRepMap.has("1:30 PM")], 
            ["2:00 PM", wedRepMap.has("2:00 PM")], 
            ["2:30 PM", wedRepMap.has("2:30 PM")], 
            ["3:00 PM", wedRepMap.has("3:00 PM")], 
            ["3:30 PM", wedRepMap.has("3:30 PM")], 
            ["4:00 PM", wedRepMap.has("4:00 PM")], 
            ["4:30 PM", wedRepMap.has("4:30 PM")], 
            ["5:00 PM", wedRepMap.has("5:00 PM")], 
            ["5:30 PM", wedRepMap.has("5:30 PM")], 
            ["6:00 PM", wedRepMap.has("6:00 PM")], 
            ["6:30 PM", wedRepMap.has("6:30 PM")], 
            ["7:00 PM", wedRepMap.has("7:00 PM")], 
            ["7:30 PM", wedRepMap.has("7:30 PM")], 
            ["8:00 PM", wedRepMap.has("8:00 PM")],
        ]))

        setThursdayRepTimes(new Map([
            ["6:30 AM", thuRepMap.has("6:30 AM")], 
            ["7:00 AM", thuRepMap.has("7:00 AM")], 
            ["7:30 AM", thuRepMap.has("7:30 AM")], 
            ["8:00 AM", thuRepMap.has("8:00 AM")], 
            ["8:30 AM", thuRepMap.has("8:30 AM")], 
            ["9:00 AM", thuRepMap.has("9:00 AM")], 
            ["9:30 AM", thuRepMap.has("9:30 AM")], 
            ["10:00 AM", thuRepMap.has("10:00 AM")], 
            ["10:30 AM", thuRepMap.has("10:30 AM")], 
            ["11:00 AM", thuRepMap.has("11:00 AM")], 
            ["11:30 AM", thuRepMap.has("11:30 AM")], 
            ["12:00 PM", thuRepMap.has("12:00 PM")], 
            ["12:30 PM", thuRepMap.has("12:30 PM")], 
            ["1:00 PM", thuRepMap.has("1:00 PM")], 
            ["1:30 PM", thuRepMap.has("1:30 PM")], 
            ["2:00 PM", thuRepMap.has("2:00 PM")], 
            ["2:30 PM", thuRepMap.has("2:30 PM")], 
            ["3:00 PM", thuRepMap.has("3:00 PM")], 
            ["3:30 PM", thuRepMap.has("3:30 PM")], 
            ["4:00 PM", thuRepMap.has("4:00 PM")], 
            ["4:30 PM", thuRepMap.has("4:30 PM")], 
            ["5:00 PM", thuRepMap.has("5:00 PM")], 
            ["5:30 PM", thuRepMap.has("5:30 PM")], 
            ["6:00 PM", thuRepMap.has("6:00 PM")], 
            ["6:30 PM", thuRepMap.has("6:30 PM")], 
            ["7:00 PM", thuRepMap.has("7:00 PM")], 
            ["7:30 PM", thuRepMap.has("7:30 PM")], 
            ["8:00 PM", thuRepMap.has("8:00 PM")],
        ]))

        setFridayRepTimes(new Map([
            ["6:30 AM", friRepMap.has("6:30 AM")], 
            ["7:00 AM", friRepMap.has("7:00 AM")], 
            ["7:30 AM", friRepMap.has("7:30 AM")], 
            ["8:00 AM", friRepMap.has("8:00 AM")], 
            ["8:30 AM", friRepMap.has("8:30 AM")], 
            ["9:00 AM", friRepMap.has("9:00 AM")], 
            ["9:30 AM", friRepMap.has("9:30 AM")], 
            ["10:00 AM", friRepMap.has("10:00 AM")], 
            ["10:30 AM", friRepMap.has("10:30 AM")], 
            ["11:00 AM", friRepMap.has("11:00 AM")], 
            ["11:30 AM", friRepMap.has("11:30 AM")], 
            ["12:00 PM", friRepMap.has("12:00 PM")], 
            ["12:30 PM", friRepMap.has("12:30 PM")], 
            ["1:00 PM", friRepMap.has("1:00 PM")], 
            ["1:30 PM", friRepMap.has("1:30 PM")], 
            ["2:00 PM", friRepMap.has("2:00 PM")], 
            ["2:30 PM", friRepMap.has("2:30 PM")], 
            ["3:00 PM", friRepMap.has("3:00 PM")], 
            ["3:30 PM", friRepMap.has("3:30 PM")], 
            ["4:00 PM", friRepMap.has("4:00 PM")], 
            ["4:30 PM", friRepMap.has("4:30 PM")], 
            ["5:00 PM", friRepMap.has("5:00 PM")], 
            ["5:30 PM", friRepMap.has("5:30 PM")], 
            ["6:00 PM", friRepMap.has("6:00 PM")], 
            ["6:30 PM", friRepMap.has("6:30 PM")], 
            ["7:00 PM", friRepMap.has("7:00 PM")], 
            ["7:30 PM", friRepMap.has("7:30 PM")], 
            ["8:00 PM", friRepMap.has("8:00 PM")],
        ]))
        
        setSaturdayRepTimes(new Map([
            ["6:30 AM", satRepMap.has("6:30 AM")], 
            ["7:00 AM", satRepMap.has("7:00 AM")], 
            ["7:30 AM", satRepMap.has("7:30 AM")], 
            ["8:00 AM", satRepMap.has("8:00 AM")], 
            ["8:30 AM", satRepMap.has("8:30 AM")], 
            ["9:00 AM", satRepMap.has("9:00 AM")], 
            ["9:30 AM", satRepMap.has("9:30 AM")], 
            ["10:00 AM", satRepMap.has("10:00 AM")], 
            ["10:30 AM", satRepMap.has("10:30 AM")], 
            ["11:00 AM", satRepMap.has("11:00 AM")], 
            ["11:30 AM", satRepMap.has("11:30 AM")], 
            ["12:00 PM", satRepMap.has("12:00 PM")], 
            ["12:30 PM", satRepMap.has("12:30 PM")], 
            ["1:00 PM", satRepMap.has("1:00 PM")], 
            ["1:30 PM", satRepMap.has("1:30 PM")], 
            ["2:00 PM", satRepMap.has("2:00 PM")], 
            ["2:30 PM", satRepMap.has("2:30 PM")], 
            ["3:00 PM", satRepMap.has("3:00 PM")], 
            ["3:30 PM", satRepMap.has("3:30 PM")], 
            ["4:00 PM", satRepMap.has("4:00 PM")], 
            ["4:30 PM", satRepMap.has("4:30 PM")], 
            ["5:00 PM", satRepMap.has("5:00 PM")], 
            ["5:30 PM", satRepMap.has("5:30 PM")], 
            ["6:00 PM", satRepMap.has("6:00 PM")], 
            ["6:30 PM", satRepMap.has("6:30 PM")], 
            ["7:00 PM", satRepMap.has("7:00 PM")], 
            ["7:30 PM", satRepMap.has("7:30 PM")], 
            ["8:00 PM", satRepMap.has("8:00 PM")],
        ]))

        setSundayRepTimes(new Map([
            ["6:30 AM", sunRepMap.has("6:30 AM")], 
            ["7:00 AM", sunRepMap.has("7:00 AM")], 
            ["7:30 AM", sunRepMap.has("7:30 AM")], 
            ["8:00 AM", sunRepMap.has("8:00 AM")], 
            ["8:30 AM", sunRepMap.has("8:30 AM")], 
            ["9:00 AM", sunRepMap.has("9:00 AM")], 
            ["9:30 AM", sunRepMap.has("9:30 AM")], 
            ["10:00 AM", sunRepMap.has("10:00 AM")], 
            ["10:30 AM", sunRepMap.has("10:30 AM")], 
            ["11:00 AM", sunRepMap.has("11:00 AM")], 
            ["11:30 AM", sunRepMap.has("11:30 AM")], 
            ["12:00 PM", sunRepMap.has("12:00 PM")], 
            ["12:30 PM", sunRepMap.has("12:30 PM")], 
            ["1:00 PM", sunRepMap.has("1:00 PM")], 
            ["1:30 PM", sunRepMap.has("1:30 PM")], 
            ["2:00 PM", sunRepMap.has("2:00 PM")], 
            ["2:30 PM", sunRepMap.has("2:30 PM")], 
            ["3:00 PM", sunRepMap.has("3:00 PM")], 
            ["3:30 PM", sunRepMap.has("3:30 PM")], 
            ["4:00 PM", sunRepMap.has("4:00 PM")], 
            ["4:30 PM", sunRepMap.has("4:30 PM")], 
            ["5:00 PM", sunRepMap.has("5:00 PM")], 
            ["5:30 PM", sunRepMap.has("5:30 PM")], 
            ["6:00 PM", sunRepMap.has("6:00 PM")], 
            ["6:30 PM", sunRepMap.has("6:30 PM")], 
            ["7:00 PM", sunRepMap.has("7:00 PM")], 
            ["7:30 PM", sunRepMap.has("7:30 PM")], 
            ["8:00 PM", sunRepMap.has("8:00 PM")],
        ]))

    }, [repeatingSchedule])

    /****************************************************************************
    *
    *                       BUILD EXCEPTIONS DATA
    *
    * ************************************************************************** */

        // Create maps for the exceptions
        const monExcepMap = new Map()
        const tueExcepMap = new Map()
        const wedExcepMap = new Map()
        const thuExcepMap = new Map()
        const friExcepMap = new Map()
        const satExcepMap = new Map()
        const sunExcepMap = new Map()
    
        UTCSchedule[0] && UTCSchedule[0].exceptions && UTCSchedule[0].exceptions.map(time => {
            const date = new Date(time)
            const momDate = moment(date)
            const dayOfWeek = momDate.format('dddd')
            const timeOfDay = timeOfDayFactory(momDate.format('hh:mm a'))
        
            if (timeOfDay.charAt(0) === '0') {
                timeOfDay = timeOfDay.substring(1, timeOfDay.length)
            }
            
            // Check if this exception is no longer a repeating time, if so, don't add it to the calendar

            switch (dayOfWeek) {
                case "Monday":
                    // Only add the exception to the map if it in the repeating map (do this for all 7 cases)
                    if (monRepMap.has(timeOfDay)) {
                        monExcepMap.set(timeOfDay, true)
                    } 
                    break
                case "Tuesday":
                    if (tueRepMap.has(timeOfDay)) {
                        tueExcepMap.set(timeOfDay, true)
                    }
                    break
                case "Wednesday":
                    if (wedRepMap.has(timeOfDay)) {
                        wedExcepMap.set(timeOfDay, true)
                    }
                    break
                case "Thursday": 
                    if (thuRepMap.has(timeOfDay)) {
                        thuExcepMap.set(timeOfDay, true)
                    }
                    break
                case "Friday":
                    if (friRepMap.has(timeOfDay)) {
                        friExcepMap.set(timeOfDay, true)
                    }
                    break;
                case "Saturday":
                    if (satRepMap.has(timeOfDay)) {
                        satExcepMap.set(timeOfDay, true)
                    }
                    break
                case "Sunday":
                    if (sunRepMap.has(timeOfDay)) {
                        sunExcepMap.set(timeOfDay, true)
                    }
                    break
                default:
                    break
            }
        })

    // Set state for the exceptions
    const [mondayExcepTimes, setMondayExcepTimes] = useState(null)
    const [tuesdayExcepTimes, setTuesdayEcxepTimes] = useState(null)
    const [wednesdayExcepTimes, setWednesdayExcepTimes] = useState(null)
    const [thursdayExcepTimes, setThursdayExcepTimes] = useState(null)
    const [fridayExcepTimes, setFridayExcepTimes] = useState(null)
    const [saturdayExcepTimes, setSaturdayExcepTimes] = useState(null)
    const [sundayExcepTimes, setSundayExcepTimes] = useState(null)

    useEffect(() => {
        setMondayExcepTimes(new Map([
            ["6:30 AM", monExcepMap.has("6:30 AM")], 
            ["7:00 AM", monExcepMap.has("7:00 AM")], 
            ["7:30 AM", monExcepMap.has("7:30 AM")], 
            ["8:00 AM", monExcepMap.has("8:00 AM")], 
            ["8:30 AM", monExcepMap.has("8:30 AM")], 
            ["9:00 AM", monExcepMap.has("9:00 AM")], 
            ["9:30 AM", monExcepMap.has("9:30 AM")], 
            ["10:00 AM", monExcepMap.has("10:00 AM")], 
            ["10:30 AM", monExcepMap.has("10:30 AM")], 
            ["11:00 AM", monExcepMap.has("11:00 AM")], 
            ["11:30 AM", monExcepMap.has("11:30 AM")], 
            ["12:00 PM", monExcepMap.has("12:00 PM")], 
            ["12:30 PM", monExcepMap.has("12:30 PM")], 
            ["1:00 PM", monExcepMap.has("1:00 PM")], 
            ["1:30 PM", monExcepMap.has("1:30 PM")], 
            ["2:00 PM", monExcepMap.has("2:00 PM")], 
            ["2:30 PM", monExcepMap.has("2:30 PM")], 
            ["3:00 PM", monExcepMap.has("3:00 PM")], 
            ["3:30 PM", monExcepMap.has("3:30 PM")], 
            ["4:00 PM", monExcepMap.has("4:00 PM")], 
            ["4:30 PM", monExcepMap.has("4:30 PM")], 
            ["5:00 PM", monExcepMap.has("5:00 PM")], 
            ["5:30 PM", monExcepMap.has("5:30 PM")], 
            ["6:00 PM", monExcepMap.has("6:00 PM")], 
            ["6:30 PM", monExcepMap.has("6:30 PM")], 
            ["7:00 PM", monExcepMap.has("7:00 PM")], 
            ["7:30 PM", monExcepMap.has("7:30 PM")], 
            ["8:00 PM", monExcepMap.has("8:00 PM")],
        ]))

        setTuesdayEcxepTimes(new Map([
            ["6:30 AM", tueExcepMap.has("6:30 AM")], 
            ["7:00 AM", tueExcepMap.has("7:00 AM")], 
            ["7:30 AM", tueExcepMap.has("7:30 AM")], 
            ["8:00 AM", tueExcepMap.has("8:00 AM")], 
            ["8:30 AM", tueExcepMap.has("8:30 AM")], 
            ["9:00 AM", tueExcepMap.has("9:00 AM")], 
            ["9:30 AM", tueExcepMap.has("9:30 AM")], 
            ["10:00 AM", tueExcepMap.has("10:00 AM")], 
            ["10:30 AM", tueExcepMap.has("10:30 AM")], 
            ["11:00 AM", tueExcepMap.has("11:00 AM")], 
            ["11:30 AM", tueExcepMap.has("11:30 AM")], 
            ["12:00 PM", tueExcepMap.has("12:00 PM")], 
            ["12:30 PM", tueExcepMap.has("12:30 PM")], 
            ["1:00 PM", tueExcepMap.has("1:00 PM")], 
            ["1:30 PM", tueExcepMap.has("1:30 PM")], 
            ["2:00 PM", tueExcepMap.has("2:00 PM")], 
            ["2:30 PM", tueExcepMap.has("2:30 PM")], 
            ["3:00 PM", tueExcepMap.has("3:00 PM")], 
            ["3:30 PM", tueExcepMap.has("3:30 PM")], 
            ["4:00 PM", tueExcepMap.has("4:00 PM")], 
            ["4:30 PM", tueExcepMap.has("4:30 PM")], 
            ["5:00 PM", tueExcepMap.has("5:00 PM")], 
            ["5:30 PM", tueExcepMap.has("5:30 PM")], 
            ["6:00 PM", tueExcepMap.has("6:00 PM")], 
            ["6:30 PM", tueExcepMap.has("6:30 PM")], 
            ["7:00 PM", tueExcepMap.has("7:00 PM")], 
            ["7:30 PM", tueExcepMap.has("7:30 PM")], 
            ["8:00 PM", tueExcepMap.has("8:00 PM")],
        ]))

        setWednesdayExcepTimes(new Map([
            ["6:30 AM", wedExcepMap.has("6:30 AM")], 
            ["7:00 AM", wedExcepMap.has("7:00 AM")], 
            ["7:30 AM", wedExcepMap.has("7:30 AM")], 
            ["8:00 AM", wedExcepMap.has("8:00 AM")], 
            ["8:30 AM", wedExcepMap.has("8:30 AM")], 
            ["9:00 AM", wedExcepMap.has("9:00 AM")], 
            ["9:30 AM", wedExcepMap.has("9:30 AM")], 
            ["10:00 AM", wedExcepMap.has("10:00 AM")], 
            ["10:30 AM", wedExcepMap.has("10:30 AM")], 
            ["11:00 AM", wedExcepMap.has("11:00 AM")], 
            ["11:30 AM", wedExcepMap.has("11:30 AM")], 
            ["12:00 PM", wedExcepMap.has("12:00 PM")], 
            ["12:30 PM", wedExcepMap.has("12:30 PM")], 
            ["1:00 PM", wedExcepMap.has("1:00 PM")], 
            ["1:30 PM", wedExcepMap.has("1:30 PM")], 
            ["2:00 PM", wedExcepMap.has("2:00 PM")], 
            ["2:30 PM", wedExcepMap.has("2:30 PM")], 
            ["3:00 PM", wedExcepMap.has("3:00 PM")], 
            ["3:30 PM", wedExcepMap.has("3:30 PM")], 
            ["4:00 PM", wedExcepMap.has("4:00 PM")], 
            ["4:30 PM", wedExcepMap.has("4:30 PM")], 
            ["5:00 PM", wedExcepMap.has("5:00 PM")], 
            ["5:30 PM", wedExcepMap.has("5:30 PM")], 
            ["6:00 PM", wedExcepMap.has("6:00 PM")], 
            ["6:30 PM", wedExcepMap.has("6:30 PM")], 
            ["7:00 PM", wedExcepMap.has("7:00 PM")], 
            ["7:30 PM", wedExcepMap.has("7:30 PM")], 
            ["8:00 PM", wedExcepMap.has("8:00 PM")],
        ]))

        setThursdayExcepTimes(new Map([
            ["6:30 AM", thuExcepMap.has("6:30 AM")], 
            ["7:00 AM", thuExcepMap.has("7:00 AM")], 
            ["7:30 AM", thuExcepMap.has("7:30 AM")], 
            ["8:00 AM", thuExcepMap.has("8:00 AM")], 
            ["8:30 AM", thuExcepMap.has("8:30 AM")], 
            ["9:00 AM", thuExcepMap.has("9:00 AM")], 
            ["9:30 AM", thuExcepMap.has("9:30 AM")], 
            ["10:00 AM", thuExcepMap.has("10:00 AM")], 
            ["10:30 AM", thuExcepMap.has("10:30 AM")], 
            ["11:00 AM", thuExcepMap.has("11:00 AM")], 
            ["11:30 AM", thuExcepMap.has("11:30 AM")], 
            ["12:00 PM", thuExcepMap.has("12:00 PM")], 
            ["12:30 PM", thuExcepMap.has("12:30 PM")], 
            ["1:00 PM", thuExcepMap.has("1:00 PM")], 
            ["1:30 PM", thuExcepMap.has("1:30 PM")], 
            ["2:00 PM", thuExcepMap.has("2:00 PM")], 
            ["2:30 PM", thuExcepMap.has("2:30 PM")], 
            ["3:00 PM", thuExcepMap.has("3:00 PM")], 
            ["3:30 PM", thuExcepMap.has("3:30 PM")], 
            ["4:00 PM", thuExcepMap.has("4:00 PM")], 
            ["4:30 PM", thuExcepMap.has("4:30 PM")], 
            ["5:00 PM", thuExcepMap.has("5:00 PM")], 
            ["5:30 PM", thuExcepMap.has("5:30 PM")], 
            ["6:00 PM", thuExcepMap.has("6:00 PM")], 
            ["6:30 PM", thuExcepMap.has("6:30 PM")], 
            ["7:00 PM", thuExcepMap.has("7:00 PM")], 
            ["7:30 PM", thuExcepMap.has("7:30 PM")], 
            ["8:00 PM", thuExcepMap.has("8:00 PM")],
        ]))
        
        setFridayExcepTimes(new Map([
            ["6:30 AM", friExcepMap.has("6:30 AM")], 
            ["7:00 AM", friExcepMap.has("7:00 AM")], 
            ["7:30 AM", friExcepMap.has("7:30 AM")], 
            ["8:00 AM", friExcepMap.has("8:00 AM")], 
            ["8:30 AM", friExcepMap.has("8:30 AM")], 
            ["9:00 AM", friExcepMap.has("9:00 AM")], 
            ["9:30 AM", friExcepMap.has("9:30 AM")], 
            ["10:00 AM", friExcepMap.has("10:00 AM")], 
            ["10:30 AM", friExcepMap.has("10:30 AM")], 
            ["11:00 AM", friExcepMap.has("11:00 AM")], 
            ["11:30 AM", friExcepMap.has("11:30 AM")], 
            ["12:00 PM", friExcepMap.has("12:00 PM")], 
            ["12:30 PM", friExcepMap.has("12:30 PM")], 
            ["1:00 PM", friExcepMap.has("1:00 PM")], 
            ["1:30 PM", friExcepMap.has("1:30 PM")], 
            ["2:00 PM", friExcepMap.has("2:00 PM")], 
            ["2:30 PM", friExcepMap.has("2:30 PM")], 
            ["3:00 PM", friExcepMap.has("3:00 PM")], 
            ["3:30 PM", friExcepMap.has("3:30 PM")], 
            ["4:00 PM", friExcepMap.has("4:00 PM")], 
            ["4:30 PM", friExcepMap.has("4:30 PM")], 
            ["5:00 PM", friExcepMap.has("5:00 PM")], 
            ["5:30 PM", friExcepMap.has("5:30 PM")], 
            ["6:00 PM", friExcepMap.has("6:00 PM")], 
            ["6:30 PM", friExcepMap.has("6:30 PM")], 
            ["7:00 PM", friExcepMap.has("7:00 PM")], 
            ["7:30 PM", friExcepMap.has("7:30 PM")], 
            ["8:00 PM", friExcepMap.has("8:00 PM")],
        ]))

        setSaturdayExcepTimes(new Map([
            ["6:30 AM", satExcepMap.has("6:30 AM")], 
            ["7:00 AM", satExcepMap.has("7:00 AM")], 
            ["7:30 AM", satExcepMap.has("7:30 AM")], 
            ["8:00 AM", satExcepMap.has("8:00 AM")], 
            ["8:30 AM", satExcepMap.has("8:30 AM")], 
            ["9:00 AM", satExcepMap.has("9:00 AM")], 
            ["9:30 AM", satExcepMap.has("9:30 AM")], 
            ["10:00 AM", satExcepMap.has("10:00 AM")], 
            ["10:30 AM", satExcepMap.has("10:30 AM")], 
            ["11:00 AM", satExcepMap.has("11:00 AM")], 
            ["11:30 AM", satExcepMap.has("11:30 AM")], 
            ["12:00 PM", satExcepMap.has("12:00 PM")], 
            ["12:30 PM", satExcepMap.has("12:30 PM")], 
            ["1:00 PM", satExcepMap.has("1:00 PM")], 
            ["1:30 PM", satExcepMap.has("1:30 PM")], 
            ["2:00 PM", satExcepMap.has("2:00 PM")], 
            ["2:30 PM", satExcepMap.has("2:30 PM")], 
            ["3:00 PM", satExcepMap.has("3:00 PM")], 
            ["3:30 PM", satExcepMap.has("3:30 PM")], 
            ["4:00 PM", satExcepMap.has("4:00 PM")], 
            ["4:30 PM", satExcepMap.has("4:30 PM")], 
            ["5:00 PM", satExcepMap.has("5:00 PM")], 
            ["5:30 PM", satExcepMap.has("5:30 PM")], 
            ["6:00 PM", satExcepMap.has("6:00 PM")], 
            ["6:30 PM", satExcepMap.has("6:30 PM")], 
            ["7:00 PM", satExcepMap.has("7:00 PM")], 
            ["7:30 PM", satExcepMap.has("7:30 PM")], 
            ["8:00 PM", satExcepMap.has("8:00 PM")],
        ]))

        setSundayExcepTimes(new Map([
            ["6:30 AM", sunExcepMap.has("6:30 AM")], 
            ["7:00 AM", sunExcepMap.has("7:00 AM")], 
            ["7:30 AM", sunExcepMap.has("7:30 AM")], 
            ["8:00 AM", sunExcepMap.has("8:00 AM")], 
            ["8:30 AM", sunExcepMap.has("8:30 AM")], 
            ["9:00 AM", sunExcepMap.has("9:00 AM")], 
            ["9:30 AM", sunExcepMap.has("9:30 AM")], 
            ["10:00 AM", sunExcepMap.has("10:00 AM")], 
            ["10:30 AM", sunExcepMap.has("10:30 AM")], 
            ["11:00 AM", sunExcepMap.has("11:00 AM")], 
            ["11:30 AM", sunExcepMap.has("11:30 AM")], 
            ["12:00 PM", sunExcepMap.has("12:00 PM")], 
            ["12:30 PM", sunExcepMap.has("12:30 PM")], 
            ["1:00 PM", sunExcepMap.has("1:00 PM")], 
            ["1:30 PM", sunExcepMap.has("1:30 PM")], 
            ["2:00 PM", sunExcepMap.has("2:00 PM")], 
            ["2:30 PM", sunExcepMap.has("2:30 PM")], 
            ["3:00 PM", sunExcepMap.has("3:00 PM")], 
            ["3:30 PM", sunExcepMap.has("3:30 PM")], 
            ["4:00 PM", sunExcepMap.has("4:00 PM")], 
            ["4:30 PM", sunExcepMap.has("4:30 PM")], 
            ["5:00 PM", sunExcepMap.has("5:00 PM")], 
            ["5:30 PM", sunExcepMap.has("5:30 PM")], 
            ["6:00 PM", sunExcepMap.has("6:00 PM")], 
            ["6:30 PM", sunExcepMap.has("6:30 PM")], 
            ["7:00 PM", sunExcepMap.has("7:00 PM")], 
            ["7:30 PM", sunExcepMap.has("7:30 PM")], 
            ["8:00 PM", sunExcepMap.has("8:00 PM")],
        ]))
    }, [UTCSchedule])


    /****************************************************************************
    *
    *               SET STATE FOR USER REQUESTING AVAILABILITIES
    *
    * ************************************************************************** */

    // This map contains the meeting time requested by the user.
    const [meetingRequest, setMeetingRequest] = useState(new Map([
        ["6:30 AM", false], 
        ["7:00 AM", false], 
        ["7:30 AM", false], 
        ["8:00 AM", false], 
        ["8:30 AM", false], 
        ["9:00 AM", false], 
        ["9:30 AM", false], 
        ["10:00 AM", false], 
        ["10:30 AM", false], 
        ["11:00 AM", false], 
        ["11:30 AM", false], 
        ["12:00 PM", false], 
        ["12:30 PM", false], 
        ["1:00 PM", false], 
        ["1:30 PM", false], 
        ["2:00 PM", false], 
        ["2:30 PM", false], 
        ["3:00 PM", false], 
        ["3:30 PM", false], 
        ["4:00 PM", false], 
        ["4:30 PM", false], 
        ["5:00 PM", false], 
        ["5:30 PM", false], 
        ["6:00 PM", false], 
        ["6:30 PM", false], 
        ["7:00 PM", false], 
        ["7:30 PM", false], 
        ["8:00 PM", false],
    ]))

    const monColumn = []
    const tueColumn = []
    const wedColumn = []
    const thurColumn = []
    const friColumn = []
    const satColumn = []
    const sunColumn = []

    const timeColumn = []
    const times = [ 
        "6:30 AM", 
        "7:00 AM", 
        "7:30 AM", 
        "8:00 AM", 
        "8:30 AM", 
        "9:00 AM", 
        "9:30 AM", 
        "10:00 AM", 
        "10:30 AM", 
        "11:00 AM", 
        "11:30 AM", 
        "12:00 PM", 
        "12:30 PM", 
        "1:00 PM", 
        "1:30 PM", 
        "2:00 PM", 
        "2:30 PM", 
        "3:00 PM", 
        "3:30 PM", 
        "4:00 PM", 
        "4:30 PM", 
        "5:00 PM", 
        "5:30 PM", 
        "6:00 PM", 
        "6:30 PM", 
        "7:00 PM", 
        "7:30 PM", 
        "8:00 PM"
    ]

    const saveChanges = () => {

        // loop through times and find all open times
        // Convert them to date format
        // Convert them to UTC date strings which will be stored in the database
        const weekTimesList = []

        for (let[time, checked] of mondayTimes) {
            if (checked) {
                // create a UTC date from this time
                const dayDate = mon.toDate()
                const date = new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate(), timesMap.get(time).hours, timesMap.get(time).minutes)
                weekTimesList.push(date.toUTCString())
            }
        }

        for (let[time, checked] of tuesdayTimes) {
            if (checked) {
                // create a UTC date from this time
                const dayDate = tue.toDate()
                const date = new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate(), timesMap.get(time).hours, timesMap.get(time).minutes)
                weekTimesList.push(date.toUTCString())
            }
        }

        for (let[time, checked] of wednesdayTimes) {
            if (checked) {
                // create a UTC date from this time
                const dayDate = wed.toDate()
                const date = new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate(), timesMap.get(time).hours, timesMap.get(time).minutes)
                weekTimesList.push(date.toUTCString())
            }
        }

        for (let[time, checked] of thursdayTimes) {
            if (checked) {
                // create a UTC date from this time
                const dayDate = thu.toDate()
                const date = new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate(), timesMap.get(time).hours, timesMap.get(time).minutes)
                weekTimesList.push(date.toUTCString())
            }
        }

        for (let[time, checked] of fridayTimes) {
            if (checked) {
                // create a UTC date from this time
                const dayDate = fri.toDate()
                const date = new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate(), timesMap.get(time).hours, timesMap.get(time).minutes)
                weekTimesList.push(date.toUTCString())
            }
        }

        for (let[time, checked] of saturdayTimes) {
            if (checked) {
                // create a UTC date from this time
                const dayDate = sat.toDate()
                const date = new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate(), timesMap.get(time).hours, timesMap.get(time).minutes)
                weekTimesList.push(date.toUTCString())
            }
        }

        for (let[time, checked] of sundayTimes) {
            if (checked) {
                // create a UTC date from this time
                const dayDate = sun.toDate()
                const date = new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate(), timesMap.get(time).hours, timesMap.get(time).minutes)
                weekTimesList.push(date.toUTCString())
            }
        }

        /* Save repeating times */
        const repTimesList = []

        for (let[time, checked] of mondayRepTimes) {
            if (checked) {
                // create a UTC date from this time
                const dayDate = mon.toDate()
                const date = new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate(), timesMap.get(time).hours, timesMap.get(time).minutes)

                const UTCTime = {
                    UTCDay: date.getUTCDay(),
                    UTCHours: date.getUTCHours(),
                    UTCMinutes: date.getUTCMinutes(),
                }
                repTimesList.push(UTCTime)
            }
        }

        for (let[time, checked] of tuesdayRepTimes) {
            if (checked) {
                // create a UTC date from this time
                const dayDate = tue.toDate()
                const date = new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate(), timesMap.get(time).hours, timesMap.get(time).minutes)

                const UTCTime = {
                    UTCDay: date.getUTCDay(),
                    UTCHours: date.getUTCHours(),
                    UTCMinutes: date.getUTCMinutes(),
                }
                repTimesList.push(UTCTime)
            }
        }

        for (let[time, checked] of wednesdayRepTimes) {
            if (checked) {
                // create a UTC date from this time
                const dayDate = wed.toDate()
                const date = new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate(), timesMap.get(time).hours, timesMap.get(time).minutes)

                const UTCTime = {
                    UTCDay: date.getUTCDay(),
                    UTCHours: date.getUTCHours(),
                    UTCMinutes: date.getUTCMinutes(),
                }
                repTimesList.push(UTCTime)
            }
        }

        for (let[time, checked] of thursdayRepTimes) {
            if (checked) {
                // create a UTC date from this time
                const dayDate = thu.toDate()
                const date = new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate(), timesMap.get(time).hours, timesMap.get(time).minutes)

                const UTCTime = {
                    UTCDay: date.getUTCDay(),
                    UTCHours: date.getUTCHours(),
                    UTCMinutes: date.getUTCMinutes(),
                }
                repTimesList.push(UTCTime)
            }
        }

        for (let[time, checked] of fridayRepTimes) {
            if (checked) {
                // create a UTC date from this time
                const dayDate = fri.toDate()
                const date = new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate(), timesMap.get(time).hours, timesMap.get(time).minutes)

                const UTCTime = {
                    UTCDay: date.getUTCDay(),
                    UTCHours: date.getUTCHours(),
                    UTCMinutes: date.getUTCMinutes(),
                }
                repTimesList.push(UTCTime)
            }
        }

        for (let[time, checked] of saturdayRepTimes) {
            if (checked) {
                // create a UTC date from this time
                const dayDate = sat.toDate()
                const date = new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate(), timesMap.get(time).hours, timesMap.get(time).minutes)

                const UTCTime = {
                    UTCDay: date.getUTCDay(),
                    UTCHours: date.getUTCHours(),
                    UTCMinutes: date.getUTCMinutes(),
                }
                repTimesList.push(UTCTime)
            }
        }

        for (let[time, checked] of sundayRepTimes) {
            if (checked) {
                // create a UTC date from this time
                const dayDate = sun.toDate()
                const date = new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate(), timesMap.get(time).hours, timesMap.get(time).minutes)

                const UTCTime = {
                    UTCDay: date.getUTCDay(),
                    UTCHours: date.getUTCHours(),
                    UTCMinutes: date.getUTCMinutes(),
                }
                repTimesList.push(UTCTime)
            }
        }

        /* Save repeating times */
        const exepTimesList = []

        for (let[time, checked] of mondayExcepTimes) {
            if (checked) {
                // create a UTC date from this time
                const dayDate = mon.toDate()
                const date = new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate(), timesMap.get(time).hours, timesMap.get(time).minutes)
                exepTimesList.push(date.toUTCString())
            }
        }

        for (let[time, checked] of tuesdayExcepTimes) {
            if (checked) {
                // create a UTC date from this time
                const dayDate = tue.toDate()
                const date = new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate(), timesMap.get(time).hours, timesMap.get(time).minutes)
                exepTimesList.push(date.toUTCString())
            }
        }

        for (let[time, checked] of wednesdayExcepTimes) {
            if (checked) {
                // create a UTC date from this time
                const dayDate = wed.toDate()
                const date = new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate(), timesMap.get(time).hours, timesMap.get(time).minutes)
                exepTimesList.push(date.toUTCString())
            }
        }

        for (let[time, checked] of thursdayExcepTimes) {
            if (checked) {
                // create a UTC date from this time
                const dayDate = thu.toDate()
                const date = new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate(), timesMap.get(time).hours, timesMap.get(time).minutes)
                exepTimesList.push(date.toUTCString())
            }
        }

        for (let[time, checked] of fridayExcepTimes) {
            if (checked) {
                // create a UTC date from this time
                const dayDate = fri.toDate()
                const date = new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate(), timesMap.get(time).hours, timesMap.get(time).minutes)
                exepTimesList.push(date.toUTCString())
            }
        }

        for (let[time, checked] of saturdayExcepTimes) {
            if (checked) {
                // create a UTC date from this time
                const dayDate = sat.toDate()
                const date = new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate(), timesMap.get(time).hours, timesMap.get(time).minutes)
                exepTimesList.push(date.toUTCString())
            }
        }

        for (let[time, checked] of sundayExcepTimes) {
            if (checked) {
                // create a UTC date from this time
                const dayDate = sun.toDate()
                const date = new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate(), timesMap.get(time).hours, timesMap.get(time).minutes)
                exepTimesList.push(date.toUTCString())
            }
        }

        // Save this weeks schedule
        const schedule = {
            week: docId,
            timezone: moment.tz.guess(),
            times: weekTimesList,
            exceptions: exepTimesList,
        }
        saveSchedule(schedule)

        if (repeating) {
            // Save repeating times
            const repTimes = {
                times: repTimesList,
                timezone: moment.tz.guess(),
            }
            saveRepTimes(repTimes)
        }   
    }

    // the drag functions are to handle click and drag time slots
    const handleDragStart = (e) => {
        e.dataTransfer.setDragImage(new Image(), 0, 0)
    }

    const handleDragEnd = () => {
        saveChanges()
    }

    // The following functions are client meeting request checks. 
    // We want to restrict what timeslots they can click
    const [showWrongDayAlert, setShowWrongDayAlert] = useState(false)
    const [showDisjointAlert, setShowDisjointAlert] = useState(false)
    const [showInfo, setShowInfo] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [requestingMeeting, setRequestingMeeting] = useState(false)
    const [dayRequested, setDayRequested] = useState(null)
    const [dayAttempted, setDayAttempted] = useState(null)
    const [dateRequested, setDateRequested] = useState(null)
    // the following state is for displaying a meeting to the user
    const [meetingRequestDetails, setMeetingRequestDetails] = useState(null)
    const [openMeetingRequestDialog, setOpenMeetingRequestDialog] = useState(false)
    const [openConfirmReject, setOpenConfirmReject] = useState(false)
    const [acceptingMeeting, setAcceptingMeeting] = useState(false)
    // the following state is for showing the details of a scheduled meeting to the SME
    const [meetingDetails, setMeetingDetails] = useState(null)
    const [openMeetingDetailsDialog, setOpenMeetingDetailsDialog] = useState(false)
    //checkbox state for SME to confirm meeting with client
    const [confirmCC, setConfirmCC] = useState(false)
    const [confirmZoom, setConfirmZoom] = useState(false)
    const [confirmPhone, setConfirmPhone] = useState(false)
    const [confirmMessaging, setConfirmMessaging] = useState(false)
    const [smeTechChoice, setSmeTechChoice] = useState('')

    // Add the event to Google calendar
    const handleAddEvent = (event, meeting) => {
        event.preventDefault()
        const gapi = window.gapi
        // sort times
        var times = meeting.times.map(time => {
            const date = new Date(time)
            return date.getTime()
        })
        times.sort((a, b) => (a > b) ? 1 : -1)
        // find the begin and end time
        const date1 = new Date(times[0])
        const beginTime = DateTime.fromJSDate(date1)
        var date2 = new Date(times[times.length-1])
        const thirtyMin = 1800000
        date2.setTime(date2.getTime() + thirtyMin)
        const endTime = DateTime.fromJSDate(date2)
        const timezone = moment.tz.guess()

        gapi.load('client:auth2', () => {
            
            gapi.client.init({
              apiKey: calendarConfig.API_KEY,
              clientId: calendarConfig.CLIENT_ID,
              discoveryDocs: calendarConfig.DISCOVERY_DOCS,
              scope: calendarConfig.SCOPES,
            })
      
            gapi.client.load('calendar', 'v3', () => {})
      
            gapi.auth2.getAuthInstance().signIn()
            .then(() => {
              var event = {
                'summary': 'Meeting with ' + (meeting.clientName ? meeting.clientName : 'Client'),
                'description': 'Agenda: ' + meeting.agenda,
                'start': {
                  'dateTime': beginTime,
                  'timeZone': timezone
                },
                'end': {
                  'dateTime': endTime,
                  'timeZone': timezone
                },
                'reminders': {
                  'useDefault': false,
                  'overrides': [
                    {'method': 'email', 'minutes': 24 * 60},
                    {'method': 'popup', 'minutes': 10}
                  ]
                }
              }
              
              var request = gapi.client.calendar.events.insert({
                'calendarId': 'primary',
                'resource': event
              })
              
              request.execute(function(event) {
                window.open(event.htmlLink, '_blank')
              })
            }).catch(e => {
              console.error('error', e)
            })
          })
    }

    const handleConfirmChecked = (event) => {
        switch (event.target.name) {
            case 'client':
                setSmeTechChoice(meetingRequestDetails.techPref)
                setConfirmCC(true)
                setConfirmZoom(false)
                setConfirmPhone(false)
                setConfirmMessaging(false)
                break
            case 'zoom':
                setSmeTechChoice('Zoom Meeting')
                setConfirmCC(false)
                setConfirmZoom(true)
                setConfirmPhone(false)
                setConfirmMessaging(false)
                break
            case 'phone':
                setSmeTechChoice('Phone Call')
                setConfirmCC(false)
                setConfirmZoom(false)
                setConfirmPhone(true)
                setConfirmMessaging(false)
                break
            case 'messaging':
                setSmeTechChoice('Messaging')
                setConfirmCC(false)
                setConfirmZoom(false)
                setConfirmPhone(false)
                setConfirmMessaging(true)
                break
            default:
                break
        }
    }

    const handleOpenInfo = () => {
        setShowInfo(true)
      }

    const handleCloseInfo = () => {
        setShowInfo(false)
    }

    const handleOpenConfirmation = () => {
        setShowConfirmation(true)
    }

    const handleCloseConfirmation = () => {
        // clear request map
        setMeetingRequest(new Map())
        // clear request status in Redux
        clearRequestStatus()
        setRequestingMeeting(false)
        setShowConfirmation(false)
    }

    const handleCloseMeetingDetails = () => {
        setOpenMeetingRequestDialog(false)
        clearAcceptStatus()
        setAcceptingMeeting(false)
    }

    const handleReject = (e) => {
        e.preventDefault()
        setOpenConfirmReject(true)
    }

    const handleRejectAction = (e) => {
        e.preventDefault()
        // finalize the rejection here with a user action
        const meeting = {
            docId: meetingRequestDetails.id,
            times: meetingRequestDetails.times,
            weekOf: docId,
            clientId: meetingRequestDetails.requestorId,
        }
        rejectMeetingRequest(meeting)
        setOpenConfirmReject(false)
        setOpenMeetingRequestDialog(false)
    }

    const handleAcceptAction = (e) => {
        e.preventDefault()
        clearAcceptStatus()
        // accept with a user action
        if (smeTechChoice === '') {
            // send out an alert!!
            setNoSelectionAlert(true)
        }
        else {
            const meeting = {
                times: meetingRequestDetails.times,
                userTimezone: moment.tz.guess(),
                clientTimezone: meetingRequestDetails.timezone,
                tech: smeTechChoice,
                agenda: meetingRequestDetails.agenda,
                clientId: meetingRequestDetails.requestorId,
                weekOf: docId,
                requestDocId: meetingRequestDetails.id,
                consultantName: ownerProfile.firstName + ' ' + ownerProfile.lastName,
            }
            setAcceptingMeeting(true)
            acceptMeetingRequest(meeting)
        }
    }

    const displayRequestTimeInfo = (details, type) => {

        const date = new Date(details.times[0])
        const dayOfWeek = moment(date).format('dddd')
        return `${moment(date).format('dddd, MMMM Do YYYY')} from ${timePeriod(type === 'scheduled' ? schedMapFactory(dayOfWeek) : reqMapFactory(dayOfWeek), details.times)} ${timezoneString(date)}`
    }

    const reqMapFactory = (_day) => {

        switch (_day) {
            case 'Monday':
                return mondayReqTimes
                break
            case 'Tuesday':
                return tuesdayReqTimes
                break
            case 'Wednesday':
                return wednesdayReqTimes
                break
            case 'Thursday':
                return thursdayReqTimes
                break
            case 'Friday':
                return fridayReqTimes
                break
            case 'Saturday':
                return saturdayReqTimes
                break
            case 'Sunday':
                return sundayReqTimes
                break
            default:
                return null
                break
        }
    }

    const schedMapFactory = (_day) => {

        switch (_day) {
            case 'Monday':
                return mondaySchedTimes
                break
            case 'Tuesday':
                return tuesdaySchedTimes
                break
            case 'Wednesday':
                return wednesdaySchedTimes
                break
            case 'Thursday':
                return thursdaySchedTimes
                break
            case 'Friday':
                return fridaySchedTimes
                break
            case 'Saturday':
                return saturdaySchedTimes
                break
            case 'Sunday':
                return sundaySchedTimes
                break
            default:
                return null
                break
        }
    }

    const dateFactory = (_day) => {

        switch (_day) {
            case 'Monday':
                return mon
                break
            case 'Tuesday':
                return tue
                break
            case 'Wednesday':
                return wed
                break
            case 'Thursday':
                return thu
                break
            case 'Friday':
                return fri
                break
            case 'Saturday':
                return sat
                break
            case 'Sunday':
                return sun
                break
            default:
                return null
                break
        }
    }

    const timezoneString = (_date) => {

        var abbrs = {
            EST : 'Eastern Standard Time',
            EDT : 'Eastern Daylight Time',
            CST : 'Central Standard Time',
            CDT : 'Central Daylight Time',
            MST : 'Mountain Standard Time',
            MDT : 'Mountain Daylight Time',
            PST : 'Pacific Standard Time',
            PDT : 'Pacific Daylight Time',
        };
        
        moment.fn.zoneName = function () {
            var abbr = this.zoneAbbr();
            return abbrs[abbr] || abbr;
        }

        if (_date) {
            const date = _date
            const dateArray = [date.getFullYear(), date.getMonth(), date.getDate()]
            const localZone = moment.tz.guess()
            if (moment.tz(dateArray, localZone) && moment.tz(dateArray, localZone).format('zz')) {
                return `${moment.tz(dateArray, localZone).format('zz')} (${moment.tz(dateArray, localZone).format('z')})`
            } else {
                return ''
            }
        }
    }

    const timePeriod = (_map, _times = null) => {

        const timesArray = []
        if (_times) {
            _times.map(time => {
                const date = new Date(time)
                const momDate = moment(date) 
                timesArray.push(timeOfDayFactory(momDate.format('hh:mm a')))
            })

            // sort times
            var sortedTimes = _times.map(time => {
                const date = new Date(time)
                return date.getTime()
            })
            sortedTimes.sort((a, b) => (a > b) ? 1 : -1)
            const startTime = new Date(sortedTimes[0])
            var endTime = new Date(sortedTimes[sortedTimes.length-1])
            const thirtyMin = 1800000
            endTime.setTime(endTime.getTime() + thirtyMin)
            const startTimeString = moment(startTime).format('LT')
            const endTimeString = moment(endTime).format('LT')

            return `${startTimeString} - ${endTimeString}`
        }

        
        var minTimeData = {hours: 24, minutes: 0}
        var maxTimeData = {hours: 0, minutes: 0}
        var minTimeString = ''
        var maxTimeString = ''
        for (let[time, day] of _map) {
            if (day) {
                if (!_times || timesArray.includes(time)) {
                    if (timesMap.get(time).hours < minTimeData.hours) {
                        minTimeData = timesMap.get(time)
                        minTimeString = time
                    }
                    else if (timesMap.get(time).hours === minTimeData.hours) {
                        if (timesMap.get(time).minutes < minTimeData.minutes) {
                            minTimeData = timesMap.get(time)
                            minTimeString = time
                        }
                    }

                    if (timesMap.get(time).hours > maxTimeData.hours) {
                        maxTimeData = timesMap.get(time)
                        maxTimeString = time
                    }
                    else if (timesMap.get(time).hours === maxTimeData.hours) {
                        if (timesMap.get(time).minutes > maxTimeData.minutes) {
                            maxTimeData = timesMap.get(time)
                            maxTimeString = time
                        }
                    }
                }
            }
        }

        // loop through time list, find current maxTime and set it to the next in the index
        for (var i = 0; i < times.length; i++) {
            if (times[i] === maxTimeString) {
                maxTimeString = times[i + 1]
                break
            }
        }

        return `${minTimeString} - ${maxTimeString}`
    }

    const displayRequestTimeInfo_OTZ = (_times, timezone) => { 
        // sort times
        var times = _times.map(time => {
            const date = new Date(time)
            return date.getTime()
        })
        times.sort((a, b) => (a > b) ? 1 : -1)

        const startTime = new Date(times[0])
        var endTime = new Date(times[times.length-1])
        const thirtyMin = 1800000
        endTime.setTime(endTime.getTime() + thirtyMin)
        const startTimeString = moment(startTime).tz(timezone).format('LT')
        const endTimeString = moment(endTime).tz(timezone).format('LT')
        const dayString = moment(startTime).tz(timezone).format('MMMM Do YYYY')
        const dateArray = [startTime.getFullYear(), startTime.getMonth(), startTime.getDate()]
        const timezoneString = moment.tz(dateArray, timezone).format('z')

        return `${dayString} from ${startTimeString} - ${endTimeString} (${timezoneString ? timezoneString : ''})`
    }

    const timeMeetsCriteria = (_day, _time) => {

        // check if this spot is available
        // throw a false if the time is in the exceptions maps
        

        // Let it pass if the time is in the Repeating times map (this is safe, because it wont get to this point if it is in the exceptions map)
        // Otherwise check if it is in the weekly map before rejecting


        var foundAdjacentSlot = false
        var emptyMap = true

        for (let[time, day] of meetingRequest) {
            if (day) {
                emptyMap = false
                // if there is a day and it isnt this day
                // this is important because it prevents the user from clicking 2 different days
                if (day !== _day) {
                    setDayAttempted(_day)
                    setDayRequested(day)
                    setShowWrongDayAlert(true)
                    return false
                }

                const _time_hours = timesMap.get(_time).hours
                const _time_minutes = timesMap.get(_time).minutes
                const timeHours = timesMap.get(time).hours
                const timeMinutes = timesMap.get(time).minutes

                // Check to see if there is an adjacent time in meetingRequest. We only need one. 
                if (_time_hours === timeHours) {
                    foundAdjacentSlot = true
                }
                else if (_time_hours - timeHours === 1) {
                    if (_time_minutes === 0 && timeMinutes === 30) {
                        foundAdjacentSlot = true
                    }
                }
                else if (_time_hours - timeHours === -1 ) {
                    if (_time_minutes === 30 && timeMinutes === 0) {
                        foundAdjacentSlot = true
                    }
                }
            }
        }
      
        if (foundAdjacentSlot || emptyMap) {
            setDateRequested(dateFactory(_day))
            return true
        }
        else {
            setShowDisjointAlert(true)
            return false
        }
    }

    // If a user removes a time that will cause a disjoint timeslot then this function will remove the later times so that it is no longer disjoint
    const preventDisjointTimes = (day, time) => {

        if (meetingRequest.get(time)) {

            if (!firstInList(day, time)) {
                for (let[_time, _checked] of meetingRequest) {
                    if (_checked) {
                        if (timesMap.get(_time).hours > timesMap.get(time).hours) {
                            setMeetingRequest(new Map(meetingRequest.set(_time, false)))
                        }
                        else if (timesMap.get(_time).hours === timesMap.get(time).hours) {
                            if (timesMap.get(time).minutes < timesMap.get(_time).minutes) {
                                setMeetingRequest(new Map(meetingRequest.set(_time, false)))
                            }
                        }
                    }   
                }
            }
        }
    }

    const firstInList = (day, time) => {

        for (let[_time, _checked] of meetingRequest) {
            if (_checked) {
                if (timesMap.get(_time).hours < timesMap.get(time).hours) {
                    return false
                } 
                else if (timesMap.get(_time).hours === timesMap.get(time).hours) {
                    if (timesMap.get(_time).minutes < timesMap.get(time).minutes) {
                        return false
                    }
                }
            }
        }
        return true
    }

    const handleRequest = (e) => {
        e.preventDefault()

        if (isMeetingTimesEmpty()) {
            // display info popup
            setShowInfo(true)
        }
        else {
            setShowConfirmation(true)
        }
    }

    const isMeetingTimesEmpty = () => {

        for (let[_time, _checked] of meetingRequest) {
            if (_checked) {
                return false
            }
        }
        return true
    }

    const handleSlotClick = (event, day, time, click) => {

        
        switch (day) {
            case "Monday":
                if (!isOwner) {
                    //Check that no other time slot is checked besides one immediately adjacent to this one
                    // Then add it to the map
                    // The calendar will be updated and the button to request meeting should be enabled
                    // check if the time is available and that it is not disjoint from any previously chosen times
                    if (!mondayExcepTimes.get(time) && !mondayReqTimes.get(time) && (mondayTimes.get(time) || mondayRepTimes.get(time)) && timeMeetsCriteria(day, time)) {
                        preventDisjointTimes(day, time)
                        setMeetingRequest(new Map(meetingRequest.set(time, meetingRequest.get(time) ? false : "Monday")))
                    }
                }
                else {
                    if (mondaySchedTimes.get(time)) {
                        // display details of meeting
                        setMeetingDetails(mondaySchedTimes.get(time))
                        setOpenMeetingDetailsDialog(true)
                    }
                    else if (mondayReqTimes.get(time)) {
                        setMeetingRequestDetails(mondayReqTimes.get(time))
                        setOpenMeetingRequestDialog(true)
                    }
                    else if (repeating) {
                        setMondayRepTimes(new Map(mondayRepTimes.set(time, !mondayRepTimes.get(time))))
                    } 
                    else if (exception) {
                        // make an exception to a weekly availability
                        // only allow this if the time slot is a repeating one
                        if (mondayRepTimes.get(time)) {
                            setMondayExcepTimes(new Map(mondayExcepTimes.set(time, !mondayExcepTimes.get(time))))
                        }
                    } 
                    else {
                        setMondayTimes(new Map(mondayTimes.set(time, !mondayTimes.get(time))))
                    }
                    if (click) {
                        saveChanges()
                    }
                }
                break
            case "Tuesday":
                if (!isOwner) {
                    if (!tuesdayExcepTimes.get(time) && !tuesdayReqTimes.get(time)  && (tuesdayTimes.get(time) || tuesdayRepTimes.get(time)) && timeMeetsCriteria(day, time)) {
                        preventDisjointTimes(day, time)
                        setMeetingRequest(new Map(meetingRequest.set(time, meetingRequest.get(time) ? false : "Tuesday")))
                    }
                }
                else {
                    if (tuesdaySchedTimes.get(time)) {
                        setMeetingDetails(tuesdaySchedTimes.get(time))
                        setOpenMeetingDetailsDialog(true)
                    }
                    else if (tuesdayReqTimes.get(time)) {
                        setMeetingRequestDetails(tuesdayReqTimes.get(time))
                        setOpenMeetingRequestDialog(true)
                    }
                    else if (repeating) {
                        setTuesdayRepTimes(new Map(tuesdayRepTimes.set(time, !tuesdayRepTimes.get(time))))
                    } 
                    else if (exception) {
                        // make an exception to a weekly availability
                        // only allow this if the time slot is a repeating one
                        if (tuesdayRepTimes.get(time)) {
                            setTuesdayEcxepTimes(new Map(tuesdayExcepTimes.set(time, !tuesdayExcepTimes.get(time))))
                        }
                    } 
                    else {
                        setTuesdayTimes(new Map(tuesdayTimes.set(time, !tuesdayTimes.get(time))))
                    }
                    if (click) {
                        saveChanges()
                    }
                }
                break
            case "Wednesday":
                if (!isOwner) {
                    if (!wednesdayExcepTimes.get(time) && !wednesdayReqTimes.get(time)  && (wednesdayTimes.get(time) || wednesdayRepTimes.get(time)) && timeMeetsCriteria(day, time)) {
                        preventDisjointTimes(day, time)
                        setMeetingRequest(new Map(meetingRequest.set(time, meetingRequest.get(time) ? false : "Wednesday")))
                    }
                }
                else {
                    if (wednesdaySchedTimes.get(time)) {
                        setMeetingDetails(wednesdaySchedTimes.get(time))
                        setOpenMeetingDetailsDialog(true)
                    }
                    else if (wednesdayReqTimes.get(time)) {
                        setMeetingRequestDetails(wednesdayReqTimes.get(time))
                        setOpenMeetingRequestDialog(true)
                    }
                    else if (repeating) {
                        setWednesdayRepTimes(new Map(wednesdayRepTimes.set(time, !wednesdayRepTimes.get(time))))
                    } 
                    else if (exception) {
                        // make an exception to a weekly availability
                        // only allow this if the time slot is a repeating one
                        if (wednesdayRepTimes.get(time)) {
                            setWednesdayExcepTimes(new Map(wednesdayExcepTimes.set(time, !wednesdayExcepTimes.get(time))))
                        }
                    } 
                    else {
                        setWednesdayTimes(new Map(wednesdayTimes.set(time, !wednesdayTimes.get(time))))
                    }
                    if (click) {
                        saveChanges()
                    }
                }
                break
            case "Thursday":
                if (!isOwner) {
                    if (!thursdayExcepTimes.get(time) && !thursdayReqTimes.get(time)  && (thursdayTimes.get(time) || thursdayRepTimes.get(time)) && timeMeetsCriteria(day, time)) {
                        preventDisjointTimes(day, time)
                        setMeetingRequest(new Map(meetingRequest.set(time, meetingRequest.get(time) ? false : "Thursday")))
                    }
                }
                else {
                    if (thursdaySchedTimes.get(time)) {
                        setMeetingDetails(thursdaySchedTimes.get(time))
                        setOpenMeetingDetailsDialog(true)
                    }
                    else if (thursdayReqTimes.get(time)) {
                        setMeetingRequestDetails(thursdayReqTimes.get(time))
                        setOpenMeetingRequestDialog(true)
                    }
                    else if (repeating) {
                        setThursdayRepTimes(new Map(thursdayRepTimes.set(time, !thursdayRepTimes.get(time))))
                    } 
                    else if (exception) {
                        // make an exception to a weekly availability
                        // only allow this if the time slot is a repeating one
                        if (thursdayRepTimes.get(time)) {
                            setThursdayExcepTimes(new Map(thursdayExcepTimes.set(time, !thursdayExcepTimes.get(time))))
                        }
                    } 
                    else {
                        setThursdayTimes(new Map(thursdayTimes.set(time, !thursdayTimes.get(time))))
                    }
                    if (click) {
                        saveChanges()
                    }
                }
                break
            case "Friday":
                if (!isOwner) {
                    if (!fridayExcepTimes.get(time) && !fridayReqTimes.get(time)  && (fridayTimes.get(time) || fridayRepTimes.get(time)) && timeMeetsCriteria(day, time)) {
                        preventDisjointTimes(day, time)
                        setMeetingRequest(new Map(meetingRequest.set(time, meetingRequest.get(time) ? false : "Friday")))
                    }
                }
                else {
                    if (fridaySchedTimes.get(time)) {
                        setMeetingDetails(fridaySchedTimes.get(time))
                        setOpenMeetingDetailsDialog(true)
                    }
                    else if (fridayReqTimes.get(time)) {
                        setMeetingRequestDetails(fridayReqTimes.get(time))
                        setOpenMeetingRequestDialog(true)
                    }
                    else if (repeating) {
                        setFridayRepTimes(new Map(fridayRepTimes.set(time, !fridayRepTimes.get(time))))
                    } 
                    else if (exception) {
                        // make an exception to a weekly availability
                        // only allow this if the time slot is a repeating one
                        if (fridayRepTimes.get(time)) {
                            setFridayExcepTimes(new Map(fridayExcepTimes.set(time, !fridayExcepTimes.get(time))))
                        }
                    } 
                    else {
                        setFridayTimes(new Map(fridayTimes.set(time, !fridayTimes.get(time))))
                    }
                    if (click) {
                        saveChanges()
                    }
                }
                break;
            case "Saturday":
                if (!isOwner) {
                    if (!saturdayExcepTimes.get(time) && !saturdayReqTimes.get(time)  && (saturdayTimes.get(time) || saturdayRepTimes.get(time)) && timeMeetsCriteria(day, time)) {
                        preventDisjointTimes(day, time)
                        setMeetingRequest(new Map(meetingRequest.set(time, meetingRequest.get(time) ? false : "Saturday")))
                    }
                }
                else {
                    if (saturdaySchedTimes.get(time)) {
                        setMeetingDetails(saturdaySchedTimes.get(time))
                        setOpenMeetingDetailsDialog(true)
                    }
                    else if (saturdayReqTimes.get(time)) {
                        setMeetingRequestDetails(saturdayReqTimes.get(time))
                        setOpenMeetingRequestDialog(true)
                    }
                    else if (repeating) {
                        setSaturdayRepTimes(new Map(saturdayRepTimes.set(time, !saturdayRepTimes.get(time))))
                    } 
                    else if (exception) {
                        // make an exception to a weekly availability
                        // only allow this if the time slot is a repeating one
                        if (saturdayRepTimes.get(time)) {
                            setSaturdayExcepTimes(new Map(saturdayExcepTimes.set(time, !saturdayExcepTimes.get(time))))
                        }
                    } 
                    else {
                        setSaturdayTimes(new Map(saturdayTimes.set(time, !saturdayTimes.get(time))))
                    }
                    if (click) {
                        saveChanges()
                    }
                }
                break
            case "Sunday":
                if (!isOwner) {
                    if (!sundayExcepTimes.get(time) && !sundayReqTimes.get(time)  && (sundayTimes.get(time) || sundayRepTimes.get(time)) && timeMeetsCriteria(day, time)) {
                        preventDisjointTimes(day, time)
                        setMeetingRequest(new Map(meetingRequest.set(time, meetingRequest.get(time) ? false : "Sunday")))
                    }
                }
                else {
                    if (sundaySchedTimes.get(time)) {
                        setMeetingDetails(sundaySchedTimes.get(time))
                        setOpenMeetingDetailsDialog(true)
                    }
                    else if (sundayReqTimes.get(time)) {
                        setMeetingRequestDetails(sundayReqTimes.get(time))
                        setOpenMeetingRequestDialog(true)
                    }
                    else if (repeating) {
                        setSundayRepTimes(new Map(sundayRepTimes.set(time, !sundayRepTimes.get(time))))
                    } 
                    else if (exception) {
                        // make an exception to a weekly availability
                        // only allow this if the time slot is a repeating one
                        if (sundayRepTimes.get(time)) {
                            setSundayExcepTimes(new Map(sundayExcepTimes.set(time, !sundayExcepTimes.get(time))))
                        }
                    } 
                    else {
                        setSundayTimes(new Map(sundayTimes.set(time, !sundayTimes.get(time))))
                    }
                    if (click) {
                        saveChanges()
                    }
                }
                break
            default:
                break
        }
    }

    var monMaster = []
    var tueMaster = []
    var wedMaster = []
    var thuMaster = []
    var friMaster = []
    var satMaster = []
    var sunMaster = []
    var masterList = [
        {schedMap: mondaySchedTimes, reqMap: mondayReqTimes, excepMap: mondayExcepTimes, repMap: mondayRepTimes, availMap: mondayTimes, list: monMaster, column: monColumn, dayString: 'Monday'},
        {schedMap: tuesdaySchedTimes, reqMap: tuesdayReqTimes, excepMap: tuesdayExcepTimes, repMap: tuesdayRepTimes, availMap: tuesdayTimes, list: tueMaster, column: tueColumn, dayString: 'Tuesday'},
        {schedMap: wednesdaySchedTimes, reqMap: wednesdayReqTimes, excepMap: wednesdayExcepTimes, repMap: wednesdayRepTimes, availMap: wednesdayTimes, list: wedMaster, column: wedColumn, dayString: 'Wednesday'},
        {schedMap: thursdaySchedTimes, reqMap: thursdayReqTimes, excepMap: thursdayExcepTimes, repMap: thursdayRepTimes, availMap: thursdayTimes, list: thuMaster, column: thurColumn, dayString: 'Thursday'},
        {schedMap: fridaySchedTimes, reqMap: fridayReqTimes, excepMap: fridayExcepTimes, repMap: fridayRepTimes, availMap: fridayTimes, list: friMaster, column: friColumn, dayString: 'Friday'},
        {schedMap: saturdaySchedTimes, reqMap: saturdayReqTimes, excepMap: saturdayExcepTimes, repMap: saturdayRepTimes, availMap: saturdayTimes, list: satMaster, column: satColumn, dayString: 'Saturday'},
        {schedMap: sundaySchedTimes, reqMap: sundayReqTimes, excepMap: sundayExcepTimes, repMap: sundayRepTimes, availMap: sundayTimes, list: sunMaster, column: sunColumn, dayString: 'Sunday'},
    ]


    if (mondayTimes && mondayExcepTimes && mondayRepTimes && mondayReqTimes && mondaySchedTimes && wednesdayTimes && wednesdayExcepTimes && wednesdayRepTimes && wednesdayReqTimes && wednesdaySchedTimes) {
        masterList.map(day => {
            times.map(time => {
                if (Boolean(day.schedMap.get(time))) {
                    day.list.push({type: 'scheduled', time: time, details: day.schedMap.get(time)})
                }
                else if (Boolean(day.reqMap.get(time))) {
                    day.list.push({type: 'requested', time: time, details: day.reqMap.get(time)})
                }
                else if (Boolean(day.excepMap.get(time))) {
                    day.list.push({type: 'exception', time: time, details: 'none'})
                }
                else if (meetingRequest.get(time) === day.dayString) {
                    day.list.push({type: 'selected', time: time, details: 'none'})
                }
                else if (Boolean(day.repMap.get(time))) {
                    day.list.push({type: 'repeating', time: time, details: 'none'})
                }
                else if (Boolean(day.availMap.get(time))) {
                    day.list.push({type: 'available', time: time, details: 'none'})
                }
                else {
                    day.list.push({type: 'blank', time: time, details: 'none'})
                }
            })
            for (var i = 0; i < day.list.length; i++) {
                const type = day.list[i].type
                const time = day.list[i].time
                const details = day.list[i].details

                if ((type === 'scheduled' || type === 'requested') && isOwner) {
                    const size = details.times.length
                    day.column.push(
                        <Appointment 
                            size={size}
                            details={details}
                            dayString={day.dayString}
                            handleSlotClick={handleSlotClick}
                            time={time}
                            type={type}
                            key={`${day.dayString} ${time}`}
                        />
                    )
                    i = i + size - 1
                }
                else {
                    day.column.push(
                        <TimeSlot 
                            isOwner={isOwner} 
                            type={type} 
                            time={time}
                            dayString={day.dayString}
                            handleDragStart={handleDragStart} 
                            handleSlotClick={handleSlotClick} 
                            handleDragEnd={handleDragEnd} 
                            key={`${day.dayString} ${time}`}
                        />
                    )
                }
            }
        })
    }

    for (const time in times) {
        if (time % 2 !== 0) {
            timeColumn.push(<Paper elevation={0} className={classes.timeMarker} key={time}>{times[time].substr(0, times[time].indexOf(':')) + ' ' + times[time].substr(times[time].length - 2, times[time].length)}</Paper>)
        } else {
            timeColumn.push(<Paper elevation={0} className={time !== '0' ? classes.timeMarker : classes.timeMarkerFirst} key={time}></Paper>)
        }
    }


    return (
        <div key={props.match.params.user_id} >
            {isOwner ? 
                null
            :
                <Paper elevation={0} className={classes.confirm}>
                    <Grid container direction='row' justify='center' alignContent='center'>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleRequest}
                        >
                            Request Appointment
                        </Button>
                    </Grid>
                </Paper>
            }
            <div className={classes.dateHeader}>
                <Grid container direction='row' justify='flex-end' alignItems='flex-start' >
                            <Paper square className={classes.timeHeader} key={"times"}>
                            </Paper>
                    <Grid item key={"Monday"} className={classes.column}>
                        <Grid container direction='column' className={classes.columnInside}>
                            <Paper variant='outlined' square className={classes.weekDay}>
                                <Grid container direction='column' justify='center' alignItems='center'>
                                    <Grid item>
                                        <h6><b>{"Mon"}</b></h6>
                                    </Grid>
                                    <Grid item>
                                        <p className={classes.number}>{mon.format('D')}</p>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>   
                    <Grid item key={"Tuesday"} className={classes.column}>
                        <Grid container direction='column' className={classes.columnInside}>
                            <Paper variant='outlined' square className={classes.weekDay}>
                                <Grid container direction='column' justify='center' alignItems='center'>
                                    <Grid item>
                                        <h6><b>{"Tue"}</b></h6>
                                    </Grid>
                                    <Grid item>
                                        <p className={classes.number}>{tue.format('D')}</p>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item key={"Wednesday"} className={classes.column}>
                        <Grid container direction='column' className={classes.columnInside}>
                            <Paper variant='outlined' square className={classes.weekDay}>
                                <Grid container direction='column' justify='center' alignItems='center'>
                                    <Grid item>
                                        <h6><b>{"Wed"}</b></h6>
                                    </Grid>
                                    <Grid item>
                                        <p className={classes.number}>{wed.format('D')}</p>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item key={"Thursday"} className={classes.column}>
                        <Grid container direction='column' className={classes.columnInside}>
                            <Paper variant='outlined' square className={classes.weekDay}>
                                <Grid container direction='column' justify='center' alignItems='center'>
                                    <Grid item>
                                        <h6><b>{"Thu"}</b></h6>
                                    </Grid>
                                    <Grid item>
                                        <p className={classes.number}>{thu.format('D')}</p>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item key={"Friday"} className={classes.column}>
                        <Grid container direction='column' className={classes.columnInside}>
                            <Paper variant='outlined' square className={classes.weekDay}>
                                <Grid container direction='column' justify='center' alignItems='center'>
                                    <Grid item>
                                        <h6><b>{"Fri"}</b></h6>
                                    </Grid>
                                    <Grid item>
                                        <p className={classes.number}>{fri.format('D')}</p>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item key={"Saturday"} className={classes.column}>
                        <Grid container direction='column' className={classes.columnInside}>
                            <Paper variant='outlined' square className={classes.weekDay}>
                                <Grid container direction='column' justify='center' alignItems='center'>
                                    <Grid item>
                                        <h6><b>{"Sat"}</b></h6>
                                    </Grid>
                                    <Grid item>
                                        <p className={classes.number}>{sat.format('D')}</p>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item key={"Sunday"} className={classes.column}>
                        <Grid container direction='column' className={classes.columnInside}>
                            <Paper variant='outlined' square className={classes.weekDay}>
                                <Grid container direction='column' justify='center' alignItems='center'>
                                    <Grid item>
                                        <h6><b>{"Sun"}</b></h6>
                                    </Grid>
                                    <Grid item>
                                        <p className={classes.number}>{sun.format('D')}</p>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid> 
            </div>
            <div className={classes.calendarParent}>
                <Paper square className={classes.calendarContainer}>
                    <Grid container direction='row' justify='flex-end' alignItems='flex-start' className={classes.container}>
                        <Grid item key={"times"} className={classes.timeColumn}>
                            <Grid container direction='column' className={classes.columnInside}>
                                {timeColumn}
                            </Grid>
                        </Grid>
                        <Grid item key={"Monday"} className={classes.column}>
                            <Grid container direction='column' className={classes.columnInside}>
                                {monColumn}
                            </Grid>
                        </Grid>
                        <Grid item key={"Tuesday"} className={classes.column}>
                            <Grid container direction='column' className={classes.columnInside}>
                                {tueColumn}
                            </Grid>
                        </Grid>
                        <Grid item key={"Wednesday"} className={classes.column}>
                            <Grid container direction='column' className={classes.columnInside}>
                                {wedColumn}
                            </Grid>
                        </Grid>
                        <Grid item key={"Thursday"} className={classes.column}>
                            <Grid container direction='column' className={classes.columnInside}>
                                {thurColumn}
                            </Grid>
                        </Grid>
                        <Grid item key={"Friday"} className={classes.column}>
                            <Grid container direction='column' className={classes.columnInside}>
                                {friColumn}
                            </Grid>
                        </Grid>
                        <Grid item key={"Saturday"} className={classes.column}>
                            <Grid container direction='column' className={classes.columnInside}>
                                {satColumn}
                            </Grid>
                        </Grid>
                        <Grid item key={"Sunday"} className={classes.column}>
                            <Grid container direction='column' className={classes.columnInside}>
                                {sunColumn}
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                key='wrong-day'
                open={showWrongDayAlert}
                onClose={() => setShowWrongDayAlert(false)}
                autoHideDuration={10000}
            >
                <Alert severity='warning'>
                    You may only request one oppointment at a time. Please remove your appointment for {dayRequested} before scheduling on {dayAttempted}.
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                key='disjoint'
                open={showDisjointAlert}
                onClose={() => setShowDisjointAlert(false)}
                autoHideDuration={10000}
            >
                <Alert severity='warning'>
                    Your requested appointment time must be continuous. Please select a time consecutive to your previously selected times. 
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                key='noAgenda'
                open={noAgendaAlert}
                onClose={() => setNoAgendaAlert(false)}
                autoHideDuration={10000}
            >
                <Alert severity='warning'>
                    Please enter an agenda for the meeting
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                key='noSelection'
                open={noSelectionAlert}
                onClose={() => setNoSelectionAlert(false)}
                autoHideDuration={10000}
            >
                {isOwner ? 
                    <Alert severity='warning'>
                        Please select a meeting technology
                    </Alert>
                :
                    <Alert severity='warning'>
                        Please select a preferred meeting technology
                    </Alert>
                }
            </Snackbar>
            <Dialog onClose={handleCloseInfo} aria-labelledby="customized-dialog-title" open={showInfo} disableScrollLock={true}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseInfo}>
                    Select a time slot
                </DialogTitle>
                <DialogContent dividers>
                <p className={classes.paragraph}>
                    If a time slot is green then you can select it to schedule a meeting at that time. 
                    Please be sure to select a time period large enough for your meeting. You can select as 
                    many time slots as you want but they must be consecutive and on the same day. 
                </p>
                <p className={classes.paragraph}><b>Don't see any available times?</b></p>
                <p className={classes.paragraph}>
                    Send this User a message to let them know you would like to have a meeting but don't see any availabilities. 
                    Check back again soon to make an appointment. 
                </p>
                <p className={classes.paragraph}><b>What do I do once I have selected a time?</b></p>
                <p className={classes.paragraph}>
                    Click this button again and you will be directed to set up the meeting!
                </p>
                </DialogContent>
                <DialogActions>
                <Button autoFocus onClick={handleCloseInfo} color="primary">
                    Ok
                </Button>
                </DialogActions>
            </Dialog>
            <Dialog 
                disableScrollLock={true}
                onClose={handleCloseConfirmation} 
                aria-labelledby="customized-dialog-title" 
                open={showConfirmation}
                maxWidth='sm'
                fullWidth
            >
                <DialogTitle id="customized-dialog-title" onClose={handleCloseConfirmation}>
                    Meeting Details
                </DialogTitle>
                <DialogContent dividers>
                <p className={classes.paragraph}><b>Time</b></p>
                <p className={classes.paragraphDetails}>
                    {`${dateRequested && moment(dateRequested).format('dddd, MMMM Do YYYY')} from ${timePeriod(meetingRequest)} ${timezoneString(dateRequested ? dateRequested.toDate() : null)}`}
                </p>
                <p className={classes.paragraph}><b>Consultant</b></p>
                <Grid container direction='row' justify='center' alignItems='center' spacing={1}>
                    <Grid item>
                        <UserAvatar userId={userId} />
                    </Grid>
                    <Grid item>
                        <Author link authorId={userId} textStyles={classes.username}/>
                    </Grid>
                </Grid>
                <div className={classes.divider}/>
                <p className={classes.paragraph}><b>Meeting preference</b></p>
                <FormGroup row>
                    <FormControlLabel
                        control={<Checkbox checked={zoom} onChange={handleChecked} name="zoom" color='primary'/>}
                        label="Video Meeting"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={phone} onChange={handleChecked} name="phone" color='primary'/>}
                        label="Phone Call"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={messaging} onChange={handleChecked} name="messaging" color='primary'/>}
                        label="Messaging"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={noPref} onChange={handleChecked} name="noPref" color='primary'/>}
                        label="No Preference"
                    />
                </FormGroup>
                <div className={classes.divider}/>
                <p className={classes.paragraph}><b>Agenda</b></p>
                <p className={classes.paragraph}>Include a brief description of what you would like to discuss with the consultant.</p>
                <TextField
                    id="agenda"
                    multiline
                    rows="8"
                    variant="outlined"
                    fullWidth
                    value={agenda}
                    onChange={handleTextIn}
                />
                </DialogContent>
                <DialogActions>
                {meetingRequestStatus ? 
                    <div style={{paddingRight: 15}}>
                        <Grid container direction='row' spacing={1} justify='flex-end'>
                            <Grid item>
                                {meetingRequestStatus && meetingRequestStatus === 'success' ?
                                    <CheckCircleIcon className={classes.successIcon} />
                                :
                                    <ErrorIcon color='error'/>
                                }
                            </Grid>
                            <Grid item>
                                {meetingRequestStatus && meetingRequestStatus === 'success' ?
                                    <p className={classes.paragraph}>Success!</p>
                                :
                                    <Fragment>
                                        <Grid container direction='column' justify='flex-end'>
                                            <p className={classes.paragraph}>Request failed, please try again</p>
                                            <Button autoFocus onClick={handleRequestMeeting} color="primary">
                                                Request Appointment
                                            </Button>
                                        </Grid>
                                    </Fragment>
                                }
                            </Grid>
                        </Grid>
                        {meetingRequestStatus && meetingRequestStatus === 'success' ?
                            <p className={classes.paragraph}>You will be notified when this appointment is confirmed.</p>
                        :
                            null
                        }
                    </div>
                :
                    (requestingMeeting ?
                        <div style={{paddingRight: 75}}>
                            <CircularProgress size={20} />
                        </div>
                    :
                        <Button autoFocus onClick={handleRequestMeeting} color="primary">
                            Request Appointment
                        </Button>
                    )
                }
                </DialogActions>
            </Dialog>
            <Dialog 
                disableScrollLock={true}
                onClose={handleCloseMeetingDetails} 
                aria-labelledby="customized-dialog-title" 
                open={openMeetingRequestDialog}
                maxWidth='sm'
                fullWidth
            >
                <DialogTitle id="customized-dialog-title" onClose={handleCloseMeetingDetails}>
                    Meeting Request Details
                </DialogTitle>
                <DialogContent dividers>
                <p className={classes.paragraph}><b>Time</b></p>
                <p className={classes.paragraphDetails}>
                    {meetingRequestDetails ? displayRequestTimeInfo(meetingRequestDetails, 'requested') : null}
                </p>
                <p className={classes.paragraph}><b>With</b></p>
                <Grid container direction='row' justify='center' alignItems='center' spacing={1}>
                    <Grid item>
                        <UserAvatar userId={meetingRequestDetails ? meetingRequestDetails.requestorId : null} />
                    </Grid>
                    <Grid item>
                        <Author link authorId={meetingRequestDetails ? meetingRequestDetails.requestorId : null} textStyles={classes.username}/>
                    </Grid>
                </Grid>
                <div className={classes.divider}/>
                <p className={classes.paragraph}><b>Clients technology preference</b></p>
                <p className={classes.paragraphDetails}>{meetingRequestDetails ? meetingRequestDetails.techPref == "Zoom Meeting" ? "Video Meeting" : meetingRequestDetails.techPref : null}</p>
                <div className={classes.divider}/>
                {meetingRequestDetails && meetingRequestDetails.techPref !== 'No Preference' ? 
                    <p className={classes.paragraph}><b>Accept the clients technology choice or choose another</b></p>
                :
                    <p className={classes.paragraph}><b>Please choose a meeting technology</b></p>
                }
                <FormGroup row>
                    {meetingRequestDetails && meetingRequestDetails.techPref !== 'No Preference' ?
                        <FormControlLabel
                            control={<Checkbox checked={confirmCC} onChange={handleConfirmChecked} name="client" color='primary'/>}
                            label="Accept Client Preference"
                        />
                    : 
                        null
                    }
                    <FormControlLabel
                        control={<Checkbox checked={confirmZoom} onChange={handleConfirmChecked} name="zoom" color='primary'/>}
                        label="Video Meeting"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={confirmPhone} onChange={handleConfirmChecked} name="phone" color='primary'/>}
                        label="Phone Call"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={confirmMessaging} onChange={handleConfirmChecked} name="messaging" color='primary'/>}
                        label="Messaging"
                    />
                </FormGroup>
                <div className={classes.divider}/>
                <p className={classes.paragraph}><b>Agenda</b></p>
                <p className={classes.agenda}>{meetingRequestDetails ? meetingRequestDetails.agenda : null}</p>
                </DialogContent>
                <DialogActions>
                {acceptMeetingStatus ? 
                    <div style={{paddingRight: 15}}>
                        <Grid container direction='row' spacing={1} justify='flex-end'>
                            <Grid item>
                                {acceptMeetingStatus && acceptMeetingStatus === 'success' ?
                                    <CheckCircleIcon className={classes.successIcon} />
                                :
                                    <ErrorIcon color='error'/>
                                }
                            </Grid>
                            <Grid item>
                                {acceptMeetingStatus && acceptMeetingStatus === 'success' ?
                                    <p className={classes.paragraph}>Success!</p>
                                :
                                    <Fragment>
                                        <Grid container direction='column' justify='flex-end'>
                                            <p className={classes.paragraph}>Failed to finalize, please try again</p>
                                            <Button autoFocus onClick={handleAcceptAction} color="primary">
                                                Try Again
                                            </Button>
                                        </Grid>
                                    </Fragment>
                                }
                            </Grid>
                        </Grid>
                        {acceptMeetingStatus && acceptMeetingStatus === 'success' ?
                            <GoogleButton clickHandler={handleAddEvent} meeting={meetingRequestDetails} />
                        :
                            null
                        }
                    </div>
                :
                    (acceptingMeeting ? 
                        <div style={{paddingRight: 75}}>
                            <CircularProgress size={20} />
                        </div>
                    :
                        <Fragment>
                            <Button autoFocus onClick={handleReject} color="secondary">
                                Reject
                            </Button>
                            <Button autoFocus onClick={handleAcceptAction} color="primary">
                                Accept
                            </Button>
                        </Fragment>
                    )
                }
                </DialogActions>
            </Dialog>
            <Dialog
                disableScrollLock={true}
                open={openConfirmReject}
                onClose={() => setOpenConfirmReject(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Reject this meeting?"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    This action cannot be undone. 
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => setOpenConfirmReject(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleRejectAction} color="primary" autoFocus>
                    Reject
                </Button>
                </DialogActions>
            </Dialog>
            <Dialog 
                disableScrollLock={true}
                onClose={() => setOpenMeetingDetailsDialog(false)} 
                aria-labelledby="details" 
                open={openMeetingDetailsDialog}
                maxWidth='sm'
                fullWidth
            >
                <DialogTitle id="customized-dialog-title" onClose={() => setOpenMeetingDetailsDialog(false)}>
                    Meeting Details
                </DialogTitle>
                <DialogContent dividers>
                <p className={classes.paragraph}><b>Time</b></p>
                <p className={classes.paragraphDetails}>
                    {meetingDetails ? displayRequestTimeInfo(meetingDetails, 'scheduled') : null}
                </p>
                {meetingDetails && meetingDetails.clientTimezone && meetingDetails.userTimezone && meetingDetails.clientTimezone !== meetingDetails.userTimezone ?
                    <Fragment>
                        <p className={classes.paragraphDetails}>and</p>
                        <Tooltip title={`Client's Time Zone`}>
                            <p className={classes.paragraphDetails}>
                                {meetingDetails ? displayRequestTimeInfo_OTZ(meetingDetails.times, meetingDetails.clientTimezone) : null}
                            </p>
                        </Tooltip>
                    </Fragment>
                :
                    null
                }
                <p className={classes.paragraph}><b>With</b></p>
                <Grid container direction='row' justify='center' alignItems='center' spacing={1}>
                    <Grid item>
                        <UserAvatar userId={meetingDetails ? meetingDetails.clientId : null} />
                    </Grid>
                    <Grid item>
                        <Author link authorId={meetingDetails ? meetingDetails.clientId : null} textStyles={classes.username}/>
                    </Grid>
                </Grid>
                <div className={classes.divider}/>
                <p className={classes.paragraph}><b>Meeting Platform</b></p>
                <p className={classes.paragraphDetails}>{meetingDetails ? meetingDetails.tech === "Zoom Meeting" ? "Video Meeting" : meetingDetails.tech : null}</p>
                <div className={classes.divider}/>
                <p className={classes.paragraph}><b>Agenda</b></p>
                <p className={classes.agenda}>{meetingDetails ? meetingDetails.agenda : null}</p>
                </DialogContent>
                <DialogActions>
                    <GoogleButton clickHandler={handleAddEvent} meeting={meetingDetails} />              
                </DialogActions>
            </Dialog>
        </div>
    )
}


const mapDispatchToProps = (dispatch) => {
    return {
      saveSchedule: (schedule) => dispatch(saveSchedule(schedule)),
      saveRepTimes: (repTimes) => dispatch(saveRepTimes(repTimes)),
      requestMeetingAction: (meeting) => dispatch(requestMeetingAction(meeting)),
      rejectMeetingRequest: (meeting) => dispatch(rejectMeetingRequest(meeting)),
      acceptMeetingRequest: (meeting) => dispatch(acceptMeetingRequest(meeting)),
      clearRequestStatus: () => dispatch(clearRequestStatus()),
      clearAcceptStatus: () => dispatch(clearAcceptStatus()),
    }
}

const mapStateToProps = (state) => {
    return {
        meetingRequestStatus: state.user.meetingRequestStatus,
        acceptMeetingStatus: state.user.acceptMeetingStatus,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Calendar))