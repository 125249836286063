import React,{ useState, Fragment } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Block from '@material-ui/icons/NotInterested'
import ToolTip from '@material-ui/core/ToolTip'
import DecryptedContent from './DecryptedContent'
import LinearProgress from '@material-ui/core/LinearProgress'


function DecryptedMessage({ encryptedText, uid, eThree, scrollToBottom, index, msgCount }) {
    const [message, setMessage] = useState(null)
    const [error, setError] = useState(null)

    // We must have these 3 props in order to render this component
    if (!eThree || !uid || !encryptedText) return <div />

    const decrypt = async () => {
        try {
        // Decrypt with Virgil Security
        // First get the msg authors public key
        const sender = uid
        // update this so that we dont look it up with every message, it is unnecessarry
        const publicKey = await eThree.findUsers(sender)
        // Decrypt text and ensure it was written by sender
        const decryptedText = await eThree.authDecrypt(encryptedText, publicKey)
  
        setMessage(decryptedText)
        setError(null)
  
        } catch(e) {
          setError(e.message)
        }
    }
    decrypt()
    // wait untill the message is decrypted before rendering the component
    if (!message) return (
        <Fragment>
            <Grid container direction='row' spacing={1} >
                <Grid item>
                    {error ? 
                        <ToolTip title='Failed to decrypt, this is either from resetting your private encryption key or not setting up security questions.'>
                            <Block fontSize='small' />
                        </ToolTip>
                    :
                        <LinearProgress color='secondary' />
                    }
                </Grid>
            </Grid>
        </Fragment>
    )

    return (
        <DecryptedContent message={message} scrollToBottom={scrollToBottom} index={index} msgCount={msgCount} />
    )
}

export default DecryptedMessage