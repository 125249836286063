/*eslint-disable*/
import React, {useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import red from '@material-ui/core/colors/red'
import PhoneInput from './PhoneInput'
import Input from '@material-ui/core/Input'
// @material-ui/icons
import Group from "@material-ui/icons/Group";
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";
import LockIcon from '@material-ui/icons/Lock';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import BusinessIcon from '@material-ui/icons/Business';
import Timeline from '@material-ui/icons/Timeline'
import Code from '@material-ui/icons/Code'
import Phone from '@material-ui/icons/PhoneIphone';
import World from '@material-ui/icons/Language';
// core components
import GridContainer from "../GridContainer.js";
import GridItem from "../GridItem.js";
import Button from "../Button.js";
import Card from "../Card.js";
import CardBody from "../CardBody.js";
import InfoArea from "../InfoArea.js";
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import {Link} from "react-router-dom";
import { withRouter } from 'react-router'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
// for signing up 
import {connect} from 'react-redux'
import { signUpBusiness, signOut, saveEThree } from '../../../Redux/Actions/authActions'

import signupPageStyle from "../../../Assets/signupPageStyle.js";
import backgroundPic from '../../../Assets/images/honey.jpg'


const useStyles = makeStyles(signupPageStyle);

const useStyles2 = makeStyles(theme => ({
  title: {
    textAlign: 'center',
    fontWeight: 400,
  },
  errorMsg: {
    color: red[500],
  },
  root: {
    backgroundColor: '#FFF',
    minHeight: '100vh',
    backgroundImage: `url(${backgroundPic})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
}))

function SignUpIndividualPage(props) {
  const { saveEThree, signOut, signUpBusiness, signupEmailError, signupPasswordError, ...rest } = props
  const classes = useStyles()
  const classes2 = useStyles2()
  // state for getting signup info
  const [email, setEmail] = useState('')
  const [url, setUrl] = useState('')
  const [companyName, setcompanyName] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(null)
  const [usernameError, setUsernameError] = useState(null)
  const [phoneError, setPhoneError] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const [uncheckedError, setUncheckedError] = useState(null)
  const [urlError, setUrlError] = useState(null)
  const [checkedTerms, setCheckedTerms] = useState(false)
  const [firstNameError, setFirstNameError] = useState(null)
  const [lastNameError, setLastNameError] = useState(null)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')

  const handleChange = (event) => {
    if (event.target.id === 'email') {
      setEmail(event.target.value)
    } else if (event.target.id === 'password') {
      setPassword(event.target.value)
      const potentialPW = event.target.value
      if (potentialPW.replace(/\s/g, '').length >= 10 && /[A-Z]/.test(potentialPW) && /[a-z]/.test(potentialPW) && /\d/.test(potentialPW) && /[^\w\s]/.test(potentialPW)) {
        setPasswordError(null)
      }
    } else if (event.target.id === 'companyName') {
      setcompanyName(event.target.value)
    } else if (event.target.id === 'firstName') {
      setFirstName(event.target.value)
      setFirstNameError(null)
    } else if (event.target.id === 'lastName') {
      setLastName(event.target.value)
      setLastNameError(null)
    } else if (event.target.id === 'phone') {
      setPhone(event.target.value)
      setPhoneError(null)
    } else if (event.target.id === 'url') {
      setUrl(event.target.value)
      setUrlError(null)
    }
  }

  const submit = (event) => { 
    event.preventDefault()
    // authenticate the user
    if (!firstName.replace(/\s/g, '').length) {
      setFirstNameError('Enter first name')
    }
    else if (!lastName.replace(/\s/g, '').length) {
      setLastNameError('Enter last name')
    }
    else if (!companyName.replace(/\s/g, '').length) {
      setUsernameError('Please enter company name')
    }
    else if (phone.length !== 14) {
      setPhoneError('Enter phone number')
    }
    else if (!url.replace(/\s/g, '').length) {
      setUrlError('Enter website url')
    }
    else if (password.replace(/\s/g, '').length < 10) {
      setPasswordError('Password must be at least 10 characters, contain at least one uppercase and one lowercase letter, one number, and one special character')
    }
    else if (!checkedTerms) {
      setUncheckedError('Please agree to the terms and conditions')
    }
    else {
      setUsernameError(null)
      const hasUpperCase = /[A-Z]/.test(password)
      const hasLowerCase = /[a-z]/.test(password)
      const hasNumber = /\d/.test(password)
      const hasSpecialChar =/[^\w\s]/.test(password)

      if (hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar) {
        const newUser = {
          email,
          password,
          firstName,
          lastName,
          companyName,
          phone,
          url,
        }
        signUpBusiness(newUser)
        .then(response => {
          response.user.sendEmailVerification()
            .then(() => {
              signOut().then(() => {
                props.history.push('/verify-email')
              })
            })
        })
      }
      else {
        setPasswordError('Password must be at least 10 characters, contain at least one uppercase and one lowercase letter, one number, and one special character')
      }
    }
  }


  const handleTermsCheck = event => {
    setCheckedTerms(event.target.checked)
    setUncheckedError(null)
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  })

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword )
  }

  const handleMouseDownPassword = event => {
    event.preventDefault();
  }


  return (
    <div className={classes2.root}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={10} md={10}>
            <Card className={classes.cardSignup}>
              <h2 className={classes2.title}>Request a License</h2>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={5} md={5}>
                  <InfoArea
                      className={classes.infoArea}
                      title="Develop Better Products"
                      description="This platform is designed to give businesses the help they need to develop the best products for the U.S. Military."
                      icon={Timeline}
                      iconColor="rose"
                    />
                    <InfoArea
                      className={classes.infoArea}
                      title="Talk to Experts"
                      description="Find a subject matter expert who will give you insight into what it would be like to use your product in the field. Don't guess anymore, find out what they actually need."
                      icon={Code}
                      iconColor="primary"
                    />
                    <InfoArea
                      className={classes.infoArea}
                      title="Customer Interviews"
                      description="Interview end users who will be using your product! Gain valuable insight to help you as a large Business or a Start Up. You need to know what the end user needs. This is the place to find out!"
                      icon={Group}
                      iconColor="info"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={5} md={5}>
                    <form className={classes.form}>
                    <Grid container spacing={2}>
                      <Grid item xs>
                      <div style={{paddingTop: 20, paddingBottom: 20}}>
                        <TextField
                          error={firstNameError ? true : false}
                          helperText={firstNameError ? firstNameError : null}
                          id='firstName'
                          onChange={handleChange}
                          value={firstName}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <AccountBoxIcon className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            placeholder: "First Name..."
                          }}
                        />
                      </div>
                      </Grid>
                      <Grid item xs>
                      <div style={{paddingTop: 20, paddingBottom: 20}}>
                        <TextField
                          error={lastNameError ? true : false}
                          helperText={lastNameError ? lastNameError : null}
                          id='lastName'
                          onChange={handleChange}
                          value={lastName}
                          fullWidth
                          inputProps={{
                            placeholder: "Last Name..."
                          }}
                        />  
                      </div>                
                      </Grid>                
                      </Grid>
                      <div style={{paddingTop: 20, paddingBottom: 20}}>
                        <TextField
                          error={usernameError ? true : false}
                          helperText={usernameError ? usernameError : null}
                          id='companyName'
                          onChange={handleChange}
                          value={companyName}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <BusinessIcon className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            placeholder: "Business Name...",
                            autoComplete: "off"
                          }}
                        />
                      </div>
                      <div style={{paddingTop: 20, paddingBottom: 20}}>
                        <InputLabel>Business phone...</InputLabel>
                        <Input
                          value={phone}
                          onChange={handleChange}
                          name="phone"
                          id="phone"
                          inputComponent={PhoneInput}
                          fullWidth
                          startAdornment={
                            <InputAdornment position="start">
                              <Phone />
                            </InputAdornment>
                          }
                          error={phoneError ? true : false}
                        />
                        <p className={classes2.errorMsg}>{phoneError ? phoneError : null}</p>
                      </div>
                      <div style={{paddingTop: 20, paddingBottom: 20}}>
                        <TextField
                          error={signupEmailError ? true : false}
                          helperText={signupEmailError ? signupEmailError : null}
                          id='email'
                          onChange={handleChange}
                          value={email}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Email className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            placeholder: "Business email..."
                          }}
                        />
                      </div>
                      <div style={{paddingTop: 20, paddingBottom: 20}}>
                        <TextField
                          error={urlError ? true : false}
                          helperText={urlError ? urlError : null}
                          id='url'
                          onChange={handleChange}
                          value={url}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <World className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            placeholder: "Website URL..."
                          }}
                        />
                      </div>
                      <div style={{paddingTop: 20, paddingBottom: 20}}>
                        <TextField
                          error={signupPasswordError ? true : passwordError ? true : false}
                          helperText={signupPasswordError ? signupPasswordError : passwordError ? passwordError : null}
                          id='password'
                          type={showPassword ? 'text' : 'password'}
                          onChange={handleChange}
                          value={password}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <LockIcon className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                            ),
                            placeholder: "Password...",
                            autoComplete: "off"
                          }}
                        />
                      </div>
                      <FormControlLabel
                        classes={{
                          label: classes.label
                        }}
                        control={
                          <Checkbox
                            onClick={handleTermsCheck}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                            checked={checkedTerms}
                          />
                        }
                        label={
                          <span>
                            I agree to the{" "}
                            <Link to='/terms' target='_blank'>terms and conditions</Link>
                            {" "}and <Link to='/privacy' target='_blank'>privacy policy</Link>.
                          </span>
                        }
                      />
                      <div className={classes.textCenter} style={{color: red[500]}}>
                        {uncheckedError ? uncheckedError : null}
                      </div>
                      <div className={classes.textCenter}>
                        <Button 
                          round 
                          color="pinterest" 
                          onClick={submit}
                        >
                          Get started
                        </Button>
                      </div>
                    </form>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    signupEmailError: state.auth.signupEmailError,
    signupPasswordError: state.auth.signupPasswordError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signUpBusiness: (newUser) => {return dispatch(signUpBusiness(newUser))},
    signOut: () => {return dispatch(signOut())},
    saveEThree: (eThree) => {dispatch(saveEThree(eThree))},
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUpIndividualPage))
