import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import CheckIcon from '@material-ui/icons/CheckCircle'
import green from '@material-ui/core/colors/green'
import Container from '@material-ui/core/Container'
import { Redirect, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
    background: {
        paddingTop: 100,
        backgroundColor: '#FFFFFF',
        minHeight: '100vh',
        paddingBottom: 100,
    },
    check: {
        fontSize: 140,
        color: green[700],
    },
    title: {
        fontWeight: 500,
    },
    subtitle: {
        fontWeight: 400,
    },
}))

function PremiumGrantedMessage(props) {
    const classes = useStyles()

    const profile = useSelector(state => state.firebase.profile)
    const auth = useSelector(state => state.firebase.auth)

    if (!profile || !auth) return <div />
    if (!profile.isLoaded || !auth.isLoaded) return <div />
    if (profile.license !== 'premium') return <Redirect to='/'/>

    return (
        <div className={classes.background}>
            <Container maxWidth='md'>
                <Grid container direction='column' justify='center' alignItems='center'>
                    <CheckIcon className={classes.check} />
                    <h3 className={classes.title}>Premium License Granted</h3>
                    <h4 className={classes.subtitle}>Congratulations! You now have the following priveleges on The User Community:</h4>
                    <Container maxWidth='sm'>
                        <ul>
                            <li><h4>Access to Premium forums which have a higher User engagement rate</h4></li>
                            <li><h4>Your name will appear with a "Premium" badge on forum posts</h4></li>
                            <li><h4>You have unlimited direct messages to end Users as well as access to their scheduler where you can request a meeting</h4></li>
                            <li><h4>Access to the "Users" page where you can find a subject matter expert</h4></li>
                        </ul>
                    </Container>
                    <h4 className={classes.subtitle}>Here are some tips on how to get the most out of your Premium license:</h4>
                    <Container maxWidth='sm'>
                        <ul>
                            <li><h4>Need feedback on an idea? Post a topic in the <Link to={`/forum`}>Forum</Link></h4></li>
                            <li><h4>Browse the <Link to={`/experts`}>Users</Link> page and connect with a certified end User</h4></li>
                            <li><h4>Send a message to a User to learn more about their expertise</h4></li>
                            <li><h4>Check out their scheduler to request a meeting</h4></li>
                        </ul>
                        <p>*Only Premium license holders and our certified end Users have access to Premium forums in order to keep your ideas secure (Be sure to check for the "Premium" badge on the forum)</p>
                        <p>*We are currently in Phase 1 of the platform launch which does not support payments. This feature will be here soon! Use this time to familiarize yourself with the platform and give feedback in the feedback forum</p>
                    </Container>
                </Grid>
            </Container>
        </div>
    )
}

export default PremiumGrantedMessage