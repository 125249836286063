/*eslint-disable*/
import React, {useState} from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import InputAdornment from "@material-ui/core/InputAdornment"
import Icon from "@material-ui/core/Icon"
import {connect} from 'react-redux'
import { signIn, signOut, saveEThree } from '../../../Redux/Actions/authActions'
// @material-ui/icons
import Email from "@material-ui/icons/Email"
import Face from "@material-ui/icons/Face"
import LockOpenIcon from '@material-ui/icons/LockOpen'
// core components
import GridContainer from "../GridContainer.js"
import GridItem from "../GridItem.js"
import Button from "../Button.js"
import Card from "../Card.js"
import CardBody from "../CardBody.js"
import CardHeader from "../CardHeader.js"
import CustomInput from "../CustomInput.js"
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import { Link } from "react-router-dom"
import grey from '@material-ui/core/colors/grey'
import TextField from '@material-ui/core/TextField'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton'
import { EThree } from '@virgilsecurity/e3kit-browser'
import firebase from 'firebase'
import { Redirect } from 'react-router-dom'
import { withRouter } from "react-router";

import loginPageStyle from "../../../Assets/loginPageStyle.js"

import image from '../../../Assets/images/helo3.jpg'

const useStyles = makeStyles(loginPageStyle)

const useStyles2 = makeStyles(theme => ({
  lostpw: {
    padding: theme.spacing(2)
  },
  link: { // gws 2/12/20
    textDecoration: 'none',
    color: grey[800],
    "&:hover": {
      color: grey[800],
      textDecoration: 'underline'
    },
    "&:visited": {
      color: grey[800]
    },
    "&:active": {
      color: grey[800]
    },
    "&:link": {
      color: grey[800]
    },
    "&:link:active": {
      color: grey[800]
    },
    "&:visited:active": {
      color: grey[800]
    },
  },
  disclaimer: {
    position: 'fixed',
    bottom: 10,
    right: 10,
    color: "#FFF",
    fontSize: 10,
    textAlign: 'center',
  },
}))

function LoginPage(props) {

  const {signIn, signOut, authEmailError, authPasswordError, saveEThree} = props
  React.useEffect(() => {
    window.scrollTo(0, 0)
    document.body.scrollTop = 0
  })
  const classes = useStyles()
  const classes2 = useStyles2()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  //  Callback to fetch virgil security token
  const getToken = firebase.functions().httpsCallable('getVirgilJwt');
  const initializeFunction = () => getToken().then(result => result.data.token)

  const handleChange = (event) => {
    if (event.target.id === 'email') {
      setEmail(event.target.value)
    } else if (event.target.id === 'password') {
      setPassword(event.target.value)
    }
  }

  const submit = (event) => {
    // authenticate the user
    event.preventDefault()

    const credentials = {
      email: email,
      password: password,
    }
    signIn(credentials).then(response => {
      //console.log('successfully logged in', response)
      if (!response.user.emailVerified) {
        response.user.sendEmailVerification().then(() => {
          signOut().then(() => {
            props.history.push('/verify-email')
          })
        })
      }
      else {
        // Initialize Virgil security
        EThree.initialize(initializeFunction).then(eThree => {
            //console.log('eThree success', eThree)
            saveEThree(eThree)
          })
      }
      }).catch(error => {
        //console.log('login error: ', error)
    })
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword )
  }

  const handleMouseDownPassword = event => {
    event.preventDefault();
  }

  return (
    <div>
      <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <form className={classes.form}>
                  <CardHeader
                    color='red'
                    signup
                    className={classes.cardHeader}
                  >
                    <h4 className={classes.cardTitle}>
                      Login
                    </h4>
                  </CardHeader>
                  <CardBody signup>
                    <div style={{paddingTop: 20, paddingBottom: 20}}>
                      <TextField
                        error={authEmailError ? true : false}
                        helperText={authEmailError ? authEmailError : null}
                        value={email}
                        id="email"
                        placeholder='Email...'
                        onChange={handleChange}
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div style={{paddingTop: 20, paddingBottom: 20}}>
                      <TextField
                        type={showPassword ? 'text' : 'password'}
                        error={authPasswordError ? true : false}
                        value={password}
                        helperText={authPasswordError ? authPasswordError : null}
                        id="password"
                        placeholder='Password...'
                        onChange={handleChange}
                        fullWidth
                        InputProps={{
                          startAdornment: ( 
                            <InputAdornment position="start">
                              <Icon className={classes.inputIconsColor}>
                                <LockOpenIcon />
                              </Icon>
                            </InputAdornment>
                          ),
                          endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                          ),
                        }}
                        autoComplete='off'
                      />
                    </div>
                  </CardBody>
                  <div className={classes.textCenter}>
                    <Button 
                      simple color="rose" 
                      size="lg"
                      onClick={submit}
                    >
                      Get Started
                    </Button>
                  </div>
                  <Grid container direction='row' justify='center' alignItems='center' spacing={3} className={classes2.lostpw}>
                    <Grid item>
                      <Link to='/forgot-pw' className={classes2.link}>
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to='/choose-registration' className={classes2.link}>
                        Create Account
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
          <p className={classes2.disclaimer}>The appearance of U.S. Department of Defense (DoD) visual information does not imply or constitute DoD endorsement</p>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authEmailError: state.auth.authEmailError,
    authPasswordError: state.auth.authPasswordError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (credentials) => {return dispatch(signIn(credentials))},
    signOut: () => {return dispatch(signOut())},
    saveEThree: (eThree) => {dispatch(saveEThree(eThree))},
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage))