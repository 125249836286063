import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
// nodejs library that concatenates classes
import classNames from "classnames";
// Material ui Components
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Favorite from "@material-ui/icons/Favorite";
import grey from '@material-ui/core/colors/grey';
import Paper from '@material-ui/core/Paper'
// Material Kit Components
import Footer from '../MaterialKitComponents/Footer'
import GridContainer from "../MaterialKitComponents/GridContainer.js";
import GridItem from '../MaterialKitComponents/GridItem'
import Button from '@material-ui/core/Button'
import MKButton from '../MaterialKitComponents/Button'
import Parallax from '../MaterialKitComponents/Parallax'
// Sections for this page
import SectionProduct from '../MaterialKitSections/SectionProduct'
import SectionUser from '../MaterialKitSections/SectionTeam'
import SectionWork from '../MaterialKitSections/SectionWork'
import SectionDev from '../MaterialKitSections/SectionLeft'
import SectionRAD from '../MaterialKitSections/SectionRAD'
// Styles
import landingPageStyle from '../../Assets/landingPageStyle'

import soldiers from '../../Assets/images/techshot.jpg'

const useStyles = makeStyles(landingPageStyle);

const useStyles2 = makeStyles(theme => ({
  learnMore: {
   color: grey[50], 
   borderColor: grey[50],
   marginLeft: 10,
  },
  signup: {
    backgroundColor: 'rgb(25,34,60)',
  },
}))

function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classes2 = useStyles2();


    return (
      <div style={{paddingBottom: 50}}>
        <Parallax image={soldiers} filter="dark">
        <div className={classes.container} >
            <GridItem xs={12} sm={6} md={6}>
              <h1 className={classes.title} style={{color: grey[50]}}>Bridging the Gap</h1>
              <h4 style={{color: grey[50], backgroundColor: 'rgba(0,0,0,0.1)'}}>
                The User Community is a platform that connects military end-users and 
                the defense innovation ecosystem that supports them. We believe that 
                incorporating feedback from Users is critical to creating high impact 
                products and technologies. Get the information you need when you need 
                it - virtual and at your fingertips.
              </h4>
              <br />
              <Link to='choose-registration'>
                <MKButton
                  color='danger'
                  size='lg'
                >
                  Sign up
                </MKButton>
              </Link>
              {/*
              <Link to='/about'>
                <Button
                  className={classes2.learnMore}
                  size="large"
                  variant='text'
                >
                  Learn More
                </Button>
              </Link>
              */}
            </GridItem>
        </div>
      </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <SectionProduct />
            <SectionUser />
            <SectionDev />
            <SectionRAD />
            <SectionWork />
          </div>
        </div>
        <Footer
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                {/*
                <ListItem className={classes.inlineBlock}>
                  <Link 
                    to="/about" 
                    className={classes.block}
                  >
                    about us
                  </Link>
                </ListItem>
                */}
                <ListItem className={classes.inlineBlock}>
                  <Link
                    to='/terms'
                    className={classes.block}
                  >
                    terms
                  </Link>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Link
                    to='/privacy'
                    className={classes.block}
                  >
                    privacy
                  </Link>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Link
                    to='/pricing'
                    className={classes.block}
                  >
                    Pricing
                  </Link>
                </ListItem>
              </List>
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()}, User Community
            </div>
          </div>
        }
      />
      </div>
    )
}
export default Landing