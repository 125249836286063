export const signIn = (credentials) => {

  return (dispatch, getState, {getFirebase}) => {
    const firebase = getFirebase();

    return new Promise(function(resolve, reject) {

      firebase.auth().signInWithEmailAndPassword(
        credentials.email,
        credentials.password
      ).then((response) => {
        resolve(response)
        dispatch({type: 'LOGIN_SUCCESS'})
      }).catch((error) => {
        reject(error)
        dispatch({type: 'LOGIN_ERROR', error})
      })
    })
  }
}

export const signOut = () => {
  return (dispatch, getState, {getFirebase}) => {
    const firebase = getFirebase()

    return new Promise(function(resolve, reject) {

      firebase.auth().signOut().then(() => {
        resolve()
        dispatch({type: 'SIGNOUT_SUCCESS'})
      }).catch((e) => {
        reject(e)
      })

    })
  }
}

export const signUp = (newUser) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firebase = getFirebase()
    const firestore = getFirestore() // this is for storing data about the user in firestore
    const batch = firestore.batch()

    return new Promise(function(resolve, reject) {
      firebase.auth().createUserWithEmailAndPassword(
        newUser.email,
        newUser.password
      ).then((response) => {
        resolve(response)
          var userRef = firestore.collection('users').doc(response.user.uid)
          batch.set(userRef, {
            username: newUser.username,
            profile: 'individual',
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            license: 'basic',
          })
          var emailRef = firestore.collection('email').doc(response.user.uid)
          batch.set(emailRef, {
            emails: [newUser.email]
          })
          batch.commit()
      .then(() => {
        dispatch({type: 'SIGNUP_SUCCESS'})
      }).catch((error) => {
        dispatch({type: 'SIGNUP_ERROR', error})
        reject(error)
      })
      }).catch((error) => {
        dispatch({type: 'SIGNUP_ERROR', error})
        reject(error)
      })
    }) 
  }
}

export const signUpBusiness = (newUser) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firebase = getFirebase()
    const firestore = getFirestore() // this is for storing data about the user in firestore
    const batch = firestore.batch()

    return new Promise(function(resolve, reject) {
      firebase.auth().createUserWithEmailAndPassword(
        newUser.email,
        newUser.password
      ).then((response) => {
        resolve(response)
        const notiRef = firestore.collection('admin_notification').doc()
        batch.set(notiRef, {
          'completeDate': firestore.FieldValue.serverTimestamp(),
          'type': 'newBusiness',
          'user': `${newUser.firstName} ${newUser.lastName}`,
          'unread': true,
        })
        const userRef = firestore.collection('users').doc(response.user.uid)
        batch.set(userRef, {
          username: `${newUser.firstName} ${newUser.lastName}`,
          profile: 'business',
          firstName: newUser.firstName,
          lastName: newUser.lastName,
          companyName: newUser.companyName,
          status: 'requested',
          phone: newUser.phone,
          url: newUser.url,
          license: 'basic',
        })
        var emailRef = firestore.collection('email').doc(response.user.uid)
        batch.set(emailRef, {
          emails: [newUser.email]
        })
        batch.commit()
      .then(() => {
        dispatch({type: 'SIGNUP_SUCCESS'})
      }).catch((error) => {
        dispatch({type: 'SIGNUP_ERROR', error})
        reject(error)
      })
      }).catch((error) => {
        dispatch({type: 'SIGNUP_ERROR', error})
        reject(error)
      })
    }) 
  }
}

export const resetPW = (email) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firebase = getFirebase()

    firebase.auth().sendPasswordResetEmail(
      email
    ).then(() => {
      dispatch({type: 'RESET_PW_EMAIL', email})
    }).catch((error) => {
      dispatch({type: 'RESET_PW_EMAIL_ERROR', error})
    })
  }
}

export const signUpSME = (newSME) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firebase = getFirebase()
    const firestore = getFirestore() // this is for storing data about the user in firestore
    const batch = firestore.batch()

    return new Promise(function(resolve, reject) {
      firebase.auth().createUserWithEmailAndPassword(
        newSME.email,
        newSME.password
      ).then((response) => {
        resolve(response)
        const appRef = firestore.collection('application').doc(response.user.uid)
        batch.set(appRef, {
          'approved': false,
          'denied': false,
          'applicationComplete': false,
        })
        const userRef = firestore.collection('users').doc(response.user.uid)
        batch.set(userRef, {
          username: newSME.username,
          firstName: newSME.firstName,
          lastName: newSME.lastName,
          profile: 'SME-applicant',
          license: 'basic',
        })
        var emailRef = firestore.collection('email').doc(response.user.uid)
        batch.set(emailRef, {
          emails: [newSME.email]
        })
        var privRef = firestore.collection('sme_private').doc(response.user.uid)
        batch.set(privRef, {
          init: true,
        })
        batch.commit()
        .then(() => {
          dispatch({type: 'SIGNUP_SME_SUCCESS'})
        }).catch((error) => {
          reject(error)
          dispatch({type: 'SIGNUP_SME_ERROR', error})
        })
      }).catch((error) => {
        reject(error)
        dispatch({type: 'SIGNUP_SME_ERROR', error})
      })
    })
  }
}

export const addAdmin = (userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const batch = firestore.batch()

    // Write premium license to database which will trigger cloud function to grant SME license
    const subRef = firestore.collection('subscription').doc(userId)
    batch.set(subRef, {
      'type': 'admin'
    })
    // change the users license to admin
    const grantedRef = firestore.collection('users').doc(userId)
    batch.update(grantedRef, {
      'license': 'admin',
    })
    // Commit the batch write
    batch.commit()
    .then(() => {
      dispatch({type: 'ADD_ADMIN', userId})
    }).catch((error) => {
      dispatch({type: 'ADD_ADMIN_ERROR', error})
    })
  }
}

export const addSME = (userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const batch = firestore.batch()

    // Write premium license to database which will trigger cloud function to grant SME license
    const subRef = firestore.collection('subscription').doc(userId)
    batch.set(subRef, {
      'type': 'SME'
    })
    // Send a notification to the user letting them know that they have been granted a license
    const notifRef = firestore.collection('users').doc(userId).collection('notification').doc()
    batch.set(notifRef, {
      'createdAt': firestore.FieldValue.serverTimestamp(),
      'type': 'SMEGranted',
      'unread': true
    })
    // change the users profile status to 'granted'
    const grantedRef = firestore.collection('users').doc(userId)
    batch.update(grantedRef, {
      'license': 'SME',
    })
    // Commit the batch write
    batch.commit()
    .then(() => {
      dispatch({type: 'ADD_SME', userId})
    }).catch((error) => {
      dispatch({type: 'ADD_SME_ERROR', error})
    })
  }
}

export const addPremium = (user) => {
  return (dispatch, getState, {getFirebase}) => {
      const firebase = getFirebase();
      // get cloud functions from firebase
      // create a reference to the cloud function I created called addSMERole
      const addPremiumUser = firebase.functions().httpsCallable('addPremiumUser')
      // call the cloud function
      addPremiumUser({ email: user.email }).then(result => {
        dispatch({type: 'ADD_PREMIUM', result})
      }).catch(error => {
        dispatch({type: 'ADD_PREMIUM_ERROR', error})
      })
  }
}

export const getAdmins = () => {

  return (dispatch, getState, {getFirebase}) => {
      const firebase = getFirebase();

      const getAdmins = firebase.functions().httpsCallable('getAdmins')
      getAdmins().then(result => {
          dispatch({type: 'GET_ADMINS', result})
      }).catch(error => {
          dispatch({type: 'GET_ADMINS_ERROR', error})
      })
  }
}

export const removeAdmin = (userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const batch = firestore.batch()

    // Write premium license to database which will trigger cloud function to grant basic license
    const subRef = firestore.collection('subscription').doc(userId)
    batch.set(subRef, {
      'type': 'basic'
    })
    // change the users license to basic
    const grantedRef = firestore.collection('users').doc(userId)
    batch.update(grantedRef, {
      'license': 'basic',
    })
    // Commit the batch write
    batch.commit()
    .then(() => {
      dispatch({type: 'REMOVE_ADMIN', userId})
    }).catch((error) => {
      dispatch({type: 'REMOVE_ADMIN_ERROR', error})
    })
  }
}

export const updateEmail = (emails) => {

  return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firestore = getFirestore()
      const auth = getState().firebase.auth

      firestore.collection('email')
              .doc(auth.uid)
              .update({
                'emails': emails 
              })
      .then(() => {
        dispatch({type: 'UPDATE_EMAIL', emails})
      })
      .catch(error => {
        dispatch({type: 'UPDATE_EMAIL_ERROR', error})
      }) 
  }
}

export const saveEThree = (eThree) => {
  return {type: 'SAVE_ETHREE', eThree}
}

export const virgilIncomplete = () => {

  return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firestore = getFirestore()
      const auth = getState().firebase.auth

      return new Promise(function(resolve, reject) {
        firestore.collection('users')
                .doc(auth.uid)
                .update({
                  'virgilComplete': false 
                })
        .then(() => {
          resolve()
          dispatch({type: 'RESET_VIRGIL'})
        })
        .catch(error => {
          reject(error)
          dispatch({type: 'RESET_VIRGIL_ERROR', error})
        }) 
    })
  }
}
