import React, { Component } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import {connect} from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import ProfilePage from '../Components/UserComponents/ProfilePage'
import UserProfilePage from '../Components/UserComponents/UserProfilePage'
import { withRouter, Redirect } from 'react-router-dom'
import ProfileSkeleton from '../Components/UserComponents/ProfileSkeleton'


function Profile(props) {

  const { auth, userProfile, token } = props
  const root = {
    textAlign: 'center',
    marginTop: 150,
  }
  const loader = {
    display: 'inlineBlock'
  }
  // wait until we recieve auth and profile to load the space 
  if (!auth.isLoaded || !userProfile) return <ProfileSkeleton />
  if (!auth.uid) return <Redirect to='/' />

  if (userProfile.profile === 'SME' || userProfile.profile === 'SME-applicant') {

    if (!token) return  <ProfileSkeleton />

    const premiumUser = token.claims.premium
    const admin = token.claims.admin
    const SME = token.claims.SME

    if (!premiumUser && !admin && !SME && auth.uid !== props.match.params.user_id) return <Redirect to='/premium-access' />

    return (
        <ProfilePage 
          authProps={auth}
          profileProps={userProfile}
          profileUserId={props.match.params.user_id}
        />
    )
  }
  else {
    return (
      <div style={{paddingTop: 100, paddingBottom: 100}}>
        <UserProfilePage
          authProps={auth}
          profileProps={userProfile}
          profileUserId={props.match.params.user_id}
        />
      </div>
    )
  }
}

export default withRouter(compose(
  firestoreConnect((props) => [
    { collection: 'users', doc: props.match.params.user_id },
  ]),
  connect((state, props) => ({
    userProfile: state.firestore.data.users && state.firestore.data.users[props.match.params.user_id],
    auth: state.firebase.auth,
    token: state.firebase.profile.token,
  }))
)(Profile))
