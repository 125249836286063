import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import VideoCallIcon from '@material-ui/icons/VideoCall'
import Tooltip from '@material-ui/core/Tooltip'

//***FIXME*** make this a protected route that only subscribed users can access*/

const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1),
    },
}))

function Session(props) {
    const classes = useStyles()

    return (
        <div style={{paddingTop: 115}}>
            <Container maxWidth = 'lg'>
                <h5>Forget about a calendar of available times, make that a future feature.</h5>
                <h5>Instead, give the business the ability to schedule a meeting by choosing a date and time.</h5>
                <h5>Send notification to the SME, when they accept, the meeting is scheduled.</h5>
            </Container>
        </div>
    )
}

export default Session