import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
    root: {
      //flexGrow: 1,
      marginTop: 30,
    },
}))

function ProfileSkeleton(props) {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Container maxWidth='md' spacing={2}>
                <Grid container direction='column' spacing={2}>
                    <Grid item>
                        <Skeleton animation='wave' height={400}/>
                    </Grid>
                    <Grid item>
                        <Skeleton animation='wave' height={100}/>  
                        <Skeleton animation='wave' height={50} />
                        <Skeleton animation='wave' height={50} />
                    </Grid>
                    <Grid item>
                        <Skeleton animation='wave' height={100}/>  
                        <Skeleton animation='wave' height={50} />
                        <Skeleton animation='wave' height={50} />
                    </Grid>
                    <Grid item>
                        <Skeleton animation='wave' height={100}/>  
                        <Skeleton animation='wave' height={50} />
                        <Skeleton animation='wave' height={50} />
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default ProfileSkeleton