import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'
import blue from '@material-ui/core/colors/blue'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Author from '../ForumComponents/Author'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import { 
  deactivateConversation, 
  minimizeConversation, 
  sendMessage, 
  sendPhotoMessage, 
  sendVideoMessage, 
  sendAttachmentMessage, 
  markRead, 
  deleteMessage 
} from '../../../Redux/Actions/chatActions'
import { connect } from 'react-redux'
import UserAvatar from '../UserComponents/UserAvatar'
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import moment from 'moment'
import PhotoOutlinedIcon from '@material-ui/icons/PhotoOutlined'
import VideoCallIcon from '@material-ui/icons/VideoCall'
import Tooltip from '@material-ui/core/Tooltip'
import Badge from '@material-ui/core/Badge'
import firebase from 'firebase'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import LinearProgress from '@material-ui/core/LinearProgress'
import Link from '@material-ui/core/Link'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Popover from '@material-ui/core/Popover'
import DecryptedMessage from './DecryptedMessage'
import { EThree } from '@virgilsecurity/e3kit-browser'
import { saveEThree } from '../../../Redux/Actions/authActions'
import MinimizeIcon from '@material-ui/icons/ExpandMore'
import MaximizeIcon from '@material-ui/icons/ExpandLess'
import useMediaQuery from '@material-ui/core/useMediaQuery'

/*
* 
* Known issues: (All fixed but #4)
* 1. glitch at the very top of message list  **Fixed** by adding an "isFirst" attribute to messages so that I know when there are no more messages to fetch
* 2. After sending more than 10 messages, the box scrolls to the halfway-point **Fixed** by making scroll location part of state and only going to midway if user is at top of scroll.
* 3. When sending a message, it pops to the top briefly before coming back to the bottom **Fixed** by using messages.slice().reverse() instead of sort
* 4. If no connection, sending results in a dissapearing message until signal returns. Must give a loading signal while message is waiting to be processed. 
*/

const useStyles = makeStyles({
    chatBox: {
      maxHeight: '70vh',
      width: 400,
      height: 500,
      marginBottom: 10,
    },
    smallScreenChatBox: {
      height: '100vh',
      width: '100vw',
    },
    header: {
      width: 400,
      height: 50,
      backgroundColor: grey[800],
    },
    smallScreenHeader: {
      height: '10vh',
      width: '100vw',
      backgroundColor: grey[800],
    },
    minHeader: {
      width: 400,
      height: 50,
      backgroundColor: grey[800],
    },
    smallScreenMinHeader: {
      width: '100vw',
      height: 50,
      backgroundColor: grey[800],
    },
    loadingHeader: {
      width: 200,
      height: 50,
      backgroundColor: grey[800],
    },
    smallScreenLoadingHeader: {
      width: 200,
      height: 50,
      backgroundColor: grey[800],
    },
    closeIcon: {
      color: grey[100],
    },
    messageArea: {
      width: 400,
      height: 300,
    },
    smallScreenMessageArea: {
      width: '100vw',
      height: '60vh',
    },
    fetchPaper: {
      width: 400,
      height: 30,
      backgroundColor: grey[50],
    },
    lastMsgPaper: {
      width: 400,
      backgroundColor: grey[200],
      padding: 10,
    },
    messageList: {
      width: 400,
      height: 300,
      overflow: 'auto',
      backgroundColor: grey[50]
    },
    smallScreenMessageList: {
      width: '100vw',
      height: '60vh',
      overflow: 'auto',
      backgroundColor: grey[50]
    },
    messageBubbleSelf: {
      padding: 5,
      maxWidth: 300,
      backgroundColor: blue[100],
    },
    messageBubbleOther: {
      padding: 5,
      maxWidth: 300,
      backgroundColor: grey[200],
    },
    inputArea: {
      height: 150,
      width: 400,
      backgroundColor: grey[50],
    },
    inputAreaSmallScreen: {
      height: '30vh',
      width: '100vw',
      maxHeight: 150,
      backgroundColor: grey[50],
    },
    input: {
      width: 300,
    },
    link: {
      textDecoration: 'none',
      color: grey[100],
      "&:hover": {
        color: grey[100],
        textDecoration: 'underline'
      },
      "&:visited": {
        color: grey[100]
      },
      "&:active": {
        color: grey[100]
      },
      "&:link": {
        color: grey[100]
      },
      "&:link:active": {
        color: grey[100]
      },
      "&:visited:active": {
        color: grey[100]
      },
    },
    image: {
      maxHeight: 90,
      maxWidth: 200,
      objectFit: 'contain',
    },
    imageList: {
      height: 90,
      width: 250,
      overflow: 'auto',
    },
    attachmentList: {
      height: 90,
      width: 350,
      overflow: 'auto',
    },
    imageCloseIcon: {
      fontSize: 12,
    },
    imageMessageBubbleSelf: {
      padding: 5,
      backgroundColor: blue[100],
    },
    attachmentMessageBubbleSelf: {
      padding: 5,
      backgroundColor: blue[100],
      maxWidth: 300,
    },
    imageMessageBubbleOther: {
      padding: 5,
      backgroundColor: grey[200],
    },
    attachmentMessageBubbleOther: {
      padding: 5,
      backgroundColor: grey[200],
      maxWidth: 300,
    },
    imageMessage: {
      maxHeight: 250,
      maxWidth: 200,
      objectFit: 'contain',
    },
  });

function ChatBox(
  { 
    chatId, 
    userId, 
    deactivateConversation, 
    minimizeConversation, 
    sendMessage, 
    sendPhotoMessage, 
    sendAttachmentMessage, 
    sendVideoMessage, 
    deleteMessage, 
    markRead, 
    saveEThree 
  }
  ) {
    const classes = useStyles();
    const messagesEndRef = useRef(null)
    var lastMessageRef = useRef(null)
    const [message, setMessage] = useState('')
    const [scrollPosition, setScrollPosition] = useState(null)
    const [messagesToFetch, setMessagesToFetch] = useState(10)
    const [photos, setPhotos] = useState([])
    const [files, setFiles] = useState([])
    const [video, setVideo] = useState(null)
    const [loadingVideo, setLoadingVideo] = useState(false)
    const [loadProgress, setLoadProgress] = useState(0)
    const [videoInputKey, setVideoInputKey] = useState(0)
    const [popoverAnchor, setPopoverAnchor] = useState(null)
    const [showDetailsFor, setShowDetailsFor] = useState(null)

    const smallScreen = useMediaQuery('(max-width:700px)')

    const scrollToBottom = () => {
      messagesEndRef.current.scrollIntoView({ behavior: 'auto'})
    }

    const scrollToLast = () => {
      lastMessageRef.current.scrollIntoView({ behavior: 'auto'})
    }

    const closeChat = (event) => {
      event.preventDefault()
      const user = {
        id: chatId
      }
      deactivateConversation(user)
    }

    const handleChange = (event) => {
      setMessage(event.target.value)
    }

    const handlePhotoClick = (photoUrl) => {
      window.open(photoUrl, '_blank')
    }

    const handleMsgDelete = (event, anchor) => {
      event.preventDefault()
      setPopoverAnchor(null)
      const message = {
        chatId,
        docId: anchor.id,
      }
      deleteMessage(message)
    }

    const handleMessageClick = (event) => {
      setPopoverAnchor(event.currentTarget)
    }

    const handleMouseEnterMsg = (msgId) => {
      setShowDetailsFor(msgId)
    }

    const handleMouseLeaveMsg = () => {
      if (!Boolean(popoverAnchor)) {
        setShowDetailsFor(null)
      }
    }

    const handleMessagePopoverClose = () => {
      setPopoverAnchor(null)
    }

    const handleMinimize = (min) => {
      const data = {
        chatId,
        minimize: !min,
      }
      minimizeConversation(data)
    }

    const openMessagePopover = Boolean(popoverAnchor)

    const handlePhotoInput = (event) => {

      var images = []
      Array.from(event.target.files).map(file => {
        images.push({ url: URL.createObjectURL(file), file: file })
      })
      setPhotos(photos => photos.concat(images))
      setVideo(null)
      setFiles([])
    }

    const handleRemoveImage = (sourceUrl) => {
      const newUrls = photos.filter((value, index, arr) => {return value.url !== sourceUrl})
      setPhotos(newUrls)
    }
    
    const handleRemoveVideo = () => {
      setVideo(null)
      setVideoInputKey(new Date())
    }

    const handleRemoveAttachment = (file) => {
      const newFiles = files.filter((value, index, arr) => {return value.url !== file.url})
      setFiles(newFiles)
    }

    const handleVideoInput = (event) => {
      const file = event.target.files[0]
      setVideo({ url: URL.createObjectURL(file), file: file })
      setPhotos([])
      setFiles([])
    }

    const handleFileInput = (event) => {
      var filesList = []
      Array.from(event.target.files).map(file => {
        filesList.push({ url: URL.createObjectURL(file), file: file, name: file.name })
      })
      setFiles(files => files.concat(filesList))
      setVideo(null)
      setPhotos([])
    }
 
    const handleSendMessage = (event, numMessages) => {
      event.preventDefault()
      
      if (photos.length > 0) {
        // sending photos
        photos.map(photo => {
          const storageRef = firebase.storage().ref().child(`message_photos/${userId}/${chatId}/${photo.file}-${window.performance.now() ? window.performance.now() : Date.now()}`)
          storageRef.put(photo.file).then(snap => {
            storageRef.getDownloadURL().then(url => {
              const photoMessage = {
                url, 
                id: chatId,
                isFirst: numMessages === 0 ? true : false,
              }
              sendPhotoMessage(photoMessage)
            }).catch(e => {
              console.error(e)
            })
          }).catch(e => {
            console.error(e)
          })
          handleRemoveImage(photo.url)
        })
        setPhotos([])
      }
      else if (video) {
        const storageRef = firebase.storage().ref().child(`message_videos/${userId}/${chatId}/${video.file}-${window.performance.now() ? window.performance.now() : Date.now()}`)
        var uploadTask = storageRef.put(video.file)
        setLoadingVideo(true)
        uploadTask.on('state_changed', function(snapshot){
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //console.log('Upload is ' + progress + '% done');
          setLoadProgress(progress)
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              //console.log('Upload is paused');
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              //console.log('Upload is running');
              break;
          }
        }, function(error) {
          // Handle unsuccessful uploads
        }, function() {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then(function(url) {
            setVideo(null)
            setLoadingVideo(false)
            //console.log('File available at', url);
            const videoMessage = {
              url,
              id: chatId,
              isFirst: numMessages === 0 ? true : false,
            }
            sendVideoMessage(videoMessage)
          });
        });
        
      }
      else if (files.length > 0) {
        files.map(file => {
          const storageRef = firebase.storage().ref().child(`message_attachments/${userId}/${chatId}/${file.name}-${window.performance.now() ? window.performance.now() : Date.now()}`)
          storageRef.put(file.file).then(snap => {
            storageRef.getDownloadURL().then(url => {
              const attachmentMessage = {
                url, 
                filename: file.name,
                id: chatId,
                isFirst: numMessages === 0 ? true : false,
              }
              sendAttachmentMessage(attachmentMessage)
            }).catch(e => {
              console.error(e)
            })
          }).catch(e => {
            console.error(e)
          })
        })
        setFiles([])
      }
      else if (message !== '') {

        (async () => {
          // Encrypt message with Virgil Security
          // Declare recipients (automatically includes sender as recipient aka use [] for only recipient)
          const recipients = [chatId]
          // get the public keys of recipients
          const publicKeys = await eThree.findUsers(recipients)
          // Encrypt the text using recipents public keys
          const encryptedText = await eThree.authEncrypt(message, publicKeys)
          const messageProp = {
            id: chatId,
            content: encryptedText,
            isFirst: numMessages === 0 ? true : false,
          }
          sendMessage(messageProp)
          setMessage('')
        // end of async
        })();

      }
      scrollToBottom()
    }

    const handleKeyPress = (event, numMessages) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        handleSendMessage(event, numMessages)
      }
    }

    const handleScroll = (event) => {
      setScrollPosition(event.target.scrollTop)
      if (event.target.scrollTop < 10 && !messages[messages.length-1].isFirst) {
        setMessagesToFetch(messages.length + 10)
      }
    }

    const eThree = useSelector(state => state.auth.eThree)

    useFirestoreConnect([
      { collection: 'users', 
        doc: userId,
        subcollections: [{ collection: 'conversations', 
                           doc: chatId,
                        }],
        storeAs: `${chatId}-chat-data`
      }
    ])
    const chatData = useSelector(state => state.firestore.ordered[`${chatId}-chat-data`])
    
    useFirestoreConnect([
        { collection: 'users', 
          doc: userId,
          subcollections: [{ collection: 'conversations', 
                             doc: chatId,
                             subcollections: [{collection: 'messages', 
                                                orderBy: [
                                                  ['timeSent', 'desc']
                                                ],
                                                limit: messagesToFetch,
                                              }]
                          }],
          storeAs: `${chatId}-messages`
        }
      ])
      const messages = useSelector(state => state.firestore.ordered[`${chatId}-messages`])
      
      useEffect(() => {
        if (!messages || !eThree || !chatData) {
          //wait
        } 
        else if (messages.length > 10 && scrollPosition < 10) {
          if (!chatData[0]) {
            //wait
          }
          else if (chatData[0].minimize === true) {
            //wait
          }
          else {
            scrollToLast()
          }
        }
        else if (messages.length <= 10) {
          if (!chatData[0]) {
            //wait
          }
          else if (chatData[0].minimize === true) {
            //wait
          }
          else {
            scrollToBottom()
          }
        }
      }, [messages, eThree, chatData])

     if (!messages || !chatData || !chatData[0]) {
         return (
        <Paper className={smallScreen ? classes.smallScreenLoadingHeader : classes.loadingHeader}>
            <Grid container direction='row' justify='center' alignItems='center'>
              <CircularProgress />
            </Grid>
        </Paper>
         )
      }

    //  Callback to fetch virgil security token
    const getToken = firebase.functions().httpsCallable('getVirgilJwt');
    const initializeFunction = () => getToken().then(result => result.data.token)

    if (!eThree) {
      // Reload 
      EThree.initialize(initializeFunction).then(eThree => {
        // initialize done
        saveEThree(eThree)
      })
      return (
        <Paper className={smallScreen ? classes.smallScreenChatBox : classes.chatBox} elevation={4}>
           <Paper className={smallScreen ? classes.smallScreenHeader : classes.header} elevation={1}>
             <Grid style={{height: '100%'}} container direction='row' justify='flex-end' alignItems='center'>
               <IconButton size='small' style={{marginRight: 10}} onClick={closeChat}>
                 <CloseIcon fontSize='small' className={classes.closeIcon} />
               </IconButton>
             </Grid>
           </Paper>
           <Grid style={{height: '80%'}} container direction='column' justify='center' alignItems='center'>
             <h3>Configuring encryption</h3>
             <CircularProgress />
           </Grid>
        </Paper>
      )
    }
    
    if (chatData[0].minimize) {
      return (
        <Paper className={smallScreen ? classes.smallScreenMinHeader : classes.minHeader}>
          <Grid container direction='row' justify='space-between' alignItems='center'>
              <Grid item>
                <Grid container direction='row' justify='flex-start' alignItems='center' >
                  <Grid item>
                    <div style={{padding: 5}}>
                      <Badge color='error' variant='dot' invisible={!chatData[0].unread} overlap="circle">
                        <UserAvatar userId={chatId} responsive />
                      </Badge>
                    </div>
                  </Grid>
                  <Grid item>
                    <h5 style={{color: grey[100], paddingLeft: 10}}><Author authorId={chatId} link={true} styles={classes.link}/></h5>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                {smallScreen ? null :
                  <IconButton size='small' onClick={() => handleMinimize(chatData[0].minimize)}>
                    {chatData[0].minimize ? <MaximizeIcon className={classes.closeIcon} /> : <MinimizeIcon className={classes.closeIcon} />}
                  </IconButton>
                }
                <IconButton size='small' style={{marginRight: 10}} onClick={closeChat}>
                  <CloseIcon fontSize='small' className={classes.closeIcon} />
                </IconButton>
              </Grid>
            </Grid>
        </Paper>
      )
    }
      // Mark read
      markRead(chatId)

      return (
        <Paper className={smallScreen ? classes.smallScreenChatBox : classes.chatBox} elevation={2}>
          <Paper className={smallScreen ? classes.smallScreenHeader : classes.header} elevation={1}>
            <Grid container direction='row' justify='space-between' alignItems='center'>
              <Grid item>
                <Grid container direction='row' justify='flex-start' alignItems='center' >
                  <Grid item>
                    <div style={{padding: 5}}>
                      <UserAvatar userId={chatId} responsive />
                    </div>
                  </Grid>
                  <Grid item>
                    <h5 style={{color: grey[100], paddingLeft: 10}}><Author authorId={chatId} link={true} styles={classes.link}/></h5>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                {smallScreen ? null :
                  <IconButton size='small' onClick={() => handleMinimize(chatData[0].minimize)}>
                    {chatData[0].minimize ? <MaximizeIcon className={classes.closeIcon} /> : <MinimizeIcon className={classes.closeIcon} />}
                  </IconButton>
                }
                <IconButton size='small' style={{marginRight: 10}} onClick={closeChat}>
                  <CloseIcon fontSize='small' className={classes.closeIcon} />
                </IconButton>
              </Grid>
            </Grid>
            <Paper className={smallScreen ? classes.smallScreenMessageArea : classes.messageArea}>
              <List className={smallScreen ? classes.smallScreenMessageList : classes.messageList} onScroll={handleScroll}>
                  <ListItem>
                    {messages.length === 0 ?
                      null
                    :
                      <Fragment>
                        {messages[messages.length-1].isFirst ? 
                          <Fragment>
                            <Paper className={classes.lastMsgPaper} elevation={0} >
                              <Grid container direction='column' justify='center' alignItems='center' spacing={1}>
                                <Grid item>
                                  <Grid container direction='row' justify='center' alignItems='center' spacing={1}>
                                    <Grid item>
                                      <UserAvatar userId={chatId} responsive />
                                    </Grid>
                                    <Grid item>
                                      <h5><Author authorId={chatId} link={true} /></h5>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  {messages[messages.length-1].timeSent && moment(messages[messages.length-1].timeSent.toDate().toString()).format('L')}
                                </Grid>
                              </Grid>
                            </Paper>
                            <Divider />
                          </Fragment>
                        :
                          <Paper className={classes.fetchPaper} elevation={0} >
                            <Grid container direction='row' justify='center' alignItems='center' >
                              <Grid item>
                                <CircularProgress variant="indeterminate" size={24} thickness={4}/>
                              </Grid>
                            </Grid>
                          </Paper>
                        }
                      </Fragment>
                    }
                  </ListItem>      
                {messages.slice().reverse().map((message, index) => (
                  <div key={message.id} ref={ (messages.length % 10 == 0) ? (index == 9 ? lastMessageRef : null) : (index == ((messages.length % 10) - 1) ? lastMessageRef : null) } >
                    <ListItem key={message.id}>
                      {message.authorIsSelf ? 
                        <Grid container direction='row' justify='flex-end' alignItems='center'>
                          {message.id === showDetailsFor ?
                            <Tooltip title='More' placement='top'>
                              <span>
                                <IconButton 
                                  size='small' 
                                  id={message.id} 
                                  onClick={handleMessageClick} 
                                  disabled={message.id !== showDetailsFor}
                                  onMouseEnter={() => handleMouseEnterMsg(message.id)} 
                                  onMouseLeave={handleMouseLeaveMsg}
                                >
                                  {message.id === showDetailsFor ? <MoreVertIcon fontSize='small'  color='disabled'/> : null }
                                </IconButton>
                              </span>
                            </Tooltip>
                          :
                            null
                          }
                          <Grid item>
                            {message.message ? 
                              <Paper className={classes.messageBubbleSelf} onMouseEnter={() => handleMouseEnterMsg(message.id)} onMouseLeave={handleMouseLeaveMsg}>
                                <DecryptedMessage 
                                  encryptedText={message.message} 
                                  uid={userId} eThree={eThree} 
                                  scrollToBottom={scrollToBottom} 
                                  index={index}
                                  msgCount={messages.length - 1}
                                />
                              </Paper>
                            :
                              <Fragment>
                                {message.photoUrl?
                                  <Paper className={classes.imageMessageBubbleSelf}>
                                    <img src={message.photoUrl} className={classes.imageMessage} onClick={() => handlePhotoClick(message.photoUrl)}/>
                                  </Paper>
                                :
                                  <Fragment>
                                    {message.attachmentUrl ? 
                                      <Paper className={classes.attachmentMessageBubbleSelf}>
                                        <Link href={message.attachmentUrl} target="_blank" rel="noopener">
                                          {message.filename}
                                        </Link>
                                      </Paper>
                                    :
                                      <video width="250" height="200" controls>
                                        <source src={message.videoUrl} type="video/mp4"/>
                                        <source src={message.videoUrl} type="video/ogg"/>
                                        Your browser does not support the video tag.
                                      </video>
                                    }
                                  </Fragment>
                                }
                              </Fragment>
                            }
                          </Grid>
                        </Grid>
                      :
                        <Grid container direction='row' justify='flex-start' alignItems='center'>
                          <Grid item>
                            {message.message ? 
                              <Paper className={classes.messageBubbleOther} onMouseEnter={() => handleMouseEnterMsg(message.id)} onMouseLeave={handleMouseLeaveMsg}>
                                <DecryptedMessage 
                                  encryptedText={message.message} 
                                  uid={chatId} eThree={eThree} 
                                  scrollToBottom={scrollToBottom}
                                  index={index}
                                  msgCount={messages.length - 1}
                                />
                              </Paper>
                            :
                              <Fragment>
                                {message.photoUrl?
                                  <Paper className={classes.attachmentMessageBubbleOther}>
                                    <img src={message.photoUrl} className={classes.imageMessage} onClick={() => handlePhotoClick(message.photoUrl)}/>
                                  </Paper>
                                :
                                  <Fragment>
                                    {message.attachmentUrl ? 
                                      <Paper className={classes.imageMessageBubbleOther}>
                                        <Link href={message.attachmentUrl} target="_blank" rel="noopener">
                                          {message.filename}
                                        </Link>
                                      </Paper>
                                    :
                                      <video width="250" height="200" controls>
                                        <source src={message.videoUrl} type="video/mp4"/>
                                        <source src={message.videoUrl} type="video/ogg"/>
                                        Your browser does not support the video tag.
                                      </video>
                                    }
                                  </Fragment>
                                }
                              </Fragment>
                            }
                          </Grid>
                            <Tooltip title='More' placement='top'>
                              <span>
                                <IconButton 
                                  size='small' 
                                  id={message.id} 
                                  onClick={handleMessageClick} 
                                  disabled={message.id !== showDetailsFor}
                                  onMouseEnter={() => handleMouseEnterMsg(message.id)} 
                                  onMouseLeave={handleMouseLeaveMsg}
                                >
                                  {message.id === showDetailsFor ? <MoreVertIcon fontSize='small' color='disabled' className={classes.moreIcon} /> : null }
                                </IconButton>
                              </span>
                            </Tooltip>
                        </Grid>
                      }
                    </ListItem>
                  </div>
                ))}
                <div ref={messagesEndRef} />
              </List>
              <Popover
                disableScrollLock={true}
                open={openMessagePopover}
                anchorEl={popoverAnchor}
                onClose={handleMessagePopoverClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
              >
                <Button onClick={(event) => handleMsgDelete(event, popoverAnchor)}>Delete</Button>
              </Popover>
            </Paper>
            <Paper className={smallScreen ? classes.inputAreaSmallScreen : classes.inputArea} elevation={0}>
              {video && loadingVideo ? <LinearProgress variant="determinate" value={loadProgress} /> : null}
              <Grid container  direction='column' >
                <Grid item>
                  <Grid container direction='row' justify='center'>
                    {photos.length > 0  ? 
                      <List className={classes.imageList}>
                        {photos.length > 0 && photos.map((image, index) => (
                          <ListItem key={index}>
                          <Badge badgeContent={<CloseIcon className={classes.imageCloseIcon} />} color="secondary" onClick={() => handleRemoveImage(image.url)}>
                            <img src={image.url} className={classes.image}/>
                            </Badge>
                          </ListItem>
                        ))}
                      </List>
                    :
                      <Fragment>
                        {video ? 
                          <Fragment>
                            <Badge badgeContent={<CloseIcon className={classes.imageCloseIcon} />} color="secondary" onClick={handleRemoveVideo} invisible={loadingVideo}>
                              <video width="150" height="90" controls>
                                <source src={video.url} type="video/mp4"/>
                                <source src={video.url} type="video/ogg"/>
                                Your browser does not support the video tag.
                              </video>
                            </Badge>
                          </Fragment>
                        :
                          <Fragment>
                            {files.length > 0 ? 
                              <List className={classes.attachmentList} dense>
                                {files.length > 0 && files.map((file, index) => (
                                  <ListItem key={index}>
                                    <ListItemIcon>
                                      <CloseIcon onClick={() => handleRemoveAttachment(file)} />
                                    </ListItemIcon>                                
                                    <Link href={file.url} target="_blank" rel="noopener">
                                      <ListItemText primary={file.name}/>
                                    </Link>
                                  </ListItem>
                                ))}
                              </List>
                            :
                              <TextField
                                id="input-area"
                                placeholder="Write a message..."
                                value={message}
                                multiline
                                rows={3}
                                variant="outlined"
                                fullWidth
                                onChange={handleChange}
                                onKeyPress={event => handleKeyPress(event, messages.length)}
                              />
                            }
                          </Fragment>
                        }
                      </Fragment>
                    }
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction='row' justify='space-between' alignItems='flex-end'>
                    <Grid item>
                      <input
                        accept="image/*"
                        hidden
                        id="raised-button-file"
                        multiple
                        type="file"
                        onChange={handlePhotoInput}
                      />
                        <label htmlFor="raised-button-file">
                        <Tooltip title='Attach an image'>
                          <IconButton component='span'>
                            <PhotoOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </label>
                      <input
                        key={videoInputKey}
                        accept="video/*"
                        hidden
                        id="upload-video"
                        type="file"
                        onChange={handleVideoInput}
                      />
                        <label htmlFor="upload-video">
                        <Tooltip title='Upload a video'>
                          <IconButton component='span'>
                            <VideoCallIcon />
                          </IconButton>
                        </Tooltip>
                      </label>
                      <input
                        hidden
                        id="upload-file"
                        multiple
                        type="file"
                        accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, .pdf, .doc, .docx"
                        onChange={handleFileInput}
                      />
                        <label htmlFor="upload-file">
                        <Tooltip title='Attach a file'>
                          <IconButton component='span'>
                            <AttachFileIcon />
                          </IconButton>
                        </Tooltip>
                      </label>
                    </Grid>
                    <Grid item>
                      <IconButton onClick={event => handleSendMessage(event, messages.length)} >
                        <SendIcon color='primary' />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Paper>
        </Paper>
      )
}


const mapDispatchToProps = (dispatch) => {
  return {
    deactivateConversation: (user) => {dispatch(deactivateConversation(user))},
    minimizeConversation: (user) => {dispatch(minimizeConversation(user))},
    sendMessage: (message) => {dispatch(sendMessage(message))},
    markRead: (chatId) => {dispatch(markRead(chatId))},
    sendPhotoMessage: (photoMessage) => {dispatch(sendPhotoMessage(photoMessage))},
    sendVideoMessage: (videoMessage) => {dispatch(sendVideoMessage(videoMessage))},
    sendAttachmentMessage: (attachmentMessage) => {dispatch(sendAttachmentMessage(attachmentMessage))},
    deleteMessage: (message) => {dispatch(deleteMessage(message))},
    saveEThree: (eThree) => {dispatch(saveEThree(eThree))},
  }
}

export default connect(null, mapDispatchToProps)(ChatBox)