import React from 'react'
import UserAvatar from './UserAvatar'
import Grid from '@material-ui/core/Grid'
import Author from '../ForumComponents/Author'
import Button from '@material-ui/core/Button'

function PaymentDetails({ payment }) {

    const handlePayment = (e) => {
        e.preventDefault()
        //alert('Write the backend for this!')
    }

    return (
        <Grid container direction='column' justify='flex-end' alignItems='flex-start'>
            <Grid item>
                <Grid container direction='row' justify='flex-start' alignItems='center' spacing={2}>
                    <Grid item>
                        <UserAvatar userId={payment.requestorId} large />
                    </Grid>
                    <Grid item>
                        <h3>{<Author authorId={payment.requestorId} textOnly />}</h3>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <h4>{`${payment.hours} ${payment.hours === '1.00' ? 'hour' : 'hours'} for:`}</h4>
                <h4>{`"${payment.description}"`}</h4>
                <Button 
                    variant='outlined'
                    color='primary'
                    onClick={handlePayment}
                >
                    Accept and Pay
                </Button>
            </Grid>
        </Grid>
    )
}

export default PaymentDetails