import React, { Component } from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import SMEApplication from '../Components/UserComponents/SMEApplication'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useEffect } from 'react'

function Application(props) {

  const { auth, profile } = props
  const userId = props.match.params.user_id
  const fromLogin = props.match.params.from_login
  useFirestoreConnect([
    {   collection: 'application', 
        doc: userId,
        storeAs: `${userId}-application`
    }
  ])
  const application = useSelector(state => state.firestore.ordered[`${userId}-application`])

  // get users emails from email collection
  const storeAs = `${userId}-sme_private`
  useFirestoreConnect([
      {
          collection: 'sme_private',
          doc: userId,
          storeAs
      }
  ])
  const smePrivate = useSelector(state => state.firestore.ordered[storeAs])

  useEffect(() => {
    if (application && application[0] && !application[0].applicationComplete) {
      props.history.push(`/consultant-application/${auth.uid}`)
    }
  }, [application])

  const root = {
    textAlign: 'center',
    marginTop: 115,
  }
  const loader = {
    display: 'inlineBlock'
  }

  if (!auth.isLoaded || !profile.isLoaded) return <div style={root}><CircularProgress style={loader} /> </div> 
  if (!auth.uid) return <Redirect to='/' />
  if (!application || !smePrivate) return <div style={root}><CircularProgress style={loader} /> </div> 
  if (!application[0] || !smePrivate[0]) return <div style={root}><CircularProgress style={loader} /> </div> 
  if (application[0].applicationComplete && fromLogin) return <Redirect to='/forum' />


  return (
    <SMEApplication
      applicationProps={application[0]}
      smePrivateProps={smePrivate[0]}
    />
  )

}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

export default withRouter(connect(mapStateToProps)(Application))