import React, { useState, Fragment } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import IconButton from '@material-ui/core/IconButton'
import { Link } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles(theme => ({
    button: {
      textDecoration: 'none',
      color: grey[900],
      "&:hover": {
        color: grey[900]
      },
      "&:visited": {
        color: grey[900]
      },
      "&:active": {
        color: grey[900]
      },
      "&:link": {
        color: grey[900]
      },
      "&:link:active": {
        color: grey[900]
      },
      "&:visited:active": {
        color: grey[900]
      },
    },
  }));

function ProfileIcon({ userId, profile }) {
    const classes = useStyles()
    const [profileAnchorEl, setProfileAnchorEl] = useState(null)

    const handleClickProfile = (event) => {
      setProfileAnchorEl(event.currentTarget)
    }
  
    const handleCloseProfile = () => {
      setProfileAnchorEl(null);
    }

    return (
        <div>
            <IconButton 
                color="inherit"
                onClick={handleClickProfile}
                aria-haspopup='true'
            >
                <AccountCircleIcon />
                <ExpandMoreIcon />
            </IconButton>
            <Menu
                id="profile-menu"
                anchorEl={profileAnchorEl}
                keepMounted
                open={Boolean(profileAnchorEl)}
                onClose={handleCloseProfile}
                disableScrollLock={true}
            >
                <Link className={classes.button} to={`/profile/${userId}`}><MenuItem>Profile</MenuItem></Link>
                <Link className={classes.button} to={`/account/${userId}`}><MenuItem>My account</MenuItem></Link>
                {profile.license === 'SME' ?
                  <Fragment>
                    <Link className={classes.button} to={`/schedule/${userId}`}><MenuItem>Schedule</MenuItem></Link>
                    <Link className={classes.button} to={`/contracts/${userId}`}><MenuItem>Contracts</MenuItem></Link>
                    <Link className={classes.button} to={`/proposals/user/${userId}`}><MenuItem>Proposals</MenuItem></Link>
                  </Fragment>
                : null}
                {/* Add this whenever Billing is live
                {profile.license === 'SME' ?
                  <Link className={classes.button} to={`/billing/${userId}`}><MenuItem>Billing</MenuItem></Link>
                : null}
                */}
            </Menu>
        </div>
    )
}

export default ProfileIcon