export const activateConversation = (user) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
  
      // Make a document (update if exists) with the messagees user id and set this conversation to active. 
      const firestore = getFirestore()
      const authId = getState().firebase.auth.uid
      firestore.collection('users')
               .doc(authId)
               .collection('conversations')
               .doc(user.id)
               .set({
                  'active': true,
                  'timeActive': firestore.FieldValue.serverTimestamp(),
                  'minimize': false,
      }, {
          merge: true
        }).then(() => {
        dispatch({type: 'ACTIVATE_CONVERSATION', user})
      }).catch((error) => {
        dispatch({type: 'ACTIVATE_CONVERSATION_ERROR', error})
      })
    }
  }

  export const deactivateConversation = (user) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
  
      // Make a document (update if exists) with the messagees user id and set this conversation to active. 
      const firestore = getFirestore()
      const authId = getState().firebase.auth.uid
      firestore.collection('users')
               .doc(authId)
               .collection('conversations')
               .doc(user.id)
               .set({
                  'active': false,
                  'minimize': false,
      }, {
          merge: true
        }).then(() => {
        dispatch({type: 'DEACTIVATE_CONVERSATION', user})
      }).catch((error) => {
        dispatch({type: 'DEACTIVATE_CONVERSATION_ERROR', error})
      })
    }
  }

  export const minimizeConversation = (data) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
  
      // Make a document (update if exists) with the messagees user id and set this conversation to active. 
      const firestore = getFirestore()
      const authId = getState().firebase.auth.uid
      firestore.collection('users')
               .doc(authId)
               .collection('conversations')
               .doc(data.chatId)
               .update({
                  'minimize': data.minimize,
        }).then(() => {
        dispatch({type: 'MINIMIZE_CONVERSATION', data})
      }).catch((error) => {
        dispatch({type: 'MINIMIZE_CONVERSATION_ERROR', error})
      })
    }
  }

  export const sendMessage = (message) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
  
      // Used a batched write for atomicity
      const firestore = getFirestore()
      const batch = firestore.batch()
      const authId = getState().firebase.auth.uid
        // this marks unread as true in the receiving users conversation doc
        const unreadRef = firestore.collection('users').doc(message.id).collection('conversations').doc(authId)
        batch.set(unreadRef, {
            'unread': true,
            'timeActive': firestore.FieldValue.serverTimestamp(),
        }, {
          merge: true
        })
        // This adds the message to the receiving users messages collection
        const sendMsgRef = firestore.collection('users').doc(message.id).collection('conversations').doc(authId).collection('messages').doc()
        batch.set(sendMsgRef, {
          'message': message.content,
          'authorIsSelf': false,
          'timeSent': firestore.FieldValue.serverTimestamp(),
          'isFirst': message.isFirst,
        })
        // This adds the message to the sending users messages collection
        const msgRecordRef = firestore.collection('users').doc(authId).collection('conversations').doc(message.id).collection('messages').doc()
        batch.set(msgRecordRef, {
          'message': message.content,
          'authorIsSelf': true,
          'timeSent': firestore.FieldValue.serverTimestamp(),
          'isFirst': message.isFirst,
        })
        // commit the batch
        batch.commit()
        .then(() => {
          dispatch({type: 'SEND_MESSAGE', message})
        }).catch(error => {
          dispatch({type: 'SEND_MESSAGE_ERROR', error})
        })
    }
  }

  export const deleteMessage = (message) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
  
      // Used a batched write for atomicity
      const firestore = getFirestore()
      const authId = getState().firebase.auth.uid
      // This deletes the message from the sending users messages collection
      firestore.collection('users').doc(authId).collection('conversations').doc(message.chatId).collection('messages').doc(message.docId).delete()
      .then(() => {
        dispatch({type: 'DELETE_MESSAGE', message})
      }).catch(error => {
        dispatch({type: 'DELETE_MESSAGE_ERROR', error})
      })
    }
  }

  export const sendPhotoMessage = (message) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
  
      // Used a batched write for atomicity
      const firestore = getFirestore()
      const batch = firestore.batch()
      const authId = getState().firebase.auth.uid
        // this marks unread as true in the receiving users conversation doc
        const unreadRef = firestore.collection('users').doc(message.id).collection('conversations').doc(authId)
        batch.set(unreadRef, {
            'unread': true,
            'timeActive': firestore.FieldValue.serverTimestamp(),
        }, {
          merge: true
        })
        // This adds the message to the receiving users messages collection
        const sendMsgRef = firestore.collection('users').doc(message.id).collection('conversations').doc(authId).collection('messages').doc()
        batch.set(sendMsgRef, {
          'photoUrl': message.url,
          'authorIsSelf': false,
          'timeSent': firestore.FieldValue.serverTimestamp(),
          'isFirst': message.isFirst,
        })
        // This adds the message to the sending users messages collection
        const msgRecordRef = firestore.collection('users').doc(authId).collection('conversations').doc(message.id).collection('messages').doc()
        batch.set(msgRecordRef, {
          'photoUrl': message.url,
          'authorIsSelf': true,
          'timeSent': firestore.FieldValue.serverTimestamp(),
          'isFirst': message.isFirst,
        })
        // commit the batch
        batch.commit()
        .then(() => {
          dispatch({type: 'SEND_MESSAGE', message})
        }).catch(error => {
          dispatch({type: 'SEND_MESSAGE_ERROR', error})
        })
    }
  }

  export const sendAttachmentMessage = (message) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
  
      // Used a batched write for atomicity
      const firestore = getFirestore()
      const batch = firestore.batch()
      const authId = getState().firebase.auth.uid
        // this marks unread as true in the receiving users conversation doc
        const unreadRef = firestore.collection('users').doc(message.id).collection('conversations').doc(authId)
        batch.set(unreadRef, {
            'unread': true,
            'timeActive': firestore.FieldValue.serverTimestamp(),
        }, {
          merge: true
        })
        // This adds the message to the receiving users messages collection
        const sendMsgRef = firestore.collection('users').doc(message.id).collection('conversations').doc(authId).collection('messages').doc()
        batch.set(sendMsgRef, {
          'attachmentUrl': message.url,
          'filename': message.filename,
          'authorIsSelf': false,
          'timeSent': firestore.FieldValue.serverTimestamp(),
          'isFirst': message.isFirst,
        })
        // This adds the message to the sending users messages collection
        const msgRecordRef = firestore.collection('users').doc(authId).collection('conversations').doc(message.id).collection('messages').doc()
        batch.set(msgRecordRef, {
          'attachmentUrl': message.url,
          'filename': message.filename,
          'authorIsSelf': true,
          'timeSent': firestore.FieldValue.serverTimestamp(),
          'isFirst': message.isFirst,
        })
        // commit the batch
        batch.commit()
        .then(() => {
          dispatch({type: 'SEND_MESSAGE', message})
        }).catch(error => {
          dispatch({type: 'SEND_MESSAGE_ERROR', error})
        })
    }
  }

  export const sendVideoMessage = (message) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
  
      // Used a batched write for atomicity
      const firestore = getFirestore()
      const batch = firestore.batch()
      const authId = getState().firebase.auth.uid
        // this marks unread as true in the receiving users conversation doc
        const unreadRef = firestore.collection('users').doc(message.id).collection('conversations').doc(authId)
        batch.set(unreadRef, {
            'unread': true,
            'timeActive': firestore.FieldValue.serverTimestamp(),
        }, {
          merge: true
        })
        // This adds the message to the receiving users messages collection
        const sendMsgRef = firestore.collection('users').doc(message.id).collection('conversations').doc(authId).collection('messages').doc()
        batch.set(sendMsgRef, {
          'videoUrl': message.url,
          'authorIsSelf': false,
          'timeSent': firestore.FieldValue.serverTimestamp(),
          'isFirst': message.isFirst,
        })
        // This adds the message to the sending users messages collection
        const msgRecordRef = firestore.collection('users').doc(authId).collection('conversations').doc(message.id).collection('messages').doc()
        batch.set(msgRecordRef, {
          'videoUrl': message.url,
          'authorIsSelf': true,
          'timeSent': firestore.FieldValue.serverTimestamp(),
          'isFirst': message.isFirst,
        })
        // commit the batch
        batch.commit()
        .then(() => {
          dispatch({type: 'SEND_MESSAGE', message})
        }).catch(error => {
          dispatch({type: 'SEND_MESSAGE_ERROR', error})
        })
    }
  }

  export const markRead = (chatId) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
  
      // Make a document (update if exists) with the messagees user id and set this conversation to active. 
      const firestore = getFirestore()
      const authId = getState().firebase.auth.uid
      firestore.collection('users')
               .doc(authId)
               .collection('conversations')
               .doc(chatId)
               .update({
                  'unread': false,
        }).then(() => {
        dispatch({type: 'MARK_MSG_READ', chatId})
      }).catch((error) => {
        dispatch({type: 'MARK_MSG_READ_ERROR', error})
      })
    }
  }

  export const clearMsgStatus = () => {
    return {type: 'CLEAR_MESSAGE_STATUS'}
  }