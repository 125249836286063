import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import PeopleSearch from '../Components/UserComponents/PeopleSearch'
import grey from '@material-ui/core/colors/grey'
import { makeStyles } from '@material-ui/core/styles'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import DisplayBillMeeting from '../Components/UserComponents/DisplayBillMeeting'
import DisplayBillUser from '../Components/UserComponents/DisplayBillUser'
import MeetingHistory from '../Components/UserComponents/MeetingHistory'

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: grey[50],
        minHeight: '80vh',
    },
    content: {
        marginTop: 25,
    },
    history: {
        width: '40%',
    },
    current: {
        width: '60%'
    },
}))

function Billing(props) {
    const { auth } = props
    const classes = useStyles()
    const [userToBill, setUserToBill] = useState(null)
    const [meetingToBill, setMeetingToBill] = useState(null)

    const handleUserId = (userId) => {
        setUserToBill(userId)
        setMeetingToBill(null)
    }

    const handleMeeting = (meeting) => {
        setMeetingToBill(meeting)
        setUserToBill(null)
    }

    const urlUid = props.match.params.user_id

    if (!auth.uid) return <div/>

    // if this is false then someone is hacking the users billing page
    // Need to restrict this page from all but the owner
    if (auth.uid !== urlUid) return <Redirect to='/' />

    return (
        <div style={{paddingTop: 125}}>
            <Container maxwidth='lg' className={classes.background}>
                <div style={{padding: 20}} />
                <PeopleSearch sendUserId={handleUserId}/>
                <Grid container direction='row' alignItems='flex-start' justify='flex-start' className={classes.content} spacing={10}>
                    <Grid item className={classes.history}>
                        <MeetingHistory userId={urlUid} sendMeetingToParent={handleMeeting}/>
                    </Grid>
                    <Grid item className={classes.current}>
                        {userToBill ? 
                            <DisplayBillUser userId={userToBill}/>
                        :
                            (meetingToBill ? 
                                <DisplayBillMeeting meeting={meetingToBill} />
                            :
                                <h3>{`Select a past meeting or search for a user to bill`}</h3>
                            )
                        }
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.firebase.auth,
    }
  }
  
  export default withRouter(connect(mapStateToProps)(Billing))