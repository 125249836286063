import React, { Fragment, useState, useEffect } from 'react'
// material core
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Fab from '@material-ui/core/Fab'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import MUISelect from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import Skeleton from '@material-ui/lab/Skeleton'
// icons
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/EditOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined'
// axios
import axios from 'axios'
// redux
import { useDispatch, useSelector } from 'react-redux'
import { addSchool, deleteSchool, editSchool } from '../../../Redux/Actions/userActions'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Icon } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    paper: {
        //marginTop: theme.spacing(1),
    },
    grid: {
        paddingTop: theme.spacing(2),
        paddingBotton: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    fab: {
        color: '#FFF'
    },
    icon: {
        color: '#000'
    },
    form: {
        width: 600,
    },
    smallForm: {
        width: 250,
    },
    select: {
        width: 250,
        height: 40,
    },
    empHeader: {
        backgroundColor: '#f5f5f5',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        height: 65,
    },
    empContainer: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }
}))

const styles = (theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
})

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <h4><b>{children}</b></h4>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function Education({ userId, isUsersProfile, educationData }) {

    // styles
    const classes = useStyles()

    // redux dispatch
    const dispatch = useDispatch()

    // state
    const [openForm, setOpenForm] = useState(false)
    const [formComplete, setFormComplete] = useState(false)
    const [years, setYears] = useState([])
    const [openConfirm, setOpenConfirm] = useState(false)
    const [toDelete, setToDelete] = useState(null)
    const [editData, setEditData] = useState(null)

    // Form state
    const [school, setSchool] = useState('')
    const [degree, setDegree] = useState('')
    const [studyArea, setStudyArea] = useState('')
    const [fromYear, setFromYear] = useState('')
    const [toYear, setToYear] = useState('')
    const [description, setDescription] = useState('')
    const [loading, setLoading] = useState(false)

    // functions
    const handleCloseForm = () => {
        setOpenForm(false)
    }

    const handleAddEducation = (e) => {
        e.preventDefault()
        setOpenForm(true)
    }

    const handleSchoolChange = (e) => {
        setSchool(e.target.value)
        validate()
    }

    const handleDegreeChange = (e) => {
        setDegree(e.target.value)
        validate()
    }

    const handleStudyAreaChange = (e) => {
        setStudyArea(e.target.value)
        validate()
    }


    const handleDescriptionChange = (e) => {
        setDescription(e.target.value)
        validate()
    }

    const handleFromYearChange = (e) => {
        setFromYear(e.target.value)
        validate()
    }

    const handleToYearChange = (e) => {
        setToYear(e.target.value)
        validate()
    }

    const handleInitDelete = (e, id) => {
        e.preventDefault()
        setToDelete(id)
        setOpenConfirm(true)
    }

    const handleDelete = (e) => {
        e.preventDefault()
        dispatch(deleteSchool(toDelete))
        setOpenConfirm(false)
    }

    const handleCloseConfirm = (e) => {
        e.preventDefault()
        setOpenConfirm(false)
        setToDelete(null)
    }

    const handleEdit = (e, data) => {
        e.preventDefault()
        setEditData(data.id)
        setSchool(data.data.school)
        setDegree(data.data.degree)
        setFromYear(data.data.fromYear)
        setToYear(data.data.toYear)
        setDescription(data.data.description)
        setStudyArea(data.data.studyArea)
        setOpenForm(true)
    }

    const validate = () => {
        if (school !== '') {
            setFormComplete(true)
        }
    }

    // submit
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const education = {
            school,
            degree,
            studyArea,
            fromYear,
            toYear,
            description
        }
        if (editData) {
            dispatch(editSchool(education, editData))
                .then(response => {
                    setLoading(false)
                    setSchool('')
                    setDegree('')
                    setStudyArea('')
                    setFromYear('')
                    setToYear('')
                    setDescription('')
                    setOpenForm(false)
                    setEditData(null)
                })
                .catch(e => {
                    console.error(e)
                    setLoading(false)
                    setEditData(null)
                })
        }
        else {      
            dispatch(addSchool(education))
            .then(response => {
                setLoading(false)
                setSchool('')
                setDegree('')
                setStudyArea('')
                setFromYear('')
                setToYear('')
                setDescription('')
                setOpenForm(false)
                setEditData(null)
            })
            .catch(e => {
                console.error(e)
                setLoading(false)
                setEditData(null)
            }) 
        }
    }

    useEffect(() => {
        if (school !== '') {
            setFormComplete(true)
        } else {
            setFormComplete(false)
        }
    }, [school])

    useEffect(() => {

            const years = function(startYear) {
                var currentYear = new Date().getFullYear(), years = [];
                startYear = startYear || 1980;  
                while ( startYear <= currentYear + 6 ) {
                    years.push(startYear++);
                }   
                return years;
            }

            setYears(years())

    }, [])
 
    return (
        <Fragment>
            <Grid
                container
                direction="column"
                className={classes.grid}
            >
                {!educationData ? 
                    <Fragment>
                        <Skeleton animation='wave' height={100}/>  
                        <Skeleton animation='wave' height={50} />
                        <Skeleton animation='wave' height={50} />
                    </Fragment>  
               :
                <Paper className={classes.paper} elevation={2}>
                    <Grid container direction='row' justify='space-between' alignItems='center' className={classes.empHeader}>
                        <Grid item>
                            <h4><b>Education</b></h4>
                        </Grid>
                        {isUsersProfile ?
                            <Grid item>
                                <Fab className={classes.fab} onClick={handleAddEducation} size='small' >
                                    <AddIcon className={classes.icon} />
                                </Fab>
                            </Grid>
                        : null}
                    </Grid> 
                    <div className={classes.empContainer}>
                        {
                            educationData && educationData.sort((a,b) => {return parseInt(b.data.fromYear) - parseInt(a.data.fromYear)}).map((ed, index) => (
                                <Fragment key={index}>
                                    <Grid container direction='row' justify='space-between' alignItems='center'>
                                        <Grid item>
                                            <h5><b>{`${ed.data.school}`}</b></h5>
                                        </Grid>
                                        {isUsersProfile ?
                                            <Grid>
                                                <IconButton onClick={(e) => handleEdit(e, ed)}><EditIcon /></IconButton>
                                                <IconButton onClick={(e) => handleInitDelete(e, ed.id)}><DeleteIcon /></IconButton>
                                            </Grid>
                                        : null}
                                    </Grid>
                                    {ed.data.degree ?
                                        <p>{`${ed.data.degree && ed.data.degree}`}</p>
                                    : null}
                                    {ed.data.studyArea ?
                                        <p>{`${ed.data.studyArea && ed.data.studyArea}`}</p>
                                    : null}
                                    {ed.data.fromYear && ed.data.toYear ?
                                        <p>{`${ed.data.fromYear && ed.data.fromYear} - ${ed.data.toYear && ed.data.toYear}`}</p>
                                    : null}
                                    {ed.data.description ?
                                        <p>{`${ed.data.description && ed.data.description}`}</p>
                                    : null}
                                    <hr />
                                </Fragment>
                            ))
                        }
                    </div>
                </Paper>
                }
            </Grid>
            <Dialog maxWidth='xl' onClose={handleCloseForm} open={openForm}>
                <DialogTitle onClose={handleCloseForm}>
                    Add Education
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container direction='column' spacing={2}>
                        <Grid item>
                            <p><b>School</b></p>
                            <TextField 
                                variant='outlined'
                                className={classes.form}
                                size='small'
                                value={school}
                                onChange={handleSchoolChange}
                                placeholder={`Ex. Texas A&M University`}
                            />
                        </Grid>
                        <Grid item>
                            <Grid container direction='row' justify='space-between' alignItems='flex-end'>
                                <Grid item>
                                    <FormControl>
                                        <InputLabel>From (optional)</InputLabel>
                                        <MUISelect 
                                            className={classes.smallForm}
                                            value={fromYear}
                                            onChange={handleFromYearChange}
                                        >
                                            {
                                                years.map(year => (
                                                    <MenuItem key={year} value={year}>{year}</MenuItem>
                                                ))
                                            }
                                        </MUISelect>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <InputLabel>To (optional)</InputLabel>
                                        <MUISelect 
                                            className={classes.smallForm}
                                            value={toYear}
                                            onChange={handleToYearChange}
                                        >
                                            {
                                                years.map(year => (
                                                    <MenuItem key={year} value={year}>{year}</MenuItem>
                                                ))
                                            }
                                        </MUISelect>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <p><b>Degree (optional)</b></p>
                            <TextField 
                                variant='outlined'
                                size='small'
                                className={classes.form}
                                value={degree}
                                onChange={handleDegreeChange}
                                placeholder={`Ex. Bachelor's`}
                            />
                        </Grid>
                        <Grid item>
                            <p><b>Study Area (optional)</b></p>
                            <TextField 
                                variant='outlined'
                                size='small'
                                className={classes.form}
                                value={studyArea}
                                onChange={handleStudyAreaChange}
                            />
                        </Grid>
                        <Grid item>
                            <p><b>Description (optional)</b></p>
                            <TextField 
                                variant='outlined'
                                size='small'
                                className={classes.form}
                                rows={5}
                                multiline
                                value={description}
                                onChange={handleDescriptionChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseForm} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={!formComplete} onClick={handleSubmit} variant='contained' color="primary">
                        { loading ?
                            <CircularProgress color='secondary' size='20px' />
                            :
                            'Save'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
            >
                <DialogTitle >{"Delete this Employment?"}</DialogTitle>
                <DialogActions>
                <Button onClick={handleCloseConfirm} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleDelete} color="secondary" >
                    Delete
                </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default Education