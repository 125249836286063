import React, { Fragment, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { Paper, CircularProgress } from '@material-ui/core'
import firebase from 'firebase/app'

const useStyles = makeStyles(theme => ({
    button: {
        textTransform: 'none',
    },
    popper: {
        padding: theme.spacing(3)
    },
}))

function PasswordConfirmation({ handler, buttonTitle, isDisabled }) {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)
    const [password, setPassword] = useState('')
    const [pwError, setPwError] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    
    const handleClose = () => {
        setAnchorEl(null)
        setPassword('')
        setPwError(null)
    }
    
    const handleChange = (e) => {
        setPassword(e.target.value)
        if (setPwError) {
            setPwError(null)
        }
    }

    const handleAdd = (e) => {
        e.preventDefault()
        setLoading(true)
        const user = firebase.auth().currentUser
        const credential = firebase.auth.EmailAuthProvider.credential(user.email, password)
        user.reauthenticateWithCredential(credential).then(() => {
            setLoading(false)
            setAnchorEl(null)
            setPassword('')
            handler(e)
        }).catch(error => {
            setLoading(false)
            if (error.code === 'auth/too-many-requests') {
                setPwError('Too many failed attempts, try again later')
            } else {
                setPwError('Invalid password')
            }
        })
    }

    return (
                <Fragment>
            <Button
                color='primary'
                onClick={handleClick}
                disabled={isDisabled}
            >
                {buttonTitle}
            </Button>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Paper className={classes.popper}>
                    <Grid container direction='column' justify='center'>
                        <Grid item>
                            <p>Enter your password to add this email</p>
                        </Grid>
                        <Grid item>
                            <TextField
                                variant='outlined'
                                size='small'
                                fullWidth
                                type='password'
                                onChange={handleChange}
                                value={password}
                                error={pwError ? true : false}
                                helperText={pwError}
                            />  
                        </Grid>
                        <Grid item>
                            <Button 
                                className={classes.button}
                                onClick={handleAdd}
                                color='primary'
                            >
                                {loading ? <CircularProgress size={30} /> : 'Add'}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Popover>
        </Fragment>
    )
}

export default PasswordConfirmation