import React, { Component } from 'react'

class Challenges extends Component {

  render() {
    return (
      <h1>Challenges Page</h1>
    )
  }
}
export default Challenges