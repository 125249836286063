import React, { useState, useRef } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Popper from '@material-ui/core/Popper'
import ListOfPeople from './ListOfPeople'
import algoliasearch from 'algoliasearch'
import { algoliaConfig } from '../../../Config/algoliaConfig'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'

// This component will provide a textfield which will render 
// a list of people returned from the input query.
// Give this a sendUserId function which will return the UserId
// of the person selected by the user.  Use that id to look up that user 
// in the database
// set link to true if you want the user to be directed to their profile upon selection **still need to implement link**
function PeopleSearch({ sendUserId, link, styleClass, portal }) {

    const [anchorEl, setAnchorEl] = useState(null)
    const [searchResults, setSearchResults] = useState(null)
    const [inputWidth, setInputWidth] = useState(0)
    const [inputValue, setInputValue] = useState('')

    const ALGOLIA_ID = algoliaConfig.app_id
    const ALGOLIA_SEARCH_KEY = algoliaConfig.search_key
    const client = algoliasearch(ALGOLIA_ID, ALGOLIA_SEARCH_KEY)
    const index = client.initIndex("users")

    const handleSearchChange = (event) => {
        setAnchorEl(event.currentTarget)
        setInputWidth(event.target.offsetWidth)
        setInputValue(event.target.value)

        if (event.target.value !== '') {
            // only query string
            index.search(event.target.value).then(({ hits }) => {
                setSearchResults(hits)
            })
        } else {
            setSearchResults(null)
        }
    }

    const handleUserSelected = () => {
        setInputValue('')
    }

    return (
        <div>
            <TextField 
                size='small'
                variant='outlined'
                placeholder='Search'
                onChange={handleSearchChange}
                value={inputValue}
                className={styleClass}
                size='small'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <Popper
                open={anchorEl ? inputValue !== '' ? true : false : false}
                anchorEl={anchorEl}
                disablePortal={portal ? false : true}
                placement='bottom-end'
            >
                <ListOfPeople 
                    results={searchResults} 
                    inputWidth={inputWidth}
                    sendUserId={sendUserId}
                    handleUserSelected={handleUserSelected}
                    link={link}
                />
            </Popper>
        </div>
    )
}

export default PeopleSearch