import React, { Fragment } from "react";
import clsx from 'clsx';
import { green } from '@material-ui/core/colors';
// used for making the prop types of this component
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {updateResume} from '../../../Redux/Actions/userActions'
// core components
import CustomInput from "../CustomInput";
import Button from "../Button";
import { connect } from 'react-redux'

import styles from "../../../Assets/customFileInputStyle";

//firebase
import firebase from 'firebase/app'

const useStyles = makeStyles(styles);


// style for spinner
const spinnerStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));


function ResumeFileInput(props) {
  const [fileNames, setFileNames] = React.useState("");
  const spinnerClasses = spinnerStyles();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const buttonClassname = clsx({
    [spinnerClasses.buttonSuccess]: success,
  });

  // eslint-disable-next-line
  const [files, setFiles] = React.useState(null);
  let hiddenFile = React.createRef();
  const onFocus = e => {
    hiddenFile.current.click(e);
  };
  // eslint-disable-next-line
  const handleSubmit = e => {
    e.preventDefault();
    // start spinnerloaded
    setLoading(true)
    setSuccess(false)
    //**upload this file to storage**
    var storageRef = firebase.storage().ref().child(`applications/${userId}/${fileNames}-${Date.now()}`)
    storageRef.put(files[0]).then((snapshot) => {
      setResume(snapshot.metadata.name)
      setLoading(false)
      setSuccess(true)
      storageRef.getDownloadURL().then(url => {
        const resume = {
          name: props.name,
          userId: props.userId,
          url: url
        }
        props.updateResume(resume)
      }).catch(error => {
        console.log('failed to get download url', error)
      })
    }).catch(error => {
      console.log('failed to upload file', error)
    })
  };
  const addFile = e => {
    let fileNames = "";
    let files = e.target.files;
    for (let i = 0; i < e.target.files.length; i++) {
      fileNames = fileNames + e.target.files[i].name;
      if (props.multiple && i !== e.target.files.length - 1) {
        fileNames = fileNames + ", ";
      }
    }
    setFiles(files);
    setFileNames(fileNames);
  };
  const {
    id,
    endButton,
    startButton,
    inputProps,
    formControlProps,
    multiple,
    userId,
    setResume
  } = props;
  const classes = useStyles();
  if (inputProps && inputProps.type && inputProps.type === "file") {
    inputProps.type = "text";
  }
  let buttonStart;
  let buttonEnd;
  if (startButton) {
    buttonStart = (
      <Button {...startButton.buttonProps}>
        {startButton.icon !== undefined ? startButton.icon : null}
        {startButton.text !== undefined ? startButton.text : null}
      </Button>
    );
  }
  if (endButton) {
    buttonEnd = (
      <Button {...endButton.buttonProps}>
        {endButton.icon !== undefined ? endButton.icon : null}
        {endButton.text !== undefined ? endButton.text : null}
      </Button>
    );
  }
  return (
    <Grid container alignItems='center' direction='column'>
      <div className={classes.inputFileWrapper}>
        <input
          type="file"
          className={classes.inputFile}
          multiple={multiple}
          ref={hiddenFile}
          onChange={addFile}
        />
        <CustomInput
          id={id}
          formControlProps={{
            ...formControlProps
          }}
          inputProps={{
            ...inputProps,
            onClick: onFocus,
            value: fileNames,
            endAdornment: buttonEnd,
            startAdornment: buttonStart
          }}
        />
        </div>
        <p>{props.resume ? 'Uploaded' : null}</p>
        <div className={spinnerClasses.wrapper}>
          <Button
            round
            variant="contained"
            color="primary"
            className={buttonClassname}
            disabled={loading || fileNames === ''}
            onClick={handleSubmit}
          >
            <CloudUploadIcon />
            Upload
          </Button>
          {loading && <CircularProgress size={24} className={spinnerClasses.buttonProgress} />}
        </div>
      </Grid>
  );
}

ResumeFileInput.defaultProps = {
  multiple: false
};

ResumeFileInput.propTypes = {
  id: PropTypes.string,
  endButton: PropTypes.object,
  startButton: PropTypes.object,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  multiple: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateResume: (resume) => dispatch(updateResume(resume)),
  }
}

export default connect(null, mapDispatchToProps)(ResumeFileInput)


