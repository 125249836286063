import React, { Fragment } from 'react';
import Author from '../ForumComponents/Author'
import UserAvatar from '../UserComponents/UserAvatar'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import Badge from '@material-ui/core/Badge';

const UserMessagesListItem = ({ userId, read }) => {

    return (
        <Fragment>
            <div key={userId} style={{paddingRight: 10}}>
                <Badge 
                    overlap="circle"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    variant='dot'
                    invisible={read} 
                    color='error'
                >
                    <UserAvatar userId={userId} />
                </Badge>
            </div>
            <Author authorId={userId} bold={true} />
        </Fragment>
    );
};

export default UserMessagesListItem