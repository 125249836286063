import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import green from '@material-ui/core/colors/green'
import blue from '@material-ui/core/colors/blue'
import purple from '@material-ui/core/colors/purple'
import grey from '@material-ui/core/colors/grey'

const useStyles = makeStyles((theme) => ({
    blank: {
        height: '3.35714285%',
        width: '100%',
    },
    available: {
        height: '3.35714285%',
        width: '100%',
        backgroundColor: green[100],
    },
    repeating: {
        height: '3.35714285%',
        width: '100%',
        backgroundColor: blue[100],
    },
    selected: {
        height: '3.35714285%',
        width: '100%',
        backgroundColor: blue[200],
    },
    exception: {
        height: '3.35714285%',
        width: '100%',
        backgroundColor: grey[300],
    },
    requested: {
        height: '3.35714285%',
        width: '100%',
        backgroundColor: purple[300],
        borderTop: 'hidden',
        borderBottom: 'hidden',
    },
    scheduled: {
        height: '3.35714285%',
        width: '100%',
        backgroundColor: grey[800],
        borderTop: 'hidden',
        borderBottom: 'hidden',
    },
  }));

function TimeSlot({isOwner, type, time, dayString, handleDragStart, handleSlotClick, handleDragEnd}) {
    const classes = useStyles()

    var style = null 

    switch (type) {
        case 'scheduled':
            isOwner ? style = classes.scheduled : style = classes.blank
            break
        case 'requested':
            isOwner ? style = classes.requested : style = classes.blank
            break
        case 'exception':
            isOwner ? style = classes.exception : style = classes.blank
            break
        case 'selected':
            style = classes.selected
            break
        case 'repeating':
            isOwner ? style = classes.repeating : style = classes.available
            break
        case 'available':
            style = classes.available
            break
        case 'blank':
            style = classes.blank
            break
        default:
            break;
    }

    return (
        <Paper
            draggable 
            onDragStart={handleDragStart} 
            onDragEnter={(e) => handleSlotClick(e, dayString, time, false)} 
            onDragEnd={handleDragEnd} 
            onClick={(e) => handleSlotClick(e, dayString, time, true)} 
            elevation={0}
            variant='outlined'
            className={style} 
            square
        />
    )
}

export default TimeSlot