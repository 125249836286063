import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import Author from '../ForumComponents/Author'
import UserAvatar from '../UserComponents/UserAvatar'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import VisibilityIcon from '@material-ui/icons/Visibility'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import { deleteTopic, deletePrivateEntry, makePublic } from '../../../Redux/Actions/topicActions' 
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    main: {
        margin: "8px",
        padding: "24px",
    },
    text: {
        maxWidth: '600px',      
    },
}))

function PrivateTopic({ topic, deleteTopic, deletePrivateEntry, makePublic }) {
    const classes = useStyles()
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [openPublicDialog, setOpenPublicDialog] = useState(false)
    const [textField, setTextField] = useState('')

    const handleChange = (event) => {
        setTextField(event.target.value)
    }

    const publicAction = () => {
        // deny user action here
        const topicData = {
            forum_doc_id: topic.forum_doc_id,
            genre_doc_id: topic.genre_doc_id,
            topic_doc_id: topic.topic_doc_id,
            private_doc_id: topic.id,
        }
        makePublic(topicData)
        setOpenPublicDialog(false)
    }

    const cancelPublic = () => {
        setOpenPublicDialog(false)
    }

    const deleteAction = () => {
        // deny user action here
        const topicData = {
            forum_doc_id: topic.forum_doc_id,
            genre_doc_id: topic.genre_doc_id,
            topic_doc_id: topic.topic_doc_id,
        }
        deleteTopic(topicData)
        deletePrivateEntry(topic.id)
        setOpenDeleteDialog(false)
    }

    const cancelDelete = () => {
        setOpenDeleteDialog(false)
    }

    const handleDelete = (e) => {
        e.preventDefault()
        setOpenDeleteDialog(true)
    }

    const handlePublic = (e) => {
        e.preventDefault()
        setOpenPublicDialog(true)
    }

    useFirestoreConnect([
        { collection: 'forum',
          doc: topic.forum_doc_id,
          storeAs: `${topic.forum_doc_id}-section`
        }
    ])
    const section = useSelector(state => state.firestore.ordered[`${topic.forum_doc_id}-section`])

    useFirestoreConnect([
        { collection: 'forum',
          doc: topic.forum_doc_id,
          subcollections: [{ collection: 'genre',
                             doc: topic.genre_doc_id,  
                          }],
          storeAs: `${topic.genre_doc_id}-genre`
        }
    ])
    const genre = useSelector(state => state.firestore.ordered[`${topic.genre_doc_id}-genre`])

    useFirestoreConnect([
        { collection: 'forum',
          doc: topic.forum_doc_id,
          subcollections: [{ collection: 'genre',
                             doc: topic.genre_doc_id, 
                             subcollections: [{ collection: 'topic',
                                                doc: topic.topic_doc_id,
                                                subcollections: [{ collection: 'comment',
                                                                    orderBy: [
                                                                        ['createdAt', 'asc']
                                                                    ],
                                                                    limit: 1,
                                                                }], 
                                             }], 
                          }],
          storeAs: `${topic.topic_doc_id}-comment`
        }
    ])
    const comment = useSelector(state => state.firestore.ordered[`${topic.topic_doc_id}-comment`])
    
    if (!section || !genre || !comment) return <div />
    if (!section[0] || !genre[0] || !comment[0]) return <div />

    return (
        <div>
            <Paper className={classes.paper}>
                <Grid container direction='row' justify='flex-start' alignItems='center' spacing={1} >
                    <Grid item>
                        <h6><b>Located: </b></h6> 
                    </Grid>
                    <Grid item>
                        <h6>{`${section[0].title} / ${genre[0].title}`}</h6>
                    </Grid>
                </Grid>
                <Grid container direction='column' justify='flex-start' alignItems='flex-start' spacing={2}>
                    <Grid item>
                        <Grid container direction='row' justify='center' alignItems='center' spacing={1}>
                            <Grid item>
                                <h6><b>Author: </b></h6>
                            </Grid>
                            <Grid item>
                                <UserAvatar userId={topic.originatingAuthorId} />
                            </Grid>
                            <Grid item> 
                                <Author authorId={topic.originatingAuthorId} bold={true} link={true} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <h6><b>Topic</b></h6>
                        <h5><b>{topic.title}</b></h5>
                    </Grid>
                    <Grid item>
                        <h6><b>Authors Post</b></h6>
                        <p>{comment[0].comment}</p>
                    </Grid>
                    <Grid item>
                        <Tooltip title='Make public' >
                            <IconButton onClick={handlePublic}>
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='Delete' >
                            <IconButton onClick={handleDelete}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Paper>
            <Dialog
                open={openPublicDialog}
                onClose={() => setOpenPublicDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Make this topic public?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This topic will be restored to the forum and available to users. 
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={cancelPublic} color="primary">
                    Cancel
                </Button>
                <Button onClick={publicAction} color="primary" autoFocus>
                    Make Public
                </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Delete this Topic?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This action cannot be undone. Type in <b>delete</b> to delete.  
                    </DialogContentText>
                    <form className={classes.main} noValidate autoComplete="off">
                    <TextField 
                        id="title" 
                        label="delete" 
                        variant="outlined" 
                        fullWidth
                        className={classes.text}
                        value={textField}
                        onChange={handleChange}
                    />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button 
                    id='cancel'
                    onClick={cancelDelete} 
                    color="primary">
                    Cancel
                    </Button>
                    <Button 
                    id='submit'
                    onClick={deleteAction} 
                    color="secondary"
                    disabled={'delete' !== textField}  
                    >
                    Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteTopic: (topic) => {dispatch(deleteTopic(topic))},
        deletePrivateEntry: (topic) => {dispatch(deletePrivateEntry(topic))},
        makePublic: (topic) => {dispatch(makePublic(topic))}
    }
}

export default connect(null, mapDispatchToProps)(PrivateTopic)