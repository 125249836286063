import React, { Component } from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import LoginPage from '../MaterialKitComponents/RegistrationComponents/LoginPage'

class Login extends Component {

  render() {
    const { auth, profile } = this.props
    const loggedIn = auth.uid
    const emailVerified = auth.emailVerified

    // The following code is for redirecting a SME applicant who just logged in to the appliation
    // The redirect only occurs if they have not completed the application
    if (!auth.isLoaded || !profile.isLoaded) return <div/> 

    // If a SME logged in and has not completed their application
    if (profile.profile === 'SME-applicant' && profile.virgilComplete && emailVerified) {
      return <Redirect to={`/consultant-application/${auth.uid}/1`}/>
    } 

    if (loggedIn && emailVerified) {
      return <Redirect to={`/forum`} />
    }
      return (
        <LoginPage />
      )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

export default withRouter(connect(mapStateToProps)(Login))