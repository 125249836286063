import React, { useState } from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { green } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import { updateProfileImage, updateProfileImagePosition } from '../../Redux/Actions/userActions'
import { connect } from 'react-redux'
import imageStyle from "../../Assets/imagesStyles";
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/EditOutlined';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Grid from '@material-ui/core/Grid';


// core components
import Button from "./Button.js";
//firebase
import firebase from 'firebase/app'

import defaultAvatar from "../../MkAssets/img/placeholder.jpg";

//style for image
const useStyles2 = makeStyles(imageStyle);
// styles
const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function ImageUpload(props) {
  const mkClasses = useStyles2();
  const classes = styles();

  const [file, setFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null)

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [horizontal, setHorizontal] = useState(props.profile.imagePosition ? props.profile.imagePosition.horizontal : 50);
  const [vertical, setVertical] = useState(props.profile.imagePosition ? props.profile.imagePosition.vertical : 50);
  const [edit, setEdit] = useState(false)

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  var imgProfile = {
    width: 200,
    height: 200,
    objectFit: 'cover',
    objectPosition: `${horizontal}% ${vertical}%`
  }

  const moveLeft = () => {
    setHorizontal(horizontal - 2)
  }

  const moveRight = () => {
    setHorizontal(horizontal + 2)
  }

  const moveUp = () => {
    setVertical(vertical + 2)
  }

  const moveDown = () => {
    setVertical(vertical - 2)
  }

  const handleEdit = () => {
    setEdit(true)
    setImagePreviewUrl(props.profile.profileImageUrl ? props.profile.profileImageUrl : defaultAvatar)
  }

  let fileInput = React.createRef();
  const handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);

  };
  // eslint-disable-next-line
  const handleSubmit = (e) => {
    e.preventDefault()
    // file is the file/image uploaded
    // in this function you can save the image (file) on form submit
    setLoading(true)
    setSuccess(false)
    //**upload this image to storage**
    if (file) {
      var storageRef = firebase.storage().ref().child(`profile_photos/${props.userId}/${file}`)
      storageRef.put(file).then((snapshot) => {
        console.log('uploaded photo')
        setLoading(false)
        setSuccess(true)
        storageRef.getDownloadURL().then((url) => {
          const photoData = {
            horizontal,
            vertical,
            url
          }
          props.updateProfileImage(photoData)
          setEdit(false)
        }).catch(error => {
          console.log('failed to get image url', error)
        })
      }).catch(error => {
        console.log('failed to upload file', error)
      })
    }
    else {
      // just update position
      const position = {
        horizontal,
        vertical
      }
      props.updateProfileImagePosition(position)
      setLoading(false)
      setEdit(false)
    }
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(props.profile.profileImageUrl ? props.profile.profileImageUrl : defaultAvatar);
    fileInput.current.value = null;
    setEdit(false)
  };
  let { avatar, addButtonProps, changeButtonProps, removeButtonProps } = props;
  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      <div>
        {edit ?
          <img 
            src={imagePreviewUrl} 
            style={imgProfile}
            alt="..." 
            className={
              mkClasses.imgRaised +
              " " +
              mkClasses.imgRoundedCircle
            }
          />
        :
          <img 
            src={props.profile.profileImageUrl ? props.profile.profileImageUrl : defaultAvatar} 
            style={imgProfile}
            alt="..." 
            className={
              mkClasses.imgRaised +
              " " +
              mkClasses.imgRoundedCircle
            }
          />
        }
      </div>
      {props.isUsersProfile ? 
        <div>
          {edit === false ? (
            <IconButton {...addButtonProps} onClick={handleEdit}>
              <EditIcon />
            </IconButton>
          ) : (
            <span>
              <Grid container direction='column' alignItems="center">
                <Grid item>
                  <IconButton {...addButtonProps} onClick={moveUp}>
                    <KeyboardArrowUpIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Grid container direction='row' spacing={4}>
                    <IconButton {...addButtonProps} onClick={moveLeft}>
                      <ChevronLeftIcon />
                    </IconButton>
                    <IconButton {...addButtonProps} onClick={moveRight}>
                      <ChevronRightIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid item>
                  <IconButton {...addButtonProps} onClick={moveDown}>
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </Grid>
                <Grid container direction='row'>
                  <Grid item>
                  <Button {...changeButtonProps} onClick={() => handleClick()}>
                    {imagePreviewUrl === defaultAvatar ? 'Add Photo' : 'Change Photo'}
                  </Button>
                  {avatar ? <br /> : null}
                  </Grid>
                  <Grid item>
                    <Button {...removeButtonProps}  onClick={() => handleRemove()}>
                      <i className="fas fa-times" /> Cancel
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>
                  <div className={classes.wrapper}>
                    <Button
                      variant="contained"
                      color="success"
                      className={buttonClassname}
                      disabled={loading || imagePreviewUrl === defaultAvatar}
                      onClick={handleSubmit}
                    >
                      Save Changes
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </Grid>
              </Grid>
            </span>
          )}
        </div>
      :
        null
      }
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateProfileImage: (photoData) => dispatch(updateProfileImage(photoData)),
    updateProfileImagePosition: (position) => dispatch(updateProfileImagePosition(position)),
  }
}


export default connect(null, mapDispatchToProps)(ImageUpload)

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
};
