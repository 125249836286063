import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux'
import { createSection } from '../../../Redux/Actions/forumActions'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';


class NewSection extends Component {
  constructor(props) {
    super(props)

    this.state = {
      title: '',
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    if (event.target.id === 'title') {
      this.setState({title: event.target.value})
    } 
  }

  handleSubmit(event) {
    event.preventDefault()
    console.log("State", this.state)
    this.props.createSection(this.state)
    this.setState({
      title: '',
    }) 
  }

  render() {

    const main = {
      padding: "24px",
      width: 400,
    }
    const button = {
      padding: 30,
    }
    const text = {
      maxWidth: '600px',
    }

    return (
      <div style={{paddingTop: 100}}>
        <Grid container direction='column' justify='center' alignItems='center'>
          <Paper style={main} elevation={3}>
            <Grid container direction='column' justify='center' alignItems='center'>
              <h4>New Section</h4>
              <TextField 
                id="title" 
                label="Title" 
                autoComplete='off'
                fullWidth
                style={text}
                value={this.state.title}
                onChange={this.handleChange}
              />
              <div style={button}>
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={this.handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </Grid>
          </Paper>
        </Grid>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createSection: (section) => {dispatch(createSection(section))}
  }
}


export default connect(null, mapDispatchToProps)(NewSection)