import React, { useState } from 'react'
import Container from '@material-ui/core/Container';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import MKButton from '../../MaterialKitComponents/Button'
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { makeStyles } from '@material-ui/core/styles';
import { approveApp, denyApp } from '../../../Redux/Actions/userActions'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ApprovedApps from './ApprovedApps'
import DeniedApps from './DeniedApps'
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import Author from '../ForumComponents/Author'
import GetEmail from '../UserComponents/GetEmail'
import GetResume from '../AdminComponents/GetResume'
import GetDD214 from '../AdminComponents/GetDD214'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1),
    },
}));


function ApplicationsAdmin({ applications, approveApp, denyApp }) {

    const classes = useStyles()
    const [openApproveDialog, setOpenApproveDialog] = useState(false)
    const [openDenyDialog, setOpenDenyDialog] = useState(false)
    const [userToDeny, setUserToDeny] = useState(null)
    const [userToApprove, setUserToApprove] = useState(null)
    const [openApproveSB, setOpenApproveSB] = useState(false);
    const [openDenySB, setOpenDenySB] = useState(false);
    const preventDefault = event => event.preventDefault()
    
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenApproveSB(false)
        setOpenDenySB(false)
    }

    const confirm = (event, id) => {
        event.preventDefault()
        // popup to confirm decision to approve
        setOpenApproveDialog(true)
        setUserToApprove(id)
        // present snack bar notification that app has been approved
    }

    const approve = () => {
        // approve the app
        approveApp(userToApprove)
        setOpenApproveDialog(false)
        setUserToApprove(null)
        setOpenApproveSB(true);
    }

    const cancel = () => {
        setOpenApproveDialog(false)
        setUserToApprove(null)
    }

    const confirmDeny = (event, userId) => {
        event.preventDefault()
        setOpenDenyDialog(true)
        setUserToDeny(userId)
    }

    const deny = () => {
        // deny user action here
        denyApp(userToDeny)
        setOpenDenyDialog(false)
        setUserToDeny(null)
        setOpenDenySB(true)
    }

    const cancelDeny = () => {
        setOpenDenyDialog(false)
        setUserToDeny(null)
    }

    if (!applications) {
        return <div /> 
    }

    return (
        <div style={{paddingBottom: 50}}>
            <Container maxWidth='lg'>
                <div style={{textAlign: 'center'}}>
                    <h3>Active Applications</h3>
                </div>
                {applications.map(app => (
                    <ExpansionPanel key={app.id}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Grid 
                                container
                                direction='row'
                                justify='space-between'
                                alignItems='center'
                            >
                                <Grid item>
                                    <h5><b>{<Author authorId={app.id} textOnly />}</b></h5>
                                </Grid>
                                <Grid item>
                                    <p>Applied {moment(app.appCompleteDate.toDate().toString()).calendar()} </p>
                                </Grid>
                            </Grid>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid 
                                container
                                direction='row'
                                justify='space-around'
                                alignItems='center'
                            >
                                <Grid item>
                                    <Grid 
                                        container
                                        direction='column'
                                        justify='flex-start'
                                        alignItems='flex-start'
                                    >
                                        <Grid item>
                                            <p>Branch: <b>{app.branchOfService}</b></p>
                                        </Grid>
                                        {app.dateOfSeparation ? 
                                            <Grid item>
                                                <p>Sep Date: <b>{moment(app.dateOfSeparation.toDate().toString()).format("MMM Do YYYY")}</b></p>
                                            </Grid>
                                        :
                                            null
                                        }
                                        {app.activeDuty === true ? 
                                            <Grid item>
                                                <p>Active Duty: <b>yes</b></p>
                                            </Grid>
                                        :
                                            null
                                        }
                                        <Grid item>
                                            <p>Job: <b>{app.job}</b></p>
                                        </Grid>
                                        {app.secondaryJob ? 
                                            <p>Secondary Job: <b>{app.secondaryJob}</b></p>
                                        :   
                                            null
                                        }
                                        <Grid item>
                                            <p>Rank: <b>{app.rank}</b></p>
                                        </Grid>
                                        <Grid item>
                                            <p>Skills: <b>{app.skills.join(', ')}</b></p>
                                        </Grid>
                                        <Grid item>
                                            <p>Email: <b>{<GetEmail userId={app.id} />}</b></p>
                                        </Grid>
                                        <Grid item>
                                            <GetDD214 userId={app.id} />
                                        </Grid>
                                        <Grid item>
                                            <GetResume userId={app.id} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        direction='column'
                                        justify='flex-start'
                                        alignItems='flex-start'
                                    >
                                        <Grid item>
                                            <MKButton                                         
                                                color='primary'
                                                variant='contained'
                                                className={classes.button}
                                                startIcon={<ThumbUpIcon />}
                                                onClick={event => confirm(event, app.id)}
                                            >
                                                Approve
                                            </MKButton>
                                        </Grid>
                                        <Grid item>
                                            <MKButton 
                                                color='danger'
                                                variant='contained'
                                                className={classes.button}
                                                startIcon={<ThumbDownIcon />}
                                                onClick={event => confirmDeny(event, app.id)}
                                            >
                                                Deny
                                            </MKButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))}
                <Grid 
                    container
                    direction='row'
                    justify='space-evenly'
                    alignItems='flex-start'
                    spacing={2}
                >
                    <Grid item xs={6}>
                       <ApprovedApps />                     
                    </Grid>
                    <Grid item xs={6}>
                        <DeniedApps />
                    </Grid>
                </Grid>
            </Container>
            <Dialog
                open={openApproveDialog}
                onClose={() => setOpenApproveDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Approve this application?"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Once approved, this user will appear in the list of experts presented to companies.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={cancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={approve} color="primary" autoFocus>
                    Approve
                </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDenyDialog}
                onClose={() => setOpenDenyDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Deny this application?"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Be sure to send this user an email to let them know why their application has been denied.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={cancelDeny} color="primary">
                    Cancel
                </Button>
                <Button onClick={deny} color="primary" autoFocus>
                    Deny
                </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                open={openApproveSB}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
                    <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
                }
            >
                <Alert onClose={handleCloseSnackbar} severity="success">
                    Application Approved
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                open={openDenySB}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
                    <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
                }
            >
                <Alert onClose={handleCloseSnackbar} severity="error">
                    Application Denied
                </Alert>
            </Snackbar>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
      approveApp: (user) => {dispatch(approveApp(user))},
      denyApp: (userId) => {dispatch(denyApp(userId))}
    }
}

export default compose(
    firestoreConnect((props) => [
      {
        collection: 'application',
        where: [
            ['applicationComplete', '==', true],
            ['approved', '==', false],
            ['denied', '==', false],
          ],
          orderBy: [
            ['appCompleteDate', 'asc']
          ],
          storeAs: 'applications'
      },
    ]),
    connect((state, props) => ({
      applications: state.firestore.ordered.applications
    }), mapDispatchToProps)
)(ApplicationsAdmin)