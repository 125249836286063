import React, { useState, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
// mui components
import { makeStyles } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
// my components
import NewProfileCard from './NewProfileCard'
import ConsultantsFilter from './ConsultantsFilter'

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    marginTop: 150,
  },
  loader: {
    display: 'inlineBlock'
  },
  fill: {
    width: '100%',
    height: '100%',
  },
  people: {
    width: '70%',
    height: '100%',
  },
  search: {
    minWidth: 200,
    width: '20%',
    height: '100%',
  },
}))

function ConsultantsPage({ consultantsList, sendFilters }) {
  const classes = useStyles()

  const handleFilters = (filters) => {
    sendFilters(filters)
  }

  return (
      <div style={{marginTop: 100}}>
        <Container maxWidth='xl'>
          <Grid container direction='row' justify='flex-start' alignItems='flex-start' spacing={2} className={classes.fill}>
            <Grid item className={classes.search}>
              <ConsultantsFilter sendUpFilters={handleFilters}/>
            </Grid>
            <Grid item className={classes.people}>
              <Grid container direction='row' justify='center' alignItems='flex-start' spacing={2}>
                {consultantsList.map(consultant => (
                  <Grid item key={consultant.consultantId}>
                      <NewProfileCard 
                          appProfile={consultant.consultant} 
                          consultantId={consultant.consultantId}
                      />
                  </Grid>   
                ))}  
              </Grid>       
            </Grid>
          </Grid>
        </Container>
      </div>
  )
}
export default ConsultantsPage