import React, { useState } from 'react'
import { makeStyles  } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles(theme => ({
    menuButton: {
        textDecoration: 'none',
        color: grey[900],
        "&:hover": {
            color: grey[900]
        },
        "&:visited": {
            color: grey[900]
        },
        "&:active": {
            color: grey[900]
        },
        "&:link": {
            color: grey[900]
        },
        "&:link:active": {
            color: grey[900]
        },
        "&:visited:active": {
            color: grey[900]
        },
    },
}));

function AdminButton() {

    const [adminMenuAnchor, setAdminMenuAnchor] = useState(null)
    const classes = useStyles();

    const openAdminMenu = event => {
        console.log("REFFF", event.currentTarget)
        setAdminMenuAnchor(event.currentTarget)
    }

    const closeAdminMenu = () => {
        setAdminMenuAnchor(null)
    }

    return (
        <div>
            <Button 
                color="inherit"
                size='small'
                onClick={openAdminMenu}
            >
                Admin
                <ExpandMoreIcon />
            </Button>
            <Menu
                id="admin-menu"
                anchorEl={adminMenuAnchor}
                keepMounted
                open={Boolean(adminMenuAnchor)}
                onClose={closeAdminMenu}
            >
                <Link to='/admin/forum' className={classes.menuButton}>
                    <MenuItem onClick={closeAdminMenu}>Forum</MenuItem>
                </Link>
                <Link to='/admin/applications' className={classes.menuButton}>
                    <MenuItem onClick={closeAdminMenu}>Applications</MenuItem>
                </Link>
                <Link to='/admin/administrators' className={classes.menuButton}>
                    <MenuItem onClick={closeAdminMenu}>Administrators</MenuItem>
                </Link>
            </Menu>
        </div>
    )
}
export default AdminButton