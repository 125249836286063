import React, { useState, useEffect, Fragment } from 'react'
// redux
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector, useDispatch } from 'react-redux'
// material
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import MUISelect from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Chip from '@material-ui/core/Chip'
import CheckIcon from '@material-ui/icons/Check'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import DialogContentText from '@material-ui/core/DialogContentText'
// local
import { activateConversation } from '../../Redux/Actions/chatActions'
import Author from '../Components/ForumComponents/Author'
// react tags
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import '../css/customTags.css'
// react router
import { useParams, useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
// moment
import moment from 'moment'
// icons
import MoreIcon from '@material-ui/icons/MoreHoriz'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
// redux actions
import { 
    addNewJob, 
    archiveJob, 
    updateJob, 
    deleteJob,
    activateJob,
    closeContractClientSide 
} from '../../Redux/Actions/jobActions'

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2)
    },
    form: {
        width: 600,
    },
    smallForm: {
        width: 250,
    }, 
    smallestForm: {
        width: 125,
    },     
    skillTag: {
        backgroundColor: 'lightgrey',
        borderRadius: 10,
        padding: 5,
        color: 'black',
    },
}))

const styles = (theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
})

// Component which renders information about an inactive jobs statu
function ContractInfo({job}) {

    return (
        <div>
            Hello
        </div>
    )
}

function DisplayClosedLTMiddleWare({user_id, auth_id, job_id}) {

    useFirestoreConnect([
        {
            collection: 'users',
            doc: user_id,
            subcollections: [
                {
                    collection: 'closed_contracts',
                    where: ['active_contract.owner', '==', auth_id],
                }
            ],
            storeAs: `${user_id}-${auth_id}-closed_contracts`
        }
    ])
    const contracts = useSelector(state => state.firestore.ordered[`${user_id}-${auth_id}-closed_contracts`])
    if (!contracts) return null
    
    var contract_id = 0
    var cid = 0
    contracts.map(c => {
        if (c.active_contract.proposal.job_id === job_id) {
            contract_id = c.active_contract.proposal.owner
            cid = c.active_contract_id
        }
    })

    return <DisplayClosedLoggedTime contract_id={contract_id} jobId={cid} />
}

function DisplayLTMiddleWare({user_id, auth_id, job_id}) {

    useFirestoreConnect([
        {
            collection: 'users',
            doc: user_id,
            subcollections: [
                {
                    collection: 'active_contracts',
                    where: ['owner', '==', auth_id],
                }
            ],
            storeAs: `${user_id}-${auth_id}-active_contracts`
        }
    ])
    const contracts = useSelector(state => state.firestore.ordered[`${user_id}-${auth_id}-active_contracts`])
    if (!contracts) return null
    
    var contract_id = 0
    var cid = 0
    contracts.map(c => {
        if (c.proposal.job_id === job_id) {
            contract_id = c.proposal.owner
            cid = c.id
        }
    })

    return <DisplayLoggedTime contract_id={contract_id} jobId={cid} />
}

function DisplayClosedLoggedTime({contract_id, jobId}) {

    const [totalTime, setTotalTime] = useState(0)

    // get the times logged for this job
    useFirestoreConnect([
        {
            collection: 'contract_time',
            doc: jobId,
            subcollections: [
                {
                    collection: 'workers',
                    doc: contract_id,
                    subcollections: [
                        {
                            collection: 'timecard'
                        }
                    ]
                }
            ],
            storeAs: `${jobId}-${contract_id}-time`
        }
    ])
    const timecard = useSelector(state => state.firestore.ordered[`${jobId}-${contract_id}-time`])

    useEffect(() => {
        if (timecard && timecard[0]) {
            let minutes = 0
            timecard.map(entry => {
                let start = entry.startTime.toDate().getTime()
                let end = entry.endTime.toDate().getTime()
                minutes += (end - start) / (1000 * 60)
            })
            setTotalTime(minutes)
        }

    }, [timecard])

    const getTimeForWeek = () => {
        let minutes = 0
        let weekBeginning = moment().startOf('isoWeek').toDate()
        let weekEnd = moment().endOf('isoWeek').toDate()
        if (timecard && timecard[0]) {
            timecard.map(entry => {
                let startDate = entry.startTime.toDate()
                let endDate = entry.endTime.toDate()
                let start = startDate.getTime()
                let end = endDate.getTime()
                if (weekBeginning.getTime() <= start && weekEnd.getTime() >= end) {
                    minutes += (end - start) / (1000 * 60)
                }
            })
        }
        return minutes
    }

    if (!timecard) return <div />

    return (
        <div>
            <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item>
                    <h5><b>Time Logged:</b></h5>
                </Grid>
            </Grid>
            <h6>{`Total: ${moment.duration(totalTime, 'minutes').asHours().toFixed(2)} hrs`}</h6>
        </div>
    )
}

function DisplayLoggedTime({contract_id, jobId}) {

    const [totalTime, setTotalTime] = useState(0)

    // get the times logged for this job
    useFirestoreConnect([
        {
            collection: 'contract_time',
            doc: jobId,
            subcollections: [
                {
                    collection: 'workers',
                    doc: contract_id,
                    subcollections: [
                        {
                            collection: 'timecard'
                        }
                    ]
                }
            ],
            storeAs: `${jobId}-${contract_id}-time`
        }
    ])
    const timecard = useSelector(state => state.firestore.ordered[`${jobId}-${contract_id}-time`])

    useEffect(() => {
        if (timecard && timecard[0]) {
            let minutes = 0
            timecard.map(entry => {
                let start = entry.startTime.toDate().getTime()
                let end = entry.endTime.toDate().getTime()
                minutes += (end - start) / (1000 * 60)
            })
            setTotalTime(minutes)
        }

    }, [timecard])

    const getTimeForWeek = () => {
        let minutes = 0
        let weekBeginning = moment().startOf('isoWeek').toDate()
        let weekEnd = moment().endOf('isoWeek').toDate()
        if (timecard && timecard[0]) {
            timecard.map(entry => {
                let startDate = entry.startTime.toDate()
                let endDate = entry.endTime.toDate()
                let start = startDate.getTime()
                let end = endDate.getTime()
                if (weekBeginning.getTime() <= start && weekEnd.getTime() >= end) {
                    minutes += (end - start) / (1000 * 60)
                }
            })
        }
        return minutes
    }

    if (!timecard) return <div />

    return (
        <div>
            <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item>
                    <h5><b>Time Logged:</b></h5>
                </Grid>
                <Grid item>
                    <Link 
                        to={`/view-time/${contract_id}/${jobId}`} 
                    >
                        See Details
                    </Link>
                </Grid>
            </Grid>
            <h6>{`Total: ${moment.duration(totalTime, 'minutes').asHours().toFixed(2)} hrs`}</h6>
            <h6>{`This week: ${moment.duration(getTimeForWeek(), 'minutes').asHours().toFixed(2)} hrs`}</h6>
        </div>
    )
}

// this is rendered when the closed-contracts tab is active
function ClosedContracts(props) {

    const classes = useStyles()

    // pull url params
    const { user_id } = useParams()

    // dispatch redux actions
    const dispatch = useDispatch()

    // state
    const [anchorEl, setAnchorEl] = useState(null)
    const [focusedContract, setFocusedContract] = useState(null)
    const [closeContract, setCloseContract] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    useFirestoreConnect([
        {
            collection: 'users',
            doc: user_id,
            subcollections: [{ 
                collection: 'closed_contracts', 
                orderBy: [
                    ['timestamp', 'desc']
                ],
            }],
            storeAs: `${user_id}-closed-contracts`
        }
    ])
    const contracts = useSelector(state => state.firestore.ordered[`${user_id}-closed-contracts`])

    const handleOpenMenu = (event, contract) => {
        setAnchorEl(event.currentTarget);
        setFocusedContract(contract)
    }

    const handleCloseMenu = () => {
        setAnchorEl(null)
        setFocusedContract(null)
    }

    if (!contracts) {
        return (
            <div style={{paddingTop: 150, textAlign: 'center'}}>
                <CircularProgress />
            </div>
        )
    }

    if (contracts.length === 0) {
        return (
            <Paper className={classes.paper}>
                <h4 style={{textAlign: 'center'}}>You currently have no contracts</h4>
            </Paper>
        )
    }
    console.log(contracts)
    console.log(focusedContract)
    
    return (
        <Fragment>
            {contracts && contracts.map((contract, index) => (
                <Paper key={index} variant='outlined' square className={classes.paper}>
                    <Grid container direction='row' justify='space-between'>
                        <Grid item>
                            <h5><b>{contract.active_contract.jobData.title}</b></h5>
                        </Grid>
                        <Grid item>
                            <Grid direction='row'>
                                <Grid item>
                                    <h5>{contract.active_contract.timestamp && `${moment(contract.timestamp.toDate()).format("MMM Do YYYY")}`}</h5>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' alignItems='space-between' spacing={2}>
                        <Grid item style={{width: '90%'}}>
                            <h5 style={{whiteSpace: 'pre-wrap'}}>{contract.active_contract.jobData.description}</h5>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' spacing={1}>
                        {contract.active_contract.jobData.skillsNeeded.map((skill, index) => (
                            <Grid item key={index}>
                                <p className={classes.skillTag}>{skill}</p>
                            </Grid>
                        ))}
                    </Grid>
                    <h5><b>{`${contract.active_contract.jobData.hourlyMin} - ${contract.active_contract.jobData.hourlyMax}`}</b>{` per hour - ${contract.active_contract.jobData.hoursPerWeek} hours per week - ${contract.active_contract.jobData.jobLength}`}</h5>
                    {contract.active_contract.jobData.questions.map((question, index) => (
                        <h5 key={index}>{question}</h5>
                    ))}
                    <Proposals jobId={contract.active_contract.jobId} />
                    <Chip
                        style={{marginLeft: 10}}
                        icon={<CheckIcon style={{color: 'green'}} />}
                        label={`1 hired`}
                        variant='outlined'             
                    />
                    <DisplayClosedLTMiddleWare user_id={contract.active_contract.user} auth_id={user_id} job_id={contract.active_contract.jobId} />
                </Paper>
            ))}
        </Fragment>
    )
}

// this is rendered when the contracts tab is active
function Contracts(props) {

    const classes = useStyles()

    // pull url params
    const { user_id } = useParams()

    // dispatch redux actions
    const dispatch = useDispatch()

    // state
    const [anchorEl, setAnchorEl] = useState(null)
    const [focusedContract, setFocusedContract] = useState(null)
    const [closeContract, setCloseContract] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    useFirestoreConnect([
        {
            collection: 'users',
            doc: user_id,
            subcollections: [{ 
                collection: 'active_contracts', 
                orderBy: [
                    ['timestamp', 'desc']
                ],
            }],
            storeAs: `${user_id}-contracts`
        }
    ])
    const contracts = useSelector(state => state.firestore.ordered[`${user_id}-contracts`])

    const handleOpenMenu = (event, contract) => {
        setAnchorEl(event.currentTarget);
        setFocusedContract(contract)
    }

    const handleCloseMenu = () => {
        setAnchorEl(null)
        setFocusedContract(null)
    }

    if (!contracts) {
        return (
            <div style={{paddingTop: 150, textAlign: 'center'}}>
                <CircularProgress />
            </div>
        )
    }

    if (contracts.length === 0) {
        return (
            <Paper className={classes.paper}>
                <h4 style={{textAlign: 'center'}}>You currently have no contracts</h4>
            </Paper>
        )
    }
    
    return (
        <Fragment>
            {contracts && contracts.map((contract, index) => (
                <Paper key={index} variant='outlined' square className={classes.paper}>
                    <Grid container direction='row' justify='space-between'>
                        <Grid item>
                            <h5><b>{contract.jobData.title}</b></h5>
                        </Grid>
                        <Grid item>
                            <Grid direction='row'>
                                <Grid item>
                                    <h5>{contract.timestamp && `${moment(contract.timestamp.toDate()).format("MMM Do YYYY")}`}</h5>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' alignItems='space-between' spacing={2}>
                        <Grid item style={{width: '90%'}}>
                            <h5 style={{whiteSpace: 'pre-wrap'}}>{contract.jobData.description}</h5>
                        </Grid>
                        <Grid item>
                            <Fab 
                                size='small'
                                onClick={(e) => {handleOpenMenu(e,contract)}}
                            >
                                <MoreIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' spacing={1}>
                        {contract.jobData.skillsNeeded.map((skill, index) => (
                            <Grid item key={index}>
                                <p className={classes.skillTag}>{skill}</p>
                            </Grid>
                        ))}
                    </Grid>
                    <h5><b>{`${contract.jobData.hourlyMin} - ${contract.jobData.hourlyMax}`}</b>{` per hour - ${contract.jobData.hoursPerWeek} hours per week - ${contract.jobData.jobLength}`}</h5>
                    {contract.jobData.questions.map((question, index) => (
                        <h5 key={index}>{question}</h5>
                    ))}
                    <Proposals jobId={contract.jobId} />
                    <Chip
                        style={{marginLeft: 10}}
                        icon={<CheckIcon style={{color: 'green'}} />}
                        label={`1 hired`}
                        variant='outlined'             
                    />
                    <DisplayLTMiddleWare user_id={contract.user} auth_id={user_id} job_id={contract.jobId} />
                </Paper>
            ))}
            {/* Menu for more floating action button */}
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                <MenuItem 
                    onClick={(e) => {
                        e.preventDefault()
                        setCloseContract(true)
                    }}
                >
                    Close Contract
                </MenuItem>
                <MenuItem 
                    onClick={(e) => {
                        e.preventDefault()
                        const user = {
                            id: focusedContract.user
                        }
                        dispatch(activateConversation(user))
                        handleCloseMenu()
                    }}
                >
                    Message User
                </MenuItem>
            </Menu>
            <Dialog
                open={closeContract}
                onClose={() => {
                    setCloseContract(false)
                }}
            >
                <DialogTitle>
                    {focusedContract && <h3>Close Contract with <span><Author authorId={focusedContract.user}/></span>?</h3>}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <h5>Make sure that you have spoken with this User about closing the contract. It is best to proceed only when you and the User have agreed to end the contract.</h5>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color='secondary'
                        onClick={(e) => {
                            e.preventDefault()
                            setCloseContract(false)
                            handleCloseMenu()
                            setErrorMessage(null)
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        color='primary'
                        disabled={loading}
                        onClick= {e => {
                            e.preventDefault()
                            setLoading(true)
                            dispatch(closeContractClientSide(focusedContract.id, focusedContract.proposal.job_id, focusedContract.user))
                            .then(() => {
                                setCloseContract(false)
                                handleCloseMenu()
                                setErrorMessage(null)
                            })
                            .catch(e => {
                                setErrorMessage('There was an error closing the contract')
                            })
                            .finally(() => {
                                setLoading(false)
                            })
                        }}
                    >
                        {loading ? 
                            <CircularProgress size='20px' />
                        :
                            `Confirm`
                        }
                    </Button>
                </DialogActions>
                <p style={{color: 'red', textAlign: 'right', paddingRight: 10}}>{errorMessage && errorMessage}</p>
            </Dialog>
        </Fragment>
    )
}

function Proposals({ jobId }) {

    // for navigation
    const history = useHistory()

    // fetch proposals for this job
    useFirestoreConnect([
        {
            collection: 'proposals',
            where: [
                ['job_id', '==', jobId]
            ],
            storeAs: `${jobId}-proposals`
        }
    ])
    const proposals = useSelector(state => state.firestore.ordered[`${jobId}-proposals`])

    if (!proposals) return null

    return (
        <Fragment>
        {proposals.length > 1 ? 
            <Chip
                icon={<CheckIcon style={{color: 'green'}} />}
                label={`${proposals.length} proposals`}
                variant='outlined'
                onClick={() => {
                    history.push(`/proposals/${jobId}`)
                }}
            />
        :
            (proposals.length > 0 ?
                <Fragment>
                    <Chip
                        icon={<CheckIcon style={{color: 'green'}} />}
                        label={`1 proposal`}
                        variant='outlined'
                        onClick={() => {
                            history.push(`/proposals/${jobId}`)
                        }}                
                    />
                </Fragment>
            : 
                null
            )
        }
        </Fragment>
    )
}

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <h4><b>{children}</b></h4>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const hourly = [
    '$10',
    '$15',
    '$20',
    '$25',
]

const lengths = [
    'week',
    'weeks',
    'month',
    'months'
]

const hours = [1,2,3,4,5,6,7,8,9,10]

function JobListings(props) {

    // styles
    const classes = useStyles()

    // for navigation
    const history = useHistory()

    let auth = useSelector(state => state.firebase.auth)
    if (auth.isLoaded) {
        if (!auth.uid) {
            history.push('/login')
        }
    }

    // pull url params
    const { user_id, tab='active' } = useParams()

    // for dispatching redux actions
    const dispatch = useDispatch()

    // local state
    const [menuAnchor, setMenuAnchor] = useState(null)
    const [inactiveMenuAnchor, setInactiveMenuAnchor] = useState(null)
    const [loading, setLoading] = useState(false)
    const [formComplete, setFormComplete] = useState(false)
    const [openForm, setOpenForm] = useState(false)
    const [focusedJob, setFocusedJob] = useState(null)
    const [editJob, setEditJob] = useState(false)
    const [currentQuestion, setCurrentQuestion] = useState('')
    const [questions, setQuestions] = useState([])
    const [jobData, setJobData] = useState({
        title: '',
        description: '',
        hourlyMin: '',
        hourlyMax: '',
        hoursPerWeek: '',
        jobLength: '',
        jobLengthName: '',
        skillsNeeded: [],
        questions: [],
    })

    // handlers
    const handleSubmitForm = (e) => {
        e.preventDefault()
        setLoading(true)
        if (editJob && focusedJob && focusedJob.id) {
            dispatch(updateJob(jobData, focusedJob.id))
            .then(() => {
                setLoading(false)
                setOpenForm(false)
                clearMenu()
                clearJobData()
            }).catch(e => setLoading(false))
        }
        else if (!editJob && !focusedJob) {
        dispatch(addNewJob(jobData))
            .then(() => {
                setLoading(false)
                setOpenForm(false)
                clearJobData()
            }).catch(e => setLoading(false))
        }
    }

    const handleArchiveJob = (e) => {
        e.preventDefault()
        if (focusedJob) {
            dispatch(archiveJob(focusedJob.jobData, focusedJob.id, focusedJob.timestamp))
        }
        clearMenu()
    }

    const handleDeleteJob = (e) => {
        e.preventDefault()
        if (focusedJob) {
            dispatch(deleteJob(focusedJob.id))
        }
        clearInactiveMenu()
    }

    const handleEditJob = (e) => {
        e.preventDefault()
        setJobData(focusedJob.jobData)
        setQuestions(focusedJob.jobData.questions)
        setOpenForm(true)
        setEditJob(true)
    }

    const handleCloseForm = (e) => {
        e.preventDefault()
        setOpenForm(false)
        clearMenu()
    }

    const handleActivateJob = (e) => {
        e.preventDefault()
        if (focusedJob) {
            dispatch(activateJob(focusedJob.jobData, focusedJob.id, focusedJob.timestamp))
        }
        clearInactiveMenu()
    }

    const isNumber = (evt) => {
        evt = (evt) ? evt : window.event
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false
        }
        return true
    }

    const clearMenu = () => {
        setMenuAnchor(null)
        setFocusedJob(null)
        setEditJob(false)
        clearJobData()
    }

    const clearInactiveMenu = () => {
        setInactiveMenuAnchor(null)
        setFocusedJob(null)
        setEditJob(false)
        clearJobData()
    }

    const clearJobData = () => {
        setJobData({
            title: '',
            description: '',
            hourlyMin: '',
            hourlyMax: '',
            hoursPerWeek: '',
            jobLength: '',
            skillsNeeded: [],
            questions: [],
        })
    }

    useFirestoreConnect([
        {
            collection: 'active_jobs',
            where: ['owner', '==', user_id],
            orderBy: [
                ['timestamp', 'desc']
            ],
            storeAs: `${user_id}-jobs`
        }
    ])
    const jobs = useSelector(state => state.firestore.ordered[`${user_id}-jobs`])

    useFirestoreConnect([
        {
            collection: 'inactive_jobs',
            where: ['owner', '==', user_id],
            orderBy: [
                ['timestamp', 'desc']
            ],
            storeAs: `${user_id}-inactive_jobs`
        }
    ])
    const inactiveJobs = useSelector(state => state.firestore.ordered[`${user_id}-inactive_jobs`])

    useEffect(() => {
        if (jobData.title !== '' && jobData.description !== '' && jobData.hourlyMin !== '' && jobData.hourlyMax !== '' && jobData.hoursPerWeek !== '' && jobData.jobLength !== '' && jobData.jobLengthName !== '' && jobData.skillsNeeded.length > 0) {
            setFormComplete(true)
        }
        else {
            setFormComplete(false)
        }
    }, [jobData])


    if (!jobs && tab == 'active') return (
        <div style={{paddingTop: 150, textAlign: 'center'}}>
         <CircularProgress />
        </div>
    )
    else if (!inactiveJobs && tab == 'archived') return (
        <div style={{paddingTop: 150, textAlign: 'center'}}>
         <CircularProgress />
        </div>
    )

    const renderNoJobs = (
        <Fragment>
            <Paper className={classes.paper}>
                <h4 style={{textAlign: 'center'}}>You have no active job listings</h4>
                <Grid container direction='row' justify='center' alignItems='center'>
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={(e) => {
                            e.preventDefault()
                            setOpenForm(true)
                        }}
                    >
                        Post a Job
                    </Button>
                </Grid>
            </Paper>
        </Fragment>
    )

    const renderJobs = (
        <Fragment>
            {jobs && jobs.map((job, index) => (
                <Paper key={index} variant='outlined' square className={classes.paper}>
                    <Grid container direction='row' justify='space-between'>
                        <Grid item>
                            <h5><b>{job.jobData.title}</b></h5>
                        </Grid>
                        <Grid item>
                            <h5>{job.timestamp && `${moment(job.timestamp.toDate()).format("MMM Do YYYY")}`}</h5>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' alignItems='center' spacing={2}>
                        <Grid item style={{width: '90%'}}>
                            <h5 style={{whiteSpace: 'pre-wrap'}}>{job.jobData.description}</h5>
                        </Grid>
                        <Grid item>
                            <Fab 
                                size='small'
                                onClick={(e) => {
                                    e.preventDefault()
                                    setFocusedJob(job)
                                    setMenuAnchor(e.currentTarget)
                                }}
                            >
                                <MoreIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' spacing={1}>
                        {job.jobData.skillsNeeded.map((skill, index) => (
                            <Grid item key={index}>
                                <p className={classes.skillTag}>{skill}</p>
                            </Grid>
                        ))}
                    </Grid>
                    <h5><b>{`$${job.jobData.hourlyMin} - $${job.jobData.hourlyMax}`}</b>{` per hour - ${job.jobData.hoursPerWeek} hours per week - ${job.jobData.jobLength} ${job.jobData.jobLengthName}`}</h5>
                    {job.jobData.questions.map((question, index) => (
                        <h5 key={index}>{question}</h5>
                    ))}
                    <Proposals jobId={job.id} />
                </Paper>
            ))}
        </Fragment>
    )

    const renderNoInactiveJobs = (
        <Fragment>
            <Paper className={classes.paper}>
                <h4 style={{textAlign: 'center'}}>You have no archived job listings</h4>
            </Paper>
        </Fragment>
    )


    const renderInactiveJobs = (
        <Fragment>
            {inactiveJobs && inactiveJobs.map((job, index) => (
                <Paper key={index} variant='outlined' square className={classes.paper}>
                    <Grid container direction='row' justify='space-between'>
                        <Grid item>
                            <h5><b>{job.jobData.title}</b></h5>
                        </Grid>
                        <Grid item>
                            <h5>{job.timestamp && `${moment(job.timestamp.toDate()).format("MMM Do YYYY")}`}</h5>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' alignItems='center' spacing={2}>
                        <Grid item style={{width: '90%'}}>
                            <h5 style={{whiteSpace: 'pre-wrap'}}>{job.jobData.description}</h5>
                        </Grid>
                        <Grid item>
                            <Fab 
                                size='small'
                                onClick={(e) => {
                                    e.preventDefault()
                                    setFocusedJob(job)
                                    setInactiveMenuAnchor(e.currentTarget)
                                }}
                            >
                                <MoreIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' spacing={1}>
                        {job.jobData.skillsNeeded.map((skill, index) => (
                            <Grid item key={index}>
                                <p className={classes.skillTag}>{skill}</p>
                            </Grid>
                        ))}
                    </Grid>
                    <h5><b>{`${job.jobData.hourlyMin} - ${job.jobData.hourlyMax}`}</b>{` per hour - ${job.jobData.hoursPerWeek} hours per week - ${job.jobData.jobLength}`}</h5>
                    {job.jobData.questions.map((question, index) => (
                        <h5 key={index}>{question}</h5>
                    ))}
                    <ContractInfo job={job} />
                </Paper>
            ))}
        </Fragment>
    )

    return (
        <div style={{paddingTop: 100, paddingBottom: 100}}>
            <Container maxWidth='md'>
                <AppBar color='default' position='static'>
                    <Toolbar>
                        {tab === 'active' ?
                            <h4 style={{paddingRight: 10}}><b>Active Jobs</b></h4>
                        :
                        null
                        }
                        {tab === 'archived' ?
                            <h4 style={{paddingRight: 10}}><b>Archived Jobs</b></h4>
                            :
                            null
                        }
                        {tab === 'contracts' ?
                            <h4 style={{paddingRight: 10}}><b>Contracts</b></h4>
                            :
                            null
                        }
                        {tab === 'closed-contracts' ?
                            <h4 style={{paddingRight: 10}}><b>Closed Contracts</b></h4>
                            :
                            null
                        }
                        <Tooltip title='View your active job listings'>
                            <Button 
                                onClick={(e) => {
                                    e.preventDefault()
                                    history.push(`/job-listings/${user_id}/active`)
                                }}
                            >
                                Jobs
                            </Button>
                        </Tooltip>
                        <Tooltip title='View your archived job listings'>
                            <Button 
                                onClick={(e) => {
                                    e.preventDefault()
                                    history.push(`/job-listings/${user_id}/archived`)
                                }}
                            >
                                Archived
                            </Button>
                        </Tooltip>
                        <Tooltip title='View your active contracts'>
                            <Button 
                                onClick={(e) => {
                                    e.preventDefault()
                                    history.push(`/job-listings/${user_id}/contracts`)
                                }}
                            >
                                Contracts
                            </Button>
                        </Tooltip>
                        <Tooltip title='View your closed contracts'>
                            <Button 
                                onClick={(e) => {
                                    e.preventDefault()
                                    history.push(`/job-listings/${user_id}/closed-contracts`)
                                }}
                            >
                                Closed
                            </Button>
                        </Tooltip>
                        {tab === 'active' ?
                            <Tooltip title='New Job'>
                                <Fab 
                                    style={{marginLeft: 'auto'}} 
                                    size='small'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setOpenForm(true)
                                    }}
                                >
                                    <AddIcon />
                                </Fab>
                            </Tooltip>
                            : null
                        }
                    </Toolbar>
                </AppBar>
                {tab === 'active' ?
                    <Fragment>{jobs.length === 0 ? renderNoJobs : renderJobs}</Fragment>
                    :
                    null
                }
                {tab === 'archived' ?
                    <Fragment>{inactiveJobs.length === 0 ? renderNoInactiveJobs : renderInactiveJobs}</Fragment>
                    :
                    null
                }
                {tab === 'contracts' ?
                    <Contracts />
                    :
                    null
                }
                {tab === 'closed-contracts' ?
                    <ClosedContracts />
                    :
                    null
                }
            </Container>

            {/* Floating Action Button Menu For active jobs*/}
            <Menu
                id="edit-menu"
                anchorEl={menuAnchor}
                keepMounted
                open={Boolean(menuAnchor)}
                onClose={clearMenu}
            >
                <MenuItem onClick={handleEditJob}>Edit</MenuItem>
                <MenuItem onClick={handleArchiveJob}>Archive</MenuItem>
            </Menu>

            
            {/* Floating Action Button Menu For inactive jobs*/}
            <Menu
                id="edit-menu"
                anchorEl={inactiveMenuAnchor}
                keepMounted
                open={Boolean(inactiveMenuAnchor)}
                onClose={clearInactiveMenu}
            >
                <MenuItem onClick={handleActivateJob}>Activate</MenuItem>
                <MenuItem onClick={handleDeleteJob}>Delete</MenuItem>
            </Menu>

            {/* New Job Listing Form */}
            <Dialog maxWidth='xl' onClose={handleCloseForm} open={openForm}>
                <DialogTitle onClose={handleCloseForm}>
                    Job
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container direction='column' spacing={2}>
                        <Grid item>
                            <p><b>Job Title</b></p>
                            <TextField 
                                variant='outlined'
                                className={classes.form}
                                size='small'
                                value={jobData.title}
                                onChange={(e) => setJobData({...jobData, title: e.target.value})}
                            />
                        </Grid>
                        <Grid item>
                            <p style={{marginBottom: 0}}><b>Budget and Job Length</b></p>
                            <Grid container direction='row' justify='space-between' alignItems='flex-end'>
                                <Grid item>
                                    <FormControl>
                                        <InputLabel>Hourly Min</InputLabel>
                                        <Input
                                            type='text'
                                            className={classes.smallForm}
                                            value={jobData.hourlyMin || ''}
                                            onChange={(e) => {
                                                const re = /^[0-9\b]+$/
                                                if (e.target.value === '' || re.test(e.target.value)) {
                                                    setJobData({...jobData, hourlyMin: e.target.value})
                                                }
                                            }}
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            endAdornment={<InputAdornment position="start">/ hour</InputAdornment>}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <InputLabel>Hourly Max</InputLabel>
                                        <Input
                                            className={classes.smallForm}
                                            value={jobData.hourlyMax || ''}
                                            onChange={(e) => {
                                                const re = /^[0-9\b]+$/
                                                if (e.target.value === '' || re.test(e.target.value)) {
                                                    setJobData({...jobData, hourlyMax: e.target.value})
                                                }
                                            }}                                            
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            endAdornment={<InputAdornment position="start">/ hour</InputAdornment>}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction='row' justify='space-between' alignItems='flex-end'>
                                <Grid item>
                                    <FormControl>
                                        <InputLabel>Hours Per Week</InputLabel>
                                        <Input
                                            className={classes.smallForm}
                                            value={jobData.hoursPerWeek || ''}
                                            onChange={(e) => {
                                                const re = /^[0-9\b]+$/
                                                if (e.target.value === '' || re.test(e.target.value)) {
                                                    setJobData({...jobData, hoursPerWeek: e.target.value})
                                                }
                                            }}  
                                            endAdornment={<InputAdornment position="start">hours / week</InputAdornment>}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <TextField
                                            label='Job Length'
                                            className={classes.smallestForm}
                                            value={jobData.jobLength || ''}
                                            onChange={(e) => {
                                                const re = /^[0-9\b]+$/
                                                if (e.target.value === '' || re.test(e.target.value)) {
                                                    setJobData({...jobData, jobLength: e.target.value})
                                                }
                                            }}                                         
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <InputLabel>month/week</InputLabel>
                                        <MUISelect 
                                            className={classes.smallestForm}
                                            value={jobData.jobLengthName || ''}
                                            onChange={(e) => setJobData({...jobData, jobLengthName: e.target.value})}
                                        >
                                            {
                                                lengths.map((length, index) => (
                                                    <MenuItem key={index} value={length}>{length}</MenuItem>
                                                ))
                                            }
                                        </MUISelect>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <p><b>Description</b></p>
                            <TextField 
                                variant='outlined'
                                size='small'
                                className={classes.form}
                                rows={5}
                                multiline
                                value={jobData.description}
                                onChange={(e) => setJobData({...jobData, description: e.target.value})}
                            />
                        </Grid>
                        <Grid item>
                            <p><b>Questions</b></p>
                            <p>Questions you would like to ask</p>
                            {questions.length > 0 && questions.map((question, index) => (
                                <Grid key={index} container direction='row' alignItems='flex-start' spacing={2}>
                                    <Grid item>
                                        <p style={{maxWidth: 500, paddingTop: 3}}><b>{question}</b></p>
                                    </Grid>
                                    <Grid item>
                                        <IconButton 
                                            size='small'
                                            onClick={(e) => {
                                                e.preventDefault()
                                                const copy = questions.slice()
                                                copy.splice(index, 1)
                                                setQuestions(copy)
                                                setJobData({
                                                    ...jobData,
                                                    questions: copy
                                                })
                                            }}
                                        >
                                            <CloseIcon fontSize='small' />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid container direction='row' justify='space-between'>
                                <Grid item>
                                    <TextField 
                                        variant='outlined'
                                        color='primary'
                                        size='small'
                                        style={{width: 500}}
                                        value={currentQuestion}
                                        placeholder='Ex. Give examples of a time when...'
                                        onChange={(e) => {
                                            setCurrentQuestion(e.target.value)
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        color='primary'
                                        onClick={(e) => {
                                            e.preventDefault()
                                            let copy = questions.slice()
                                            if (currentQuestion !== '') {
                                                copy.push(currentQuestion)
                                                setQuestions(copy)
                                                setJobData({
                                                    ...jobData,
                                                    questions: copy
                                                })
                                            }
                                            setCurrentQuestion('')
                                        }}
                                    >
                                        <AddIcon />
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <p><b>Skills Needed</b></p>
                            <TagsInput 
                                value={jobData.skillsNeeded}
                                onChange={(tags) => setJobData({...jobData, skillsNeeded: tags})}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseForm} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={!formComplete} onClick={handleSubmitForm} variant='contained' color="primary">
                        { loading ?
                            <CircularProgress color='secondary' size='20px' />
                            :
                            'Save'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default JobListings