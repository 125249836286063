import React, { useState, Fragment } from 'react' 
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux'
import { addAdmin } from '../../../Redux/Actions/authActions'
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import grey from '@material-ui/core/colors/grey'
import green from '@material-ui/core/colors/green'
import PeopleSearch from '../UserComponents/PeopleSearch'
import ShowUser from './ShowUser'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  form: {
    width: 300,
  },
  input: {
      margin: 15,
  },
  paper: {
    height: 350,
    width: 500,
    padding: 60,
    color: grey[900],
    textAlign: 'center',
},
success: {
    color: green[500],
},
}));


function AddAdmin({ addAdmin, addAdminError, addAdminSuccess }) {
    const classes = useStyles();
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(false)

    const submit = (event, _user) => {
        event.preventDefault()
        setLoading(true)
        addAdmin(_user)
    }

    const handleUser = (userId) => {
        setUser(userId)
    }

    return (
        <div style={{paddingTop: 115}}>
            <Container maxWidth='md'>
                <Grid container direction='column' justify='center' alignItems='center'>
                    <Paper className={classes.paper} elevation={2}>
                        <Grid container direction='column' justify='center' alignItems='center'>      
                            <h5>Search for the user you would like to make an admin</h5>
                            <PeopleSearch sendUserId={handleUser} portal />
                            <Fragment>
                                <p className={classes.success}><b>{addAdminSuccess ? addAdminSuccess : null}</b></p>
                            </Fragment>
                            {user ? 
                                <Fragment>
                                    <ShowUser userId={user} changeLoading={setLoading}/>
                                    {loading ?
                                        <CircularProgress />
                                    :
                                        <Button 
                                            variant="contained"
                                            onClick={(event) => submit(event, user)}
                                            color='primary'
                                        >
                                            Make Admin
                                        </Button>
                                    } 
                                </Fragment>
                            :
                                null
                            }      
                        </Grid>
                    </Paper>
                </Grid>
            </Container>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      addAdminError: state.auth.addAdminError,
      addAdminSuccess: state.auth.addAdminSuccess,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addAdmin: (userId) => dispatch(addAdmin(userId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAdmin)