// action creator
export const createTopic = (topic) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const batch = firestore.batch()
    const authId = getState().firebase.auth.uid

    const docRef = firestore.collection('forum')
                  .doc(topic.forum_doc_id)
                  .collection('genre')
                  .doc(topic.genre_doc_id)
                  .collection('topic')
                  .doc()
    batch.set(docRef, {
      'title': topic.topic,
      'lastComment': firestore.FieldValue.serverTimestamp(),
      'originatingAuthorId': authId,
      'lastAuthor': topic.author,
      'views': [],
      'totalComments': 1,
      'isPrivate': false,
      'createdAt': firestore.FieldValue.serverTimestamp(),
    })
    // add comment
    const comRef =  firestore.collection('forum')
                              .doc(topic.forum_doc_id)
                              .collection('genre')
                              .doc(topic.genre_doc_id)
                              .collection('topic')
                              .doc(docRef.id)
                              .collection('comment')
                              .doc()
    batch.set(comRef, {
      'authorId': authId,
      'comment': topic.comment,
      'upVotes': [],
      'edits': 0,
      'createdAt': firestore.FieldValue.serverTimestamp(),
    })
    // update the last post data in the forum genre
    const genreRef = firestore.collection('forum')
                              .doc(topic.forum_doc_id)
                              .collection('genre')
                              .doc(topic.genre_doc_id)
    batch.update(genreRef, {
      'lastPost': firestore.FieldValue.serverTimestamp(),
    })
    // commmit batch
    batch.commit()
    .then(() => {
      dispatch({type: 'CREATE_TOPIC', topic: topic})
    }).catch((error) => {
      dispatch({type: 'CREATE_TOPIC_ERROR', error})
    })
  }
}

export const deleteTopic = (topic) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    firestore.collection('forum')
              .doc(topic.forum_doc_id)
              .collection('genre')
              .doc(topic.genre_doc_id)
              .collection('topic')
              .doc(topic.topic_doc_id)
              .delete()
    .then(() => {
      dispatch({type: 'DELETE_TOPIC', topic: topic})
    }).catch((error) => {
      dispatch({type: 'DELETE_TOPIC_ERROR', error})
    })
  }
}

export const deletePrivateEntry = (privateId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    firestore.collection('private_topic')
              .doc(privateId)
              .delete()
    .then(() => {
      dispatch({type: 'DELETE_PRIVATE', id: privateId})
    }).catch((error) => {
      dispatch({type: 'DELETE_PRIVATE_ERROR', error})
    })
  }
}

export const addView = (view) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {  

    const firestore = getFirestore()
    const firebase = getFirebase()

    var docRef = firestore.collection('forum')
                          .doc(view.forum_doc_id)
                          .collection('genre')
                          .doc(view.genre_doc_id)
                          .collection('topic')
                          .doc(view.topic_doc_id)
    
    docRef.get().then(function(doc) {
      if (doc.exists) {
        if (!doc.data().views.includes(view.userId)) {
          firestore.collection('forum')
                  .doc(view.forum_doc_id)
                  .collection('genre')
                  .doc(view.genre_doc_id)
                  .collection('topic')
                  .doc(view.topic_doc_id)
                  .update({
                    'views': firebase.firestore.FieldValue.arrayUnion(view.userId)
                  })
          .then(() => {
            dispatch({type: 'UPDATE_VIEWS', view: view})
          }).catch((error) => {
            dispatch({type: 'UPDATE_VIEWS_ERROR', error})
          })
        }
      }
    }).catch((error) => {
      dispatch({type: 'UPDATE_VIEWS_ERROR', error})
    })
  }
}

export const makePrivate = (topic) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const batch = firestore.batch()
    // mark private
    const docRef = firestore.collection('forum')
                            .doc(topic.forum_doc_id)
                            .collection('genre')
                            .doc(topic.genre_doc_id)
                            .collection('topic')
                            .doc(topic.topic_doc_id)
    batch.update(docRef, {
      'isPrivate': true,
    })
    // add to private collection
    const privRef = firestore.collection('private_topic').doc()
    batch.set(privRef, {
      'forum_doc_id': topic.forum_doc_id,
      'genre_doc_id': topic.genre_doc_id,
      'topic_doc_id': topic.topic_doc_id, 
      'originatingAuthorId': topic.topic.originatingAuthorId,
      'title': topic.title,
    })
    // commit batch
    batch.commit()
    .then(() => {
      dispatch({type: 'MAKE_TOPIC_PRIVATE', topic: topic})
    }).catch(error => {
      dispatch({type: 'MAKE_TOPIC_PRIVATE_ERROR', error})
    })
  }
}

export const makePublic = (topic) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const batch = firestore.batch()
    // mark public
    const docRef = firestore.collection('forum')
                            .doc(topic.forum_doc_id)
                            .collection('genre')
                            .doc(topic.genre_doc_id)
                            .collection('topic')
                            .doc(topic.topic_doc_id)
    batch.update(docRef, {
      'isPrivate': false,
    })
    // delete from private collection
    const privRef = firestore.collection('private_topic').doc(topic.private_doc_id)
    batch.delete(privRef)
    // commit batch
    batch.commit()
    .then(() => {
      dispatch({type: 'DELETE_PRIVATE', id: topic.private_doc_id})
    }).catch(error => {
      dispatch({type: 'DELETE_PRIVATE_ERROR', error})
    })
  }
}


export const moveTopic = (topic) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    // this function needs to deep copy the topic data to the destination Forum
    // Finally, delete the old topic

    // first, create a new topic in the 'move to' forum
    const firestore = getFirestore()
    const batch = firestore.batch()

    const topicRef = firestore.collection('forum')
                              .doc(topic.moveToForumDocId)
                              .collection('genre')
                              .doc(topic.moveToGenreDocId)
                              .collection('topic')
                              .doc()
    batch.set(topicRef, {
      'title': topic.title,
      'lastComment': topic.topic.lastComment,
      'lastAuthor': topic.topic.lastAuthor,
      'views': topic.topic.views,
      'totalComments': topic.topic.totalComments,
      'isPrivate': topic.topic.isPrivate,
      'originatingAuthorId': topic.topic.originatingAuthorId,
      'createdAt': topic.topic.createdAt,
    })

    const deleteRef = firestore.collection('forum')
                                .doc(topic.forum_doc_id)
                                .collection('genre')
                                .doc(topic.genre_doc_id)
                                .collection('topic')
                                .doc(topic.topic_doc_id)
    batch.delete(deleteRef)
    // Next, fetch the comments from the topic that we want to move
    const newLocationId = topicRef.id
    firestore.collection('forum')
              .doc(topic.forum_doc_id)
              .collection('genre')
              .doc(topic.genre_doc_id)
              .collection('topic')
              .doc(topic.topic_doc_id)
              .collection('comment')
              .get()
    .then(function(commentsCollection) {
      const loopBatch = firestore.batch()
      commentsCollection.forEach(function(commentDoc) {
        // write each comment doc into the new topic location
        const comRef = firestore.collection('forum')
                                .doc(topic.moveToForumDocId)
                                .collection('genre')
                                .doc(topic.moveToGenreDocId)
                                .collection('topic')
                                .doc(newLocationId)
                                .collection('comment')
                                .doc()
        loopBatch.set(comRef, {
          'authorId': commentDoc.data().authorId,
          'comment': commentDoc.data().comment,
          'upVotes': commentDoc.data().upVotes,
          'edits': commentDoc.data().edits,
          'createdAt': commentDoc.data().createdAt
        })
      })
      loopBatch.commit()
      .then(() => {
        // good
      }).catch((e) => {
        // bad
      })
    }).catch((error) => {
      dispatch({type: 'MOVE_TOPIC_ERROR', error})
    })
    // commit batch
    batch.commit()
    .then(() => {
      dispatch({type: 'MOVE_TOPIC', topic})
    }).catch(error => {
      dispatch({type: 'MOVE_TOPIC_ERROR', error})
    })
  }
}
