export const ranks = ['E-1', 'E-2', 'E-3', 'E-4', 'E-5', 'E-6', 'E-7', 'E-8', 'E-9', 'W-1', 'W-2', 'W-3', 'W-4', 'W-5', 'O-1', 'O-2', 'O-3', 'O-4', 'O-5', 'O-6', 'O-7', 'O-8', 'O-9', 'O-10']

export const enlisted_AFSC_codes = [
    '1A0XX In-Flight Refueling',
    '1A1XX Flight Engineer',
    '1A2XX Aircraft Loadmaster',
    '1A3XX Airborne Mission System',
    '1A4XX Airborne Operations',
    '1A6XX Flight Attendant',
    '1A7XX Aerial Gunner',
    '1A8XX Airborne Cryptologic Linguist',
    '1B4XX Cyberspace Defense Operations',
    '1C0XX Aviation Resource Management',
    '1C1XX Air Traffic Control',
    '1C2XX Combat Control',
    '1C3XX Command Post',
    '1C4XX Tactical Air Control Party',
    '1C5XX Command and Control Battle Management Operations',
    '1C6XX Space Systems Operations',
    '1C7XX Airfield Management',
    '1N0XX Operations Intelligence',
    '1N1XX Geospatial Intelligence',
    '1N2XX Signals Intelligence Analyst',
    '1N3XX Cryptologic Language Analyst',
    '1N4XX Network Intelligence Analyst',
    '1POXX Aircrew Flight Equipment',
    '1S0XX Safety',
    '1T0XX Survival, Evasion, Resistance and Escape',
    '1T2XX Pararescue',
    '1U0XX Career RPA Sensor Operator',
    '1W0XX Weather',
    '2A0XX Avionics Test Station and Components',
    '2A3XX Avionics Systems',
    '2A5XX Aerospace Maintenance',
    '2A6XX Aerospace Propulsion',
    '2A7XX Aircraft Metals Technology',
    '2E1XX Satellite, Wideband and Telemetry Systems',
    '2E2XX Network Infrastructure Systems',
    '2E6XX Communication Cable and Antenna Systems',
    '2F0XX Fuels',
    '2G0XX Logistics Plans',
    '2M0XX Missile Maintenance',
    '2P0XX Precision Measurement Equipment Laboratory',
    '2R0XX Maintenance Management Analysis',
    '2R1XX Maintenance Management Production',
    '2S0XX Materiel Management',
    '2T0XX Traffic Management',
    '2T1XX Vehicle Operations',
    '2T2XX Air Transportation',
    '2T3XX Vehicle Maintenance',
    '2W0XX Munitions Systems',
    '2W1XX Aircraft Armament Systems',
    '2W2XX Nuclear Weapons',
    '3A0XX Knowledge Operations Management',
    '3C0XX Communication-Computer Systems',
    '3C1XX Information Systems Technology',
    '3C2XX Network Integration',
    '3D0XX Knowledge Operations Management',
    '3D1XX Client Systems',
    '3E0XX Electrical Systems',
    '3E1XX Heating, Ventilation, AC, Refrigeration',
    '3E2XX Pavement and Construction Equipment',
    '3E3XX Structural',
    '3E4XX Water and Fuel Systems Maintenance',
    '3E5XX Engineering',
    '3E6XX Operations Management',
    '3E7XX Fire Protection',
    '3E8XX Explosive Ordnance Disposal',
    '3E9XX Emergency Management',
    '3M0XX Services',
    '3N0XX Public Affairs',
    '3N1XX Regional Band',
    '3N2XX Premier Band',
    '3P0XX Security Forces',
    '3S0XX Personnel',
    '3S1XX Equal Opportunity',
    '3S2XX Education and Training',
    '3S3XX Manpower',
    '4A0XX Health Services Management',
    '4A1XX Medical Material',
    '4A2XX Biomedical Equipment',
    '4B0XX Bioenvironmental Engineering',
    '4C0XX Mental Health Service',
    '4D0XX Diet Therapy',
    '4E0XX Public Health',
    '4H0XX Cardiopulmonary Laboratory',
    '4J0XX Physical Medicine',
    '4M0XX Aerospace and Operational Physiology',
    '4N0XX Aerospace Medical Service',
    '4N1XX Surgical Service',
    '4P0XX Pharmacy',
    '4R0XX Diagnostic Imaging',
    '4T0XX Medical Laboratory',
    '4V0XX Ophthalmic',
    '4Y0XX Dental Assistant',
    '5J0XX Paralegal',
    '5R0XX Chaplain Assistant',
    '6C0XX Contracting',
    '6F0XX Financial Management and Comptroller',
    '7S0XX Special Investigations',
    '8A1XX Career Assistance Adviser',
    '8A2XX Enlisted Aide',
    '8B0XX Military Training Instructor',
    '8B1XX Military Training Leader',
    '8B2XX Academy Military Training NCO',
    '8C0XX Airmen/Family Readiness Center',
    '8D0XX Strategic Debriefer',
    '8F0XX First Sergeant',
    '8G0XX Honor Guard',
    '8H0XX Airman Dorm Leader',
    '8M0XX Postal',
    '8P0XX Courier',
    '8P1XX Defense Attaché',
    '8R0XX Enlisted Accessions Recruiter',
    '8R2XX Second-Tier Recruiter',
    '8R3XX Third-Tier Recruiter',
    '8S0XX Missile Facility Manager',
    '8T0XX Professional Military Education Instructor',
    '9A0XX Awaiting Retraining-Reasons Beyond Control',
    '9A1XX Awaiting Retraining-Reasons Within Control',
    '9A2XX Awaiting Discharge, Separation, Retirement for Reasons Within Their Control',
    '9A3XX Awaiting Discharge, Separation, Retirement for Reasons Beyond Their Control',
    '9C0XX Chief Master Sergeant of the Air Force',
    '9D0XX Dormitory Manager',
    '9E0XX Command Chief Master Sergeant',
    '9F0XX First Term Airmen Center',
    '9G1XX Group Superintendent',
    '9J0XX Prisoner',
    '9L0XX Interpreter/Translator',
    '9P0XX Patient',
    '9R0XX Civil Air Patrol-USAF Reserve Assistance NCO',
    '9S1XX Scientific Applications Specialist',
    '9T0XX Basic Enlisted Airman',
    '9T1XX Officer Trainee',
    '9T2XX Pre-Cadet Assignee',
    '9WOXX Wounded Warrior',
]

export const officer_AFSC_codes = [
    '11BX Bomber Pilot',
    '11EX Experimental Test Pilot',
    '11FX Fighter Pilot',
    '11GX Generalist Pilot',
    '11HX Rescue Pilot',
    '11KX Trainer Pilot',
    '11MX Mobility Pilot',
    '11RX Reconnaissance/Surveillance/Electronic Warfare Pilot',
    '11SX Special Operations Pilot',
    '11UX Remotely Piloted Aircraft Pilot',
    '12BX Bomber Combat Systems Officer',
    '12EX Experimental Test Combat Systems Officer',
    '12FX Fighter Combat Systems Officer',
    '12GX Generalist Combat Systems Officer',
    '12HX Rescue Combat Systems Officer',
    '12KX Trainer Combat Systems Officer',
    '12MX Mobility Combat Systems Officer',
    '12RX Reconnaissance/Surveillance/Electronic Warfare Combat Systems Officer',
    '12SX Special Operations Combat Systems Officer',
    '12UX Remotely Piloted Aircraft (Rpa)',
    '13AX Astronaut',
    '13BX Air Battle Manager',
    '13CX Special Tactics',
    '13DX Combat Rescue Officer',
    '13LX Air Liaison Officer',
    '13MX Airfield Operations',
    '13NX Nuclear And Missile Operations',
    '13SX Space Operations',
    '14FX Information Operations',
    '14NX Intelligence',
    '15WX Weather',
    '16FX Regional Affairs Strategist (Ras)',
    '16GX Air Force Operations Staff Officer',
    '16PX Political-Military Affairs Strategist (Pas)',
    '16RX Planning And Programming',
    '17C Cyberspace Operations Commander',
    '17DX Cyberspace Operations',
    '17SX Cyber Warfare Operations',
    '18AX Attack Remotely Piloted Aircraft Pilot',
    '18EX Experimental Test Remotely Piloted Aircraft Pilot',
    '18GX Generalist Remotely Piloted Aircraft Pilot',
    '18RX Reconnaissance Remotely Piloted Aircraft Pilot',
    '18SX Special Operations Remotely Piloted Aircraft Pilot',
    '20C Logistics Commander',
    '21AX Aircraft Maintenance',
    '21MX Munitions And Missile Maintenance',
    '21RX Logistics Readiness',
    '30C Support Commander',
    '31PX Security Forces',
    '32EX Civil Engineer',
    '35BX Band',
    '35PX Public Affairs',
    '38FX Force Support',
    '40C Medical Commanderhealth Services Utilization Field',
    '41AX Health Services Administrator',
    '42BX Physical Therapist',
    '42EX Optometrist',
    '42FX Podiatrist',
    '42GX Physician Assistant',
    '42NX Audiologist',
    '42PX Clinical Psychologist',
    '42SX Clinical Social Worker',
    '42TX Occupational Therapist',
    '43AX Aerospace And Operational Physiologist',
    '43BX Biomedical Scientist',
    '43DX Dietitian',
    '43EX Bioenvironmental Engineer',
    '43HX Public Health Officer',
    '43PX Pharmacist',
    '43TX Biomedical Laboratory',
    '44A Chief, Hospital/Clinic Services',
    '44BX Preventive Medicine',
    '44DX Pathologist',
    '44EX Emergency Services Physician',
    '44FX Family Physician',
    '44GX General Practice Physician',
    '44HX Nuclear Medicine Physician',
    '44JX Clinical Geneticist',
    '44KX Pediatrician',
    '44MX Internist',
    '44NX Neurologist',
    '44OX Physician',
    '44PX Psychiatrist',
    '44RX Diagnostic Radiologist',
    '44SX Dermatologist',
    '44TX Radiotherapist',
    '44UX Occupational Medicine',
    '44YX Critical Care Medicine',
    '44ZX Allergist',
    '45AX Anesthesiologist',
    '45BX Orthopedic Surgeon',
    '45EX Ophthalmologist',
    '45GX Obstetrician And Gynecologist',
    '45NX Otorhinolaryngologist',
    '45PX Physical Medicine Physician',
    '45SX Surgeon',
    '45UX Urologist',
    '46AX Nursing Administrator',
    '46FX Flight Nurse',
    '46NX Clinical Nurse',
    '46PX Mental Health Nurse',
    '46SX Operating Room Nurse',
    '46YX Advanced Practice Registered Nurse (Aprn)',
    '47BX Orthodontist',
    '47DX Oral And Maxillofacial Pathologist',
    '47EX Endodontist',
    '47GX Dentist',
    '47HX Periodontist',
    '47KX Pediatric Dentist',
    '47PX Prosthodontist',
    '47SX Oral And Maxillofacial Surgeon',
    '48AX Aerospace Medicine Specialist',
    '48GX General Medical Officer (Gmo), Flight Surgeon',
    '48RX Residency Trained Flight Surgeon',
    '48VX Pilot-Physician',
    '51JX Judge Advocate',
    '52RX Chaplain',
    '60CX Senior Materiel Leader-Upper Echelon',
    '61AX Operations Research Analyst',
    '61CX Chemist/Nuclear Chemist',
    '61DX Physicist/Nuclear Engineer',
    '62EX Developmental Engineer',
    '62SX Materiel Leader',
    '63AX Acquisition Manager',
    '63GX Senior Materiel Leader-Lower Echelon',
    '63SX Materiel Leader',
    '64PX Contracting',
    '63FX Financial Management',
    '65W Cost Analysis',
    '71SX Special Investigations',
    '81TX Instructor',
    '82IX Recruiting Service',
    '84H Historian',
    '85G United States Air Force Honor Guard',
    '86M Operations Management',
    '86P Command And Control',
    '87G Wing Inspector General',
    '87I Director, Wing Inspections',
    '87Q Director, Complaints Resolution',
    '88A Aide-De-Camp',
    '90G0 General Officer',
    '91C0 Commander',
    '91W0 Wing Commander',
    '92J0 Nondesignated Lawyer',
    '92J1 Afrotc Educational Delay Law Student',
    '92J2 Funded Legal Education Program Law Student',
    '92J3 Excess Leave Law Student',
    '92M0 Health Professions Scholarship Program (Hpsp) Medical Student',
    '92M1 Uniformed Services University Of Health Sciences (Usuhs) Student',
    '92M2 Hpsp Biomedical Science Student',
    '92P0 Physician Assistant Student',
    '92R0 Chaplain Candidate',
    '92S0 Student Officer Authorization',
    '92T0 Pilot Trainee',
    '92T1 Combat Systems Officer Trainee',
    '92T2 Air Battle Manager Trainee',
    '92T3 Remotely Piloted Aircraft Pilot Trainee',
    '92W0 Combat Wounded Warrior 92w1 Reserved For Future Use',
    '92W2 Combat Wounded Warrior With Exemptions',
    '92W3 Non-Combat Wounded Warrior 92w4 Wounded Warrior – Limited Assignment Status (Las)',
    '92W5 Wounded Warrior - Retired/Discharged',
    '92W7 Reserved For Future Use',
    '92W8 Reserved For Future Use',
    '92W9 Reserved For Future Use',
    '93P0 Patient',
    '95A0 Non-Ead Usafr Academy Liaison Officer (Alo) Or Civil Air Patrol Reserve Assistance Program (Caprap) Officer',
    '96A0 Disqualified Officer, Reasons Beyond Their Control',
    '96B0 Disqualified Officer, Reasons Within Their Control',
    '96D0 Officer Not Available For Use In Awarded Afsc For Cause',
    '96U0 Unclassified Officer',
    '96V0 Unallotted',
    '97E0 Executive Officer',
    '99A0 Unspecified Afsc',
    '99G0 Gold Bar Diversity Recruiter',    
]

export const enlisted_marine_codes = [
    '0100 Basic Administrative Marine',
    '0111 Administrative Specialist',
    '0147 Equal Opportunity Advisor (EOA) (FMOS)',
    '0149 Substance Abuse Control Specialist (FMOS)',
    '0161 Postal Clerk',
    '0171 Manpower Information Systems (MIS) Analyst (NMOS)',
    '0200 Basic Intelligence Marine', 
    '0211 Counterintelligence/Human Intelligence Specialist',
    '0212 Technical Surveillance Countermeasures Specialist',
    '0231 Intelligence Specialist',
    '0239 Intelligence Analyst',
    '0241 Imagery Analysis Specialist',
    '0261 Geographic Intelligence Specialist',
    '0291 Intelligence Chief',
    '0300 Basic Infantry Marine',
    '0311 Rifleman',
    '0310 Combat Cosmotologist',
    '0312 Riverine Assault Craft Marine',
    '0313 Light Armor Vehicle Marine',
    '0314 Rigid Raiding Craft/Rigid Hull Inflatable Boat Coxswain',
    '0316 Combat Rubber Reconnaissance Craft Coxswain',
    '0317 Scout Sniper',
    '0321 Reconnaissance Marine',
    '0323 Reconnaissance Marine, Parachute Qualified',
    '0324 Reconnaissance Marine, Combatant Diver Qualified',
    '0326 Reconnaissance Marine, Parachute and Combatant Diver Qualified',
    '0331 Machine Gunner',
    '0341 Mortarman',
    '0351 Infantry Assault Marine',
    '0352 Antitank Missile Gunner',
    '0365 Infantry Squad Leader',
    '0369 Infantry Unit Leader',
    '0372 Critical Skills Operator',
    '0399 Operations Chief',
    '0400 Basic Logistics Marine',
    '0411 Maintenance Management Specialist',
    '0431 Logistics/Embarkation Specialist',
    '0451 Airborne and Air Delivery Specialist',
    '0471 Personnel Retrieval and Processing Specialist',
    '0472 Personnel Retrieval and Processing Technician',
    '0477 Expeditionary Logistics Instructor',
    '0481 Landing Support Specialist',
    '0491 Logistics/Mobility Chief',
    '0500 Basic MAGTF Marine',
    '0511 MAGTF Planning Specialist',
    '0521 Military Information Support Operations',
    '0531 Civil Affairs Noncommissioned Officer',
    '0551 Information Operations Specialist',
    '0570 Foreign Security Forces Advisor',
    '0571 Advanced Foreign Security Forces Advisor',
    '0600 Basic Communications Marine',
    '0612 Tactical Switching Operator',
    '0619 Telecommunications Systems Chief',
    '0621 Transmission Systems Operator',
    '0622 Digital Multi-channel Wideband Transmission Equipment Operator',
    '0623 Tropospheric Scatter Transmissions System Operator',
    '0627 Satellite Communications Operator',
    '0629 Transmissions Chief',
    '0631 Network Administrator',
    '0633 Network Transport Technician',
    '0639 Network Chief',
    '0648 Spectrum Manager',
    '0651 Cyber Network Operator',
    '0659 Cyber Network Systems Chief',
    '0671 Data Systems Administrator',
    '0673 Applications Developer',
    '0679 Data Systems Chief',
    '0681 Information Security Technician',
    '0688 Cyber Security Technician',
    '0689 Cybersecurity Chief',
    '0691 Communications Training Instructor',
    '0699 Communications Chief',
    '0800 Basic Field Artillery Marine',
    '0811 Field Artillery Cannoneer',
    '0814 High Mobility Artillery Rocket System Operator',
    '0842 Field Artillery Radar Operator',
    '0844 Field Artillery FDC (Fire Direction Control)',
    '0847 Field Artillery Sensor Support Marine',
    '0848 Field Artillery FDC Operations Chief',
    '0861 Field Artillery Scout Observer Marine',
    '0911 Marine Corps Drill Instructor',
    '0913 Marine Combat Instructor',
    '0916 Martial Arts Instructor',
    '0917 Martial Arts Instructor-Trainer',
    '0919 Force Fitness Instructor',
    '0918 Water Safety/Survival Instructor',
    '0931 Marksmanship Instructor',
    '0932 Small Arms Weapons Instructor',
    '0933 Marksmanship Coach',
    '1100 Basic Utilities Marine',
    '1141 Electrician',
    '1142 Electrical Equipment Repair Specialist',
    '1161 Refrigeration Mechanic',
    '1169 Utilities Chief',
    '1171 Water Support Technician',
    '1300 Basic Engineer, Construction, Facilities, & Equipment Marine',
    '1316 Metal Worker',
    '1341 Engineer Equipment Mechanic',
    '1342 Small Craft Mechanic',
    '1343 Assault Breacher Vehicle Mechanic',
    '1345 Engineer Equipment Operator',
    '1349 Engineer Equipment Chief',
    '1361 Engineer Assistant',
    '1371 Combat Engineer',
    '1372 Assault Breacher Vehicle Operator',
    '1391 Bulk Fuel Specialist',
    '1711 Cyberspace Exploitation Operator',
    '1721 Cyberspace Defensive Operator',
    '1799 Cyberspace Operations Chief',
    '1800 Basic Tank and Assault Amphibious Vehicle',
    '1812 M1A1 Tank Crewman',
    '1833 Assault Amphibious Vehicle Marine',
    '1834 ACV Marine',
    '2100 Basic Ground Ordnance Maintenance Marine',
    '2111 Small Arms Repairer/Technician',
    '2112 Precision Weapons Repairer',
    '2131 Towed Artillery Systems Technician',
    '2141 Assault Amphibious Vehicle (AAV) Repairer/Technician',
    '2146 Main battle tank (MBT) Repairer/Technician',
    '2147 Light Armored Vehicle (LAV) Repairer/Technician',
    '2148 Expeditionary Fighting Vehicle (EFV) Repairer/Technician',
    '2149 Ordnance Vehicle Maintenance Chief',
    '2161 Machinist',
    '2171 Electro-Optical Ordnance Repairer/Technician',
    '2181 Senior Ground Ordnance Weapons Chief',
    '2300 Basic Ammunition and Explosive Ordnance Disposal',
    '2311 Ammunition Technician',
    '2336 Explosive Ordnance Disposal (EOD) Technician',
    '2600 Basic Signals Intelligence/Ground Electronic Warfare Operator',
    '2611 Cryptologic Digital Network Operator/Analyst',
    '2621 Special Communications Signals Collection Operator/Analyst',
    '2623 Radio Reconnaissance Man - BRC, Jump, and SERE qualified',
    '2629 Signals Intelligence Analyst',
    '2631 Electronic Intelligence (ELINT) Intercept Operator/Analyst',
    '2641 Cryptologic Linguist Operator Analyst',
    '2642 Advanced Cryptologic Linguist Operator Analyst',
    '2649 Cryptanalyst',
    '2651 Special Intelligence System Administrator/Communicator',
    '2691 Signals Intelligence/Electronic Warfare Chief',
    '2711 Pakistani Pashtu',
    '2712 Arabic (Modern Standard)',
    '2713 Arabic (Egyptian)',
    '2714 Arabic (Syrian)',
    '2716 Amharic',
    '2717 Bengali',
    '2718 Hebrew',
    '2719 Hindi',
    '2721 Kurdish',
    '2722 Persian',
    '2723 Somali',
    '2724 Swahili',
    '2726 Turkish',
    '2727 Urdu',
    '2733 Burmese',
    '2734 Cambodian',
    '2736 Cantonese',
    '2737 Chinese (Mandarin)',
    '2738 Indonesian',
    '2741 Korean',
    '2746 Thai',
    '2747 Vietnamese',
    '2776 Albanian',
    '2777 Armenian',
    '2778 Bulgarian',
    '2779 Czech',
    '2781 Estonian',
    '2782 Georgian',
    '2783 Hungarian',
    '2784 Latvian',
    '2786 Lithuanian',
    '2787 Macedonian',
    '2788 Polish',
    '2789 Romanian',
    '2791 Russian',
    '2792 Serb-Croat',
    '2793 Slovenian',
    '2794 Ukrainian',
    '2754 Dutch',
    '2756 Finnish',
    '2757 French',
    '2758 German',
    '2759 Greek',
    '2761 Haitian-Creole',
    '2762 Icelandic',
    '2763 Italian',
    '2764 Norwegian',
    '2766 Portuguese (BR)',
    '2767 Portuguese (EU)',
    '2768 Spanish',
    '2769 Swedish',
    '2800 Basic Data/Communications Maintenance Marine',
    '2811 Telephone Technician',
    '2821 Technical Controller',
    '2823 Technical Control Chief',
    '2831 Digital Wideband Systems Maintainer',
    '2841 Ground Electronics Transmission Systems Maintainer',
    '2847 Ground Electronics Telecommunications/IT Systems Maintainer',
    '2848 Tactical Remote Sensor System Maintainer',
    '2862 Electronics Maintenance Technician',
    '2871 Calibrations Technician',
    '2874 Metrology Technician',
    '2887 Artillery Electronics Technician',
    '2891 Ground Electronics Systems Maintenance Chief',
    '3000 Basic Supply Administration and Operations Marine',
    '3043 Supply Chain and Materiel Management Specialist',
    '3044 Contract Specialist',
    '3051 Warehouse Clerk',
    '3052 Packaging Specialist',
    '3072 Aviation Supply Clerk',
    '3100 Basic Distribution Management Marine',
    '3112 Distribution Management Specialist',
    '3381 Food Service Specialist',
    '3372 Marine Aide-enlisted aide to General and Flag officers',
    '3400 Basic Financial Management Marine',
    '3432 Finance Technician',
    '3441 Non-appropriated fund Audit Technician',
    '3451 Fiscal/Budget Technician',
    '3500 Basic Motor Transport Marine',
    '3521 Automotive Organizational Technician',
    '3522 Automotive Intermediate Mechanic',
    '3523 Vehicle Recovery Mechanic',
    '3524 Fuel and Electrical Systems Mechanic',
    '3526 Crash/Fire/Rescue Vehicle Mechanic',
    '3529 Motor Transport Maintenance Chief',
    '3531 Motor Vehicle Operator',
    '3533 Logistics Vehicle Systems Operator',
    '3534 Semitrailer Refueler Operator',
    '3536 Vehicle Recovery Operator',
    '3537 Motor Transport Operations Chief',
    '3538 Licensing Examiner',
    '4100 Basic Exchange Marine',
    '4133 Morale, Welfare, Recreation Specialist',
    '4400 Basic Legal Services Marine',
    '4421 Legal Services Specialist',
    '4422 Legal Services Reporter',
    '4500 Basic Communication Strategy & Operations Marine',
    '4512 Combat Graphics Specialist',
    '4531 Combat Mass Communicator',
    '4541 Combat Photographer',
    '4571 Combat Videographer',
    '4591 Communication and Strategy Operations Chief',
    '5700 Basic Chemical, Biological, Radiological, and Nuclear Defense Marine',
    '5711 Chemical, Biological, Radiological, and Nuclear Defense Specialist',
    '5800 Basic Military Police and Corrections Marine',
    '5811 Military Police',
    '5812 Working Dog Handler',
    '5813 Accident Investigator',
    '5814 Physical Security Specialist',
    '5816 Special Reaction Team Member',
    '5819 Military Police Investigator',
    '5821 Criminal Investigator CID Agent',
    '5822 Forensic Psycho-physiologist (Polygraph Examiner)',
    '5831 Correctional Specialist',
    '5832 Correctional Counselor',
    '5900 Basic Electronics Maintenance Marine',
    '5912 Avenger System Maintainer',
    '5939 Aviation Communication Systems Technician',
    '5941 Aviation Primary Surveillance Radar Repair Man',
    '5948 Aviation Radar Technician',
    '5951 Aviation Meteorological Equipment Technician, OMA/IMA',
    '5952 Air Traffic Control Navigational Aids Technician',
    '5953 Air Traffic Control Radar Technician',
    '5954 Air Traffic Control Communications Technician',
    '5959 Air Traffic Control Systems Maintenance Chief',
    '5962 Tactical Data Systems Equipment Repairer',
    '5963 Tactical Air Operations Module Repairer',
    '5974 Tactical Data Systems Administrator',
    '5979 Tactical Air Operations Module/Air Defense Technician',
    '5993 Electronics Maintenance Chief',
    '3215 Basic Aircraft Maintenance Marine',
    '6012 Aviation Maintenance Controller/Production Controller',
    '6016 Collateral Duty Inspector (CDI)',
    '6018 Aviation Quality Assurance Representative (QAR) Inspector',
    '6019 Aircraft Maintenance Chief',
    '6023 Aircraft Power Plants Test Cell Operator',
    '6033 Aircraft Nondestructive Inspection Technician',
    '6042 Individual Material Readiness List (IMRL) Asset Manager',
    '6043 Aircraft Welder',
    '6046 Aircraft Maintenance Administration Specialist',
    '6048 Flight Equipment Technician',
    '6049 NALCOMIS Application Administrator/Analyst',
    '6061 Aircraft Intermediate Level Hydraulic/Pneumatic Mechanic-Trainee',
    '6062 Aircraft Intermediate Level Hydraulic/Pneumatic Mechanic',
    '6071 Aircraft Maintenance Support Equipment (SE) Mechanic-Trainee',
    '6072 Aircraft Maintenance Support Equipment (SE) Hydraulic/Pneumatic/Structures Mechanic',
    '6073 Aircraft Maintenance Support Equipment (SE) Electrician/Refrigeration Mechanic',
    '6074 Cryogenics Equipment Operator',
    '6091 Aircraft Intermediate Level Structures Mechanic-Trainee',
    '6092 Aircraft Intermediate Level Structures Mechanic',
    '6100 Helicopter/Tiltrotor Mechanic-Trainee',
    '6112 Helicopter Mechanic, CH-46',
    '6113 Helicopter Mechanic, CH-53',
    '6114 Helicopter Mechanic, UH/AH-1',
    '6116 Tiltrotor Mechanic, MV-22',
    '6122 Helicopter Power Plants Mechanic, T-58',
    '6123 Helicopter Power Plants Mechanic, T-64',
    '6124 Helicopter Power Plants Mechanic, T-400/T-700',
    '6132 Helicopter/Tiltrotor Dynamic Components Mechanic',
    '6151 Helicopter/Tiltrotor Airframe Mechanic-Trainee',
    '6152 Helicopter Airframe Mechanic, CH-46',
    '6153 Helicopter Airframe Mechanic, CH-53',
    '6154 Helicopter Airframe Mechanic, UH/AH-1',
    '6156 Tiltrotor Airframe Mechanic, MV-22',
    '6162 Presidential Support Specialist',
    '6172 Helicopter Crew Chief, CH-46',
    '6173 Helicopter Crew Chief, CH-53',
    '6174 Helicopter Crew Chief, UH-1N/Y',
    '6176 Tiltrotor Crew Chief, MV-22',
    '6177 Weapons and Tactics Crew Chief Instructor',
    '6178 VH-60N Presidential Helicopter Crew Chief',
    '6179 VH-3D Presidential Helicopter Crew Chief',
    '6199 Enlisted Aircrew/Aerial Observer/Gunner',
    '6211 Fixed-wing Aircraft Mechanic-Trainee',
    '6212 Fixed-Wing Aircraft Mechanic, AV-8/TAV-8',
    '6213 Fixed-Wing Aircraft Mechanic, EA-6',
    '6214 Unmanned Aerial Vehicle (UAV) Mechanic',
    '6216 Fixed-Wing Aircraft Mechanic, KC-130',
    '6217 Fixed-Wing Aircraft Mechanic, F/A-18',
    '6218 Fixed-Wing Aircraft Mechanic, F35B',
    '6222 Fixed-Wing Aircraft Power Plants Mechanic, F-402',
    '6223 Fixed-Wing Aircraft Power Plants Mechanic, J-52',
    '6226 Fixed-Wing Aircraft Power Plants Mechanic, T-56',
    '6227 Fixed-wing Aircraft Power Plants Mechanic, F-404',
    '6242 Fixed-Wing Aircraft Flight Engineer, KC-130',
    '6243 Fixed-Wing Transport Aircraft Specialist, C-9',
    '6244 Fixed-Wing Transport Aircraft Specialist, C-12',
    '6246 Fixed-Wing Transport Aircraft Specialist, C-20',
    '6247 Fixed-Wing Transport Aircraft Specialist, UC-35',
    '6251 Fixed-Wing Aircraft Airframe Mechanic-Trainee',
    '6252 Fixed-Wing Aircraft Airframe Mechanic, AV-8/TAV-8',
    '6253 Fixed-Wing Aircraft Airframe Mechanic, EA-6',
    '6256 Fixed-Wing Aircraft Airframe Mechanic, KC-130',
    '6257 Fixed-Wing Aircraft Airframe Mechanic, F/A-18',
    '6258 Fixed-Wing Aircraft Airframe Mechanic, F-35B',
    '6276 Fixed-Wing Aircraft Crew Master, KC-130',
    '6281 Fixed-Wing Aircraft Safety Equipment Mechanic- Trainee',
    '6282 Fixed-Wing Aircraft Safety Equipment Mechanic, AV-8/TAV-8',
    '6283 Fixed-Wing Aircraft Safety Equipment Mechanic, EA-6',
    '6286 Fixed-Wing Aircraft Safety Equipment Mechanic, KC-130',
    '6287 Fixed-Wing Aircraft Safety Equipment Mechanic, F/A-18',
    '6311 Aircraft Communications/Navigation/Electrical/Weapon Systems Technician-Trainee',
    '6313 Aircraft Communications/Navigation/Radar Systems Technician',
    '6314 Unmanned Aerial Vehicle (UAV) Avionics Technician',
    '6316 Aircraft Communications/Navigation Systems Technician, KC-130',
    '6317 Aircraft Communications/Navigation Systems Technician, F/A-18',
    '6322 Aircraft Communications/Navigation/Electrical Systems Technician, CH-46',
    '6323 Aircraft Communications/Navigation/Electrical Systems Technician, CH-53',
    '6324 Aircraft Communications/Navigation/Electrical/Weapon Systems Technician, U/AH-1',
    '6326 Aircraft Communications/Navigation/Electrical/Weapon Systems Technician, V-22',
    '6331 Aircraft Electrical Systems Technician-Trainee',
    '6332 Aircraft Electrical Systems Technician, AV-8',
    '6333 Aircraft Electrical Systems Technician, EA-6',
    '6336 Aircraft Electrical Systems Technician, KC-130',
    '6337 Aircraft Electrical Systems Technician, F/A-18',
    '6338 Aircraft Avionics Technician, F-35',
    '6344 Aircraft Electric Systems Technician, UH-1N/AH-1T',
    '6365 Aircraft Communications/Navigation/DECM/Radar Systems Technician, EA-6B',
    '6386 Aircraft Electronic Countermeasures Systems Technician, EA-6B',
    '6391 Avionics Maintenance Chief',
    '6411 Aircraft Communications/Navigation Systems Technician- Trainee, IMA',
    '6412 Aircraft Communications Systems Technician, IMA',
    '6413 Aircraft Navigation Systems Technician, IFF/RADAR/TACAN, IMA',
    '6422 Aircraft Cryptographic Systems Technician, IMA',
    '6423 Aviation Electronic Microminiature/Instrument and Cable Repair Technician, IMA',
    '6431 Aircraft Electrical Systems Technician-Trainee',
    '6432 Aircraft Electrical/Instrument/Flight Control Systems Technician, Fixed Wing, IMA',
    '6433 Aircraft Electrical/Instrument/flight Control Systems Technician, Helicopter',
    '6434 Advanced Aircraft Electrical/Instrument/Flight Control Systems Technician, IMA',
    '6461 Hybrid Test Set Technician, IMA',
    '6462 Avionics Test Set (ATS) Technician, IMA',
    '6463 Radar Test Station (RTS)/Radar Systems Test Station (RSTS) Technician, IMA',
    '6464 Aircraft Inertial Navigation System Technician, IMA',
    '6466 Aircraft Forward Looking Infrared/Electro-Optical Technician, IMA',
    '6467 Consolidated Automatic Support System (CASS) Technician, IMA',
    '6468 Aircraft Electrical Equipment Test Set (EETS)/Mobile Electronic Test Set (METS) Technician',
    '6469 Advanced Automatic Test Equipment Technician, IMA',
    '6482 Aircraft Electronic Countermeasures Systems Technician, Fixed Wing, IMA',
    '6483 Aircraft Electronic Countermeasures Systems Technician, Helicopter, IMA',
    '6484 Aircraft Electronic Countermeasures Systems/RADCOM/CAT IIID Technician, IMA',
    '6486 Advanced Aircraft Electronic Countermeasures Technician, IMA',
    '6491 Aviation Precision Measurement Equipment (PME) Chief',
    '6492 Aviation Precision Measurement Equipment/Calibration and Repair Technician, IMA',
    '6499 Mobile Facilities Technician',
    '6511 Aviation Ordnance Trainee',
    '6531 Aviation Ordnance Technician',
    '6541 Aviation Ordnance Systems Technician',
    '6591 Aviation Ordnance Chief',
    '6613 Radio Communication and Navigation Systems Technician on Utility Aircraft',
    '6617 Enlisted Aviation Logistician',
    '6672 Aviation Supply Specialist',
    '6694 Aviation Logistics Information Management and Support Specialists',
    '6800 Meteorology & Oceanography Services',
    '6842 METOC Analyst Forecaster',
    '6852 METOC Impact Analyst',
    '7011 Expeditionary Airfield Systems Technician',
    '7041 Aviation Operations Specialist',
    '7051 Aircraft Rescue and Firefighting Specialist',
    '7212 Low Altitude Air Defense Gunner',
    '7222 Hawk Missile Operator',
    '7236 Tactical Air Defense Controller',
    '7242 Air Support Operations Operator',
    '7251 Air Traffic Controller – Trainee',
    '7252 Air Traffic Controller – Tower',
    '7253 Air Traffic Controller–Radar Arrival/Departure Controller',
    '7254 Air Traffic Controller–Radar Approach Controller',
    '7257 Air Traffic Controller',
    '7291 Senior Air Traffic Controller',
    '7313 Helicopter Specialist, AH-1Z/UH-1Y',
    '7314 Unmanned Aircraft System (UAS) Operator',
    '7371 Tactical Systems Operator Trainee',
    '7372 Tactical Systems Operator/Mission Specialist',
    '7382 Airborne Radio Operator/In-flight Refueling Observer/Loadmaster',
]

export const officer_marine_codes = [
    '0201 Basic Intelligence Officer',
    '0202 Marine Air-Ground Task Force Intelligence Officer',
    '0203 Ground Intelligence Officer',
    '0204 Counterintelligence/Human Source Intelligence Officer',
    '0205 Master Analyst',
    '0206 Signals Intelligence/Ground Electronic Warfare Officer',
    '0207 Air Intelligence Officer',
    '0210 Counterintelligence/Human Source Intelligence Operations Officer',
    '0233 Intelligence Tactics Instructor',
    '0277 Weapons and Tactics Instructor Intelligence Officer',
    '0301 Basic Infantry Officer',
    '0302 Infantry Officer',
    '0303 Light-Armored Reconnaissance Officer',
    '0306 Infantry Weapons Officer',
    '0307 Expeditionary Ground Reconnaissance Officer',
    '0370 Special Operations Officer',
    '0401 Basic Logistics Officer',
    '0402 Logistics Officer',
    '0405 Aerial Delivery Officer',
    '0407 Personnel Retrieval and Processing Officer',
    '0430 Mobility Officer',
    '0477 Expeditionary Logistics Instructor' ,
    '0501 Basic MAGTF Officer',
    '0502 Force Deployment Planning and Execution Officer',
    '0505 Marine Air Ground Task Force Planners',
    '0506 Red Team Member' ,
    '0510 Basic Information Operations Staff Officer',
    '0520 Military Information Support Operations Officer',
    '0530 Civil Affairs Officer',
    '0535 Civil-Military Operations Planner',
    '0540 Space Operations Staff Officer',
    '0550 Advanced Information Operations',
    '0570 Foreign Security Forces Advisor',
    '0571 Advanced Foreign Security Forces Advisor',
    '0577 Operations and Tactics Instructor',
    '0601 Basic Communications Officer',
    '0602 Communications Officer',
    '0603 Marine Air-Ground Task Force Communications Planner',
    '0605 Cyber Network Operations Officer',
    '0610 Telecommunications Systems Engineering Officer',
    '0620 Tactical Communications Planning and Engineer Officer',
    '0620 Space and Waveform Integration Officer',
    '0630 Network Engineering Officer',
    '0640 Strategic Electromagnetic Spectrum Officer',
    '0650 Cyber Network Operations Engineer',
    '0670 Data Systems Engineering Officer',
    '0691 Communications Training Instructor',
    '0801 Basic Field Artillery Officer',
    '0802 Field Artillery Officer',
    '0803 Target Acquisition Officer',
    '0840 Naval Surface Fire Support Planner',
    '0930 Range Officer',
    '1120 Utilities Officer',
    '1301 Basic Combat Engineer Officer',
    '1302 Combat Engineer Officer',
    '1310 Engineer Equipment Officer',
    '1330 Facilities Management Officer',
    '1390 Bulk Fuel Officer',
    '1702 Cyberspace Officer',
    '1705 Cyberspace Warfare Development Officer',
    '1710 Offensive Cyberspace Weapons Officer',
    '1720 Defensive Cyberspace Weapons Officer',
    '1801 Basic Tank and Amphibious Assault Vehicle Officer',
    '1802 Tank Officer',
    '1803 Assault Amphibious Vehicle Officer',
    '2102 Ordnance Officer',
    '2110 Ordnance Vehicle Maintenance Officer',
    '2120 Weapons Repair Officer',
    '2125 Electro-Optic Instrument Repair Officer',
    '2305 Explosive Ordnance Disposal Officer',
    '2340 Ammunition Officer',
    '2602 Signals Intelligence/Electronic Warfare Officer',
    '2611 Cryptologic Digital Network Technician/Analyst (Officer)',
    '2711 Pakistani Pashtu',
    '2712 Arabic (Modern Standard)',
    '2713 Arabic (Egyptian)',
    '2714 Arabic (Syrian)',
    '2716 Amharic',
    '2717 Bengali',
    '2718 Hebrew',
    '2719 Hindi',
    '2721 Kurdish',
    '2722 Persian',
    '2723 Somali',
    '2724 Swahili',
    '2726 Turkish',
    '2727 Urdu',
    '2733 Burmese',
    '2734 Cambodian',
    '2736 Cantonese',
    '2737 Chinese (Mandarin)',
    '2738 Indonesian',
    '2741 Korean',
    '2746 Thai',
    '2747 Vietnamese',
    '2776 Albanian',
    '2777 Armenian',
    '2778 Bulgarian',
    '2779 Czech',
    '2781 Estonian',
    '2782 Georgian',
    '2783 Hungarian',
    '2784 Latvian',
    '2786 Lithuanian',
    '2787 Macedonian',
    '2788 Polish',
    '2789 Romanian',
    '2791 Russian',
    '2792 Serb-Croat',
    '2793 Slovenian',
    '2794 Ukrainian',
    '2754 Dutch',
    '2756 Finnish',
    '2757 French',
    '2758 German',
    '2759 Greek',
    '2761 Haitian-Creole',
    '2762 Icelandic',
    '2763 Italian',
    '2764 Norwegian',
    '2766 Portuguese (BR)',
    '2767 Portuguese (EU)',
    '2768 Spanish',
    '2769 Swedish',
    '2801 Basic Ground Electronics Maintenance Officer',
    '2802 Electronics Maintenance Officer',
    '2805 Electronics Maintenance Officer',
    '3002 Ground Supply Officer',
    '3010 Ground Supply Operations Officer',
    '3102 Distribution Management Officer',
    '3302 Food Service Officer',
    '3402 Finance Officer',
    '3404 Financial Management Officer',
    '3408 Financial Management Resource Officer',
    '3410 Non-appropriated fund Auditing Officer',
    '3450 Planning, Programming, and Budgeting System Officer',
    '3502 Motor Transport Officer',
    '3510 Motor Transport Maintenance Officer',
    '4130 Marine Corps Exchange Officer',
    '4330 Historical Officer',
    '4401 Student Judge Advocate',
    '4402 Judge Advocate',
    '4405 Master of International Law',
    '4406 Master of Environmental Law',
    '4407 Master of Labor Law',
    '4408 Master of Procurement Law',
    '4409 Master of Criminal Law',
    '4410 Master of Law',
    '4430 Legal Administrative Officer',
    '4502 Communication Strategy & Operations Officer',
    '4503 Visual Information Officer',
    '4801 Recruiting Officer-Marine Corps Total Force Expert',
    '4802 Recruiting Officer-Operational Expert',
    '4803 Recruiting Officer-Procurement Expert',
    '4804 Recruiting Officer-Multiple Tour Expert',
    '4810 Recruiting Officer',
    '5702 Chemical, Biological, Radiological, and Nuclear Defense Officer',
    '5803 Military Police Officer',
    '5804 Corrections Officer',
    '5805 Criminal Investigation Officer',
    '5902 Electronics Maintenance Officer',
    '5910 Aviation Radar Maintenance Officer',
    '5950 Air Traffic Control Systems Maintenance Officer',
    '5970 Data Systems Maintenance Officer',
    '6502 Aviation Ordnance Officer',
    '6602 Aviation Supply Officer',
    '6604 Aviation Supply Operations Officer',
    '6677 Weapons and Tactics Instructor-Aviation Logistician',
    '6802 Meteorological and Oceanographic Services Officer',
    '6877 Weapons and Tactics Instructor-METOC',
    '7002 Expeditionary Airfield and Emergency Services Officer',
    '7077 Weapons and Tactics Instructor - Aviation Ground Support',
    '7202 Air Command and Control Officer',
    '7204 Low Altitude Air Defense Officer',
    '7208 Air Support Control Officer',
    '7210 Air Defense Control Officer',
    '7220 Air Traffic Control Officer',
    '7277 Weapons and Tactics Instructor-Air Control',
    '7315 Unmanned Aircraft System Electronic Warfare Officer',
    '7380 Tactical Systems Officer/Mission Specialist',
    '7502 Forward Air Controller/Air Officer',
    '7503 Billet Designator - Fixed-Wing Pilot',
    '7504 Billet Designator - Naval Flight Officer',
    '7505 Billet Designator - Helicopter Pilot',
    '7506 Billet Designator - Any Pilot/Naval Flight Officer',
    '7507 Pilot VMA FRS Basic AV-8B Pilot',
    '7509 Pilot VMA AV-8B Qualified',
    '7513 Pilot Helicopter AH-1Z/UH-1Y',
    '7516 Pilot VMFA FRS Basic F-35B Pilot',
    '7517 VH-92, Presidential Helicopter Pilot',
    '7518 Pilot VMFA, F-35 Qualified',
    '7521 Pilot VMFA, F-4B Qualified, F/A-18 FRS Basic',
    '7522 Pilot VMFA, F-4S Qualified',
    '7523 Pilot VMFA, F/A-18 Qualified',
    '7524 Weapons Systems Officer, F/A-18D FRS Basic',
    '7525 Weapons Systems Officer, F/A-18D Qualified',
    '7527 Pilot VMFA, F/A-18D Qualified',
    '7531 Pilot VMM, V-22 FRS Basic',
    '7532 Pilot VMM, V-22 Qualified',
    '7541 Pilot VMAQ/VMFP, EA-6B FRS Basic',
    '7542 Pilot VMAQ/VMFP, EA-6A Qualified',
    '7543 Pilot VMAQ/VMFP, EA-6B Qualified',
    '7545 Pilot VMAQ/VMFP, RF-4B Qualified',
    '7550 Pilot VMGR, Maritime Advance',
    '7551 Pilot VMGR, C-9 Qualified',
    '7552 Pilot VMGR, TC-4C Qualified',
    '7553 Pilot, C-20 Qualified',
    '7554 Pilot VMGR, UC-35 Qualified',
    '7555 Pilot VMGR, UC-12B Qualified',
    '7556 Pilot VMGR, KC-130 Co-Pilot (T2P/T3P)',
    '7557 Pilot VMGR, KC-130 Aircraft Commander',
    '7558 Pilot HMH/M/L/A, CH-53D FRS Basic',
    '7559 Pilot VMGR, CT-39 Qualified',
    '7560 Pilot HMH/M/L/A, CH-53E FRS Basic',
    '7561 Pilot HMH/M/L/A, CH-46 FRS Basic',
    '7562 Pilot HMH/M/L/A, CH-46 Qualified',
    '7563 Pilot HMH/M/L/A, UH-1 Qualified',
    '7564 Pilot HMH/M/L/A, CH-53 A/D Qualified',
    '7565 Pilot HMH/M/L/A, AH-1 Qualified',
    '7566 Pilot HMH/M/L/A, CH-53E Qualified',
    '7567 Pilot HMH/M/L/A, UH-1N FRS Basic',
    '7568 Pilot HMH/M/L/A, AH-1 FRS Basic',
    '7574 Qualified Supporting Arms Coordinator',
    '7576 Pilot VMO',
    '7577 Weapons and Tactics Instructor',
    '7578 Student, Naval Flight Officer',
    '7580 Student, Tactical Navigator Flight',
    '7582 Electronic Warfare Officer, EA-6B FRS Basic',
    '7583 Bombardier/Navigator, A-6E Qualified',
    '7584 Electronic Warfare Officer, EA-6A Qualified',
    '7585 Airborne Reconnaissance Officer Qualified RF-4B',
    '7587 Radar Intercept Officer, F-4S',
    '7588 Electronic Warfare Officer, EA-6B Qualified',
    '7589 V/STOL Landing Signal Officer',
    '7590 Landing Signal Officer Trainee',
    '7591 Naval Flight Officer',
    '7592 Pilot VMAW',
    '7593 Landing Signal Officer, Phase I/II Qualified',
    '7594 Landing Signal Officer, Phase III Qualified',
    '7595 Test Pilot/Flight Test Project Officer',
    '7596 Aviation Safety Officer',
    '7597 Pilot, Rotary Wing, Basic',
    '7598 Pilot, Fixed Wing, Basic',
    '7599 Student Naval Aviator',
    '8001 Basic Officer',
    '8002 Joint Terminal Attack Controller',
    '8003 General Officer',
    '8005 Special Assignment Officer',
    '8006 Billet Designator—Unrestricted Officer',
    '8007 Billet Designator—Unrestricted Ground Officer',
    '8009 Billet Designator—Air Control/Antiair Warfare Officer',
    '8012 Ground Safety Officer',
    '8016 Special Technical Operations',
    '8023 Parachute Officer' ,
    '8024 Combatant Diver Officer' ,
    '8026 Parachute/Combatant Diver Officer',
    '8040 Colonel, Logistician',
    '8041 Colonel, Ground',
    '8042 Colonel, Naval Aviator/Naval Flight Officer',
    '8051 Operations Research Specialist',
    '8055 Information Management Officer',
    '8156 Hazardous Material/Hazardous Waste Officer',
    '8057 Acquisition Professional Candidate',
    '8058 Acquisition Manager',
    '8059 Aviation Acquisition Management Professional',
    '8060 Acquisition Specialist',
    '8061 Acquisition Management Professional',
    '8077 Weapons And Tactics Instructor Air Officer',
    '8220 Billet Designator—Political Military Officer',
    '8221 Regional Affairs Officer, Latin America',
    '8222 Regional Affairs Officer, Former Soviet Union',
    '8223 Regional Affairs Officer, Northeast Asia',
    '8224 Regional Affairs Officer, Middle East/North Africa',
    '8225 Regional Affairs Officer, Sub-Saharan Africa',
    '8226 Regional Affairs Officer, Southwest Asia',
    '8227 Regional Affairs Officer, Western Europe',
    '8228 Regional Affairs Officer, Eastern Asia',
    '8229 Regional Affairs Officer, Eastern Europe',
    '8240 Basic Foreign Area officer',
    '8241 Foreign Area Officer, Latin America',
    '8242 Foreign Area Officer, Former Soviet Union',
    '8243 Foreign Area Officer, Peoples Republic of China',
    '8244 Foreign Area Officer, Middle East/North Africa',
    '8245 Foreign Area Officer, Sub-Saharan Africa',
    '8246 Foreign Area Officer, Southwest Asia',
    '8247 Foreign Area Officer, Western Europe',
    '8248 Foreign Area Officer, East Asia',
    '8249 Foreign Area Officer, Eastern Europe',
    '8802 Education Officer',
    '8803 Leadership Development Specialist',
    '8820 Aeronautical Engineer',
    '8824 Electronics Engineer',
    '8825 Modeling and Simulation Officer',
    '8826 Ordnance Systems Engineer',
    '8831 Environmental Engineering Management Officer',
    '8832 Nuclear Engineer',
    '8834 Technical Information Operation Officer',
    '8840 Manpower Management Officer',
    '8844 Financial Management Specialist',
    '8846 Data Systems Specialist',
    '8848 Management, Data Systems Officer',
    '8850 Operations Analyst',
    '8852 Defense Systems Analyst',
    '8858 Command, Control, Communications, Computers and Intelligence',
    '8862 Material Management Officer',
    '8866 Space Operations Officer',
    '8878 Historian',
    '9701 Joint Qualified Officer Nominee',
    '9702 Joint Qualified Officer',
]

export const enlisted_army_codes = [
    '00D Special Duty Assignment',
    '00F MOS Immaterial National Guard Bureau',
    '00G MOS Immaterial US Army Reserve',
    '00S Special Duty Assignment AFSC',
    '00Z Command Sergeant Major',
    '09B Trainee Unassigned',
    '09C Trainee Language',
    '09D College Trainee',
    '09G Army National Guard on Active Duty Medical Hold',
    '09H US Army Reserve on Active Duty Medical Hold',
    '09J GED Completion Program',
    '09M March 2 Success',
    '09N Nurse Corps Candidate',
    '09R Simultaneous MBR Program',
    '09S Commissioned Officer Candidate',
    '09T College Student Army National Guard Officer Program',
    '09U Prior Service or Branch Transfer without Defined MOS',
    '09W Warrant Officer Candidate',
    '11B Infantryman',
    '11C Indirect Fire Infantryman',
    '11X Undetermined Infantry',
    '11Z Infantry Senior Sergeant',
    '12B Combat Engineer',
    '12C Bridge Crewmember',
    '12D Diver',
    '12G Quarrying Specialist',
    '12H Construction Engineering Supervisor',
    '12K Plumber',
    '12M Firefighter',
    '12N Horizontal Construction Engineer',
    '12P Prime Power Production Specialist',
    '12Q Power Line Distribution Specialist',
    '12R Interior Electrician',
    '12T Technical Engineer',
    '12V Concrete and Asphalt Equipment Operator',
    '12W Carpentry and Masonry Specialist',
    '12X General Engineering Supervisor',
    '12Y Geospatial Engineer',
    '12Z Combat Engineering Senior Sergeant',
    '13B Cannon Crewmember',
    '13F Fire Support Specialist',
    '13J Fire Control Specialist',
    '13M Multiple Launch Rocket System/High Mobility Artillery Rocket System Crewmember',
    '13R Field Artillery Firefinder Radar Operator',
    '13Z Field Artillery Senior Sergeant/Sergeant Major',
    '14E PATRIOT Fire Control Enhanced Operator/Maintainer',
    '14G Air Defense Battle Management System Operator',
    '14H Air Defense Enhanced Early Warning System Operator',
    '14P Air and Missile Defense Crewmember',
    '14S Avenger Crew Member',
    '14T PATRIOT Launching Station Enhanced Operator/Maintainer',
    '14Z Air Defense Artillery (ADA) Senior Sergeant',
    '15B Aircraft Powerplant Repairer',
    '15D Aircraft Powertrain Repairer',
    '15E Unmanned Aircraft Systems Repairer',
    '15F Aircraft Electrician',
    '15G Aircraft Structural Repairer',
    '15H Aircraft Pneudraulics Repairer',
    '15K Aircraft Components Repair Supervisor',
    '15M UH-1 Helicopter Repairer (RC)',
    '15N Avionic Mechanic',
    '15P Aviation Operations Specialist',
    '15Q Air Traffic Control Operator',
    '15R AH-64 Attack Helicopter Repairer',
    '15T UH-60 Helicopter Repairer',
    '15U CH-47 Helicopter Repairer',
    '15V Observation/Scout Helicopter Repairer (RC)',
    '15W Unmanned Aerial Vehicle Operator',
    '15X AH-64A Armament/Electrical/Avionics Systems Repairer',
    '15Y AH-64D Armament/Electrical/Avionic Systems Repairer',
    '15Z Aircraft Maintenance Senior Sergeant',
    '17C Cyber Operations Specialist',
    '17E Electronic Warfare Specialist',
    '18B Special Forces Weapons Sergeant',
    '18C Special Forces Engineer Sergeant',
    '18D Special Forces Medical Sergeant',
    '18E Special Forces Communications Sergeant',
    '18F Special Forces Intelligence Sergeant',
    '18X Special Forces Candidate',
    '18Z Special Forces Senior Sergeant',
    '19D Cavalry Scout',
    '19K M1 Armor Crewman',
    '19Z Armor Senior Sergeant',
    '25B Information Technology Specialist',
    '25C Radio Operator-Maintainer',
    '25D Cyber Network Defender',
    '25E Electromagnetic Spectrum Manager',
    '25F Network Switching Systems Operator-Maintainer',
    '25L Cable Systems Installer-Maintainer',
    '25M Multimedia Illustrator',
    '25N Nodal Network Systems Operators-Maintainer',
    '25P Microwave Systems Operator/Maintainer',
    '25Q Multichannel Transmission Systems Operator-Maintainer',
    '25R Visual Information Equipment Operator-Maintainer',
    '25S Satellite Communications Systems Operator/Maintainer',
    '25T Satellite/Microwave Systems Chief',
    '25U Signal Support Systems Specialist',
    '25V Combat Documentation/Production Specialist',
    '25W Telecommunications Operations Chief',
    '25X Chief Signal NCO',
    '25Z Visual Information Operations Chief',
    '27D Paralegal Specialist',
    '31B Military Police',
    '31D CID Special Agent',
    '31E Internment/Resettlement Specialist',
    '31K Working Dog Handler',
    '31Z Military Police Sergeant Major',
    '09L Interpreter/Translator',
    '35F Intelligence Analyst',
    '35G Geospatial Intelligence Imagery Analyst',
    '35L Counterintelligence Special Agent',
    '35M Human Intelligence Collector',
    '35N Signals Intelligence Analyst',
    '35P Cryptologic Linguist',
    '35Q Cryptologic Network Warfare Specialist',
    '35S Signals Collector/Analyst',
    '35T Military Intelligence Systems Maintainer/Integrator',
    '35V Signals Intelligence Senior Sergeant/Chief Signals Intelligence Sergeant',
    '35X Intelligence Senior Sergeant/Chief Intelligence Sergeant',
    '35Y Chief Counterintelligence/Human Intelligence Sergeant',
    '35Z Signals Intelligence (Electronic Warfare) / Senior Sergeant/ Chief',
    '36B Financial Management Technician',
    '37F Psychological Operations Specialist',
    '38B Civil Affairs Specialist',
    '42A Human Resources Specialist',
    '42R Musician',
    '42S Special Band Member',
    '46Q Public Affairs Specialist',
    '46R Public Affairs Broadcast Specialist',
    '46S Public Affairs Mass Communications Specialist',
    '46Z Chief Public Affairs NCO',
    '51C Acquisition, Logistics & Technology (AL&T) Contracting NCO',
    '56M Religious Affairs Specialist',
    '68A Biomedical Equipment Specialist',
    '68B Orthopedic Specialist',
    '68C Practical Nursing Specialist-(LPN/LVN)',
    '68D Operating Room Specialist',
    '68E Dental Specialist',
    '68F Physical Therapy Specialist',
    '68G Patient Administration Specialist (formerly 71G)',
    '68H Optical Laboratory Specialist',
    '68J Medical Logistics Specialist',
    '68K Medical Laboratory Specialist',
    '68L Occupational Therapy Specialist',
    '68M Nutrition Care Specialist',
    '68N Cardiovascular Specialist',
    '68P Radiology Specialist',
    '68Q Pharmacy Specialist',
    '68R Veterinary Food Inspection Specialist',
    '68S Preventive Medicine Specialist',
    '68T Animal Care Specialist',
    '68U Ear, Nose, and Throat (ENT) Specialist',
    '68V Respiratory Specialist',
    '68W Combat Medic Specialist',
    '68X Behavioral Health Specialist',
    '68Y Eye Specialist',
    '68Z Chief Medical NCO',
    '74D Chemical, Biological, Radiological and Nuclear (CBRN) Specialist',
    '88H Cargo Specialist',
    '88K Watercraft Operator',
    '88L Watercraft Engineer',
    '88M Motor Transport Operator',
    '88N Transportation Management Coordinator',
    '88P Railway Equipment Repairer (RC)',
    '88T Railway Section Repairer (RC)',
    '88U Railway Operations Crew Member (RC)',
    '88Z Transportation Senior Sergeant',
    '89A Ammunition Stock Control and Accounting Specialist',
    '89B Ammunition Specialist',
    '89D Explosive Ordnance Disposal Specialist',
    '91A M1 Abrams Tank System Maintainer',
    '91B Wheeled Vehicle Mechanic',
    '91C Utilities Equipment Repairer',
    '91D Power Generation Equipment Repairer',
    '91E Allied Trades Specialist',
    '91F Small Arms/Towed Artillery Repairer',
    '91G Fire Control Repairer',
    '91H Track Vehicle Repairer',
    '91J Quartermaster and Chemical Equipment Repairer',
    '91L Construction Equipment Repairer',
    '91M Bradley Fighting Vehicle System Maintainer',
    '91P Self Propelled Artillery Systems Maintainer',
    '91S Stryker Systems Maintainer',
    '91X Maintenance Supervisor',
    '91Z Senior Maintenance Supervisor',
    '94A Land Combat Electronic Missile System Repairer',
    '94D Air Traffic Control Equipment Repairer',
    '94E Radio & Communications Security (COMSEC) Equipment Repairer',
    '94F Computer/Detection Systems Repairer',
    '94H Test Measurement and Diagnostic Equipment (TMDE) Maintenance Support Specialist',
    '94M Radar Repairer',
    '94P Multiple Launch Rocket System (MLRS) Repairer',
    '94R Avionic and Survivability Repairer',
    '94S PATRIOT System Repairer',
    '94T AVENGER System Repairer',
    '94W Electronic Maintenance Chief',
    '94X Senior Missile Systems Maintainer',
    '94Y Integrated Family of Test Equipment (IFTE) Operator/Maintainer',
    '94Z Senior Electronic Maintenance Chief',
    '92A Automated Logistical Specialist',
    '92F Petroleum & Supply Specialist',
    '92G Culinary Specialist',
    '92L Petroleum Laboratory Specialist',
    '92M Mortuary Affairs Specialist',
    '92R Parachute Rigger',
    '92S Shower/Laundry and Clothing Repair Specialist',
    '92W Water Treatment Specialist',
    '92Y Unit Supply Specialist',
    '92Z Senior Noncommissioned Logistician',
]

export const officer_army_codes = [
    '00A Duties Unassigned',
    '00B General Officer',
    '00C Relieved from Duty; Sick in Hospital or Quarters',
    '00D Newly Commissioned Officers Awaiting Entry on Active Duty for Officer Basic Course Attendance',
    '00E Student Officer',
    '01A Officer Generalist',
    '01B Aviation/Infantry/Armor/MI Immaterial',
    '01C Chemical/Engineer/MP Immaterial',
    '01D Army Financial Management/Adjutant General immaterial',
    '02A Combat Arms Generalist',
    '02B Infantry/Armor Immaterial',
    '02C Infantry/Armor/Field Artillery/Engineer Immaterial',
    '03A Infantry/Armor Immaterial',
    '05A Army Medical Department',
    '09G Army National Guard on Active Duty Medical Hold',
    '09H US Army Reserve on Active Duty Medical Hold',
    '001A Unqual in Auth WO MOS',
    '002A Patient',
    '003A Student',
    '004A Duties Unassigned',
    '011A Brch/MOS Immaterial',
    '019G Army National Guard on Active Duty Medical Hold',
    '019H US Army Reserve on Active Duty Medical Hold',
    '11A Infantry Officer',
    '12A Engineer, General',
    '120A Construction Engineer Technician',
    '125D Geospatial Information Technician',
    '13A Field Artillery Officer',
    '131A Field Artillery Targeting Technician',
    '14A Air Defense Artillery Officer',
    '140A Command and Control Systems Integrator',
    '140K Air And Missile Defense (AMD) Tactician',
    '140L Air and Missile Defense (AMD) Technician',
    '140Z Air Defense Artillery (ADA) Immaterial',
    '15A Aviation Officer',
    '15B Aviation Combined Arms Operations',
    '15C Aviation All-Source Intelligence Officer',
    '15D Aviation Maintenance Officer',
    '150A Air Traffic and Air Space Management Technician',
    '150U Unmanned Aircraft Systems Operations Technician',
    '151A Aviation Maintenance Technician (Nonrated)',
    '152C OH-6 Pilot',
    '152E AH-64E Attack Pilot',
    '152F AH-64A Attack Pilot',
    '152G AH-1 Attack Pilot (RC)',
    '152H AH-64D Attack Pilot',
    '153A Rotary Wing Aviator',
    '153B UH-1 Pilot (RC)',
    '153D UH-60 Pilot',
    '153DD UH-60 MEDEVAC Pilot',
    '153E MH-60 Pilot',
    '153L UH-72A Pilot',
    '153M UH-60M Pilot',
    '154C CH-47D Pilot',
    '154E MH-47 Pilot',
    '154F CH-47F Pilot',
    '155A Fixed Wing Aviator',
    '155E C-12 Pilot',
    '155F Jet Aircraft Pilot',
    '155G O-5A/EO-5B/RC-7 Pilot',
    '17A Cyber Warfare Officer',
    '17B Cyber Electromagnetic Activities Officer - Electronic Warfare',
    '170A Cyber Operations Technician',
    '170B Cyber Electromagnetic Activities Technician - Electronic Warfare',
    '18A Special Forces Officer',
    '180A Special Forces Warrant Officer',
    '19A Armor General',
    '19B Armor Officer',
    '19C Cavalry',
    '25A Signal, General',
    '255A Information Services Technician',
    '255N Network Management Technician',
    '255S Information Protection Technician',
    '255Z Senior Network Operations Technician',
    '26A Network Systems Engineer',
    '26B Information Systems Engineer',
    '26Z Senior Information Network Engineer',
    '27A Judge Advocate',
    '27B Military Judge',
    '270A Legal Administrator',
    '30A Information Operations Officer',
    '31A Military Police',
    '311A CID Special Agent',
    '34A Strategic Intelligence Officer',
    '35D All Source Intelligence Officer',
    '35E Counterintelligence Officer',
    '35F Human Intelligence Officer',
    '35G Signals Intelligence Officer',
    '350F All Source Intelligence Technician',
    '350G Imagery Intelligence Technician',
    '351Z Attaché Technician',
    '351L Counterintelligence Supervisory Special Agent',
    '351M Human Intelligence Collection Technician',
    '351Y Area Intelligence Technician',
    '352N Signal Intelligence Analysis Technician',
    '352S Signals Collector Technician',
    '353T Intelligence Systems Maintenance Technician',
    '36A Financial Manager',
    '37A Psychological Operations',
    '37X Psychological Operations, Designated',
    '38A Civil Affairs',
    '38G Military Government Specialist',
    '38X Civil Affairs, Designated',
    '40A Space Operations',
    '40C Army Astronaut',
    '42B Human Resources Officer',
    '42C Army Bands',
    '42H Senior Human Resources Officer',
    '20A Human Resources Technician',
    '420C Bandmaster',
    '46A Public Affairs, General',
    '46X Public Affairs, General',
    '47A USMA, Professor',
    '47C USMA, Professor of English',
    '47D USMA, Professor of Electrical Engineering and Computer Science',
    '47E USMA, Professor of Law',
    '47F USMA, Professor of Systems Engineering',
    '47G USMA, Professor of Foreign Languages',
    '47H USMA, Professor of Physics',
    '47J USMA, Professor of Social Sciences',
    '47K USMA, Professor of History',
    '47L USMA, Professor of Behavioral Sciences and Leadership',
    '47M USMA, Professor of Chemistry',
    '47N USMA, Professor of Mathematical Sciences',
    '47P USMA, Professor of Geography and Environmental Engineering',
    '47Q USMA, Professor and Associate Dean',
    '47R USMA, Professor of Civil and Mechanical Engineering',
    '47S USMA, Professor of Physical Education',
    '47T USMA, Professor of Leader Development and Organizational Learning',
    '47U USMA, Professor of Military Art and Science',
    '47V USMA, Professor of Army Cyber',
    '48B Latin America',
    '48C Europe (no longer used -- these officers are now designated 48E)',
    '48D South Asia',
    '48E Eurasia',
    '48F China',
    '48G Mideast/North Africa',
    '48H Northeast Asia',
    '48I Southeast Asia',
    '48J Africa, South of the Sahara',
    '48X Foreign Area Officer',
    '49A Operations Research/Systems Analysis',
    '49W Trained, ORSA',
    '49X Untrained, ORSA',
    '50A Force Development',
    '51A Program Management',
    '51C Contract Management',
    '51R Systems Automation Acquisition and Engineering',
    '51S Research and Engineering',
    '51T Test and Evaluation',
    '51Z Acquisitions',
    '57A Simulation Operations Officer',
    '56A Command and Unit Chaplain',
    '56D Clinical Pastoral Educator',
    '56X Chaplain Candidate',
    '58A Army Marketing Officer',
    '60A Operational Medicine',
    '60B Nuclear Medicine Officer',
    '60C Preventive Medicine Officer',
    '60D Occupational Medicine Officer',
    '60F Pulmonary Disease/Critical Care Officer',
    '60G Gastroenterologist',
    '60H Cardiologist',
    '60J Obstetrician and Gynecologist',
    '60K Urologist',
    '60L Dermatologist',
    '60M Allergist, Clinical Immunologist',
    '60N Anesthesiologist',
    '60P Pediatrician',
    '60Q Pediatric Sub-Specialist',
    '60R Child Neurologist',
    '60S Ophthalmologist',
    '60T Otolaryngologist',
    '60U Child Psychiatrist',
    '60V Neurologist',
    '60W Psychiatrist',
    '61A Nephrologist',
    '61B Medical Oncologist/Hematologist',
    '61C Endocrinologist',
    '61D Rheumatologist',
    '61E Clinical Pharmacologist',
    '61F Internist',
    '61G Infectious Disease Officer',
    '61H Family Medicine',
    '61J General Surgeon',
    '61K Thoracic Surgeon',
    '61L Plastic Surgeon',
    '61M Orthopedic Surgeon',
    '61N Flight Surgeon',
    '61P Physiatrist',
    '61Q Radiation Oncologist',
    '61R Diagnostic Radiologist',
    '61U Pathologist',
    '61W Peripheral Vascular Surgeon',
    '61Z Neurosurgeon',
    '62A Emergency Physician',
    '62B Field Surgeon',
    '63A General Dentist',
    '63B Comprehensive Dentist',
    '63D Periodontist',
    '63E Endodontist',
    '63F Prosthodontist',
    '63H Public Health Dentist',
    '63K Pediatric Dentist',
    '63M Orthodontist',
    '63N Oral and Maxillofacial Surgeon',
    '63P Oral Pathologist',
    '63R Executive Dentist',
    '64A Field Veterinary Service',
    '64B Veterinary Preventive Medicine',
    '64C Veterinary Laboratory Animal Medicine',
    '64D Veterinary Pathology',
    '64E Veterinary Comparative Medicine',
    '64F Veterinary Clinical Medicine',
    '64Z Senior Veterinarian',
    '640A Food Safety Officer',
    '65A Occupational Therapy',
    '65B Physical Therapy',
    '65C Dietitian',
    '65D Physician Assistant',
    '65X Specialist Allied Operations',
    '66B Community Health Nurse',
    '66C Psychiatric/Mental Health Nurse',
    '66E Perioperative Nurse',
    '66F Nurse Anesthetist',
    '66G Obstetrics and Gyneco',
    '66H Medical-Surgical Nurse',
    '66N Generalist Nurse',
    '66P Family Nurse Practitioner',
    '66R Psychiatric Nurse Practitioner',
    '66S Critical Care Nurse',
    '66T Emergency Room Nurse',
    '67A Health Services',
    '67B Laboratory Sciences',
    '67C Preventive Medicine Sciences',
    '67D Behavioral Sciences',
    '67E Pharmacy',
    '67F Optometry',
    '67G Podiatry',
    '67J Aeromedical Evacuation',
    '670A Health Services Maintenance Technician',
    '70A Health Care Administration',
    '70B Health Services Administration',
    '70C Health Services Comptroller',
    '70D Health Services Systems Management',
    '70E Patient Administration',
    '70F Health Services Human Resources',
    '70H Health Services Plans, Operations, Intelligence, Security, and Training',
    '70K Health Services Materiel',
    '71A Microbiology',
    '71B Biochemistry',
    '71E Clinical Laboratory',
    '71F Research Psychology',
    '72A Nuclear Medical Science',
    '72B Entomology',
    '72C Audiology',
    '72D Environmental Science and Engineering',
    '73A Social Work',
    '73B Clinical Psychology',
    '74A Chemical, General',
    '740A Chemical, Biological, Radiological and Nuclear (CBRN) Warrant Officer',
    '90A Multifunctional Logistician (LG)',
    '88A Transportation, General',
    '88B Traffic Management',
    '88C Marine and Terminal Operations',
    '88D Motor/Rail Transportation',
    '880A Marine Deck Officer',
    '881A Marine Engineering Officer',
    '882A Mobility Officer',
    '89E Explosive Ordnance Disposal Officer',
    '91A Materiel Maintenance and Munitions Management Officer',
    '890A Ammunition Warrant Officer',
    '913A Armament Systems Maintenance Warrant Officer',
    '914A Allied Trades Warrant Officer',
    '915A Automotive Maintenance Warrant Officer',
    '915E Senior Automotive Maintenance Warrant Officer',
    '919A Engineer Equipment Maintenance Warrant Officer',
    '948B Electronic Systems Maintenance Warrant Officer',
    '948D Electronic Missile Systems Maintenance Warrant Officer',
    '948E Senior Electronics Maintenance Warrant Officer',
    '92A Quartermaster Officer',
    '92D Aerial Delivery and Materiel',
    '920A Property Accounting Technician',
    '920B Supply Systems Technician',
    '921A Airdrop Systems Technician',
    '922A Food Service Technician',
    '923A Petroleum Systems Technician',
]

export const enlisted_navy_rates = [
    'ABE Aviation Boatswain\'s Mate - Equipment',
    'ABF Aviation Boatswain\'s Mate - Fuels',
    'ABH Aviation Boatswain\'s Mate - Handling',
    'AC Air Traffic Controlman',
    'AD Aviation Machinist Mate',
    'AE Aviation Electronics Mate',
    'AG Aviation Aerographer\'s Mate',
    'AIRC Aircrewman',
    'AM Aviation Structural Mechanic',
    'AME Aviation Structural Mechanic - Equipment',
    'AN Airman',
    'AO Aviation Ordnanceman',
    'AS Aviation Support Equipment Technician',
    'AT Aviation Electronics Technician',
    'AWF Aircrewman',
    'AWO Aircrewman',
    'AWR Aircrewman',
    'AWS Aircrewman',
    'AWV Aircrewman',
    'AZ Aviation Maintenance Administrationman',
    'BM Boatswain\'s Mate',
    'BU Builder',
    'CE Construction Electrician',
    'CM Construction Mechanic',
    'CS Culinary Specialist',
    'CS (SS) Culinary Specialist (Submarine)',
    'CTI Cryptologic Technician Interpretive',
    'CTI/ATF Cryptologic Technician - Interpretive',
    'CTM Cryptologic Technician - Maintenance',
    'CTN/ATF Cryptologic Technician Interpretive',
    'CTR Cryptologic Technician - Collection',
    'CTT/AEF Cryptologic Technician - Technical',
    'CTT/SG	Cryptologic Technician Technical',
    'DC Damage Controlman',
    'EA Engineering Aid',
    'EM Electricians Mate',
    'EM (NUC) Electricians Mate (Nuclear Field)',
    'EN Engineman',
    'EO Equipment Operator',
    'EOD Explosive Ordinance Disposal',
    'ET Electronics Technician',
    'ET Electronics Technician (Submarine)',
    'ET (NUC) Electronics Technician (Nuclear)',
    'ETV/ETR Electronics Technician (Submarine)',
    'FC Fire Controlman',
    'FN Fireman',
    'FT (SS) Fire Control Technician (Submarine)',
    'GM Gunner\'s Mate',
    'GSE Gas Turbine Systems Technician - Electrical',
    'GSM Gas Turbine Systems Technician - Mechanical',
    'HM/5YO Hospital Corpsman',
    'HM/SG Hospital Corpsman',
    'HT Hull Maintenance Technician',
    'IC Interior Communications Electrician',
    'IS Intelligence Specialist',
    'IS/ATF Intelligence Specialist',
    'IT Information System Technician',
    'IT/ATF Information System Technician',
    'IT/SG Information System Technician',
    'ITS Information System Technician',
    'LN Legalman',
    'LS Logistics Specialist',
    'LS (SS) Logistics Specialist - Submarines',
    'MA Master at Arms',
    'MC Mass Communications Specialist',
    'MM Machinist Mate',
    'MM (NUC) Machinist Mate (Nuclear Field)',
    'MMA/MMW Machinist Mate (Submarine)',
    'MN Mineman',
    'MR Machinery Repairman',
    'MT Missile Technician (Submarine)',
    'NC Navy Counselor Fleet Career Counselor',
    'NC Navy Counselor Career Recruiting Force',
    'ND Navy Diver',
    'OS Operations Specialist',
    'PR Aircrew Survival Equipmentman',
    'PS Personnel Specialist',
    'QM Quartermaster',
    'RP Religious Program Specialist',
    'SB Special Warfare Boat Operator',
    'SECF Submarine Electronics/Computer Field',
    'SH Ship\'s Serviceman',
    'SN Seaman',
    'SN Seaman (Submarine)',
    'SO Special Warfare Operator',
    'STG Sonar Technician - Surface',
    'STG/SG Sonar Technician - Surface',
    'STS Sonar Technician (Submarine)',
    'SW Steelworker',
    'UCT Underwater Construction Team',
    'UT Utilitiesman',
    'YN (SS) Yeoman (Submarine)',
]

export const officer_navy_rates = [
    'Navy Reserve Officer Training Corps (NROTC)',
    'Aerospace Maintenance',
    'Naval Aviator (Pilot)',
    'Naval Aviator (NFO)',
    'Chaplain (Direct Appointment)',
    'Chaplain (Student Selection Program)',
    'Civil Engineer',
    'Civil Engineer (Collegiate Acceptance Program)',
    'Cryptology',
    'Engineering Duty Officer',
    'Intelligence (Intel)',
    'JAG (Law School)',
    'Medical Services Corps',
    'Naval Reactors Engineer',
    'Nuclear Officer (Surface)',
    'Nuclear Officer (Submarines)',
    'Nuclear Power School Instructor',
    'Nurse Corps',
    'Oceanography',
    'Public Affairs',
    'SEAL (Special Warfare)',
    'Special Operations (EOD)',
    'Special Operations (Diver)',
    'Supply',
    'Surface Warfare Officer',
]

