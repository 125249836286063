import React from 'react' 
import DndSectionList from './DndSectionList'
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Grid from '@material-ui/core/Grid';

function ArrangeSections({ forums }) {

  const paper = {
    padding: 50,
  }

  if (!forums) return <div/>

  return (
    <div style={{paddingTop: 100}}>
      <Grid container direction='column' justify='center' alignItems='center'>
        <Paper style={paper} elevation={3}>
          <Grid container direction='column' justify='center' alignItems='center'>
            <h5>Drag and drop to arrange sections</h5>
            <DndSectionList forums={forums}/>
          </Grid>
        </Paper>
      </Grid>
    </div>
  )
} 

export default compose(
  firestoreConnect((props) => [
    {
      collection: 'forum',
      orderBy: ['index'],
    },
  ]),
  connect((state, props) => ({
    forums: state.firestore.ordered.forum
  }))
)(ArrangeSections)