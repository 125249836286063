import React, { Fragment, useState } from 'react'
import {connect} from 'react-redux'
import { Link, withRouter, Redirect } from 'react-router-dom'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Button from '@material-ui/core/Button'
import { ExpansionPanelActions, CircularProgress } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { updateEmail } from '../../Redux/Actions/authActions'
import { updateUsername } from '../../Redux/Actions/userActions'
import RemoveEmail from '../Components/AccountComponents/RemoveEmail'
import PasswordConfirmation from '../Components/AccountComponents/PasswordConfirmation'
import MakePrimaryEmail from '../Components/AccountComponents/MakePrimaryEmail'
import firebase from 'firebase/app'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import green from '@material-ui/core/colors/green'
import { getFirestore } from 'redux-firestore'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { EThree } from '@virgilsecurity/e3kit-browser'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'


const useStyles = makeStyles(theme => ({
    main: {
        width: '100%'
    },
    button: {
        textTransform: 'none',
    },
    successIcon: {
        color: green[300],
        marginTop: 10,
    }
}))

function Account(props) {
    const classes = useStyles()
    const profileId = props.match.params.user_id
    const { auth, profile, updateEmail, updateUsername, eThree } = props

    // STATE
    const [addEmail, setAddEmail] = useState(false)
    const [newEmail, setNewEmail] = useState('')
    const [currentPw, setCurrentPw] = useState('')
    const [newPw, setNewPw] = useState('')
    const [confirmPw, setConfirmPw] = useState('')
    const [pwError, setPwError] = useState(null)
    const [newPwError, setNewPwError] = useState(null)
    const [updated, setUpdated] = useState(false)
    const [newUsername, setNewUsername] = useState('')
    const [newUnError, setNewUnError] = useState(null)
    const [showPw1, setShowPw1] = useState(false)
    const [showPw2, setShowPw2] = useState(false)
    const [showPw3, setShowPw3] = useState(false)

    // get users emails from email collection
    const storeAs = `${profileId}-emails`
    useFirestoreConnect([
        {
            collection: 'email',
            doc: profileId,
            storeAs
        }
    ])
    const emails = useSelector(state => state.firestore.ordered[storeAs])

    const handleAddEmail = (e) => {
        if (newEmail && newEmail !== '') {
            var emails = userEmails ? userEmails : []
            emails.push(newEmail)
            updateEmail(emails)
            setNewEmail('')
        }
    }

    const handleRemoveEmail = (e, emailToRemove) => {
        var emails = userEmails ? userEmails : []
        emails.splice(emails.indexOf(emailToRemove), 1)
        updateEmail(emails)
    }

    const handleEmailChange = (e) => {
        setNewEmail(e.target.value)
    } 
    
    const handleNameChange = (e) => {
        setNewUsername(e.target.value)
        if (newUnError) {
            setNewUnError(null)
        }
    }

    const handlePwChange = (e) => {
        e.preventDefault()
        switch (e.target.id) {
            case 'current':
                setCurrentPw(e.target.value)
                if (pwError) {
                    setPwError(null)
                }
                break
            case 'new':
                setNewPw(e.target.value)
                if (e.target.value === confirmPw) {
                    setNewPwError(null)
                }
                break
            case 'confirm':
                setConfirmPw(e.target.value)
                if (newPw === e.target.value) {
                    setNewPwError(null)
                }
                break
            default:
                break
        }
    }

    const handlePwPanelChange = () => {
        setNewPw('')
        setConfirmPw('')
        setCurrentPw('')
    }

    const handleUpdatePw = (e) => {
        e.preventDefault()

        if (newPw !== confirmPw) {
            setNewPwError('Passwords do not match')
        }
        else {
            const user = firebase.auth().currentUser
            const credential = firebase.auth.EmailAuthProvider.credential(user.email, currentPw)
            user.reauthenticateWithCredential(credential).then(() => {
                user.updatePassword(newPw).then(() => {
                    setUpdated(true)
                    setNewPw('')
                    setConfirmPw('')
                    setCurrentPw('')
                }).catch(e => {
                    console.log(e)
                })
            }).catch(error => {
                if (error.code === 'auth/too-many-requests') {
                    setPwError('Too many failed attempts, try again later')
                } else {
                    setPwError('Invalid password')
                }
            })
        }
    }

    const handleUpdateUsername = (e) => {
        const firestore = getFirestore()
        var usernameExists = false
        firestore.collection('users').where('username', '==', newUsername).get()
            .then(snapshot => {
                if (snapshot.empty) {
                    updateUsername(newUsername)
                    setNewUsername('')
                } else {
                    setNewUnError('Username taken')
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    if (auth.isLoaded && !auth.uid) return <Redirect to='/' />
    if (!emails || !emails[0] || !emails[0].emails) return <div style={{paddingTop: 200, textAlign: 'center'}}><CircularProgress /></div>
    const userEmails = emails[0].emails

    return (
        <div style={{paddingTop: 100}}>
            <Container maxWidth='md'>
                <Paper className={classes.main}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <h4><b>Email addresses</b></h4>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container direction='column' justify='flex-start'>
                                <Grid item>
                                    <Grid container direction='row' justify='space-between'>
                                        <Grid item>
                                            <p><b>{auth.email}</b></p>
                                        </Grid>
                                        <Grid item>
                                            <p>Primary</p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {userEmails.map(email => (
                                    (email !== auth.email ?
                                        <Fragment key={email}>
                                            <Divider style={{marginBottom: 15}}/>
                                            <Grid item>
                                                <Grid container direction='row' justify='space-between'>
                                                    <Grid item>
                                                        <p><b>{email}</b></p>
                                                    </Grid>
                                                    <Grid item>
                                                        <MakePrimaryEmail email={email} />
                                                        <RemoveEmail handleRemoveEmail={handleRemoveEmail} email={email}/>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Fragment>
                                    :
                                        null
                                    )
                                ))}
                            </Grid>
                        </ExpansionPanelDetails>
                        <ExpansionPanelActions>
                            <Grid container direction='column' justify='flex-start' alignItems='flex-start'>
                                {addEmail ? 
                                    <Fragment>
                                        <Grid item>
                                            <TextField 
                                                variant='outlined'
                                                size='small'
                                                onChange={handleEmailChange}
                                                value={newEmail}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction='row' justify='flex-start'>
                                                <Grid item>
                                                    <Button 
                                                        onClick={() => setAddEmail(false)}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <PasswordConfirmation handler={handleAddEmail} buttonTitle='Submit' isDisabled={false}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Fragment>
                                :
                                    <Button 
                                        className={classes.button} 
                                        color='primary'
                                        onClick={() => setAddEmail(true)}
                                    >
                                        Add email address
                                    </Button>
                                }
                            </Grid>
                        </ExpansionPanelActions>
                    </ExpansionPanel>
                    <ExpansionPanel onChange={handlePwPanelChange}>
                        <ExpansionPanelSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <h4><b>Change Password</b></h4>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {updated ?
                                <Grid container direction='row' justify='flex-start' spacing={2}>
                                    <Grid item>
                                        <CheckCircleIcon className={classes.successIcon}/> 
                                    </Grid>
                                    <Grid item>
                                        <h5>Successfully Updated Password</h5>
                                    </Grid>
                                </Grid>
                            :
                                <Grid container direction='column' justify='flex-start' spacing={1}>
                                    <Grid item>
                                        <p>Current password</p>
                                        <TextField 
                                            variant='outlined'
                                            size='small'
                                            type={showPw1 ? 'text' : 'password'}
                                            id='current'
                                            onChange={handlePwChange}
                                            error={pwError ? true : false}
                                            helperText={pwError}
                                            value={currentPw}
                                            InputProps = {{
                                                endAdornment: 
                                                <InputAdornment position='end'>
                                                    <IconButton onClick={() => setShowPw1(!showPw1)}>
                                                        {showPw1 ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <p>New password</p>
                                        <TextField 
                                            variant='outlined'
                                            size='small'
                                            type={showPw2 ? 'text' : 'password'}
                                            id='new'
                                            onChange={handlePwChange}
                                            value={newPw}
                                            InputProps = {{
                                                endAdornment: 
                                                <InputAdornment position='end'>
                                                    <IconButton onClick={() => setShowPw2(!showPw2)}>
                                                        {showPw2 ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <p>Confirm new password</p>
                                        <TextField 
                                            variant='outlined'
                                            size='small'
                                            type={showPw3 ? 'text' : 'password'}
                                            id='confirm'
                                            onChange={handlePwChange}
                                            error={newPwError ? true : false}
                                            helperText={newPwError}
                                            value={confirmPw}
                                            InputProps = {{
                                                endAdornment: 
                                                <InputAdornment position='end'>
                                                    <IconButton onClick={() => setShowPw3(!showPw3)}>
                                                        {showPw3 ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }}
                                        />
                                    </Grid>
                                </Grid> 
                            }
                        </ExpansionPanelDetails>
                        <ExpansionPanelActions>
                            <Grid container direction='row' justify='flex-start'>
                                <Button 
                                    color='primary'  
                                    disabled={currentPw === '' ? true : newPw === '' ? true : confirmPw === '' ? true : false}   
                                    onClick={handleUpdatePw} 
                                >
                                    Save
                                </Button>
                            </Grid>
                        </ExpansionPanelActions>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <h4><b>Change Username</b></h4>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container direction='column' justify='flex-start' spacing={4}>
                                <Grid item>
                                    <Grid container direction='row' justify='space-between'>
                                        <Grid item>
                                            <p><b>{profile.username}</b></p>
                                        </Grid>
                                        <Grid item>
                                            <p>Current</p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <p>New username</p>
                                    <TextField
                                        variant='outlined'
                                        size='small'
                                        value={newUsername}
                                        onChange={handleNameChange}
                                        error={newUnError ? true : false}
                                        helperText={newUnError}
                                    />
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                        <ExpansionPanelActions>
                            <Grid container direction='row' justify='flex-start'>
                                <PasswordConfirmation 
                                    handler={handleUpdateUsername}
                                    buttonTitle='Save' 
                                    isDisabled={newUsername === ''}
                                />
                            </Grid>
                        </ExpansionPanelActions>
                    </ExpansionPanel>
                </Paper>
            </Container>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      eThree: state.auth.eThree,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateEmail: (email) => dispatch(updateEmail(email)),
        updateUsername: (username) => dispatch(updateUsername(username)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Account))