import React from 'react'
import Button from '@material-ui/core/Button'
import { getPremium } from '../../Redux/Actions/userActions'
import { connect } from 'react-redux'
import grey from '@material-ui/core/colors/grey'
import red from '@material-ui/core/colors/red'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import { Link } from 'react-router-dom'
import backgroundPic from '../../Assets/images/honey.jpg'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 100,
    backgroundColor: '#FFF',
    minHeight: '100vh',
  },
  background: {
    paddingTop: 100,
    minHeight: '100vh',
    backgroundImage: `url(${backgroundPic})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  title: {
    textAlign: 'center',
    fontWeight: 400,
  },
  center: {
    textAlign: 'center',
  },
  cardTitle: {
    textAlign: 'center',
    paddingBottom: 0,
    marginBottom: 0,
    fontWeight: 400,
    fontSize: 24,
  },
  premTitle: {
    textAlign: 'center',
    paddingBottom: 0,
    marginBottom: 0,
    paddingTop: 0,
    marginTop: 0,
    marginLeft: 58,
    fontWeight: 400,
    fontSize: 24,
  },
  cardSubtitle: {
    textAlign: 'center',
    paddingTop: 0,
    marginTop: 0,
    fontWeight: 300,
    fontSize: 15,
  },
  subtitle: {
    textAlign: 'center',
  },
  basic: {
    height: 350,
    width: 250,
    position: 'relative',
  },
  premium: {
    height: 370,
    width: 250,
    position: 'relative',
  },
  header: {
    width: '100%',
    height: '22%',
    backgroundColor: grey[200],
  },
  premHeader: {
    width: '100%',
    height: '26%',
    backgroundColor: grey[200],
  },
  price: {
    fontSize: 44,
    color: grey[900],
  },
  contact: {
    fontSize: 28,
    color: grey[900],
    textAlign: 'center',
    paddingTop: 15,
    paddingBottom: 9,
  },
  strike: {
    fontSize: 44,
    color: red[800],
  },
  item: {
    textAlign: 'center',
    fontSize: 16,
  },
  itemTitle: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 600,
  },
  caption: {
    textAlign: 'center',
    fontSize: 11,
  },
  buttonContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    padding: theme.spacing(1),
  },
  star: {
    marginLeft: 35,
  },
  paper: {
    paddingTop: 50,
    paddingBottom: 70,
    marginTop: 25,
  }
}))

function Pricing({ getPremium, token, test }) {
  const classes = useStyles()

  const handlePremium = (e) => {
    e.preventDefault()
    getPremium()
  }

  return (
    <div className={classes.background}>
      <Container maxWidth='lg'>
          <h1 className={classes.title}>Pricing</h1>
          <Container maxWidth='md'>
            <h3 className={classes.subtitle}>Our basic license is for the individual who wants to collaborate for the good of the Armed Forces. Premium licenses will be granted for free during beta on a first come first serve basis to qualified businesses.</h3>
          </Container>
          <div style={{padding: 30}} />
          <Grid container direction='row' justify='center' alignItems='flex-end' spacing={5} >
            <Grid item>
              <Paper className={classes.basic}>
                <div className={classes.header}>
                  <h3 className={classes.cardTitle}>Basic</h3>
                  <p className={classes.cardSubtitle}>Individuals</p>
                </div>
                <p className={classes.center}><span className={classes.price}>{`$0`}</span>{`/mo`}</p>
                <p className={classes.item}>Access to some forums</p>
                <p className={classes.item}>Encrypted messaging</p>
                <p className={classes.item}>Personal profile</p>
                <p className={classes.item}>No credit card required</p>
                <div className={classes.buttonContainer}>
                  <Link to='/registration/individual'>
                    <Button fullWidth color='primary' variant='outlined'>Sign up</Button>
                  </Link>
                </div>
              </Paper>
            </Grid>
            <Grid item>
              <Paper className={classes.premium} elevation={4}>
                <div className={classes.premHeader}>
                  <h3 className={classes.premTitle}>Premium<span><StarBorderIcon className={classes.star} /></span></h3>
                  <p className={classes.cardSubtitle}>Small Business, Academia, Entrepreneurs</p>
                </div>
                <p className={classes.center}><strike className={classes.strike}><span className={classes.price}>{`$350`}</span></strike>{`/mo*`}</p>
                <p className={classes.itemTitle}>Everything from Basic plus:</p>
                <p className={classes.item}>Access to premium forums</p>
                <p className={classes.item}>Contact and hire expert Users</p>
                <p className={classes.caption}>*We are giving a free license to qualified early adopters.</p>
                <div className={classes.buttonContainer}>
                  <Link to='/registration/business'>
                    <Button fullWidth color='primary' variant='contained'>Request Access</Button>
                  </Link>
                </div>
              </Paper>
            </Grid>
            <Grid item>
              <Paper className={classes.basic}>
                <div className={classes.header}>
                  <h3 className={classes.cardTitle}>Enterprise</h3>
                  <p className={classes.cardSubtitle}>{`Medium & Large Business`}</p>
                </div>
                <p className={classes.contact}>{`Contact for pricing`}</p>
                <p className={classes.itemTitle}>Everything from Premium plus:</p>
                <p className={classes.item}>Multiple licenses</p>
                <p className={classes.item}>Discounted bulk license rate</p>
                <div className={classes.buttonContainer}>
                  <Button fullWidth color='primary' variant='outlined'>Contact us</Button>
                </div>
              </Paper>
            </Grid>
          </Grid>
      </Container> 
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    token: state.firebase.profile.token
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPremium: () => dispatch(getPremium()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pricing)