import React, { useState, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import { IconButton, Paper } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import green from '@material-ui/core/colors/green'
import purple from '@material-ui/core/colors/purple'
import grey from '@material-ui/core/colors/grey'
import blue from '@material-ui/core/colors/blue'
import {connect} from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle' 
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import CalendarMiddleWare from '../Components/UserComponents/CalendarMiddleWare'
import { hasReadInstructions } from '../../Redux/Actions/userActions'


const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />)

const BlueCheckbox = withStyles({
    root: {
      color: blue[400],
      '&$checked': {
        color: blue[600],
      },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1),
    },
    root: {
        textAlign: 'center',
        marginTop: 150,
    },
    loader: {
        display: 'inlineBlock'
    },
    container: {
        display: 'flex',
    },
    form: {
        width: '100%',
    },
    title: {
        width: '100%',
    },
    header: {
        width: '100%'
    },
    info: {
        margin: theme.spacing(1),
    },
    paragraph: {
        fontSize: 18
    },
    greenCircle: {
        height: 20,
        width: 20,
        backgroundColor: green[300],
        borderRadius: '50%',
    },
    blueCircle: {
        height: 20,
        width: 20,
        backgroundColor: blue[300],
        borderRadius: '50%',
    },
    greyCircle: {
        height: 20,
        width: 20,
        backgroundColor: grey[300],
        borderRadius: '50%',
    },
    purpleCircle: {
        height: 20,
        width: 20,
        backgroundColor: purple[300],
        borderRadius: '50%',
    },
    blackCircle: {
        height: 20,
        width: 20,
        backgroundColor: grey[900],
        borderRadius: '50%',
    },
    calContainer: {
        height: '100vh',
    },  
}))

const dialogStyles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
})

const DialogTitle = withStyles(dialogStyles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    )
})

const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent)
  
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
}))(MuiDialogActions)

function Schedule(props) {
    const classes = useStyles()
    const userId = props.match.params.user_id
    const { auth, profile, hasReadInstructions } = props
    const isOwner = auth.uid === userId
    const [repeating, setRepeating] = useState(false)
    const [weekly, setWeekly] = useState(true)
    const [exception, setException] = useState(false)
    const [openInfo, setOpenInfo] = useState(profile.readSchedHelp ? false : isOwner ? true : false)

    const handleOpenInfo = () => {
      setOpenInfo(true);
    }
    const handleCloseInfo = () => {
      // mark this as read
      hasReadInstructions();
      setOpenInfo(false);
    }

    const handleChecked = (event) => {

        switch (event.target.name) {
            case 'exception':
                switch (exception) {
                    case true:
                        setException(false)
                        setRepeating(false)
                        setWeekly(true)
                        break
                    case false:
                        setException(true)
                        setRepeating(false)
                        setWeekly(false)
                        break
                }
                break
            case 'weekly':
                switch (weekly) {
                    case true:
                        setException(false)
                        setRepeating(true)
                        setWeekly(false)
                        break
                    case false:
                        setException(false)
                        setRepeating(false)
                        setWeekly(true)
                        break
                }
                break
            case 'repeating':
                switch (repeating) {
                    case true:
                        setException(false)
                        setRepeating(false)
                        setWeekly(true)
                        break
                    case false:
                        setException(false)
                        setRepeating(true)
                        setWeekly(false)
                        break
                }
                break     
        
            default:
                break
        }
    }

    const handleNext = (e) => {
        e.preventDefault()
        // calculate the next week
        const nextWeek = moment(currentWeek).add(7, 'days')
        // calculate the doc id for that week
        const weekDayUTC = new Date() 
        weekDayUTC.setFullYear(nextWeek.toDate().getFullYear()) 
        weekDayUTC.setUTCMonth(nextWeek.toDate().getMonth())
        weekDayUTC.setUTCDate(nextWeek.toDate().getDate())
        weekDayUTC.setUTCHours(0)
        weekDayUTC.setUTCMinutes(0)
        weekDayUTC.setUTCSeconds(0)
        weekDayUTC.setUTCMilliseconds(0)
        const nextWeekDocId = weekDayUTC.toUTCString()
        // Set state
        setCurrentWeek(nextWeek)
        setDocId(nextWeekDocId)
    }

    const handlePrevious = (e) => {
        e.preventDefault()
        const prevWeek = moment(currentWeek).subtract(7, 'days')
        // calculate the doc id for that week
        const weekDayUTC = new Date() 
        weekDayUTC.setFullYear(prevWeek.toDate().getFullYear()) 
        weekDayUTC.setUTCMonth(prevWeek.toDate().getMonth())
        weekDayUTC.setUTCDate(prevWeek.toDate().getDate())
        weekDayUTC.setUTCHours(0)
        weekDayUTC.setUTCMinutes(0)
        weekDayUTC.setUTCSeconds(0)
        weekDayUTC.setUTCMilliseconds(0)
        const prevWeekDocId = weekDayUTC.toUTCString()
        // Set state
        setCurrentWeek(prevWeek)
        setDocId(prevWeekDocId)
    }

    // Calculate the monday of the current week 
    const date = new Date()
    const dayOfWeek = date.getDay()
    const offset = dayOfWeek - 1
    const monday = moment().subtract(offset, 'days')
    // Set state for current week
    const [currentWeek, setCurrentWeek] = useState(monday)
    // Calculate the doc id for the current week
    const weekDayUTC = new Date() 
    weekDayUTC.setFullYear(monday.toDate().getFullYear()) 
    weekDayUTC.setUTCMonth(monday.toDate().getMonth())
    weekDayUTC.setUTCDate(monday.toDate().getDate())
    weekDayUTC.setUTCHours(0)
    weekDayUTC.setUTCMinutes(0)
    weekDayUTC.setUTCSeconds(0)
    weekDayUTC.setUTCMilliseconds(0)
    const currentWeekDocId = weekDayUTC.toUTCString()
    // set state for doc id
    const [docId, setDocId] = useState(currentWeekDocId)


    useFirestoreConnect([
        {   collection: 'users', 
            doc: userId,
            storeAs: `${userId}-profile`
        }
    ])
    const ownerProfile = useSelector(state => state.firestore.ordered[`${userId}-profile`])

    useFirestoreConnect([
        { collection: 'users', 
          doc: userId,
          subcollections: [{ collection: 'schedule', 
                             doc: 'repeating',
                          }],
            storeAs: `${props.match.params.user_id}-repeating-schedule`
        }
    ])
    const repeatingSchedule = useSelector(state => state.firestore.ordered[`${props.match.params.user_id}-repeating-schedule`])



    if (!auth.isLoaded) return <div className={classes.root}><CircularProgress className={classes.loader} /> </div>
    // Redirect to home page if user logs out  	
    if (!auth.uid) return <Redirect to='/' />

    if (!ownerProfile || !repeatingSchedule) return <div className={classes.root}><CircularProgress className={classes.loader} /> </div>


    return (
        <Paper className={classes.calContainer}>
            <Paper elevation={0}>
                <Grid container direction='column' justify='flex-start' alignItems='center' className={classes.header}>
                    <Grid item className={classes.title}>
                        <Paper elevation={0} style={{marginTop: 75}}>
                            <Grid container direction='row' justify='center' alignItems='center'>
                                <IconButton onClick={handlePrevious}>
                                    <NavigateBeforeIcon fontSize='large'/>
                                </IconButton>
                                <h3 style={{textAlign: 'center'}}><b>Week of {currentWeek.format('MMMM D')}</b></h3>
                                <IconButton onClick={handleNext}>
                                    <NavigateNextIcon fontSize='large'/>
                                </IconButton>
                            </Grid>
                        </Paper>
                    </Grid>
                    {auth.uid === userId ?
                        <Grid item className={classes.form}>
                            <Paper elevation={0}>
                                <Grid container direction='row' justify='center' alignItems='center'>
                                    <Tooltip title='How to use the scheduler'>     
                                        <IconButton className={classes.info} onClick={handleOpenInfo}>
                                            <InfoOutlinedIcon /> 
                                        </IconButton>       
                                    </Tooltip>
                                    <FormGroup row>
                                        <Tooltip title='Set availabilities week by week' >
                                            <FormControlLabel
                                                control={<GreenCheckbox checked={weekly} onChange={handleChecked} name="weekly" />}
                                                label="One time"
                                            />
                                        </Tooltip>
                                        <Tooltip title='Set availabilities that will repeat weekly' >
                                            <FormControlLabel
                                                control={<BlueCheckbox checked={repeating} onChange={handleChecked} name="repeating" color='primary'/>}
                                                label="Repeating"
                                            />
                                        </Tooltip>
                                        <Tooltip title='Make an exception to a weekly availability'>
                                            <FormControlLabel
                                                control={<Checkbox checked={exception} onChange={handleChecked} name="exception" color='secondary'/>}
                                                label="Exception"
                                            />
                                        </Tooltip>
                                    </FormGroup>
                                </Grid>
                            </Paper>
                        </Grid>
                    :
                        null
                    }
                </Grid>
            </Paper>
            <CalendarMiddleWare
                week={currentWeek} 
                docId={docId}
                key={docId}
                repeating={repeating}
                repeatingSchedule={repeatingSchedule}
                exception={exception}
                isOwner={auth.uid === userId}
                userId={userId}
                ownerProfile={ownerProfile[0]}
            />
            <Dialog onClose={handleCloseInfo} aria-labelledby="customized-dialog-title" open={openInfo} disableScrollLock={true}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseInfo}>
                    How to use the scheduler
                </DialogTitle>
                <DialogContent dividers>
                <p className={classes.paragraph}>
                    The scheduler lets clients know when you are available for a meeting. They will be able to
                    see your availabilities and request a meeting with you during one of these times. Once a meeting
                    is requested you will be able to accept or reject the meeting.
                </p>
                <Grid container direction='row' justify='flex-start' spacing={1}>
                    <Grid item>
                        <p className={classes.paragraph}><b>One time</b></p>
                    </Grid>
                    <Grid item>
                        <div className={classes.greenCircle} />
                    </Grid>
                </Grid>
                <p className={classes.paragraph}>
                    Select the "One time" option in order to make week by week availabilities. 
                    These availabilities will appear green in the scheduler and will not repeat weekly. 
                </p>
                <Grid container direction='row' justify='flex-start' spacing={1}>
                    <Grid item>
                        <p className={classes.paragraph}><b>Repeating</b></p>
                    </Grid>
                    <Grid item>
                        <div className={classes.blueCircle} />
                    </Grid>
                </Grid>
                <p className={classes.paragraph}>
                    Are you available at the same time every week? Use the "Repeating" option to make availabilities
                    which repeat weekly. These will appear blue in the scheduler.   
                </p>
                <Grid container direction='row' justify='flex-start' spacing={1}>
                    <Grid item>
                        <p className={classes.paragraph}><b>Exception</b></p>
                    </Grid>
                    <Grid item>
                        <div className={classes.greyCircle} />
                    </Grid>
                </Grid>
                <p className={classes.paragraph}>
                    Going on vacation? Simply make an exception to your repeating availabilities. Use the "Exception" option
                    to cancel a repeating availability for a given week. This will not affect availabilities for other weeks and can only 
                    be used to cancel repeating availabilites. When an exception has been made, it will appear as grey in the scheduler to 
                    let you know that the repeating availability has been cancelled for that week.    
                </p>
                <Grid container direction='row' justify='flex-start' spacing={1}>
                    <Grid item>
                        <p className={classes.paragraph}><b>Meeting Requests</b></p>
                    </Grid>
                    <Grid item>
                        <div className={classes.purpleCircle} />
                    </Grid>
                </Grid>
                <p className={classes.paragraph}>
                    When a client requests a meeting it will appear purple in the scheduler.
                    Click on that meeting time to see the details of the meeting and confirm or deny.
                    Important! You must confirm the meeting for it to be finalized.
                </p>
                <Grid container direction='row' justify='flex-start' spacing={1}>
                    <Grid item>
                        <p className={classes.paragraph}><b>Scheduled Meetings</b></p>
                    </Grid>
                    <Grid item>
                        <div className={classes.blackCircle} />
                    </Grid>
                </Grid>
                <p className={classes.paragraph}>
                    Once you have confirmed a meeting with a client it will appear black in the scheduler.
                    You can click on it to see the details of the meeting.
                </p>
                <p className={classes.paragraph}><b>Time Zones</b></p>
                <p className={classes.paragraph}>
                    Is your client in a different time zone? Don't worry, your availabilites will be converted to their own time zone
                    automatically. You do not need to know each others time zone in order to schedule a meeting. We take care of that for 
                    you so you can focus on more important things!  
                </p>
                </DialogContent>
                <DialogActions>
                <Button autoFocus onClick={handleCloseInfo} color="primary">
                    Done
                </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
      hasReadInstructions: () => dispatch(hasReadInstructions()),
    }
}


const mapStateToProps = (state) => {
    return {
      auth: state.firebase.auth,
      profile: state.firebase.profile,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Schedule))