import React, { useState, Fragment } from 'react'
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import red from '@material-ui/core/colors/red'
import { connect } from 'react-redux'
import { billUser } from '../../../Redux/Actions/userActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import green from '@material-ui/core/colors/green'
import ErrorIcon from '@material-ui/icons/Error'

const useStyles = makeStyles((theme) => ({
    inputText: {
        width: '50%'
    },
    red: {
        color: red[300]
    },
    success: {
        color: green[300],
    }
  }))

function TimeLog({ userProfile, billUser, paymentRequestStatus }) {
    const classes = useStyles()

    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedEndDate, setSelectedEndDate] = useState(null)
    const [description, setDescription] = useState('')
    const [openConfirm, setOpenConfirm] = useState(false)
    const [requestSent, setRequestSent] = useState(false)

    const handleDateChange = (date) => {
        setSelectedDate(date)
        setSelectedEndDate(date)
    }

    const handleBeginDateChange = (date) => {
      setSelectedDate(date)
    }

    const handleEndDateChange = (date) => {
        setSelectedEndDate(date)
    }

    const handleDescChange = (event) => {
        setDescription(event.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setOpenConfirm(true)
    }

    const handleConfirm = (e) => {
        e.preventDefault()
        setOpenConfirm(false)
        setRequestSent(true)
        const data = {
            hours: getHours(),
            beginDate: selectedDate,
            endDate: selectedEndDate,
            description: description,
            clientId: userProfile.id
        }
        billUser(data)
    }

    const getHours = () => {
        const beginTime = selectedDate.getTime()
        const endTime = selectedEndDate.getTime()
        const diff = endTime - beginTime
        const toBill = diff / 3600000
        return toBill.toFixed(2)
    }

    return (
        <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container direction='row' spacing={1}>
                    <Grid item>
                        <KeyboardDatePicker
                            margin="normal"
                            id='Meeting Date'
                            label="Meeting Date"
                            format="MM/dd/yyyy"
                            value={selectedDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <KeyboardTimePicker
                            margin="normal"
                            id="start-time"
                            label="Start Time"
                            value={selectedDate}
                            onChange={handleBeginDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <KeyboardTimePicker
                            margin="normal"
                            id="end-time"
                            label="End Time"
                            value={selectedEndDate}
                            onChange={handleEndDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container direction='column' spacing={2}>
                    <Grid item>
                        <TextField 
                            label='Brief Description' 
                            multiline 
                            rows={4} 
                            value={description} 
                            onChange={handleDescChange}
                            className={classes.inputText}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item>
                        {requestSent && !paymentRequestStatus ?
                            <CircularProgress />
                        : 
                            (requestSent && paymentRequestStatus ? 
                                (paymentRequestStatus === 'success' ? 
                                    <Grid container direction='row' justify='flex-start' alignItems='center' spacing={2}>
                                        <Grid item>
                                            <CheckCircleIcon className={classes.success} />
                                        </Grid>
                                        <Grid item>
                                            <h4>Sent</h4>
                                        </Grid>
                                    </Grid>
                                :
                                    <Grid container direction='row' justify='flex-start' alignItems='center' spacing={2}>
                                        <Grid item>
                                            <ErrorIcon className={classes.red} />
                                        </Grid>
                                        <Grid item>
                                            <h4>Failed, please try again</h4>
                                        </Grid>
                                    </Grid>
                                )
                            :
                                <Button 
                                    variant='contained' 
                                    color='primary'
                                    onClick={handleSubmit}
                                >
                                    Send Payment Request
                                </Button>
                            )
                        }
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
            <Dialog
                open={openConfirm}
                onClose={() => setOpenConfirm(false)}
            >
                <DialogTitle>{"Confirm Details"}</DialogTitle>
                <DialogContent>
  
                        {selectedDate ? 
                            <Fragment>
                                <p>{`You are logging ${getHours()} hours with ${userProfile.firstName ? userProfile.firstName : userProfile.username} ${userProfile.lastName ? userProfile.lastName : null}`}</p>
                                <p>For: </p>
                                {description !== '' ? 
                                    <p>{`"${description}"`}</p>
                                :
                                    <p className={classes.red}>You gave no description. (It is good to enter a reason for billed time)</p>
                                }
                            </Fragment>
                        :
                            (`Please choose a date in order to proceed`)
                        }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirm(false)} color="primary">
                        Cancel
                    </Button>
                    {selectedDate ? 
                        <Button onClick={handleConfirm} color="primary" autoFocus>
                            Confirm
                        </Button>
                    :
                        null
                    }
                </DialogActions>
            </Dialog>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        billUser: (data) => {dispatch(billUser(data))},
    }
  }

const mapStateToProps = (state) => {
    return {
        paymentRequestStatus: state.user.paymentRequestStatus,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeLog)