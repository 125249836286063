import React, { useState, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import Grid from '@material-ui/core/Grid'
import Circular from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { EThree } from '@virgilsecurity/e3kit-browser'
import firebase from 'firebase'
import { Redirect } from 'react-router-dom'
import red from '@material-ui/core/colors/red'
import CircularProgress from '@material-ui/core/CircularProgress'
import { virgilIncomplete } from '../../../Redux/Actions/authActions'
import { connect } from 'react-redux'

const useStyles = makeStyles(theme => ({
    center: {
        textAlign: 'center',
        maxWidth: 700,
    },
    error: {
        paddingTop: 10,
        color: red[500],
        textAlign: 'center',
    },
}))

function ResetVirgil({ cancelCallback, successCallback, virgilIncomplete }) {
    const classes = useStyles()

    // State
    const [resetSuccess, setResetSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [resetError, setResetError] = useState(null)

    //  Callback to fetch virgil security token
    const getToken = firebase.functions().httpsCallable('getVirgilJwt');
    const initializeFunction = () => getToken().then(result => result.data.token)

    const eThree = useSelector(state => state.auth.eThree)

    const handleReset = (e) => {
        e.preventDefault()

        setLoading(true)
        setResetError(null)
        
        if (!eThree) {
            EThree.initialize(initializeFunction).then(eThree => {
                // rotate the PK
                eThree.resetPrivateKeyBackup()
                    .then(() => {
                        console.log('successful deletion')
                        eThree.rotatePrivateKey()  
                            .then(() => {
                                console.log('successful rotation')
                                setLoading(false)
                                setResetSuccess(true)
                                // change virgil complete to false here
                                virgilIncomplete()
                                    .then(() => {
                                        successCallback()
                                    })
                                    .catch((e) => {
                                        console.error(e)
                                        setResetError('Failed to initialize question reset, key is not backed up')
                                    })
                            })
                            .catch(e => {
                                console.error('failed rotation: ', e)
                            })
                    })
                    .catch(e => {
                        console.error('rotate error: ', e)
                        setLoading(false)
                        setResetError('Failed to reset, please try again')
                    })
            })
        }
        else {
            // rotate the PK
            eThree.resetPrivateKeyBackup()
            .then(() => {
                console.log('successful deletion')
                eThree.rotatePrivateKey()  
                    .then(() => {
                        console.log('successful rotation')
                        setLoading(false)
                        setResetSuccess(true)
                        // change virgil complete to false here
                        virgilIncomplete()
                            .then(() => {
                                successCallback()
                            })
                            .catch((e) => {
                                console.error(e)
                                setResetError('Failed to initialize question reset, key is not backed up')
                            })
                    })
                    .catch(e => {
                        console.error('failed rotation: ', e)
                    })
            })
            .catch(e => {
                console.error('rotate error: ', e)
                setLoading(false)
                setResetError('Failed to reset, please try again')
            })
        }
    }

    return (
        <div style={{paddingBottom: 30}}>
            <h2 className={classes.center}>Encryption Key Reset</h2>
            <h4 className={classes.center}><b>Read this before resetting</b></h4>
            <h5 className={classes.center}>
                The three questions are used to backup your private encryption key so that you can decrypt messages on a new device. 
                In the case that you forget the answers to the questions there is no longer a way to retrieve your 
                encryption key on a new device. You must get a new encryption key in order to continue using the messenger. This new key 
                will not be able to decrypt any past messages. If you must access past messages then you should still be able to do so 
                on any device you have used in the past to send messages. We recommend that you do this before resetting the key since you will 
                not be able to access any past messages once it is reset. We apologize for this inconvenience but we make your security a top priority!
            </h5>
            <Grid container direction='row' justify='center' alignItems='center'>
                {!loading ?
                    <Fragment>
                        <Button onClick={cancelCallback} variant='outlined' color='primary'>Go back</Button>
                        <Button onClick={handleReset}>reset key</Button>
                    </Fragment>
                :
                    <CircularProgress />
                }
            </Grid>
            <p className={classes.error}>{resetError}</p>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        virgilIncomplete: () => dispatch(virgilIncomplete()),
    }
}
  
export default connect(null, mapDispatchToProps)(ResetVirgil)