import React, { useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import GoogleNormal from '../../../Assets/images/google_normal.png'
import GoogleFocus from '../../../Assets/images/google_focus.png'
import GooglePressed from '../../../Assets/images/google_pressed.png'

function GoogleButton({ clickHandler, meeting }) {

    const [hover, setHover] = useState(false)
    const [pressed, setPressed] = useState(false)

    const handleHover = () => {
        setHover(true)
        setPressed(false)
    }

    const handleClick = (e) => {
        e.preventDefault()
        setPressed(true)
        clickHandler(e, meeting)
    }

    return (
        <Tooltip title='Add this meeting to your Google Calendar'>
            <input 
                type='image' 
                onMouseEnter={handleHover} 
                onMouseLeave={() => setHover(false)}
                onClick={handleClick}
                src={pressed ? GooglePressed : hover ? GoogleFocus : GoogleNormal} 
            />
        </Tooltip> 
    )
}

export default GoogleButton