import React, { useState, Fragment } from 'react'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {connect} from 'react-redux'
import Author from '../ForumComponents/Author'
import UserAvatar from '../UserComponents/UserAvatar'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { removeAdmin } from '../../../Redux/Actions/authActions'
import Paper from '@material-ui/core/Paper'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import { getAdmins } from '../../../Redux/Actions/authActions'


const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      maxWidth: 500,
      backgroundColor: theme.palette.background.paper,
    },
    paper: {
        width: '100%',
        maxWidth: 400,
        padding: theme.spacing(5),
    },
    inline: {
      display: 'inline',
    },
    username: {
        paddingLeft: 20,
    },
  }));

function ManageAdmins({ admins, removeAdmin, getAdmins }) {
    const classes = useStyles();
    const [adminToRemove, setadminToRemove] = useState(false)
    const [openRemoveDialog, setOpenRemoveDialog] = useState(false)


    const handleRemove = (e, userId) => {
        e.preventDefault()
        setadminToRemove(userId)
        setOpenRemoveDialog(true)
    }

    const cancelRemove = () => {
        setOpenRemoveDialog(false)
        setadminToRemove(null)
    }

    const removeAction = () => {
        removeAdmin(adminToRemove)
        setadminToRemove(null)
        setOpenRemoveDialog(false)
    }

    if(!admins) {
        getAdmins()
        return (
            <div style={{paddingTop: 115}}>
                <Grid container direction='column' justify='center' alignItems='center'>
                    <Paper className={classes.paper} elevation={2}>
                        <Grid container direction='column' justify='center' alignItems='center'>
                            <h5>Loading Admins</h5>
                            <CircularProgress />
                        </Grid>
                    </Paper>
                </Grid>
            </div>
        )
    }

    return (
        <div style={{paddingTop: 115}}>
            <Container maxWidth='md'>
                <Grid container direction='column' justify='center' alignItems='center'>
                    <Paper className={classes.paper} elevation={2} >
                        <Grid container direction='column' justify='center' alignItems='center'>      
                            <h4>Current users with admin privileges</h4>
                            <h6 style={{textAlign: 'center'}}>*Refresh this page after removing an admin to see changes</h6>
                            <List className={classes.root}>
                                {admins.map((admin, index) => (
                                    <Fragment key={index}>
                                        <ListItem >
                                            <Grid container direction='row' justify='space-between' alignItems='center'>
                                                <Grid item>
                                                    <Grid container direction='row' justify='flex-start' alignItems='center'>
                                                        <UserAvatar userId={admin.userId} />
                                                        <div className={classes.username}>
                                                            <h5><Author authorId={admin.userId} bold={true} link={true} /></h5>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip title="Remove admin privileges" >
                                                        <IconButton onClick={event => handleRemove(event, admin.userId)}>
                                                            <RemoveCircleIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    </Fragment>
                                ))}
                            </List>
                        </Grid>
                    </Paper>
                </Grid>
            </Container>
            <Dialog
                open={openRemoveDialog}
                onClose={() => setOpenRemoveDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Remove Admin?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This user will no longer have admin privileges. 
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={cancelRemove} color="primary">
                    Cancel
                </Button>
                <Button onClick={removeAction} color="primary" autoFocus>
                    Remove
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        admins: state.auth.admins
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        removeAdmin: (userId) => {dispatch(removeAdmin(userId))},
        getAdmins: () => {dispatch(getAdmins())}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageAdmins)