import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
// @material-ui/icons
// import  from "@material-ui/icons/";
// core components
import GridContainer from "../MaterialKitComponents/GridContainer.js";
import GridItem from "../MaterialKitComponents/GridItem.js";
import Card from "../MaterialKitComponents/Card.js";
import CardHeader from "../MaterialKitComponents/CardHeader.js";
import CardBody from "../MaterialKitComponents/CardBody.js";
import CardFooter from "../MaterialKitComponents/CardFooter.js";
import Muted from '../MaterialKitComponents/Muted.js'
import Button from "../MaterialKitComponents/Button.js";

import futureTank from '../../Assets/images/futuretank.jpg'


import teamsStyle from "../../Assets/teamsStyle.js";
import teamStyle from "../../Assets/teamStyle.js";

const style = {
  ...teamsStyle,
  ...teamStyle,
  justifyContentCenter: {
    justifyContent: "center"
  }
};

const useStyles = makeStyles(style);

export default function SectionRAD() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <Grid container direction='row' justify='center' alignItems='center' spacing={5}>
        <Grid item sm>
          <h2 className={classes.title}>Boost Research</h2>
          <h4 className={classes.description}>
            Leverage the expertise and experiences of our warfighters to develop the next 
            generation of military technologies at revolutionary speeds and efficiency. 
            These Users have <i>lived</i> the problem. 
          </h4>
        </Grid>
        <Grid item>
          <Paper elevation={24} style={{borderRadius: '5%'}}>
            <img style={{borderRadius: '5%', maxWidth: '90vw'}} src={futureTank} width='500' />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
