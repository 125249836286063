import React, { useState, useCallback } from 'react'
import Card from './Card'
import update from 'immutability-helper'
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux'
import { updateSectionIndex } from '../../../Redux/Actions/forumActions'

const style = {
  width: 300,
  marginTop: 10,
}
const SectionContainer = ({ forums, updateSectionIndex }) => {
  {

    var stateArray = forums && forums.map(section => ({
      doc_id: section.id,
      title: section.title,
    }))

    const [cards, setCards] = useState(stateArray)

    const handleSave = (e) => {
      e.preventDefault()
      cards.map((card, index) => {
        const section = {
          doc_id: card.doc_id,
          index,
        }
        updateSectionIndex(section)
      })
    } 

    const moveCard = useCallback(
      (dragIndex, hoverIndex) => {
        const dragCard = cards[dragIndex]
        setCards(
          update(cards, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          }),
        )
      },
      [cards],
    )
    const renderCard = (card, index) => {
      return (
        <Card
          key={card.doc_id}
          index={index}
          id={card.doc_id}
          text={card.title}
          moveCard={moveCard}
        />
      )
    }
    return (
      <>
        <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
        <div style={{paddingTop: 15}}>
          <Button 
            color='primary' 
            variant='outlined'
            onClick={handleSave}
          >
            Save Changes
          </Button>
        </div>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateSectionIndex: (section) => {dispatch(updateSectionIndex(section))}
  }
}

export default connect(null, mapDispatchToProps)(SectionContainer)
