import React from 'react'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import Calendar from './Calendar'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
        marginTop: 150,
    },
    loader: {
        display: 'inlineBlock'
    },
}))

function CalendarMiddleWare({ week, docId, repeating, exception, isOwner, userId, ownerProfile, repeatingSchedule }) {
    const classes = useStyles()
    // Fetch Schedule
    useFirestoreConnect([
        { 
            collection: 'users',
            doc: userId,
            subcollections: [{
                collection: 'schedule',
                doc: docId
            }],
            storeAs: `${userId}-${docId}-schedule`
        }
    ])
    // Fetch Scheduled
    useFirestoreConnect([
        { collection: 'users', 
          doc: userId,
          subcollections: [{ collection: 'scheduled', 
                             where: ['weekOf', '==', docId]
                          }],
            storeAs: `${userId}-${docId}-scheduled`
        }
    ])
    // Fetch Requested
    useFirestoreConnect([
        { 
            collection: 'users',
            doc: userId,
            subcollections: [{
                collection: 'requested',
                where: ['weekOf', '==', docId]
            }],
            storeAs: `${userId}-${docId}-requested`
        }
    ])


    const schedule = useSelector(state => state.firestore.ordered[`${userId}-${docId}-schedule`])
    const requested = useSelector(state => state.firestore.ordered[`${userId}-${docId}-requested`])
    const scheduled = useSelector(state => state.firestore.ordered[`${userId}-${docId}-scheduled`])

    if (!schedule || !requested || !scheduled) return <div className={classes.root}><CircularProgress className={classes.loader} /> </div>

    return (
        <Calendar 
            UTCSchedule={schedule}
            weekOf={week}
            docId={docId}
            repeating={repeating}
            repeatingSchedule={repeatingSchedule}
            exception={exception}
            isOwner={isOwner}
            userId={userId}
            requested={requested}
            scheduled={scheduled}
            ownerProfile={ownerProfile}
        />
    )
}

export default CalendarMiddleWare