import React from 'react'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import PrivateTopic from './PrivateTopic'


function PrivateTopics() {

    useFirestoreConnect([
        { collection: 'private_topic' }
    ])
    const topics = useSelector(state => state.firestore.ordered.private_topic)

    if (!topics) return <div />

    return (
        <div>
            {topics.map(topic => (
                <PrivateTopic key={topic.id} topic={topic} />
            ))}
        </div>
    )
}

export default PrivateTopics