import React, { Component, Fragment } from "react";
import { ForumAdmin, 
         Challenges, 
         Profile, 
         Consultants, 
         Forum, 
         Login, 
         Media, 
         Pricing, 
         SignupAsIndividual, 
         Landing, 
         ForumTopics, 
         ForumDiscussion, 
         ChooseRegistrationType,
         SignUpAsBusiness,
         SignUpAsSME,
         Application,
         LostPassword,
         AdminNavigation,
         Session,
         Schedule,
         Meeting,
         Terms,
         Account,
         Billing,
         PaymentRequest,
         PremiumAccess,
         BasicAccess,
         SmeAccess,
         PremiumGrantedMessage,
         VirgilSetup,
         UserAccessGranted,
         Privacy,
         AboutUs,
         NewDeviceConfirmation,
         VerifyEmailReminder,
         Contracts,
         LogTime,
         Jobs,
         JobListings,
         ProposalMW,
         DisplayProposals,
         DisplayUserProposals,
         ViewTime
        } from './Pages'
import { Navigation } from './Components'
import BottomAppBar from '../Components/Components/MessagingComponents/BottomAppBar'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { makeStyles, createMuiTheme, ThemeProvider  } from '@material-ui/core/styles'
import '../MkAssets/scss/material-kit-pro-react.scss?v=1.8.0'
import grey from '@material-ui/core/colors/grey'
import blue from '@material-ui/core/colors/blue'
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'


const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Roboto',
      '"Helvetica"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      light: blue[700],
      main: blue[800],
      dark: blue[900],
    },
    secondary: {
      light: grey[800],
      main: grey[900],
    },
    action: {
      light: green[500],
      main: green[700],
      dark: green[900],
    },
    link: {
      light: red[600],
      main: '#cc2127',
      dark: red[900]
    },
    default: {
      main: grey[100],
    },
  },
});


function App() {
  

  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Router>
          <Navigation />
          <div style={{background: grey[300], paddingTop: 0, paddingBottom: 0, minHeight: '100vh' }}>
            <Switch>
              <Route exact path='/' component={Landing} />
              <Route path='/admin/:location?' exact component={AdminNavigation} />
              <Route path='/experts' component={Consultants} />
              {/*<Route path='/about' component={AboutUs} />*/}
              <Route path='/forgot-pw' component={LostPassword} />
              <Route path='/verify-email' component={VerifyEmailReminder} />
              <Route path='/forum' exact component={Forum} />
              <Route path='/login' component={Login} />
              <Route path='/pricing' component={Pricing} />
              <Route path='/terms' component={Terms} />
              <Route path='/privacy' component={Privacy} />
              <Route path='/premium-access' component={PremiumAccess} />
              <Route path='/basic-access' component={BasicAccess} />
              <Route path='/sme-access' component={SmeAccess} />
              <Route path='/messenger-setup' component={VirgilSetup} />
              <Route path='/new-device' component={NewDeviceConfirmation} />
              <Route path='/premium-granted' component={PremiumGrantedMessage} />
              <Route path='/user-access' component={UserAccessGranted} />
              <Route path='/consultant-application/:user_id/:from_login?' exact component={Application} />
              <Route path='/profile/:user_id' exact component={Profile} />
              <Route path='/account/:user_id' exact component={Account} />
              <Route path='/contracts/:user_id/:tab?' exact component={Contracts} />
              <Route path='/log-time/:user_id/:job_id' exact component={LogTime} />
              <Route path='/view-time/:user_id/:job_id' exact component={ViewTime} />
              <Route path='/session/:user_id' exact component={Session} />
              <Route path='/schedule/:user_id' exact component={Schedule}/>
              <Route path='/jobs' exact component={Jobs}/>
              <Route path='/job-listings/:user_id/:tab?' exact component={JobListings}/>
              <Route path='/proposal/:job_id' exact component={ProposalMW}/>
              <Route path='/proposals/:job_id' exact component={DisplayProposals}/>
              <Route path='/proposals/user/:user_id/:tab?' exact component={DisplayUserProposals}/>
              {/* Add these routes to enable billing and payments
              <Route path='/billing/:user_id' exact component={Billing}/>
              <Route path='/payment-request/:user_id/:doc_id?' exact component={PaymentRequest}/>
              */}
              <Route path='/meeting/:meeting_id/:user_id' exact component={Meeting}/>
              <Route path='/choose-registration' exact component={ChooseRegistrationType} />
              <Route path='/registration/individual' component={SignupAsIndividual} />
              <Route path='/registration/business' component={SignUpAsBusiness} />
              <Route path='/choose-registration/Consultant' component={SignUpAsSME} />
              <Route path='/forum/:forum_id/:genre_id' exact component={ForumTopics} />
              <Route path='/forum/:forum_id/:genre_id/:topic_id' exact component={ForumDiscussion} />
            </Switch>
          </div>
          <BottomAppBar />
        </Router>
      </Fragment>
    </ThemeProvider>
    );
}
export default App

