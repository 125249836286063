import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect } from 'react'

const useStyles = makeStyles({
    message: {
        wordWrap: 'break-word',
        maxWidth: 300,
        whiteSpace: 'pre-wrap',
    },
})

function DecryptedContent({ message, scrollToBottom, index, msgCount }) {
    const classes = useStyles()

    useEffect(() => {
        if (msgCount === index || msgCount <= 10) 
            scrollToBottom()
    }, [])

    return (
        <p className={classes.message}>{message}</p>
    )
}

export default DecryptedContent;