import React, { Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import {Link} from "react-router-dom";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles({
  link: { // gws 2/12/20
    textDecoration: 'none',
    color: grey[900],
    "&:hover": {
      color: grey[900],
      textDecoration: 'underline'
    },
    "&:visited": {
      color: grey[900]
    },
    "&:active": {
      color: grey[900]
    },
    "&:link": {
      color: grey[900]
    },
    "&:link:active": {
      color: grey[900]
    },
    "&:visited:active": {
      color: grey[900]
    },
  },
});

function Author({ authorId, author, bold, link, styles, textStyles, textOnly, addText, addTextBegin }) {
  const classes = useStyles()

  if (!author) {
    return null
  }

  if (textOnly) {
      if (author.firstName && author.lastName) {
        var string = `${author.firstName} ${author.lastName}`
        if (addText) {
          string = string + ' ' + addText
        }
        if (addTextBegin) {
          string = addTextBegin + ' ' + string
        }
        return string
      } else {
        var string = author.username
        if (addText) {
          string = string + ' ' + addText
        }
        if (addTextBegin) {
          string = addTextBegin + ' ' + string
        }
        return string
      }
  }
  
  return  (
    <Fragment>
      {link ? 
        <Link to={`/profile/${authorId}`} className={styles ? styles : classes.link}>
          {textStyles ?
            (bold ?
              <p className={textStyles}><b>{author.firstName && author.lastName ? `${author.firstName} ${author.lastName}` : author.username}</b></p>
            :
              <p className={textStyles}>{author.firstName && author.lastName ? `${author.firstName} ${author.lastName}` : author.username}</p>
            )
          :
            (bold ?
              <Fragment><b>{author.firstName && author.lastName ? `${author.firstName} ${author.lastName}` : author.username}</b></Fragment>
            :
              <Fragment>{author.firstName && author.lastName ? `${author.firstName} ${author.lastName}` : author.username}</Fragment>
            )
          }
        </Link>
      :
        <Fragment>
          {textStyles ? 
            (bold ?
              <p className={textStyles}><b>{author.firstName && author.lastName ? `${author.firstName} ${author.lastName}` : author.username}</b></p>
            :
              <p className={textStyles}>{author.firstName && author.lastName ? `${author.firstName} ${author.lastName}` : author.username}</p>
            )
          :
            (bold ?
              <Fragment><b>{author.firstName && author.lastName ? `${author.firstName} ${author.lastName}` : author.username}</b></Fragment>
            :
              <Fragment>{author.firstName && author.lastName ? `${author.firstName} ${author.lastName}` : author.username}</Fragment>
            )
          }
        </Fragment>
      }
    </Fragment>
  )
}

export default compose(
  firestoreConnect((props) => [
    { collection: 'users', doc: props.authorId } // or `todos/${props.todoId}`
  ]),
  connect(({ firestore: { data } }, props) => ({
    author: data.users && data.users[props.authorId]
  }))
 )(Author) 