import React, { useState, Fragment } from 'react';
import { makeStyles, withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import TablePaginationActions from './TablePaginationActions' 
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import TopicAdminActionButtons from './TopicAdminActionButtons'
import { NewTopicButton } from '..';
import moment from 'moment'
import CircularProgress from '@material-ui/core/CircularProgress';
import firebase from 'firebase/app'
import grey from '@material-ui/core/colors/grey';


const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
  link: { // gws 2/12/20
    textDecoration: 'none',
    color: grey[900],
    "&:hover": {
      color: grey[900],
      textDecoration: 'underline'
    },
    "&:visited": {
      color: grey[900]
    },
    "&:active": {
      color: grey[900]
    },
    "&:link": {
      color: grey[900]
    },
    "&:link:active": {
      color: grey[900]
    },
    "&:visited:active": {
      color: grey[900]
    },
  },
  container: {
    paddingBottom: 50,
  },
  loader: {
    display: 'inlineBlock'
  }, 
  rootSpinner: {
    textAlign: 'center',
    marginTop: '50px',
  }
});


const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

function TopicsTable({ forum_doc_id, genre_doc_id, topics, token }) {

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [admin, setAdmin] = useState(false)
  
  firebase.auth().onAuthStateChanged(user => {
    if (user) {
      user.getIdTokenResult().then(idTokenResult => {
        setAdmin(idTokenResult.claims.admin)
      }).catch(error => {
        console.log(error)
      })
    }
    else {
      setAdmin(false)
    }
  })

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  if (!topics) {
    return <div className={classes.rootSpinner}><CircularProgress className={classes.loader} /> </div>
  }

  var publicTopics = []
  for (var i = 0; i < topics.length; i++) {
    if (!topics[i].isPrivate) {
      publicTopics.push(topics[i])
    }
  }

  return (
      <TableContainer component={Paper} className={classes.container}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead className={classes.table} aria-label='Topics table' >
            <TableRow>
              <StyledTableCell>
                <NewTopicButton
                  forum_doc_id={forum_doc_id}
                  genre_doc_id={genre_doc_id}  
                />
              </StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
              {admin ? <StyledTableCell align="right"></StyledTableCell> : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? publicTopics.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : publicTopics
            ).map(topic => (
              <StyledTableRow key={topic.id}>
                <StyledTableCell component="th" scope="row">
                  <Link 
                    to={'/forum/' + forum_doc_id + '/' + genre_doc_id + '/' + topic.id} 
                    className={classes.link}
                  >
                    <Typography variant="body1" className={classes.title}>
                      {topic.title}
                    </Typography>
                </Link>
                </StyledTableCell>
                <StyledTableCell size='small' align="right">
                  {topic.lastComment ? 
                    <Fragment>
                      {moment(topic.lastComment.toDate().toString()).calendar()} by {topic.lastAuthor} 
                    </Fragment>
                  :
                    null
                  }
                </StyledTableCell> 
                <StyledTableCell size='small' align="right">
                <ul style={ {listStyleType: 'none', padding: '0px', margin: '0px'} }>
                    <li>
                      {topic.totalComments === 1 ? 
                        <Fragment>{topic.totalComments} post</Fragment> 
                        : 
                        <Fragment>{topic.totalComments} posts</Fragment>
                      }
                    </li>
                    <li>
                      {topic.views.length === 1 ? 
                        <Fragment>{topic.views.length} view</Fragment> 
                      :
                        <Fragment>{topic.views.length} views</Fragment> 
                      }
                    </li>
                  </ul>
                </StyledTableCell>
                {admin ?
                  <StyledTableCell size='small' align="right">
                    <TopicAdminActionButtons 
                      title={topic.title}
                      topic={topic}
                      forum_doc_id={forum_doc_id}
                      genre_doc_id={genre_doc_id}
                      topic_doc_id={topic.id}
                    />  
                  </StyledTableCell>
                :
                  null
                }
              </StyledTableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={admin ? 4 : 3}
                count={topics.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
  );
            
}
export default compose(
  firestoreConnect((props) => [
    { collection: 'forum', 
      doc: props.forum_doc_id, 
      subcollections: [{collection: 'genre',
                        doc: props.genre_doc_id,
                        subcollections: [
                          {
                            collection: 'topic',
                            where: [
                              ['isPrivate', '==', false],
                            ],
                            orderBy: [
                              ['createdAt', 'desc'],
                            ],
                          }
                        ]
                      }],
      storeAs: `${props.genre_doc_id}-topics`
    }
  ]),
  connect((state, props) => ({
    topics: state.firestore.ordered[`${props.genre_doc_id}-topics`],
  }))
 )(TopicsTable)